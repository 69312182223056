// This file is automatically generated. Do not edit manually.

import { CustomerOrderOfferLine } from "src/api/generated/erp/db/types/tables/customerOrderOfferLine";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { IsoDateString } from "src/types/dateTime";
import { CustomerOrderOffer } from "src/api/generated/erp/db/types/tables/customerOrderOffer";
import { CustomerOrderOfferLineView } from "src/api/generated/erp/db/types/tables/customerOrderOfferLineView";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderOfferLineEditApi {
	export async function getFormInitData(args: {
		customerOrderOfferId: number;
		customerOrderOfferLineId: number | null | undefined;
		parentLineId: number | null | undefined;
	}): Promise<CustomerOrderOfferLineEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/lines/getFormInitData",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
				customerOrderOfferLineId: args.customerOrderOfferLineId,
				parentLineId: args.parentLineId,
			},
		});
		return response as CustomerOrderOfferLineEditApi_FormInitData;
	}

	export async function insert(args: { offerLine: CustomerOrderOfferLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/lines/insert",
			method: "POST",
			data: {
				offerLine: args.offerLine,
			},
		});
		return response as number;
	}

	export async function update(args: { offerLine: CustomerOrderOfferLine }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/lines/update",
			method: "POST",
			data: {
				offerLine: args.offerLine,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerOrderOfferLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/lines/delete",
			method: "POST",
			data: {
				customerOrderOfferLineIds: args.customerOrderOfferLineIds,
			},
		});
		return response as void;
	}

	export async function getSalesPartOptions(args: {
		customerOfferId: number;
		searchQuery: string;
		currentSelection: number | null | undefined;
	}): Promise<Array<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/lines/getSalesPartOptions",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
			},
		});
		return response as Array<SalesPartView>;
	}

	export async function getSalesPartDefaultPrice(args: {
		salesPartId: number;
		customerOfferId: number;
		withVat: boolean;
		vatCodeId: number;
		plannedDeliveryDate: IsoDateString;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/lines/getSalesPartDefaultPrice",
			method: "POST",
			data: {
				salesPartId: args.salesPartId,
				customerOfferId: args.customerOfferId,
				withVat: args.withVat,
				vatCodeId: args.vatCodeId,
				plannedDeliveryDate: args.plannedDeliveryDate,
			},
		});
		return response as number;
	}
}

export interface CustomerOrderOfferLineEditApi_FormInitData {
	defaultLineNumber: number;
	offer: CustomerOrderOffer;
	offerLine: CustomerOrderOfferLineView | null | undefined;
	parentLine: CustomerOrderOfferLine | null | undefined;
	salesPart: SalesPartView | null | undefined;
	vatCodeOptions: Array<VatCode>;
}
