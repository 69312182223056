import { CatalogPartRevisionAttributeRulesApi_AttributeRuleDto } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionAttributeRulesApi.ts";
import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useState } from "react";
import { CatalogPartRevisionAttributeRulesDataGrid } from "src/components/views/erp/configurator/managing/catalogPart/attributeRules/CatalogPartRevisionAttributeRulesDataGrid.tsx";
import { AavoCodeMirror } from "src/components/common/codeMirror/AavoCodeMirror.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { CatalogPartRevisionUnrestrictedEditingWarning } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionUnrestrictedEditingWarning.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";

export interface CatalogPartRevisionAttributeRulesViewProps {
	catalogPartRevision: CatalogPartRevisionView;
}

export const CatalogPartRevisionAttributeRulesView = ({
	catalogPartRevision,
}: CatalogPartRevisionAttributeRulesViewProps) => {
	const [selectedRule, setSelectedRule] = useState<CatalogPartRevisionAttributeRulesApi_AttributeRuleDto | undefined>(
		undefined,
	);
	return (
		<VerticalBox flex={1}>
			<CatalogPartRevisionUnrestrictedEditingWarning revision={catalogPartRevision} />
			<AavoMosaic
				viewId={"CADDA7EA89969CB0"}
				layout={{
					type: "row",
					first: {
						type: "panel",
						title: i18n.t("attributes"),
						content: (
							<CatalogPartRevisionAttributeRulesDataGrid
								catalogPartRevision={catalogPartRevision}
								onSelectionChanged={setSelectedRule}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("function"),
						content:
							selectedRule == null ?
								<CenteredTypography>{i18n.t("select_attribute")}</CenteredTypography>
							:	<AavoCodeMirror
									key={selectedRule?.catalogPartRevisionAttributeConfigurationRuleId}
									value={selectedRule?.ruleScript}
									disabled
								/>,
					},
				}}
			/>
		</VerticalBox>
	);
};
