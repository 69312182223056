import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import i18n from "i18next";
import { ScrappingForm } from "src/components/views/erp/warehouse/inventory/manualEventViews/impl/ScrappingForm.tsx";
import { faArrowRight, faBalanceScale, faMinus, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { ManualWithdrawalForm } from "src/components/views/erp/warehouse/inventory/manualEventViews/impl/ManualWithdrawalForm.tsx";
import { ManualInventoryForm } from "src/components/views/erp/warehouse/inventory/manualEventViews/impl/ManualInventoryForm.tsx";
import { ManualInputForm } from "src/components/views/erp/warehouse/inventory/manualEventViews/impl/ManualInputForm.tsx";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Box, Grid, useTheme } from "@mui/material";
import { AavoButton } from "../../../../../common/buttons/AavoButton.tsx";
import { CenteredTypography } from "../../../../../common/CenteredTypography.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ManualTransferForm } from "src/components/views/erp/warehouse/inventory/manualEventViews/impl/ManualTransferForm.tsx";

export const ManualInventoryActionsView = () => {
	const theme = useTheme();

	return (
		<Grid
			container
			spacing={theme.spacing(1)}
			flex={1}
			alignItems={"stretch"}
			margin={1}
			width={"unset"}
			overflow={"auto"}
		>
			<ManualInventoryButton
				icon={faMinus}
				label={i18n.t("withdrawal")}
				form={(props) => <ManualWithdrawalForm {...props} />}
			/>
			<ManualInventoryButton
				icon={faPlus}
				label={i18n.t("input")}
				form={(props) => <ManualInputForm {...props} />}
			/>
			<ManualInventoryButton
				icon={faBalanceScale}
				label={i18n.t("inventory")}
				form={(props) => <ManualInventoryForm {...props} />}
			/>
			<ManualInventoryButton
				icon={faTrash}
				label={i18n.t("scrapping")}
				form={(props) => <ScrappingForm {...props} />}
			/>
			<ManualInventoryButton
				icon={faArrowRight}
				label={i18n.t("transfer")}
				form={(props) => <ManualTransferForm {...props} />}
			/>
		</Grid>
	);
};

interface ManualInventoryButtonProps {
	icon: IconDefinition;
	label: string;
	title?: string;
	form: (commonProps: FormCommonProps<void>) => JSX.Element;
}

const ManualInventoryButton = ({ icon, label, form }: ManualInventoryButtonProps) => {
	const { openDialog } = useGenericDialog();
	const { showToast } = useAavoToast();

	return (
		<Grid item xs={12} sm={6} display={"flex"} alignItems={"stretch"}>
			<AavoButton
				variant={"outlined"}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						size: "sm",
						title: label,
						content: form({
							onCompleted: async () => {
								showToast({ title: i18n.t("saved"), message: i18n.t("event_saved") });
								await closeDialog();
							},
						}),
					}));
				}}
				sx={{
					borderWidth: "3px",
					flex: 1,
				}}
				children={
					<Box>
						<FontAwesomeIcon
							icon={icon}
							style={{
								fontSize: "3rem",
							}}
						/>
						<CenteredTypography
							sx={{
								fontSize: "2rem",
							}}
						>
							{label}
						</CenteredTypography>
					</Box>
				}
			/>
		</Grid>
	);
};
