import { Typography } from "@mui/material";
import i18n from "i18next";
import { PartRevisionView } from "src/api/generated/erp/db/types/tables/partRevisionView.ts";

export interface SitePartRevisionUnrestrictedEditingWarningProps {
	revision: PartRevisionView | undefined | null;
}

export const SitePartRevisionUnrestrictedEditingWarning = ({
	revision,
}: SitePartRevisionUnrestrictedEditingWarningProps) => {
	if (revision == null) return;
	if (revision.partRevisionState !== "RELEASED") return;
	if (!revision.unrestrictedEditingCascaded) return;

	return (
		<Typography
			variant={"subtitle1"}
			color={"error"}
			children={i18n.t("part_revision_unrestricted_editing_warning")}
			sx={{
				marginY: 0.5,
				marginX: 1.5,
			}}
		/>
	);
};
