import { DeliveryPackageView } from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import {
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { DeliveryPackageForm } from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackageForm.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCalculator, faCheck, faPrint, faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { TypedGridSortModel } from "src/components/common/dataGrid/types.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { DeliveryPackageLinesDataGrid } from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackageLinesDataGrid.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { ReactNode } from "react";
import { DeliveryPackageDataGridApi } from "src/api/generated/erp/delivery/api/deliveryPackageDataGridApi.ts";
import { DeliveryPackageApi } from "src/api/generated/erp/delivery/api/deliveryPackageApi.ts";
import {
	DeliveryState,
	DeliveryStateValues,
	getDeliveryStateLabel,
	getDeliveryStateLabels,
} from "src/api/generated/erp/db/types/enums/deliveryState.ts";
import {
	DeliveryPackageState,
	getDeliveryPackageStateLabels,
} from "src/api/generated/erp/db/types/enums/deliveryPackageState.ts";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import { OpenDeliveryViewButton } from "src/components/views/erp/utilComponents/OpenDeliveryViewButton.tsx";
import { createNullableDeliverySource } from "src/components/views/erp/delivery/deliverySourceUtils.ts";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import { DeliverySourceType } from "src/api/generated/erp/db/types/enums/deliverySourceType.ts";

export interface DeliveryPackagesDataGridProps extends RefreshableElementProps {
	gridId: string;
	onSelectedPackageChanged?: (row: DeliveryPackageView[] | []) => void;
	deliverySourceType?: DeliverySourceType;
	deliverySource?: DeliverySource;
	deliveryId?: number | null;
	onlyPackagesWithoutConnectedDelivery?: boolean;
	packageStates?: DeliveryPackageState[];
	deliveryPackageId?: number | null;
	showSourceDocumentsButton?: boolean;
	selectedRows?: DeliveryPackageView[];
	showSearchField?: boolean;
	showDeliveryStateSelection?: boolean;
	showPrintPackageLabelButton?: boolean;
	showCalculateWeightButton?: boolean;
	sortModel?: TypedGridSortModel<DeliveryPackageView>;
	addEnabled?: boolean;
	editable?: boolean;
	showStateChangeActions?: boolean;
	onStateChanged?: () => void;
	disablePagination?: boolean;
	actionBarExtraComponents?: ReactNode;
}

export const DeliveryPackagesDataGrid = ({
	gridId,
	onSelectedPackageChanged,
	packageStates = [],
	deliverySource,
	deliverySourceType,
	deliveryId,
	onlyPackagesWithoutConnectedDelivery,
	deliveryPackageId = null,
	selectedRows,
	refreshRef,
	onStateChanged,
	addEnabled = false,
	editable = false,
	showDeliveryStateSelection = false,
	showSearchField = false,
	showPrintPackageLabelButton = false,
	showCalculateWeightButton = false,
	showStateChangeActions = false,
	showSourceDocumentsButton = false,
	disablePagination = false,
	actionBarExtraComponents = null,
}: DeliveryPackagesDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const { defaultSiteId } = useGlobalInitData();

	const { dataGridProps, refreshData, currentParams, onlySelectedRow } = useServerSideDataGridModel({
		refreshRef: refreshRef,
		gridId: gridId + "_v2",
		fetchData: (params) =>
			DeliveryPackageDataGridApi.getDeliveryPackages({
				source: deliverySource,
				sourceType: deliverySourceType,
				deliveryId: deliveryId,
				packageState: packageStates,
				deliveryPackageId: deliveryPackageId,
				onlyPackagesWithoutConnectedDelivery: onlyPackagesWithoutConnectedDelivery,
				...params,
			}),
		initialParams: {
			searchQuery: "",
			deliveryState: genericNullableValue<DeliveryState>(),
			customerId: genericNullableValue<number>(),
			siteId: genericNullableValue<number>(defaultSiteId),
		},
		getRowId: (row) => row.deliveryPackageId,
		onSelectionChanged: (selectedRows) => {
			onSelectedPackageChanged?.(selectedRows);
		},
		selectedRows: selectedRows,
		usePagination: !disablePagination,
		selectFirstRowOnLoad: true,
	});

	return (
		<ControlledAsyncCrudDataGrid<DeliveryPackageView>
			{...dataGridProps}
			columns={[
				deliverySource == null && [
					integerColumn({
						field: "sourceId",
						headerName:
							deliverySourceType === "PROJECT" ? i18n.t("project_no")
							: deliverySourceType === "CUSTOMER_ORDER" ? i18n.t("order_no")
							: i18n.t("source_id"),
						width: 60,
					}),
					textColumn({
						field: "sourceDescription",
						headerName:
							deliverySourceType === "PROJECT" ? i18n.t("project")
							: deliverySourceType === "CUSTOMER_ORDER" ? i18n.t("customer_order")
							: i18n.t("source"),
						width: 200,
					}),
				],
				integerColumn({
					field: "packageNo",
					headerName: i18n.t("package_no"),
					width: 80,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
				}),
				textColumn({
					field: "packageTypeName",
					headerName: i18n.t("type"),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getDeliveryPackageStateLabels(),
					width: 125,
				}),
				textColumn({
					field: "warehouseLocationCode",
					headerName: i18n.t("warehouse_location_short"),
					width: 80,
				}),
				floatColumn({
					field: "weight",
					headerName: i18n.t("weight"),
					width: 75,
				}),
				floatColumn({
					field: "length",
					headerName: i18n.t("length"),
					width: 75,
				}),
				floatColumn({
					field: "width",
					headerName: i18n.t("width"),
					width: 75,
				}),
				floatColumn({
					field: "height",
					headerName: i18n.t("height"),
					width: 75,
				}),
				enumColumn({
					field: "deliveryState",
					headerName: i18n.t("delivery_state"),
					enumLabels: getDeliveryStateLabels(),
					width: 125,
				}),
				textColumn({
					field: "sourcePostalCode",
					headerName: i18n.t("postal_code"),
				}),
				textColumn({
					field: "sourceCity",
					headerName: i18n.t("city"),
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 150,
				}),
				dateTimeColumn({
					field: "pickedAt",
					headerName: i18n.t("picked_at"),
					width: 150,
				}),
				integerColumn({
					field: "deliveryPackageId",
					headerName: i18n.t("id"),
					width: 75,
				}),
			]}
			form={{
				dialogSize: "md",
				dialogTitle: i18n.t("package"),
				addRowEnabled: deliverySource != null && addEnabled,
				editEnabled: editable,
				component: ({ row, onCompleted, onFormEdited }) => {
					const deliverySourceFromPropsOrRow =
						deliverySource ??
						createNullableDeliverySource({
							sourceType: row?.sourceType,
							sourceId: row?.sourceId,
						});
					if (deliverySourceFromPropsOrRow == null) {
						console.error("deliverySource should not be null here");
						return null;
					}
					return (
						<DeliveryPackageForm
							deliverySource={deliverySourceFromPropsOrRow}
							deliveryPackageId={row?.deliveryPackageId}
							onCompleted={(result) => {
								onCompleted(result);
							}}
							onFormEdited={onFormEdited}
						/>
					);
				},
			}}
			remove={
				editable ?
					{
						type: "enabled",
						action: async (params) => {
							await DeliveryPackageDataGridApi.delete_({
								deliveryPackages: params.items,
							});
						},
					}
				:	undefined
			}
			actionBarComponents={
				<>
					{deliveryId == null && deliverySource == null && (
						<SelectSiteField value={currentParams.siteId} onChange={(siteId) => refreshData({ siteId })} />
					)}
					{showDeliveryStateSelection && (
						<SelectField
							label={i18n.t("delivery_state")}
							options={DeliveryStateValues}
							getOptionKey={(option) => option}
							getOptionLabel={(option) => getDeliveryStateLabel(option)}
							onChange={async (value) => {
								await refreshData({ deliveryState: value });
							}}
						/>
					)}
					{showSearchField && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={async (input) => {
								await refreshData({ searchQuery: input });
							}}
						/>
					)}
					{showPrintPackageLabelButton && (
						<AsyncButton
							icon={faPrint}
							label={i18n.t("package_label")}
							variant={"outlined"}
							disabled={() => {
								if (!onlySelectedRow) return i18n.t("select_line");
								return false;
							}}
							onClick={async () => {
								if (!onlySelectedRow) return;
								const fileHandle = await DeliveryPackageApi.printDeliveryPackageLabel({
									deliveryPackageId: onlySelectedRow.deliveryPackageId,
								});
								downloadFile(fileHandle);
							}}
						/>
					)}
					{showCalculateWeightButton && (
						<AsyncButton
							icon={faCalculator}
							label={i18n.t("calculate_weight")}
							variant={"outlined"}
							disabled={() => {
								if (!onlySelectedRow) return i18n.t("select_line");
								return false;
							}}
							onClick={async () => {
								if (!onlySelectedRow) return;
								await DeliveryPackageApi.calculateDeliveryPackageWeight({
									deliveryPackageId: onlySelectedRow.deliveryPackageId,
								});
								await refreshData();
							}}
						/>
					)}
					{showStateChangeActions &&
						(onlySelectedRow == undefined || onlySelectedRow.state === "INITIAL") && (
							<AsyncButton
								label={i18n.t("mark_picked")}
								variant={"outlined"}
								icon={faCheck}
								disabled={!onlySelectedRow ? i18n.t("select_line") : false}
								onClick={async () => {
									if (!onlySelectedRow) return;
									await DeliveryPackageApi.setPackageStateToPicked({
										deliveryPackageId: onlySelectedRow.deliveryPackageId,
									});
									onStateChanged?.();
									await refreshData();
								}}
							/>
						)}
					{showStateChangeActions && onlySelectedRow?.state === "COMPLETED" && (
						<AsyncButton
							label={i18n.t("return_open")}
							variant={"outlined"}
							icon={faUndo}
							onClick={async () => {
								if (!onlySelectedRow) return;
								await DeliveryPackageApi.setPackageStateToUnpicked({
									deliveryPackageId: onlySelectedRow.deliveryPackageId,
								});
								onStateChanged?.();
								await refreshData();
							}}
						/>
					)}
					{actionBarExtraComponents}
				</>
			}
			rowContextMenuComponents={({ row }) => [
				row.sourceType === "CUSTOMER_ORDER" && (
					<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.sourceId} />
				),
				showSourceDocumentsButton && row.sourceType === "CUSTOMER_ORDER" && row.sourceId != null && (
					<DocumentsOfObjectButton
						key={"orderDocuments"}
						label={i18n.t("order_documents")}
						objectRef={{
							objectType: "CUSTOMER_ORDER",
							objectId: row.sourceId,
						}}
						editable={"onlyOfficeForExistingDocuments"}
						variant={"menu"}
					/>
				),
				row.deliveryId != null && (
					<OpenDeliveryViewButton
						key={"openDelivery"}
						onlyDeliveryId={row.deliveryId}
						deliverySource={createNullableDeliverySource({
							sourceType: row?.sourceType,
							sourceId: row?.sourceId,
						})}
					/>
				),
				<AsyncMenuButton
					key={"openPackageLines"}
					label={i18n.t("package_lines")}
					icon={faShare}
					onClick={() => {
						openDialog(() => ({
							title: i18n.t("package_lines"),
							size: "xl",
							content: <DeliveryPackageLinesDataGrid deliveryPackage={row} />,
						}));
					}}
				/>,
			]}
		/>
	);
};
