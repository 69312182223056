import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
    coloredBooleanColumn,
    dateColumn,
    enumColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getSalesContractStateLabels} from "src/api/generated/erp/db/types/enums/salesContractState.ts";
import {
    CustomerSalesContractsDataGridApi,
    CustomerSalesContractsDataGridApi_SalesContractDto,
} from "src/api/generated/erp/sales/pricing/api/customerSalesContractsDataGridApi.ts";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    CustomerSalesContractForm
} from "src/components/views/erp/sales/pricing/salesContracts/CustomerSalesContractForm.tsx";
import {CustomerSalesContractEditApi} from "src/api/generated/erp/sales/pricing/api/customerSalesContractEditApi.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faBan, faShare} from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";

export interface CustomerSalesContractsDataGridProps {
	customerId: number;
	onSelectionChanged: (contract: CustomerSalesContractsDataGridApi_SalesContractDto | null) => void;
}

export const CustomerSalesContractsDataGrid = ({
	customerId,
	onSelectionChanged,
}: CustomerSalesContractsDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	return (
		<ClientSideDataGridModel
			gridId={"EF48521D1011802F8"}
			fetchData={() => CustomerSalesContractsDataGridApi.getSalesContracts({ customerId: customerId })}
			getRows={(data) => data}
			onSelectionChanged={(rows) => onSelectionChanged(rows?.[0] ?? null)}
			getRowId={(row) => row.salesContractId}
			initialParams={{}}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => {
				return (
					<CrudDataGrid<CustomerSalesContractsDataGridApi_SalesContractDto>
						disableMultipleRowSelection={true}
						columns={[
							textColumn({
								field: "name",
								headerName: i18n.t("name"),
								width: 170,
							}),
							coloredBooleanColumn({
								field: "isActive",
								headerName: i18n.t("active"),
								falseColor: "red",
							}),
							enumColumn({
								field: "state",
								headerName: i18n.t("state"),
								enumLabels: getSalesContractStateLabels(),
								width: 130,
							}),
							dateColumn({
								field: "activeFrom",
								headerName: i18n.t("active_from"),
								width: 150
							}),
							dateColumn({
								field: "activeTo",
								headerName: i18n.t("active_to"),
								width: 150
							}),
							integerColumn({
								field: "salesContractId",
								headerName: i18n.t("id"),
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"SALES_CONTRACT",
										onlySelectedRow?.salesContractId,
									)}
								/>
							</>
						}
						rowContextMenuComponents={({ row }) => [
							row.state == "INITIAL" && (
								<AsyncMenuButton
									key={"release"}
									icon={faShare}
									label={i18n.t("release")}
									onClick={async () => {
										const confirm = await showConfirmDialog({
											title: i18n.t("release"),
											message: i18n.t("release_contract_confirm"),
										});
										if (!confirm) {
											return;
										}
										await CustomerSalesContractEditApi.releaseSalesContract({
											salesContractId: row.salesContractId,
										});
										await refreshData();
									}}
								/>
							),
							row.state != "DEACTIVATED" && (
								<AsyncMenuButton
									key={"deactivate"}
									icon={faBan}
									label={i18n.t("deactivate")}
									onClick={async () => {
										const confirm = await showConfirmDialog({
											title: i18n.t("deactivate"),
											message: i18n.t("deactivate_contract_confirm"),
										});
										if (!confirm) {
											return;
										}
										await CustomerSalesContractEditApi.deactivateSalesContract({
											salesContractId: row.salesContractId,
										});
										await refreshData();
									}}
								/>
							),
						]}
						form={{
							addRowEnabled: true,
							editEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("sales_contract"),
							component: ({ onCompleted, onFormEdited, row }) => (
								<CustomerSalesContractForm
									customerId={customerId}
									contract={row}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						remove={{
							type: "enabled",
							action: async ({ items }) => {
								const item = items[0];
								if (item == null) {
									return;
								}
								await CustomerSalesContractEditApi.delete_({ contractId: item.salesContractId });
								await refreshData();
							},
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
