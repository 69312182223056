import { faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton";
import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi.ts";
import i18n from "i18next";

export interface UndoTaskChangesButtonProps {
	unmodifiedTask: Task | null;
	isDirty: boolean;
	refreshTask: () => Promise<unknown>;
	setNotDirty: () => void;
}

export const UndoTaskChangesButton = ({
	unmodifiedTask,
	isDirty,
	refreshTask,
	setNotDirty,
}: UndoTaskChangesButtonProps) => {
	const showConfirmDialog = useConfirmDialog();
	if (!unmodifiedTask) return null;
	if (!isDirty) return null;

	return (
		<AavoIconButton
			icon={faUndo}
			color={"black"}
			onClick={async () => {
				const confirmed = await showConfirmDialog({
					message: i18n.t("undo_changes_confirmation"),
				});
				if (!confirmed) return;

				await TaskUpdateApi.updateTask({ task: unmodifiedTask });
				await refreshTask();
				setNotDirty();
			}}
		/>
	);
};
