import React, { useMemo, useState } from "react";
import { FeatureChangeHintComponent } from "./FeatureChangeHintComponent";
import { FeatureChangeHint, FeatureChangeHintContext } from "./FeatureChangeHintContext.tsx";

export const FeatureChangeHintContextProvider = ({ children }: React.PropsWithChildren) => {
	const [hint, setHint] = useState<FeatureChangeHint | undefined>(undefined);
	const contextValue = useMemo(
		() => ({
			showHint: (hint: FeatureChangeHint) => {
				setHint(hint);
			},
			hideHint: () => {
				setHint(undefined);
			},
		}),
		[setHint],
	);

	return (
		<FeatureChangeHintContext.Provider value={contextValue}>
			<FeatureChangeHintComponent hint={hint} hideHint={contextValue.hideHint} />
			{children}
		</FeatureChangeHintContext.Provider>
	);
};
