// This file is automatically generated. Do not edit manually.

import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspaceInfoApi {
	export async function getInfoViewData(args: { workspaceId: number }): Promise<WorkspaceInfoApi_WorkspaceInfoData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/info/getInfoViewData",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
			},
		});
		return response as WorkspaceInfoApi_WorkspaceInfoData;
	}
}

export interface WorkspaceInfoApi_WorkspaceInfoData {
	pinnedDocuments: Array<DocumentConnectionView>;
	workspace: WorkspaceView;
}
