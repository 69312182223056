import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {
    ProductionLineDataGridApi,
    ProductionLineDataGridApi_ProductionLineDto,
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineDataGridApi";
import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import {coloredBooleanColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {concatWithPipe} from "src/utils/strings.tsx";
import {ProductionLine} from "src/api/generated/erp/db/types/tables/productionLine.ts";
import {ProductionLineForm} from "src/components/views/erp/production/baseData/productionLines/ProductionLineFrom.tsx";
import {
    ProductionLineEditApi
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineEditApi.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faBan, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {AavoCheckbox} from "src/components/common/inputFields/AavoCheckbox.tsx";

export interface ProductionLinesDataGridProps {
	productionLineGroupId: number;
	onSelectionChanged: (selectedRow: ProductionLine | undefined) => void;
}

export const ProductionLinesDataGrid = ({
	productionLineGroupId,
	onSelectionChanged,
}: ProductionLinesDataGridProps) => {
	const { refreshData, dataGridProps, currentParams } = useClientSideDataGridModel({
		fetchData: (params) => ProductionLineDataGridApi.getDataGridData({ productionLineGroupId, ...params }),
		initialParams: {
			showOnlyActiveProductionLines: false,
		},
		gridId: "D2E1F092F012772A",
		getRowId: (row) => row.productionLineId,
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
		selectFirstRowOnLoad: true,
	});
	return (
		<ControlledAsyncCrudDataGrid<ProductionLineDataGridApi_ProductionLineDto>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "productionLineName",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "capacityUnitName",
					headerName: i18n.t("capacity_unit"),
					width: 150,
				}),
				textColumn({
					field: "productionLineOperationNo",
					headerName: i18n.t("operation"),
					width: 200,
					valueGetter: (_, row) =>
						concatWithPipe(row.productionLineOperationNo, row.productionLineOperationDescription),
				}),
				textColumn({
					field: "taktTimeViewName",
					headerName: i18n.t("takt_time_view"),
					width: 200,
				}),
				integerColumn({
					field: "productionLineId",
					headerName: i18n.t("id"),
					width: 100,
				}),
				coloredBooleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
					falseColor: "red",
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("production_line"),
				dialogSize: "md",
				component: ({ row, onCompleted, onFormEdited }) => (
					<ProductionLineForm
						productionLineGroupId={productionLineGroupId}
						productionLineId={row?.productionLineId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			rowContextMenuComponents={({ row }) => [
				<AsyncMenuButton
					key="setActivity"
					label={row.isActive ? i18n.t("deactivate") : i18n.t("activate")}
					icon={row.isActive ? faBan : faCheck}
					onClick={async () => {
						await ProductionLineEditApi.setProductionLineActivity({
							productionLineId: row.productionLineId,
							isActive: !row.isActive,
						});
						await refreshData();
					}}
				/>,
			]}
			actionBarMenuComponents={[
				<AavoCheckbox
					key={"showOnlyActiveProductionLines"}
					label={i18n.t("only_active")}
					checked={currentParams.showOnlyActiveProductionLines}
					onChange={async (value) => {
						await refreshData({ showOnlyActiveProductionLines: value });
					}}
				/>,
			]}
			remove={({ items }) => ProductionLineEditApi.delete_({ productionLineId: items[0]!.productionLineId })}
			{...dataGridProps}
		/>
	);
};
