// This file is automatically generated. Do not edit manually.

import { ObjectPermission } from "src/api/generated/iam/model/objectPermission";
import { ObjectMemberType } from "src/api/generated/iam/model/objectMemberType";
import { AppRole } from "src/api/generated/postgres/db/types/role_management/tables/appRole";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspacePermissionEditApi {
	export async function getWorkspacePermissions(args: { workspaceId: number }): Promise<Array<ObjectPermission>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/permissions/getWorkspacePermissions",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
			},
		});
		return response as Array<ObjectPermission>;
	}

	export async function getFormInitData(): Promise<WorkspacePermissionEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/permissions/getFormInitData",
			method: "POST",
			data: {},
		});
		return response as WorkspacePermissionEditApi_FormInitData;
	}

	export async function savePermission(args: {
		workspaceId: number;
		memberType: ObjectMemberType;
		memberId: number;
		adminPermissions: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/permissions/savePermission",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				memberType: args.memberType,
				memberId: args.memberId,
				adminPermissions: args.adminPermissions,
			},
		});
		return response as void;
	}

	export async function deletePermission(args: {
		workspaceId: number;
		memberType: ObjectMemberType;
		memberId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/permissions/deletePermission",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				memberType: args.memberType,
				memberId: args.memberId,
			},
		});
		return response as void;
	}
}

export interface WorkspacePermissionEditApi_FormInitData {
	roleOptions: Array<AppRole>;
	userOptions: Array<AppUser>;
}
