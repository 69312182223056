import { AavoViewsContext, AavoViewsDispatchContext } from "src/components/views/legacy/AavoViewsContext.tsx";
import { AavoDialog } from "src/components/common/dialogs/AavoDialog";
import { keyValuePairsToRecord } from "src/utils/keyValuePair.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { useStartUpData } from "src/contexts/StartUpDataContext.ts";

export const FrontendModalViews = () => {
	const { state } = useContextOrThrow(AavoViewsContext);
	const dispatch = useContextOrThrow(AavoViewsDispatchContext);
	const frontendModals = state.frontendViewModals;
	const startUpData = useStartUpData();
	return frontendModals.map((viewDef) => {
		const viewParamsAsRecord = keyValuePairsToRecord(viewDef.viewParams);

		const closeModal = () => {
			dispatch({ type: "closeFrontendModalWithUuid", uuid: viewDef.uuid });
		};
		const refreshSourceView = () => {
			dispatch({
				type: "setViewActionEvents",
				startUpData: startUpData,
				events: [
					{
						action: {
							type: "viewAction",
							actionSpecifier: "VIEW_COMMON|REFRESH",
							target: {
								type: "view",
								viewId: viewDef.sourceViewId,
							},
							params: [],
							predicate: {
								type: "always",
							},
						},
						senderViewId: "",
						allParams: [],
						staticParams: [],
						senderSourceViewId: null,
						senderParentViewId: null,
						initiator: {
							type: "ViewInitiator",
							mouseEvent: null
						}
					},
				],
			});
		};

		const viewProps = viewDef.mapViewParamsToProps(
			viewParamsAsRecord,
			closeModal,
			refreshSourceView,
		) as any;

		return (
			<AavoDialog
				key={`aavo-frontend-modal-view-${viewDef.uuid}`}
				size={viewDef.modalSize}
				title={viewDef.title}
				onClose={closeModal}
			>
				{<viewDef.component {...viewProps} />}
			</AavoDialog>
		);
	});
};
