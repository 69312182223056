import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import { ObjectCommentingView } from "../../common/objectComments/ObjectCommentingView";
import { CustomerOrderInfoView } from "./CustomerOrderInfoView";
import i18n from "i18next";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { AavoMosaicNode, AavoMosaicPanel } from "src/components/common/mosaic/types.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";

export interface CustomerOrderInfoAndCommentsViewProps {
	customerOrderId: number;
}

export const CustomerOrderInfoAndCommentsView = ({
	customerOrderId,
}: CustomerOrderInfoAndCommentsViewProps) => {
	const isMobile = useMediaQueryBreakpointDown("sm");
	const { tenantConfig } = useTenantCustomizations();

	if (!tenantConfig.erp.customerOrderCommentingEnabled)
		return <CustomerOrderInfoView customerOrderId={customerOrderId} />;

	const customerOrderInfoView: AavoMosaicPanel = {
		type: "panel",
		title: i18n.t("info"),
		content: <CustomerOrderInfoView customerOrderId={customerOrderId} />,
	};

	const commentsView: AavoMosaicPanel = {
		type: "panel",
		title: i18n.t("comments"),
		content: <ObjectCommentingView objectType={"CUSTOMER_ORDER"} objectId={customerOrderId} />,
	};

	const layout: AavoMosaicNode =
		isMobile ?
			{
				type: "tabs",
				items: [customerOrderInfoView, commentsView],
			}
		:	{
				type: "row",
				first: customerOrderInfoView,
				second: commentsView,
			};

	return <AavoMosaic viewId={"D18C6B8C56853E8"} layout={layout} />;
};
