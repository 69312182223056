import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	iconColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { getTaskSourceTypeLabels } from "src/api/generated/postgres/db/types/tasks/enums/taskSourceType.ts";
import {
	getTaskStateLabel,
	getTaskStateLabels,
} from "src/api/generated/io/aavo/applications/db/postgres/enums/taskState.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import { TasksViewProps } from "src/components/views/tasks/TasksView.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField";
import { MultiSelectField } from "src/components/common/inputFields/MultiSelectField.tsx";
import { enumOptions } from "src/components/common/forms/fields/selectionFieldUtils.ts";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField";
import {
	decodeTaskSourceNullable,
	encodeTaskSource,
	encodeTaskSourceNullable,
	nullableTaskSourceRef,
} from "src/components/views/tasks/taskSources/taskSourceUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { faChartGantt, faCheck, faPaperclip, faPlay, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { TaskActionsApi } from "src/api/generated/tasks/api/taskActionsApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { useTheme } from "@mui/material";
import { TaskDesktopForm } from "src/components/views/tasks/desktop/TaskDesktopForm.tsx";
import { ContentWithIcon } from "src/components/common/misc/ContentWithIcon.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useTaskContextMenu } from "src/components/views/tasks/desktop/TaskContextMenu.tsx";
import { AsyncTasksGanttView } from "../gantt/AsyncTasksGanttView";
import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";
import i18n from "i18next";
import { TaskQueryDto } from "src/api/generated/tasks/api/taskQueryDto.ts";
import { getTaskStateIcon } from "../taskStateUtils";
import { DocumentsOfObjectSourceButton } from "../../documents/objectDocuments/DocumentsOfObjectSourceButton";
import { AddDocumentsToObjectButton } from "src/components/views/documents/AddDocumentsToObjectButton.tsx";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";

export interface TasksDesktopViewProps extends TasksViewProps {}

export const TasksDesktopView = ({
	refreshRef,
	onTaskCompleted,
	onTaskCompletionReverted,
	taskQueryParams,
	showCanBeStarted = false,
	showIsInSchedulePlanning = false,
	showTaskSourceInfo = true,
	showAssignedTo = true,
	showResponsiblePerson = true,
	showResponsiblePersonFilter = false,
	showStateFilter = true,
	showGanttButton = true,
	taskSourceRef,
	usePagination = true,
}: TasksDesktopViewProps) => {
	const { dataGridProps, currentParams, refreshData, onlySelectedRow } = useServerSideDataGridModel({
		gridId: "EC191A6871842AB4",
		fetchData: TaskQueryApi.queryTasks,
		getRowId: (row) => row.taskId,
		initialParams: {
			sources: null,
			states: [],
			responsiblePersonId: null,
			assignedToUserId: null,
			searchQuery: "",
			...taskQueryParams,
		},
		usePagination: usePagination,
	});

	setRefreshRefValue(refreshRef, refreshData);

	const theme = useTheme();
	const { openDialog } = useGenericDialog();
	const { getTaskContextMenuContent } = useTaskContextMenu({
		refreshData,
		showSourceButtons: showTaskSourceInfo,
	});

	return (
		<ControlledAsyncCrudDataGrid<TaskQueryDto>
			columns={[
				iconColumn({
					field: "hasConnectedDocuments",
					headerName: i18n.t("has_connected_documents"),
					renderHeader: () => null,
					getIconProps: (row) => (row.hasConnectedDocuments ? { icon: faPaperclip } : undefined),
				}),
				textColumn({
					field: "title",
					headerName: i18n.t("task"),
					width: 250,
				}),
				dateColumn({
					field: "deadlineDate",
					headerName: i18n.t("deadline_date_short"),
				}),
				enumColumn({
					field: "taskState",
					headerName: i18n.t("state"),
					enumLabels: getTaskStateLabels(),
					renderCell: ({ row }) => {
						const { icon, color } = getTaskStateIcon(row, theme);
						return (
							<ContentWithIcon
								icon={icon}
								color={color}
								content={getTaskStateLabel(row.taskState)}
							/>
						);
					},
				}),
				showCanBeStarted &&
					booleanColumn({
						field: "canBeStarted",
						headerName: i18n.t("can_be_started"),
					}),
				showIsInSchedulePlanning &&
					booleanColumn({
						field: "showInSchedulePlanning",
						headerName: i18n.t("show_in_schedule_planning"),
					}),
				showTaskSourceInfo &&
					textColumn({
						field: "sourceDescription",
						headerName: i18n.t("source"),
						width: 250,
					}),
				showTaskSourceInfo &&
					enumColumn({
						field: "sourceType",
						headerName: i18n.t("source_type"),
						enumLabels: getTaskSourceTypeLabels(),
						width: 150,
					}),
				showAssignedTo &&
					textColumn({
						field: "assignedToUserName",
						headerName: i18n.t("executor"),
						width: 150,
					}),
				showResponsiblePerson &&
					textColumn({
						field: "responsiblePersonUserName",
						headerName: i18n.t("responsible_person"),
						width: 150,
					}),
				dateColumn({
					field: "earliestStartDate",
					headerName: i18n.t("earliest_start_date"),
					width: 150,
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 150,
				}),
				dateTimeColumn({
					field: "actualStartDate",
					headerName: i18n.t("actual_start"),
					width: 150,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("comment"),
					width: 150,
				}),
				floatColumn({
					field: "workloadEstimateHours",
					headerName: i18n.t("workload_estimate_hours"),
				}),
				integerColumn({
					field: "taskId",
					headerName: i18n.t("number_shortened"),
				}),
			]}
			{...dataGridPersistentStateProps("2E19FF651F0FF6C5")}
			form={{
				dialogSize: "lg",
				dialogTitle: i18n.t("task"),
				editEnabled: true,
				addRowEnabled: true,
				component: ({ row, onCompleted, onFormEdited }) => (
					<TaskDesktopForm
						taskId={row?.taskId}
						newTaskSourceRef={
							nullableTaskSourceRef(row?.sourceType, row?.sourceId) ?? taskSourceRef
						}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("TASK", onlySelectedRow?.taskId)}
					/>
					<DocumentsOfObjectSourceButton
						sourceRef={nullableAavoObjectRef(
							onlySelectedRow?.sourceType,
							onlySelectedRow?.sourceId,
						)}
					/>
					<AddDocumentsToObjectButton
						objectRef={nullableAavoObjectRef("TASK", onlySelectedRow?.taskId)}
					/>
					{showGanttButton && (
						<AavoButton
							label={""}
							icon={faChartGantt}
							tooltip={i18n.t("show_gantt")}
							color={"primary"}
							onClick={() => {
								openDialog(() => ({
									title: i18n.t("gantt"),
									content: <AsyncTasksGanttView taskQueryParams={taskQueryParams} />,
								}));
							}}
						/>
					)}
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(input) => refreshData({ searchQuery: input })}
					/>
					{taskQueryParams.states === undefined && showStateFilter && (
						<MultiSelectField
							label={i18n.t("state")}
							options={enumOptions(getTaskStateLabels())}
							onChange={(value) => refreshData({ states: value })}
							value={currentParams.states}
							getOptionKey={(option) => option.key}
							getOptionLabel={(option) => option.label}
							sx={{
								minWidth: 120,
							}}
						/>
					)}
					{taskQueryParams.sources === undefined && (
						<AsyncSelectField
							label={i18n.t("source")}
							fetchOptions={({ searchQuery, currentSelection }) =>
								TaskQueryApi.getTaskSourceOptions({
									searchQuery: searchQuery,
									currentSelection: decodeTaskSourceNullable(currentSelection),
									states: currentParams.states,
									assignedToUserId: currentParams.assignedToUserId,
									responsiblePersonId: currentParams.responsiblePersonId,
								})
							}
							defaultValue={encodeTaskSourceNullable(currentParams.sources?.at(0))}
							getOptionKey={(option: TaskSourceRef) => encodeTaskSource(option)}
							getOptionLabel={(option) => option.sourceDescription}
							onChange={async (value) => {
								await refreshData({
									sources: value != null ? [value] : null,
								});
							}}
							popperProps={{
								width: 800,
							}}
						/>
					)}
					{taskQueryParams.assignedToUserId == null && (
						<AsyncSelectField<AppUser, number>
							label={i18n.t("executor")}
							fetchOptions={({ searchQuery, currentSelection }) =>
								TaskQueryApi.getTaskAssignedToUserOptions({
									...currentParams,
									currentSelection: currentSelection,
									userNameSearchQuery: searchQuery,
								})
							}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => option.name}
							onChange={async (value) => {
								await refreshData({
									assignedToUserId: value?.id ?? null,
								});
							}}
						/>
					)}
					{taskQueryParams.responsiblePersonId == null && showResponsiblePersonFilter && (
						<AsyncSelectField<AppUser, number>
							label={i18n.t("responsible_person")}
							fetchOptions={({ searchQuery, currentSelection }) =>
								TaskQueryApi.getTaskResponsiblePersonOptions({
									...currentParams,
									currentSelection: currentSelection,
									userNameSearchQuery: searchQuery,
								})
							}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => option.name}
							onChange={async (value) => {
								await refreshData({
									responsiblePersonId: value?.id ?? null,
								});
							}}
						/>
					)}
					{onlySelectedRow && onlySelectedRow.taskState === "RELEASED" && (
						<AsyncButton
							label={i18n.t("start")}
							icon={faPlay}
							variant={"outlined"}
							onClick={async () => {
								await TaskActionsApi.startTask({ taskId: onlySelectedRow.taskId });
								await refreshData();
							}}
						/>
					)}
					{onlySelectedRow && onlySelectedRow?.taskState === "STARTED" && (
						<AsyncButton
							label={i18n.t("revert_start")}
							icon={faUndo}
							variant={"outlined"}
							onClick={async () => {
								await TaskActionsApi.revertStartTask({
									taskId: onlySelectedRow.taskId,
								});
								await refreshData();
							}}
						/>
					)}
					{onlySelectedRow && onlySelectedRow?.taskState === "STARTED" && (
						<AsyncButton
							label={i18n.t("complete")}
							icon={faCheck}
							variant={"outlined"}
							onClick={async () => {
								await TaskActionsApi.completeTask({
									taskId: onlySelectedRow.taskId,
								});
								await refreshData();
								onTaskCompleted?.(onlySelectedRow);
							}}
						/>
					)}
					{onlySelectedRow && onlySelectedRow?.taskState === "COMPLETED" && (
						<AsyncButton
							label={i18n.t("revert_complete")}
							icon={faUndo}
							variant={"outlined"}
							onClick={async () => {
								await TaskActionsApi.revertCompleteTask({
									taskId: onlySelectedRow.taskId,
								});
								await refreshData();
								onTaskCompletionReverted?.(onlySelectedRow);
							}}
						/>
					)}
				</>
			}
			rowContextMenuComponents={({ allSelectedRows }) => getTaskContextMenuContent(allSelectedRows)}
			{...dataGridProps}
		/>
	);
};
