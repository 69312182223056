import { ResolvedFrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";
import React from "react";

export const FrontendViewsContext = React.createContext<FrontendViewsContextValue | undefined>(undefined);

export interface FrontendViewsContextValue {
	allMainMenuFrontendViews: ResolvedFrontendViewConfig[];
	getFrontendViewByKey: (key: string) => ResolvedFrontendViewConfig | undefined;
	getFrontendViewByUrl: (url: string) => ResolvedFrontendViewConfig | undefined;
}
