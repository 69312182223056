import {
	FilterableAavoTreeViewItemModel,
	filterTreeItems,
} from "src/components/common/tree/filtering/treeItemFilter.tsx";
import { flatMapTreeItems } from "src/components/common/tree/AavoTreeView.utils.ts";
import { WorkspacesTreeViewApi_WorkspaceDto } from "src/api/generated/workspaces/api/workspacesTreeViewApi";

export interface WorkspacesTreeViewState {
	rootTreeItems: WorkspaceTreeViewItemModel[];
	allTreeItems: WorkspaceTreeViewItemModel[];
	filteredTreeItems: WorkspaceTreeViewItemModel[];
	searchQuery: string;
	categoryIdFilter: number | null;
	expandedWorkspaceIds: number[];
	treeViewEditable: boolean;
	selectedWorkspace: WorkspaceTreeViewItemModel | null;
}

export type WorkspaceTreeViewItemModel = FilterableAavoTreeViewItemModel<WorkspacesTreeViewApi_WorkspaceDto>;

export type WorkspacesTreeViewStateAction =
	| {
			type: "setSearchQuery";
			searchQuery: string;
	  }
	| {
			type: "setCategoryFilter";
			categoryId: number | null;
	  }
	| {
			type: "setWorkspaceIsExpanded";
			workspaceId: number;
			isExpanded: boolean;
	  }
	| {
			type: "setEditable";
			editable: boolean;
	  }
	| {
			type: "refreshData";
			workspaces: WorkspacesTreeViewApi_WorkspaceDto[];
	  }
	| {
			type: "setExpandedWorkspaces";
			workspaceIds: number[];
	  }
	| {
			type: "setSelectedWorkspace";
			workspaceId: number | null;
	  }
	| {
			type: "revealWorkspace";
			workspaceId: number;
	  };

export function workspacesTreeViewReducer(
	state: WorkspacesTreeViewState,
	action: WorkspacesTreeViewStateAction,
): WorkspacesTreeViewState {
	switch (action.type) {
		case "setSearchQuery":
			return setSearchQuery(state, action.searchQuery);
		case "setCategoryFilter":
			return setCategoryFilter(state, action.categoryId);
		case "setWorkspaceIsExpanded":
			return setWorkspaceIsExpanded(state, action.workspaceId, action.isExpanded);
		case "setEditable":
			return setEditable(state, action.editable);
		case "refreshData":
			return refreshData(state, action.workspaces);
		case "setExpandedWorkspaces":
			return setExpandedWorkspaces(state, action.workspaceIds);
		case "setSelectedWorkspace":
			return setSelectedWorkspace(state, action.workspaceId);
		case "revealWorkspace":
			return revealWorkspace(state, action.workspaceId);
		default:
			return state;
	}
}

export function initializeWorkspaceTreeState(
	workspaces: WorkspacesTreeViewApi_WorkspaceDto[],
): WorkspacesTreeViewState {
	const rootWorkspaceTreeViewItems = mapWorkspaceTreeData(workspaces);
	return {
		rootTreeItems: rootWorkspaceTreeViewItems,
		allTreeItems: flatMapTreeItems(rootWorkspaceTreeViewItems),
		filteredTreeItems: rootWorkspaceTreeViewItems,
		searchQuery: "",
		categoryIdFilter: null,
		expandedWorkspaceIds: [],
		treeViewEditable: false,
		selectedWorkspace: rootWorkspaceTreeViewItems[0] ?? null,
	};
}

function setSearchQuery(state: WorkspacesTreeViewState, searchQuery: string): WorkspacesTreeViewState {
	return setFilteredItems({
		...state,
		searchQuery: searchQuery,
	});
}

function setCategoryFilter(state: WorkspacesTreeViewState, categoryId: number | null): WorkspacesTreeViewState {
	return setFilteredItems({ ...state, categoryIdFilter: categoryId });
}

function setWorkspaceIsExpanded(
	state: WorkspacesTreeViewState,
	workspaceId: number,
	isExpanded: boolean,
): WorkspacesTreeViewState {
	return {
		...state,
		expandedWorkspaceIds:
			isExpanded ?
				[...state.expandedWorkspaceIds, workspaceId]
			:	state.expandedWorkspaceIds.filter((id) => id !== workspaceId),
	};
}

function setEditable(state: WorkspacesTreeViewState, editable: boolean): WorkspacesTreeViewState {
	return {
		...state,
		treeViewEditable: editable,
	};
}

function refreshData(
	state: WorkspacesTreeViewState,
	workspaces: WorkspacesTreeViewApi_WorkspaceDto[],
): WorkspacesTreeViewState {
	const rootWorkspaceTreeViewItems = mapWorkspaceTreeData(workspaces);
	return setFilteredItems({
		...state,
		rootTreeItems: rootWorkspaceTreeViewItems,
		allTreeItems: flatMapTreeItems(rootWorkspaceTreeViewItems),
	});
}

function setExpandedWorkspaces(state: WorkspacesTreeViewState, workspaceIds: number[]): WorkspacesTreeViewState {
	return {
		...state,
		expandedWorkspaceIds: workspaceIds,
	};
}

function setSelectedWorkspace(state: WorkspacesTreeViewState, workspaceId: number | null): WorkspacesTreeViewState {
	const selectedItem = state.allTreeItems.find((item) => item.workspaceId === workspaceId) ?? null;
	return {
		...state,
		selectedWorkspace: selectedItem,
	};
}

function setFilteredItems(state: WorkspacesTreeViewState) {
	const hasNotBeenFiltered = state.searchQuery.trim() === "" && state.categoryIdFilter == null;
	if (hasNotBeenFiltered) {
		return {
			...state,
			filteredTreeItems: state.rootTreeItems,
		};
	}

	const filteredItems = filterTreeItems({
		rootTreeItems: state.rootTreeItems,
		isSearchMatch: (item) => {
			const nodeIsSearchQueryMatch = item.workspaceName.toLowerCase().includes(state.searchQuery.toLowerCase());
			const nodeIsCategoryMatch =
				state.categoryIdFilter != null ? item.categoryId === state.categoryIdFilter : true;
			return nodeIsSearchQueryMatch && nodeIsCategoryMatch;
		},
	});

	const ret = {
		...state,
		filteredTreeItems: filteredItems,
	};

	return setExpandedWorkspaces(ret, [
		...state.expandedWorkspaceIds,
		...filteredItems.filter((item) => item.expand).map((item) => item.workspaceId),
	]);
}

function revealWorkspace(state: WorkspacesTreeViewState, workspaceId: number): WorkspacesTreeViewState {
	const workspace = state.allTreeItems.find((item) => item.workspaceId === workspaceId);
	if (workspace == null) return state;

	const ancestors = getWorkspaceAncestors(state, workspace);
	return setExpandedWorkspaces(
		state,
		ancestors.map((item) => item.workspaceId),
	);
}

function mapWorkspaceTreeData(workspaces: WorkspacesTreeViewApi_WorkspaceDto[]): WorkspaceTreeViewItemModel[] {
	return workspaces
		.filter((item) => item.parentId == null)
		.map((rootWorkspace) => mapWorkspace(rootWorkspace, workspaces));
}

function mapWorkspace(
	workspace: WorkspacesTreeViewApi_WorkspaceDto,
	allWorkspaces: WorkspacesTreeViewApi_WorkspaceDto[],
): WorkspaceTreeViewItemModel {
	const children = getWorkspaceChildren(workspace, allWorkspaces);
	return {
		...workspace,
		children: children.map((child) => mapWorkspace(child, allWorkspaces)),
		directSearchMatch: false,
		expand: false,
	};
}

function getWorkspaceChildren(
	workspace: WorkspacesTreeViewApi_WorkspaceDto,
	allWorkspaces: WorkspacesTreeViewApi_WorkspaceDto[],
): WorkspacesTreeViewApi_WorkspaceDto[] {
	return allWorkspaces.filter((item) => item.parentId === workspace.workspaceId);
}

function getWorkspaceAncestors(
	state: WorkspacesTreeViewState,
	workspace: WorkspacesTreeViewApi_WorkspaceDto,
): WorkspacesTreeViewApi_WorkspaceDto[] {
	if (workspace.parentId == null) return [];
	const parent = state.allTreeItems.find((item) => item.workspaceId === workspace.parentId);
	if (parent == null) return [];
	return [parent, ...getWorkspaceAncestors(state, parent)];
}
