import { DocumentsOfObjectButton } from "../../../documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "../../../../common/inputFields/AavoTextField.tsx";
import i18n from "i18next";
import { MultiSelectField } from "../../../../common/inputFields/MultiSelectField.tsx";
import {
	CustomerOrderStateValues,
	getCustomerOrderStateLabel,
} from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import { SelectField } from "../../../../common/inputFields/SelectField.tsx";
import { AsyncSelectField } from "../../../../common/inputFields/AsyncSelectField.tsx";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { CustomerOrderQueryApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";
import { LazySelectField } from "../../../../common/inputFields/LazySelectField.tsx";
import { AavoButton } from "../../../../common/buttons/AavoButton.tsx";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons";
import {
	CustomerOrderDataGridApi_CustomerOrderDto,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { CenteredTypography } from "../../../../common/CenteredTypography.tsx";
import { useTheme } from "@mui/material";
import { nullIfBlank } from "src/utils/strings.tsx";
import {
	CustomerOrdersDataGridFilterParams
} from "src/components/views/erp/sales/customerOrder/CustomerOrdersDataGrid.tsx";

export interface CustomerOrderDataGridActionBarComponentsProps {
	selectedCustomerOrder: CustomerOrderDataGridApi_CustomerOrderDto | undefined;
	refreshData: (params?: Partial<CustomerOrdersDataGridFilterParams>) => Promise<unknown>;
}

export const CustomerOrdersDataGridActionBarComponents = ({
	selectedCustomerOrder,
	refreshData,
}: CustomerOrderDataGridActionBarComponentsProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const theme = useTheme();

	return (
		<>
			<DocumentsOfObjectButton
				objectRef={nullableAavoObjectRef(
					"CUSTOMER_ORDER",
					selectedCustomerOrder?.customerOrderId,
				)}
			/>
			<AavoTextField
				label={i18n.t("search")}
				onSubmit={(input) => refreshData({ searchQuery: input })}
			/>
			<MultiSelectField
				label={i18n.t("state")}
				options={CustomerOrderStateValues.filter((state) => state !== "CANCELLED")}
				getOptionKey={(option) => option}
				getOptionLabel={(option) => getCustomerOrderStateLabel(option)}
				onChange={async (values) => {
					await refreshData({ stateFilter: values });
				}}
			/>
			{tenantConfig.erp.useCustomerOrderTenantStates && (
				<SelectField
					label={tenantConfig.erp.customerOrderTenantStateColumnLabel}
					options={tenantConfig.erp.customerOrderTenantStates}
					getOptionKey={(option) => option.key}
					getOptionLabel={(option) => option.label}
					onChange={async (value) => {
						await refreshData({ tenantStateFilter: value });
					}}
				/>
			)}
			<AsyncSelectField<AppUser, number>
				label={i18n.t("responsible_person")}
				fetchOptions={({ searchQuery, currentSelection }) =>
					CustomerOrderQueryApi.getCustomerOrderResponsiblePersonOptions({
						currentSelection: currentSelection,
						userNameSearchQuery: searchQuery,
					})
				}
				getOptionKey={(option) => option.id}
				getOptionLabel={(option) => option.name}
				onChange={async (value) => {
					await refreshData({
						responsiblePersonId: value?.id ?? null,
					});
				}}
			/>
			<LazySelectField
				label={i18n.t("type")}
				fetchOptions={CustomerOrderQueryApi.getCustomerOrderTypeOptions}
				getOptionKey={(option) => option.customerOrderTypeId}
				getOptionLabel={(option) => option.name}
				onChange={async (value) => {
					await refreshData({ customerOrderTypeId: value });
				}}
			/>
			<AavoButton
				icon={faGlobe}
				disabled={nullIfBlank(selectedCustomerOrder?.relatedUrl) == null}
				tooltip={`${i18n.t("open_related_website")} (${selectedCustomerOrder?.relatedUrl})`}
				onClick={() => {
					if (selectedCustomerOrder?.relatedUrl == null) return;
					window.open(selectedCustomerOrder.relatedUrl, "");
				}}
			/>
			{selectedCustomerOrder?.billingPlanIsComplete === false && (
				<CenteredTypography color={theme.palette.error.main}>
					{`${i18n.t("billing_plan_invalid")}`}
				</CenteredTypography>
			)}
		</>
	);
};
