// This file is automatically generated. Do not edit manually.

import { AavoObjectType } from "src/api/generated/common/sourceType/aavoObjectType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ObjectModificationLogEventLine } from "src/api/generated/erp/db/types/tables/objectModificationLogEventLine";
import { IsoDateTimeString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ObjectChangeLogDataGridApi {
	export async function getObjectChangeLog(args: {
		objectType: AavoObjectType;
		objectId: number;
		columnNameFilter: string | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ObjectChangeLogDataGridApi_ChangeLogData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/changeLogging/objectChangeLogDataGrid/getObjectChangeLog",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
				columnNameFilter: args.columnNameFilter,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ObjectChangeLogDataGridApi_ChangeLogData;
	}
}

export interface ObjectChangeLogDataGridApi_ChangeLogData {
	columns: Array<ObjectChangeLogDataGridApi_ColumnDefinitionDto>;
	gridData: ServerSideDataModelResult<ObjectChangeLogDataGridApi_ObjectModificationEventDto>;
}

export interface ObjectChangeLogDataGridApi_ColumnDefinitionDto {
	columnLabelLocalizationKey: string;
	columnName: string;
}

export interface ObjectChangeLogDataGridApi_ObjectModificationEventDto {
	modificationEventLines: Array<ObjectModificationLogEventLine>;
	modifiedAt: IsoDateTimeString;
	modifiedByUserId: number;
	modifiedByUserName: string;
	objectId: number;
	objectModificationLogEventId: number;
	objectType: string;
}
