import {AavoMosaic} from "../../../../common/mosaic/AavoMosaic.tsx";
import {useState} from "react";
import {
    CustomerOrderDataGridApi_CustomerOrderDto
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import {CustomerOrdersDataGrid} from "./CustomerOrdersDataGrid.tsx";
import i18n from "i18next";
import {
    CustomerOrderLinesDataGrid
} from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLinesDataGrid.tsx";
import {CenteredTypography} from "../../../../common/CenteredTypography.tsx";
import {SingleSourceTasksView} from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import {DeliveryPackagesDataGrid} from "../../delivery/deliveryPackage/DeliveryPackagesDataGrid.tsx";
import {DeliveryPackageView} from "src/api/generated/erp/db/types/tables/deliveryPackageView.ts";
import {
    DeliveryPackageLinesDataGrid
} from "src/components/views/erp/delivery/deliveryPackage/DeliveryPackageLinesDataGrid.tsx";
import {DeliveryView} from "src/api/generated/erp/db/types/tables/deliveryView.ts";
import {DeliveriesDataGrid} from "../../delivery/delivery/DeliveriesDataGrid.tsx";
import {
    CustomerOrderInvoicesDataGrid
} from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderInvoicesDataGrid.tsx";
import {
    CustomerOrderAttributesForm
} from "src/components/views/erp/sales/customerOrder/CustomerOrderAttributesForm.tsx";
import {
    CustomerOrderBillingPlanCrudDataGrid
} from "src/components/views/erp/sales/billingPlan/customerOrder/CustomerOrderBillingPlanCrudDataGrid.tsx";
import {CustomerOrderInfoAndCommentsView} from "./CustomerOrderInfoAndCommentsView.tsx";
import {CustomerOrderReclamationsDataGrid} from "./CustomerOrderReclamationsDataGrid.tsx";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {CustomerOrdersPageContext} from "./CustomerOrdersPageContext.tsx";
import {CustomerOrderSalesConditionDataGrid} from "./CustomerOrderSalesConditionDataGrid.tsx";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";
import {
    CustomerOrderSalesCommissionView
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/order/CustomerOrderSalesCommissionView.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";

export interface CustomerOrdersPageProps {
	onlyCustomerOrderToShowId?: number;
}

export const CustomerOrdersPage = ({ onlyCustomerOrderToShowId }: CustomerOrdersPageProps) => {
	const [selectedCustomerOrder, setSelectedCustomerOrder] = useState<
		CustomerOrderDataGridApi_CustomerOrderDto | undefined
	>(undefined);

	const selectCustomerOrderTypography = <CenteredTypography>{i18n.t("select_customer_order")}</CenteredTypography>;

	const customerOrdersRefreshRef = useRefreshRef();
	const customerOrderLinesRefreshRef = useRefreshRef();
	const attributeViewRefreshRef = useRefreshRef();
	const tasksViewRefreshRef = useRefreshRef();
	const billingPlanViewRefreshRef = useRefreshRef();
	const salesCommissionViewRefreshRef = useRefreshRef();

	const userPermissions = useUserPermissions();

	const tasksEnabled = useTenantCustomizations().tenantConfig.tasks.enabled ?? false;

	return (
		<CustomerOrdersPageContext.Provider
			value={{
				customerOrdersRefreshRef,
				customerOrderLinesRefreshRef,
				attributeViewRefreshRef,
				tasksViewRefreshRef,
				billingPlanViewRefreshRef,
				salesCommissionViewRefreshRef
			}}
		>
			<AavoMosaic
				viewId={"9705F52BFA8D92A6"}
				layout={{
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("customer_orders"),
						content: (
							<CustomerOrdersDataGrid
								onlyCustomerOrderToShowId={onlyCustomerOrderToShowId}
								selectedCustomerOrder={selectedCustomerOrder}
								setSelectedCustomerOrder={setSelectedCustomerOrder}
							/>
						),
					},
					second: {
						type: "tabs",
						items: [
							{
								type: "panel",
								title: i18n.t("customer_order_lines"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderLinesDataGrid
											key={selectedCustomerOrder.customerOrderId}
											customerOrderId={selectedCustomerOrder.customerOrderId}
											parentLineId={null}
										/>
									),
							},
							tasksEnabled && {
								type: "panel",
								title: i18n.t("tasks"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<SingleSourceTasksView
											key={selectedCustomerOrder.customerOrderId}
											taskSourceRef={{
												sourceType: "CUSTOMER_ORDER",
												sourceId: selectedCustomerOrder.customerOrderId,
											}}
											refreshRef={tasksViewRefreshRef}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("packages"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<DeliveryPackagesView customerOrderId={selectedCustomerOrder.customerOrderId} />
									),
							},
							{
								type: "panel",
								title: i18n.t("deliveries"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderDeliveriesView
											customerOrderId={selectedCustomerOrder.customerOrderId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("billing_plan"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography
									: !selectedCustomerOrder.billingPlanEnabled ?
										<CenteredTypography children={i18n.t("billing_plan_not_enabled")} />
									:	<CustomerOrderBillingPlanCrudDataGrid
											key={selectedCustomerOrder.customerOrderId}
											customerOrderId={selectedCustomerOrder.customerOrderId}
											refreshRef={billingPlanViewRefreshRef}
											customerOrdersRefreshRef={customerOrdersRefreshRef}
										/>,
							},
							{
								type: "panel",
								title: i18n.t("invoices"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderInvoicesDataGrid
											key={selectedCustomerOrder.customerOrderId}
											customerOrderId={selectedCustomerOrder.customerOrderId}
											editable={false}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("conditions"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderSalesConditionDataGrid
											key={selectedCustomerOrder.customerOrderId}
											customerOrderId={selectedCustomerOrder.customerOrderId}
											onlyIncomplete={false}
											editable={true}
										/>
									),
							},
							selectedCustomerOrder?.salesCommissionId != null &&
								userPermissions.sales.manageCustomerOrderSalesCommissions && {
									type: "panel",
									title: i18n.t("commissions"),
									content: (
										<CustomerOrderSalesCommissionView
											key={selectedCustomerOrder.salesCommissionId}
											salesCommissionId={selectedCustomerOrder.salesCommissionId}
											salesCommissionViewRefreshRef={salesCommissionViewRefreshRef}
										/>
									),
								},
							{
								type: "panel",
								title: i18n.t("reclamations"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderReclamationsDataGrid
											key={selectedCustomerOrder.customerOrderId}
											customerOrder={selectedCustomerOrder}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("attributes"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderAttributesForm
											key={selectedCustomerOrder.customerOrderId}
											customerOrderId={selectedCustomerOrder.customerOrderId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("info"),
								content:
									selectedCustomerOrder == null ? selectCustomerOrderTypography : (
										<CustomerOrderInfoAndCommentsView
											key={selectedCustomerOrder.customerOrderId}
											customerOrderId={selectedCustomerOrder.customerOrderId}
										/>
									),
							},
						],
					},
				}}
			/>
		</CustomerOrdersPageContext.Provider>
	);
};

const DeliveryPackagesView = ({ customerOrderId }: { customerOrderId: number }) => {
	const [selectedPackage, setSelectedPackage] = useState<DeliveryPackageView | undefined>(undefined);
	return (
		<AavoMosaic
			viewId={"F480E81E3E653D6B"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("packages"),
					content: (
						<DeliveryPackagesDataGrid
							key={customerOrderId}
							gridId={"215795481EC3817C"}
							onSelectedPackageChanged={(selectedPackages) => setSelectedPackage(selectedPackages[0])}
							deliverySource={{
								type: "customerOrder",
								customerOrderId: customerOrderId,
							}}
							selectedRows={selectedPackage ? [selectedPackage] : []}
							showCalculateWeightButton={false}
							showStateChangeActions
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("package_lines"),
					content:
						selectedPackage == null ?
							<CenteredTypography children={i18n.t("select_package")} />
						:	<DeliveryPackageLinesDataGrid
								key={selectedPackage.deliveryPackageId}
								deliveryPackage={selectedPackage}
								editable={selectedPackage.state === "INITIAL"}
							/>,
				},
			}}
		/>
	);
};

const CustomerOrderDeliveriesView = ({ customerOrderId }: { customerOrderId: number }) => {
	const [selectedDelivery, setSelectedDelivery] = useState<DeliveryView | undefined>(undefined);
	return (
		<AavoMosaic
			viewId={"F480E81E3E658D6B"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("deliveries"),
					content: (
						<DeliveriesDataGrid
							key={customerOrderId}
							gridId={"F75A4E4E5856D928"}
							deliverySource={{
								type: "customerOrder",
								customerOrderId: customerOrderId,
							}}
							onSelectionChanged={(selectedDelivery) => setSelectedDelivery(selectedDelivery)}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("delivery_packages"),
					content:
						selectedDelivery == null ?
							<CenteredTypography children={i18n.t("select_delivery")} />
						:	<DeliveryPackagesDataGrid
								key={selectedDelivery.deliveryId}
								gridId={"215795487EC3817C"}
								deliveryId={selectedDelivery.deliveryId}
								editable={selectedDelivery.deliveryState === "INITIAL"}
								disablePagination
								showStateChangeActions
								showPrintPackageLabelButton
							/>,
				},
			}}
		/>
	);
};
