import { useCallback, useState } from "react";
import { IdentitySelection } from "./IdentitySelection";
import { EnvironmentSelection } from "./EnvironmentSelection";
import { EnvironmentId, IdentityId } from "src/storage/appState/types";
import { getOrInitSelectedEnvironmentIdStorage, setSelectedEnvironmentIdStorage } from "src/storage/appState";
import {
	getSelectedIdentityStorage,
	setSelectedIdentityStorage,
} from "src/storage/appState/identitySelection";
import { useStartUpData } from "src/contexts/StartUpDataContext.ts";

export const DrawerSelectionFields = () => {
	const startUpData = useStartUpData();
	const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentId>(
		getOrInitSelectedEnvironmentIdStorage(),
	);
	const [selectedIdentity, setSelectedIdentity] = useState<IdentityId>(getSelectedIdentityStorage());

	const changeIdentity = useCallback(
		(identity: IdentityId) => {
			setSelectedIdentityStorage(identity);
			setSelectedIdentity(identity);
		},
		[setSelectedIdentity],
	);

	const changeEnvironment = useCallback(
		(environment: EnvironmentId) => {
			setSelectedEnvironmentIdStorage(environment);
			setSelectedEnvironment(environment);
		},
		[setSelectedEnvironment],
	);

	const handleIdentityChanged = (value: IdentityId) => {
		changeIdentity(value);
		changeEnvironment(null);
		window.location.reload();
	};

	const handleEnvironmentChanged = (value: EnvironmentId) => {
		changeEnvironment(value);
		window.location.reload();
	};

	return (
		<>
			<IdentitySelection
				options={startUpData.identityOptions}
				value={selectedIdentity}
				onChange={handleIdentityChanged}
			/>
			<EnvironmentSelection
				options={startUpData.environments}
				value={selectedEnvironment}
				onChange={handleEnvironmentChanged}
			/>
		</>
	);
};
