import {Box, Typography} from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";
import {mergeSx} from "src/utils/styles.ts";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import {formatNumber} from "src/utils/numberUtils.ts";

export interface LabelValueViewProps {
	sx?: SxProps<Theme>;
	items: LabelValueViewItem[];
	fontVariant?: "body1" | "body2";
}

export type LabelValueViewItem = LabelValueField | LabelValueViewSection | false;

export interface LabelValueField {
	label: string | null;
	value: string | boolean | number | null | undefined;
	multiline?: boolean;
}

export interface LabelValueViewSection {
	type: "section";
	label: string;
	fields: LabelValueField[];
}

export const LabelValueView = ({ sx, items, fontVariant = "body1" }: LabelValueViewProps) => {
	return (
		<Box
			sx={mergeSx(
				{
					display: "grid",
					gridTemplateColumns: "auto 1fr",
					overflow: "auto",
					columnGap: 2,
					rowGap: 0.75,
					padding: 1,
					alignContent: "start",
				},
				sx,
			)}
		>
			{items.map((item) => {
				if (item === false) return;
				if ("type" in item) {
					return <SectionComponent key={item.label} section={item} fontVariant={fontVariant} />;
				} else {
					return <FieldComponent key={item.label} field={item} fontVariant={fontVariant} />;
				}
			})}
		</Box>
	);
};

interface SectionComponentProps extends Pick<LabelValueViewProps, "fontVariant"> {
	section: LabelValueViewSection;
}

const SectionComponent = ({ section, fontVariant }: SectionComponentProps) => {
	return (
		<>
			<Typography
				variant="h5"
				sx={{
					gridColumn: "1 / -1",
					marginTop: 1,
					textDecoration: "underline",
				}}
			>
				{section.label}
			</Typography>
			{section.fields.map((field) => (
				<FieldComponent key={field.label} field={field} fontVariant={fontVariant} />
			))}
		</>
	);
};

interface FieldComponentProps extends Pick<LabelValueViewProps, "fontVariant"> {
	field: LabelValueField;
}

const FieldComponent = ({ field, fontVariant }: FieldComponentProps) => {
	if (field.value == null) return;

	let content;
	if (typeof field.value === "boolean") {
		content = (
			<HorizontalBox alignSelf={"center"}>
				{field.value ?
					<FontAwesomeIcon icon={faCheck} />
				:	<FontAwesomeIcon icon={faTimes} />}
			</HorizontalBox>
		);
	} else if (typeof field.value === "number") {
		content = <Typography>{formatNumber(field.value)}</Typography>;
	} else if (field.multiline) {
		content = field.value.split("\n").map((line, index) => (
			<VerticalBox
				key={index}
				sx={{
					gridColumn: "1 / -1",
				}}
			>
				<Typography variant={fontVariant}>{line}</Typography>
			</VerticalBox>
		));
	} else {
		content = <Typography variant={fontVariant}>{field.value}</Typography>;
	}

	return (
		<>
			{field.label != null && (
				<Typography variant={fontVariant} fontWeight={"bold"}>
					{field.label}:
				</Typography>
			)}
			{content}
		</>
	);
};
