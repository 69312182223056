import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import { FormMultiFileInputField } from "src/components/common/forms/fields/FormMultiFileInputField.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { TypographyWithLineBreaks } from "src/components/common/typography/TypographyWithLineBreaks.tsx";
import { DocumentCreationApi } from "src/api/generated/documents/api/documentCreationApi.ts";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/MessageDialogContext.tsx";
import { linebreaksToHtmlBreaks } from "src/utils/strings.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { ShopOrderOperationApi } from "src/api/generated/erp/production/api/shopOrderOperation/shopOrderOperationApi.ts";
import { ShopOrderOperationDocumentService_UploadDocumentsToShopOrderOperationsResult } from "src/api/generated/erp/production/service/shopOrderOperationDocumentService.ts";

export interface ImportDocumentsToShopOrderOperationsButtonProps {
	shopOrderId: number;
}

export const ImportDocumentsToShopOrderOperationsButton = ({
	shopOrderId,
}: ImportDocumentsToShopOrderOperationsButtonProps) => {
	const showFormDialog = useFormInput();
	const showMessageDialog = useMessageDialog();

	return (
		<AsyncButton variant={"menu"} label={i18n.t("import_documents")} icon={faUpload} onClick={onClick} />
	);

	async function onClick() {
		const defaultCategory = await DocumentCreationApi.getDefaultDocumentCategoryForNewDocument({
			objectType: "SHOP_ORDER_OPERATION",
		});

		await showFormDialog<{ documentCategoryId: number; files: File[] }>({
			title: i18n.t("import_documents"),
			defaultValues: {
				documentCategoryId: defaultCategory.documentCategoryId,
			},
			content: ({ control }) => (
				<>
					<FormLazySelectField
						control={control}
						name={"documentCategoryId"}
						label={i18n.t("document_category")}
						fetchOptions={() => DocumentQueryApi.getDocumentCategoryOptions()}
						getOptionKey={(o) => o.documentCategoryId}
						getOptionLabel={(o) => o.name}
						rules={requireRule()}
					/>
					<FormMultiFileInputField
						control={control}
						name={"files"}
						label={i18n.t("files")}
						placeholder={i18n.t("choose_or_drop_file")}
					/>
					<TypographyWithLineBreaks variant={"body2"}>
						{i18n.t("import_documents_to_shop_order_lines_dialog_helper_text")}
					</TypographyWithLineBreaks>
				</>
			),
			submitLabel: i18n.t("import"),
			onSubmit: (input) => importFiles(input.documentCategoryId, input.files),
		});
	}

	async function importFiles(documentCategoryId: number, files: File[]) {
		const fileHandles = await Promise.all(files.map(uploadFile));
		const result = await ShopOrderOperationApi.uploadDocumentsToShopOrderOperations({
			shopOrderId: shopOrderId,
			categoryId: documentCategoryId,
			filesToUpload: fileHandles,
		});

		showMessageAfterImport(result);
	}

	function showMessageAfterImport({
		unresolvedFileNames,
	}: ShopOrderOperationDocumentService_UploadDocumentsToShopOrderOperationsResult) {
		const content =
			unresolvedFileNames.length === 0 ?
				i18n.t("all_files_imported_successfully")
			:	i18n.t("failed_to_find_shop_order_operation_for_following_files") +
				":\n\n" +
				unresolvedFileNames.map((f) => `- ${f}`).join("\n");

		showMessageDialog({
			title: i18n.t("files_imported"),
			content: (
				<VerticalBox my={1} mx={2}>
					{linebreaksToHtmlBreaks(content)}
				</VerticalBox>
			),
		});
	}
};
