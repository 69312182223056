import { CloseDialogFunc, OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { FormCommonProps, FormResult } from "src/components/common/forms/types.ts";
import React, { JSXElementConstructor } from "react";
import { AavoDialogSize } from "src/components/common/dialogs/AavoDialog.tsx";

export interface OpenFormOnDialogParams<TComponentProps, TResult> {
	openDialog: OpenGenericDialogFunc;
	component: JSXElementConstructor<TComponentProps>;
	props: FormComponentPropsProvider<TComponentProps>;
	title: React.ReactNode;
	size?: AavoDialogSize;
	confirmCloseIfEdited?: boolean;
	keepOpenAfterSubmit?: boolean;
	onSubmit?: (result: TResult) => void | Promise<unknown>;
}

export type FormComponentPropsProvider<TComponentProps> =
	| Omit<TComponentProps, "onCompleted" | "onFormEdited">
	| ((closeDialog: CloseDialogFunc) => Omit<TComponentProps, "onCompleted" | "onFormEdited">);

export function openFormOnDialog<TComponentProps, TResult>({
	openDialog,
	component,
	onSubmit,
	props: propsProvider,
	confirmCloseIfEdited = true,
	keepOpenAfterSubmit = false,
	...dialogParams
}: OpenFormOnDialogParams<TComponentProps, TResult>) {
	openDialog(({ closeDialog, onContentEdited }) => {
		const componentProps: Omit<TComponentProps, "onCompleted" | "onFormEdited"> =
			typeof propsProvider === "function" ? propsProvider(closeDialog) : propsProvider;

		const formProps: FormCommonProps<TResult> = {
			onCompleted: async (result: FormResult<TResult>) => {
				if (result.type === "success") {
					if (onSubmit) {
						await onSubmit(result.value);
					}
					if (!keepOpenAfterSubmit) {
						await closeDialog();
					}
				} else if (result.type === "cancel") {
					await closeDialog({
						confirmIfEdited: confirmCloseIfEdited,
					});
				}
			},
			onFormEdited: () => {
				if (confirmCloseIfEdited) {
					onContentEdited();
				}
			},
		};

		const mappedComponentProps = {
			...componentProps,
			...formProps,
		};

		return {
			...dialogParams,
			content: React.createElement(component, mappedComponentProps),
		};
	});
}
