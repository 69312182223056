import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";

export interface UserPermissions {
	production: {
		setShopOrderProductionLine: boolean;
	};
	project: {
		isExternalProjectUser: boolean;
	};
	sales: {
		readCustomerOrders: boolean;
		manageCustomerOfferSalesCommissions: boolean;
		manageCustomerOrderSalesCommissions: boolean;
		onlySalespersonOwnOfferData: boolean;
	};
	documents: {
		denyConnectExistingDocument: boolean;
	};
}

export const useUserPermissions = (): UserPermissions => {
	const { userPermissions } = useGlobalInitData();

	return {
		production: {
			setShopOrderProductionLine: userPermissions.includes("production:set_shop_order_production_line"),
		},
		project: {
			isExternalProjectUser: userPermissions.includes("project:activity_job_queue:external_user"),
		},
		sales: {
			readCustomerOrders: userPermissions.includes("sales:read_customer_orders"),
			manageCustomerOrderSalesCommissions: userPermissions.includes(
				"sales:manage_customer_order_sales_commissions",
			),
			manageCustomerOfferSalesCommissions: userPermissions.includes(
				"sales:manage_customer_offer_sales_commissions",
			),
			onlySalespersonOwnOfferData: userPermissions.includes("sales:only_salesperson_own_offer_data"),
		},
		documents: {
			denyConnectExistingDocument: userPermissions.includes("documents:deny_connect_existing_document"),
		},
	};
};
