import { Control, useFieldArray } from "react-hook-form";
import { castControl } from "src/components/common/forms/formCasting.ts";
import { ObjectAttributeFieldFormComponent } from "./ObjectAttributeFieldFormComponent";
import {
	ObjectAttributeValueWithField
} from "src/api/generated/erp/common/objectAttributes/model/objectAttributeValueWithField.ts";

export interface ObjectAttributesEmbeddedFormProps<TFieldValues extends ObjectAttributesEmbeddedFormValues> {
	control: Control<TFieldValues>;
}

export interface ObjectAttributesEmbeddedFormValues {
	attributes: ObjectAttributeValueWithField[];
}

export const ObjectAttributesEmbeddedForm = <TFieldValues extends ObjectAttributesEmbeddedFormValues>({
	control: controlProp,
}: ObjectAttributesEmbeddedFormProps<TFieldValues>) => {
	const control = castControl<TFieldValues, ObjectAttributesEmbeddedFormValues>(controlProp);

	const attributesFieldArray = useFieldArray({
		control,
		name: "attributes",
	});

	return attributesFieldArray.fields.map((formField, index) => {
		return (
			<ObjectAttributeFieldFormComponent
				key={formField.id}
				control={control}
				name={`attributes.${index}.attributeValue`}
				fieldDefinition={formField.field}
			/>
		);
	});
};
