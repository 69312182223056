import { TaskMobileForm } from "./TaskMobileForm.tsx";
import { SingleTaskMobileViewFooter } from "src/components/views/tasks/mobile/SingleTaskMobileViewFooter.tsx";
import { VerticalBox } from "../../../common/box/VerticalBox.tsx";
import { useState } from "react";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { PageLoading } from "src/components/common/PageLoading.tsx";
import { Divider } from "@mui/material";
import { TasksViewProps } from "src/components/views/tasks/TasksView.tsx";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import dayjs from "dayjs";
import { TaskWithUserNames } from "src/components/views/tasks/types.ts";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { taskIsSaved } from "../taskUtils.ts";
import { TaskSourceRef } from "src/api/generated/tasks/taskSource/taskSourceRef.ts";

export interface SingleTaskMobileViewProps
	extends Pick<TasksViewProps, "onTaskCompleted" | "onTaskCompletionReverted"> {
	task: TaskView | null;
	refreshTask?: () => Promise<TaskView | undefined>;
	updateLocalTask?: (task: TaskWithUserNames) => void;
	closeTaskView: () => void;
	refreshTasks: () => Promise<unknown>;
	newTaskSourceRef?: TaskSourceRef;
}

export const SingleTaskMobileView = ({
	task: taskInitial,
	refreshTask: refreshTaskProp,
	updateLocalTask,
	onTaskCompleted,
	onTaskCompletionReverted,
	closeTaskView,
	refreshTasks,
	newTaskSourceRef,
}: SingleTaskMobileViewProps) => {
	const { appUserId, userName } = useGlobalInitData();
	const [isDirty, setIsDirty] = useState(false);
	const [changesSaved, setChangesSaved] = useState(true);
	const [showOverlaySpinner, setShowOverlaySpinner] = useState(false);
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const [task, setTask] = useState<TaskWithUserNames>(taskInitial ?? getNewTask());

	return (
		<VerticalBox
			sx={{
				flex: 1,
			}}
		>
			<TaskMobileForm task={task} onTaskChanged={onTaskChanged} markTaskDirty={markTaskDirty} />
			<Divider />
			<SingleTaskMobileViewFooter
				task={task}
				refreshTask={refreshTask}
				changesSaved={changesSaved}
				onTaskCompleted={onTaskCompleted}
				onTaskCompletionReverted={onTaskCompletionReverted}
				unmodifiedTask={taskInitial}
				isDirty={isDirty}
				setNotDirty={() => setIsDirty(false)}
				onNewTaskSaved={async () => {
					closeTaskView();
					await refreshTasks();
				}}
			/>
			{showOverlaySpinner && <PageLoading overlay />}
		</VerticalBox>
	);

	function markTaskDirty() {
		setChangesSaved(false);
	}

	async function onTaskChanged(task: TaskWithUserNames) {
		updateLocalTask?.(task);
		setTask(task);
		setIsDirty(true);

		if (taskIsSaved(task)) {
			await updateTask(task);
		}
	}

	async function updateTask(task: TaskWithUserNames) {
		try {
			setChangesSaved(false);
			await TaskUpdateApi.updateTask({ task });
		} catch (e) {
			logErrorAndShowOnDialog(e);
			await refreshTask();
		} finally {
			setChangesSaved(true);
		}
	}

	async function refreshTask() {
		try {
			setShowOverlaySpinner(true);
			const newTask = await refreshTaskProp?.();
			if (newTask !== undefined) setTask(newTask);
		} finally {
			setShowOverlaySpinner(false);
		}
	}

	function getNewTask(): TaskWithUserNames {
		return {
			taskId: -1,
			title: "",
			taskDescription: "",
			sourceDescription: "",
			earliestStartDate: null,
			deadlineDate: dayJsToDateIsoString(dayjs()),
			note: "",
			taskState: "INITIAL",
			createdAt: dayjs().toISOString(),
			createdByUserId: appUserId,
			assignedToUserId: appUserId,
			assignedToUserName: userName,
			responsiblePersonUserId: appUserId,
			responsiblePersonUserName: userName,
			canBeStarted: true,
			showInSchedulePlanning: false,
			lastUpdated: null,
			sourceType: newTaskSourceRef?.sourceType,
			sourceId: newTaskSourceRef?.sourceId,
			releasedDate: null,
			actualStartDate: null,
			completedDate: null,
			cancelledDate: null,
			workloadEstimateHours: null,
			internalContext: "",
			startedByUserId: null,
			completedByUserId: null,
		};
	}
};
