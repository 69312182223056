// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderOfferCopyFormApi {
	export async function getFormInitData(): Promise<CustomerOrderOfferCopyFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/copyForm/getFormInitData",
			method: "POST",
			data: {},
		});
		return response as CustomerOrderOfferCopyFormApi_FormInitData;
	}

	export async function copyCustomerOrderOffer(args: {
		sourceCustomerOrderOfferId: number;
		targetSiteId: number;
		targetCustomerId: number;
		targetPlannedDeliveryDate: IsoDateString;
		targetDeliveryAddressId: number;
		targetInvoiceAddressId: number;
		copyTasks: boolean;
		copyLines: boolean;
		copyDocuments: boolean;
		copyConfigurations?: boolean;
		copyBillingPlan?: boolean;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/copyForm/copyCustomerOrderOffer",
			method: "POST",
			data: {
				sourceCustomerOrderOfferId: args.sourceCustomerOrderOfferId,
				targetSiteId: args.targetSiteId,
				targetCustomerId: args.targetCustomerId,
				targetPlannedDeliveryDate: args.targetPlannedDeliveryDate,
				targetDeliveryAddressId: args.targetDeliveryAddressId,
				targetInvoiceAddressId: args.targetInvoiceAddressId,
				copyTasks: args.copyTasks,
				copyLines: args.copyLines,
				copyDocuments: args.copyDocuments,
				copyConfigurations: args.copyConfigurations,
				copyBillingPlan: args.copyBillingPlan,
			},
		});
		return response as number;
	}
}

export interface CustomerOrderOfferCopyFormApi_FormInitData {
	siteOptions: Array<Site>;
}
