import {
	CatalogPartRevisionAttributeRulesApi,
	CatalogPartRevisionAttributeRulesApi_FormInitData,
} from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionAttributeRulesApi.ts";
import { DeepPartial } from "react-hook-form";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";
import { CatalogPartRevisionAttributeConfigurationRule } from "src/api/generated/erp/db/types/tables/catalogPartRevisionAttributeConfigurationRule.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { CatalogPartRevisionUnrestrictedEditingWarning } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionUnrestrictedEditingWarning.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";

export interface CatalogPartRevisionAttributeRuleFormProps extends FormCommonProps<number> {
	catalogPartRevision: CatalogPartRevisionView;
	ruleId: number | undefined;
}

interface FormValues extends CatalogPartRevisionAttributeConfigurationRule {}

export const CatalogPartRevisionAttributeRuleForm = (props: CatalogPartRevisionAttributeRuleFormProps) => {
	const { catalogPartRevision, ruleId, onCompleted, onFormEdited } = props;

	const disabled = catalogPartRevision.state === "DESIGNED" && !catalogPartRevision.unrestrictedEditingCascaded;

	return (
		<VerticalBox flex={1}>
			<CatalogPartRevisionUnrestrictedEditingWarning revision={catalogPartRevision} />
			<AsyncForm
				fetch={() =>
					CatalogPartRevisionAttributeRulesApi.getFormInitData({
						catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
						ruleId: ruleId,
					})
				}
				getDefaultValues={getDefaultValues}
				getUseFormProps={() => ({
					disabled,
				})}
				submit={submit}
				onCompleted={onCompleted}
				onFormEdited={onFormEdited}
				render={({ control, data: { attributeFieldOptions } }) => (
					<>
						<FormSelectField
							control={control}
							name={"attributeFieldId"}
							label={i18n.t("attribute")}
							disabled={ruleId != null}
							options={attributeFieldOptions}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => option.label}
							rules={requireRule()}
						/>
						<FormConfiguratorLuaEditor
							control={control}
							name={"ruleScript"}
							label={i18n.t("function")}
							productFamilyVersionId={catalogPartRevision.configurationProductFamilyVersionId}
							catalogPartRevisionId={catalogPartRevision.catalogPartRevisionId}
						/>
					</>
				)}
			/>
		</VerticalBox>
	);

	function getDefaultValues({ rule }: CatalogPartRevisionAttributeRulesApi_FormInitData): DeepPartial<FormValues> {
		return (
			rule ?? {
				catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (ruleId == null) {
			return await CatalogPartRevisionAttributeRulesApi.insert({ rule: values });
		} else {
			await CatalogPartRevisionAttributeRulesApi.update({ rule: values });
			return ruleId;
		}
	}
};
