import { LegacyPushAction } from "./legacyPushAction";
import React from "react";

export const PushNotificationsContext = React.createContext<
	PushNotificationsContextValue | undefined
>(undefined);

export interface PushNotificationsContextValue {
	registrationToken: string | null;
	legacyPushAction: LegacyPushAction | null;
	onPushActionHandled: (actionId: string) => void;
	deleteRegistration: () => Promise<void>;
}
