import { createContext } from "react";
import { LoggedError } from "src/errorHandling/errorLogging.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const ErrorDialogContext = createContext<ErrorDialogContextValue | undefined>(undefined);

export const useErrorDialog = () => useContextOrThrow(ErrorDialogContext);

export interface ErrorDialogContextValue {
	showErrorDialog: (error: LoggedError) => void;
	logErrorAndShowOnDialog: LogErrorAndShowOnDialogFunc;
	withErrorHandling: (func: () => unknown | Promise<unknown>) => Promise<void>;
}

export type LogErrorAndShowOnDialogFunc = (error: unknown, internalMessage?: string) => LoggedError;

