import {DeepPartial} from "react-hook-form";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {nonNegativeFloatRule, requireRule} from "src/components/common/forms/validation";
import i18n from "i18next";
import {useState} from "react";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {
    PartReorderPointCalculationParams
} from "src/api/generated/io/aavo/applications/db/erp/types/partReorderPointCalculationParams.ts";
import {inverseNormalStdDev} from "src/utils/statistics.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {SitePartQueryApi} from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/MessageDialogContext.tsx";
import {ChartWithArrowDownIcon} from "src/components/common/icons/ChartWithArrowDownIcon.tsx";
import {round} from "src/utils/numberUtils.ts";
import {MapPinWithArrowDownIcon} from "src/components/common/icons/MapPinWithArrowDownIcon.tsx";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {Site} from "src/api/generated/erp/db/types/tables/site.ts";

export interface PartReorderPointCalculationFormProps extends FormCommonProps<FormResult> {
	calculationParams: PartReorderPointCalculationParams | undefined;
	partId: number;
	site: Site;
}

export interface FormValues extends PartReorderPointCalculationParams {}

export interface FormResult {
	reorderPoint: number | null;
	calculationParams: PartReorderPointCalculationParams;
}

export const PartReorderPointCalculationForm = ({
	calculationParams: defaultCalculationParams,
	partId,
	site,
	onCompleted,
	onFormEdited,
}: PartReorderPointCalculationFormProps) => {
	const defaultValues: DeepPartial<FormValues> =
		defaultCalculationParams != null ? defaultCalculationParams : (
			{
				serviceLevelPercent: site.partReorderPointCalculationDefaultParams?.serviceLevelPercent ?? 95,
				replenishmentTimeDaysAvg: site.partReorderPointCalculationDefaultParams?.replenishmentTimeDaysAvg ?? 0,
				replenishmentTimeDaysStDev:
					site.partReorderPointCalculationDefaultParams?.replenishmentTimeDaysStDev ?? 0,
			}
		);

	const [replenishmentDemandStDev, setReplenishmentDemandStDev] = useState<number | null>(
		calculateReplenishmentDemandStDev(defaultValues),
	);
	const [reorderPoint, setReorderPoint] = useState<number | null>(calculateReorderPoint(defaultValues));

	const showMessageDialog = useMessageDialog();

	return (
		<AavoForm
			defaultValues={defaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submit}
			render={({ control, watch, setValue }) => {
				watch((newValues) => {
					setReplenishmentDemandStDev(calculateReplenishmentDemandStDev(newValues));
					setReorderPoint(calculateReorderPoint(newValues));
				});

				return (
					<>
						<AsyncButton
							iconEl={<ChartWithArrowDownIcon />}
							label={i18n.t("fetch_demand_values_from_control_chart")}
							sx={{
								alignSelf: "flex-start",
							}}
							onClick={async () => {
								const values = await SitePartQueryApi.getPartDemandValuesFromControlChart({
									partId: partId,
								});
								if (values == null) {
									showMessageDialog({
										title: i18n.t("no_demand_values_found"),
										content: i18n.t("part_has_not_demand_values_on_control_chart"),
									});
									return;
								}
								setValue("demandPerDayAvg", values.averageDemand);
								setValue("demandPerDayStDev", round(values.standardDeviation, 2));
							}}
						/>
						<FormNumberField
							control={control}
							name={"demandPerDayAvg"}
							label={`${i18n.t("average_demand_per_day")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"demandPerDayStDev"}
							label={`${i18n.t("demand_st_dev")}*`}
							rules={requireRule()}
						/>
						<AsyncButton
							name={"fetchReplenishmentValues"}
							iconEl={<MapPinWithArrowDownIcon />}
							label={i18n.t("fetch_replenishment_values_from_site")}
							onClick={async () => {
								const site = await SitePartQueryApi.getPartSite({ partId: partId });
								const params = site.partReorderPointCalculationDefaultParams;
								if (
									params == undefined ||
									(params.replenishmentTimeDaysAvg == null &&
										params.replenishmentTimeDaysStDev == null &&
										params.serviceLevelPercent == null)
								) {
									showMessageDialog({
										title: i18n.t("values_not_found"),
										content: i18n.t("no_values_assigned_to_site"),
									});
									return;
								}
								setValue("replenishmentTimeDaysAvg", params.replenishmentTimeDaysAvg);
								setValue("replenishmentTimeDaysStDev", params.replenishmentTimeDaysStDev);
								setValue("serviceLevelPercent", params.serviceLevelPercent);
							}}
						/>
						<FormNumberField
							control={control}
							name={"replenishmentTimeDaysAvg"}
							label={`${i18n.t("replenishment_time_days")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"replenishmentTimeDaysStDev"}
							label={`${i18n.t("replenishment_time_st_dev")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"serviceLevelPercent"}
							label={`${i18n.t("service_level_percent")}*`}
							rules={nonNegativeFloatRule((value) => {
								if (value < 0 || value > 100) return i18n.t("value_must_be_between_0_and_100");
							})}
						/>
						<AavoTextField
							label={i18n.t("replenishment_demand_st_dev")}
							value={replenishmentDemandStDev == null ? "-" : `${replenishmentDemandStDev.toFixed(2)}`}
							disabled
						/>
						<AavoTextField
							label={i18n.t("reorder_point")}
							value={reorderPoint == null ? "-" : `${reorderPoint}`}
							disabled
						/>
						<FormTextField control={control} name={"note"} label={i18n.t("note")} />
					</>
				);
			}}
		/>
	);

	function calculateReplenishmentDemandStDev(values: DeepPartial<FormValues>): number | null {
		if (
			values.demandPerDayAvg == null ||
			values.demandPerDayStDev == null ||
			values.replenishmentTimeDaysAvg == null ||
			values.replenishmentTimeDaysStDev == null
		)
			return null;

		return Math.sqrt(
			values.replenishmentTimeDaysAvg * Math.pow(values.demandPerDayStDev, 2) +
				Math.pow(values.demandPerDayAvg, 2) * Math.pow(values.replenishmentTimeDaysStDev, 2),
		);
	}

	function calculateReorderPoint(values: DeepPartial<FormValues>): number | null {
		const replenishmentDemandStDev = calculateReplenishmentDemandStDev(values);
		if (
			replenishmentDemandStDev == null ||
			values.demandPerDayAvg == null ||
			values.replenishmentTimeDaysAvg == null ||
			values.serviceLevelPercent == null ||
			values.serviceLevelPercent < 0 ||
			values.serviceLevelPercent > 100
		)
			return null;

		const serviceLevelFactor = inverseNormalStdDev(values.serviceLevelPercent / 100);

		return Math.ceil(
			values.demandPerDayAvg * values.replenishmentTimeDaysAvg + serviceLevelFactor * replenishmentDemandStDev,
		);
	}

	function submit(formValues: FormValues): FormResult {
		return {
			reorderPoint: reorderPoint,
			calculationParams: formValues,
		};
	}
};
