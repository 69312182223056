import { AavoDialogProps } from "src/components/common/dialogs/AavoDialog.tsx";
import React from "react";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const GenericDialogContext = React.createContext<GenericDialogContextValue | undefined>(undefined);

export const useGenericDialog = (): GenericDialogContextValue => useContextOrThrow(GenericDialogContext);

export interface GenericDialogContextValue {
	openDialog: OpenGenericDialogFunc;
}

export type OpenGenericDialogFunc = (
	propsProvider: OpenGenericDialogFuncProps | OpenGenericDialogFuncPropsProvider,
) => void;

export type OpenGenericDialogFuncPropsProvider = (params: {
	closeDialog: CloseDialogFunc;
	onContentEdited: () => void;
	onDataDirtyStateChanged: DataDirtyStateChangeHandler;
}) => OpenGenericDialogFuncProps;

export type CloseDialogFunc = (params?: CloseDialogParams) => Promise<void>;

export interface CloseDialogParams {
	confirmIfEdited?: boolean;
}

export interface OpenGenericDialogFuncProps
	extends Omit<AavoDialogProps, "onClose" | "children" | "content"> {
	onClose?: () => unknown | Promise<unknown>;
	content: React.ReactNode;
}