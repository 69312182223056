// This file is automatically generated. Do not edit manually.

import { SalesPartPackageLine } from "src/api/generated/erp/db/types/tables/salesPartPackageLine";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartContentLineFormApi {
	export async function getFormData(args: {
		parentSalesPartId: number;
		childSalesPartPackageLineId: number | null | undefined;
	}): Promise<SalesPartContentLineFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartContentLineForm/getFormData",
			method: "POST",
			data: {
				parentSalesPartId: args.parentSalesPartId,
				childSalesPartPackageLineId: args.childSalesPartPackageLineId,
			},
		});
		return response as SalesPartContentLineFormApi_FormInitData;
	}

	export async function insert(args: { salesPartPackageLine: SalesPartPackageLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartContentLineForm/insert",
			method: "POST",
			data: {
				salesPartPackageLine: args.salesPartPackageLine,
			},
		});
		return response as number;
	}

	export async function update(args: { salesPartPackageLine: SalesPartPackageLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartContentLineForm/update",
			method: "POST",
			data: {
				salesPartPackageLine: args.salesPartPackageLine,
			},
		});
		return response as number;
	}

	export async function getSalesPartOptions(args: {
		parentSalesPartId: number;
		searchQuery: string;
		currentSelection: number | null | undefined;
	}): Promise<Array<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartContentLineForm/getSalesPartOptions",
			method: "POST",
			data: {
				parentSalesPartId: args.parentSalesPartId,
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
			},
		});
		return response as Array<SalesPartView>;
	}
}

export interface SalesPartContentLineFormApi_FormInitData {
	childSalesPart: SalesPartPackageLine | null | undefined;
	nextLineNumber: number;
}
