import { createContext } from "react";
import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { ConfiguratorComponentDragItem } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/configuratorComponentDnd.ts";
import { ConfigurationComponentView } from "src/api/generated/erp/db/types/tables/configurationComponentView.ts";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";

export const ProductFamilyVersionManagingViewContext = createContext<
	ProductFamilyVersionManagingViewContextValue | undefined
>(undefined);

export interface ProductFamilyVersionManagingViewContextValue {
	productFamilyVersion: ConfigurationProductFamilyVersion;
	allComponents: Array<ConfigurationComponentView>;
	selectedComponents: ConfigurationComponentView[];
	selectComponent: (params: SelectComponentParams) => Promise<void | "cancelled">;
	unselectAllComponents: () => Promise<void | "cancelled">;
	addComponent: (newComponent: ConfigurationComponent) => Promise<ConfigurationComponent>;
	updateComponent: (updatedComponent: ConfigurationComponent) => Promise<ConfigurationComponent>;
	deleteComponent: (componentId: number) => Promise<unknown>;
	onComponentDropped: (params: OnDropComponentParams) => void;
	moveComponentsToAnotherTab: (componentIds: number[], newTabId: number) => Promise<unknown>;
	focusedDataIsDirty: boolean;
	setFocusedDataIsDirty: (isDirty: boolean) => void;
	getOrderNumForNewTab: () => number;
	refresh: () => Promise<unknown>;
	limitedEditing: boolean;
}

export interface OnDropComponentParams {
	dragItem: ConfiguratorComponentDragItem;
	targetPosition: number;
	parentTabComponentId: number | undefined;
}

export interface SelectComponentParams {
	component: ConfigurationComponent;
	keepExistingSelections: boolean;
}
