import { WorkspaceConnectionsApi } from "src/api/generated/workspaces/api/workspaceConnectionsApi.ts";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getWorkspaceConnectedItemTypeLabels } from "src/api/generated/postgres/db/types/workspaces/enums/workspaceConnectedItemType.ts";
import { WorkspaceConnectedItem } from "src/api/generated/workspaces/connections/workspaceConnectedItem.ts";
import { AddWorkspaceConnectionsView } from "src/components/views/workspaces/workspaceConnections/AddWorkspaceConnectionsView.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faShareSquare, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { LinkWithPlusIcon } from "src/components/common/icons/LinkWithPlusIcon.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { getWorkspaceConnectedItemConfiguration } from "src/components/views/workspaces/workspaceConnections/connectionTypes/workspaceConnectedItemConfigurations.tsx";

export interface WorkspaceConnectionsDataGridProps {
	workspaceId: number;
}

export const WorkspaceConnectionsDataGrid = ({ workspaceId }: WorkspaceConnectionsDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const refreshRef = useRefreshRef();
	const showConfirmDialog = useConfirmDialog();
	return (
		<ClientSideDataGridModel
			gridId={"7377DA05716CD770"}
			initialParams={{}}
			fetchData={async () => await WorkspaceConnectionsApi.getConnectedItems({ workspaceId: workspaceId })}
			getRows={(data) => data}
			getRowId={(row) => `${row.type}_${row.itemId}`}
			refreshRef={refreshRef}
			render={({ refreshData, dataGridProps, selectedRows, onlySelectedRow }) => {
				return (
					<AavoDataGrid<WorkspaceConnectedItem>
						columns={[
							textColumn({
								field: "name",
								headerName: i18n.t("name"),
								width: 200,
							}),
							enumColumn({
								field: "type",
								headerName: i18n.t("type"),
								enumLabels: getWorkspaceConnectedItemTypeLabels(),
								width: 200,
							}),
						]}
						onRowDoubleClick={({ row }) => openConnectedItem(row)}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faTrash}
									onClick={async () => {
										const confirmed = await showConfirmDialog({
											title: i18n.t("are_you_sure"),
										});
										if (!confirmed) return;
										await WorkspaceConnectionsApi.deleteConnections({
											workspaceId: workspaceId,
											workspaceConnectedItems: selectedRows,
										});
										await refreshData();
									}}
								/>
								<AsyncButton
									iconEl={<LinkWithPlusIcon />}
									label={i18n.t("add")}
									variant={"outlined"}
									onClick={() =>
										openDialog(({ closeDialog }) => ({
											title: i18n.t("add_connection"),
											size: "sm",
											content: (
												<AddWorkspaceConnectionsView
													workspaceId={workspaceId}
													closeDialog={closeDialog}
													connectionViewRefreshRef={refreshRef}
												/>
											),
										}))
									}
								/>
								<AsyncButton
									icon={faShareSquare}
									label={i18n.t("open")}
									variant={"outlined"}
									disabled={!onlySelectedRow}
									onClick={() => {
										if (!onlySelectedRow) return;
										openConnectedItem(onlySelectedRow);
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openConnectedItem(item: WorkspaceConnectedItem) {
		const typeConfiguration = getWorkspaceConnectedItemConfiguration(item.type);
		typeConfiguration.openItemDialog({
			itemId: item.itemId,
			openDialog: openDialog,
		});
	}
};
