import React, { useMemo } from "react";
import { PartConfiguratorDocumentContext } from "./PartConfiguratorDocumentContext";

export interface PartConfiguratorDocumentContextProviderProps extends React.PropsWithChildren {
	componentsWithDocument: number[];
	componentIdWithActiveDocument: number | null;
	setComponentIdWithActiveDocument: (componentId: number) => void;
}

export const PartConfiguratorDocumentContextProvider = ({
	children,
	componentsWithDocument,
	componentIdWithActiveDocument,
	setComponentIdWithActiveDocument,
}: PartConfiguratorDocumentContextProviderProps) => {
	const contextValue = useMemo(
		() => ({
			getComponentHasDocument: (componentId: number) => componentsWithDocument.includes(componentId),
			componentIdWithActiveDocument,
			setComponentIdWithActiveDocument,
		}),
		[componentsWithDocument, componentIdWithActiveDocument, setComponentIdWithActiveDocument],
	);

	return (
		<PartConfiguratorDocumentContext.Provider value={contextValue}>
			{children}
		</PartConfiguratorDocumentContext.Provider>
	);
};
