import React, { useCallback, useState } from "react";
import { ConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialog";
import { ConfirmDialogContext, ShowConfirmDialogFuncParams } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import i18n from "i18next";

export interface ConfirmProviderProps extends React.PropsWithChildren {}

type ResolveFunction = (value: boolean | PromiseLike<boolean>) => void;

const DEFAULT_CONFIRM_OPTIONS = {
	title: i18n.t("are_you_sure"),
};

export const ConfirmDialogProvider = ({ children }: ConfirmProviderProps) => {
	const [options, setOptions] = useState<ShowConfirmDialogFuncParams>(DEFAULT_CONFIRM_OPTIONS);
	const [resolveFuncList, setResolveFuncList] = useState<[ResolveFunction] | []>([]);
	const resolveFunc = resolveFuncList[0];

	const confirm = (o?: ShowConfirmDialogFuncParams) => {
		return new Promise<boolean>((resolve) => {
			setOptions(o ?? {});
			setResolveFuncList([resolve]);
		});
	};

	const closeDialog = useCallback(() => {
		setResolveFuncList([]);
	}, [setResolveFuncList]);

	const onCancel = useCallback(() => {
		resolveFunc?.(false);
		closeDialog();
	}, [resolveFunc, closeDialog]);

	const onConfirm = useCallback(() => {
		resolveFunc?.(true);
		closeDialog();
	}, [resolveFunc, closeDialog]);

	return (
		<>
			<ConfirmDialogContext.Provider value={confirm}>{children}</ConfirmDialogContext.Provider>
			{resolveFunc !== undefined && (
				<ConfirmDialog onConfirm={onConfirm} onClose={onCancel} {...options}></ConfirmDialog>
			)}
		</>
	);
};
