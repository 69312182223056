import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { faShareSquare } from "@fortawesome/pro-regular-svg-icons";
import { WorkspacesView } from "../WorkspacesView";
import { WorkspaceConnectionsApi } from "src/api/generated/workspaces/api/workspaceConnectionsApi";
import { WorkspaceConnectedItemType } from "src/api/generated/postgres/db/types/workspaces/enums/workspaceConnectedItemType.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface ConnectedItemWorkspacesDataGridProps {
	connectedItemType: WorkspaceConnectedItemType;
	connectedItemId: number;
}

export const ConnectedItemWorkspacesDataGrid = ({
	connectedItemType,
	connectedItemId,
}: ConnectedItemWorkspacesDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			gridId={"D6F928DD53230A04"}
			fetchData={() =>
				WorkspaceConnectionsApi.getConnectedItemWorkspaces({
					connectedItemType,
					connectedItemId,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) => row.workspaceId}
			render={({ dataGridProps, onlySelectedRow }) => {
				return (
					<CrudDataGrid<WorkspaceView>
						columns={[
							textColumn({
								field: "workspaceName",
								headerName: i18n.t("workspace"),
							}),
							textColumn({
								field: "description",
								headerName: i18n.t("description"),
								width: 300,
							}),
							textColumn({
								field: "responsiblePersonName",
								headerName: i18n.t("responsible_person"),
							}),
						]}
						onRowDoubleClick={({ row }) => openWorkspace(row)}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faShareSquare}
									label={i18n.t("open")}
									disabled={!onlySelectedRow}
									variant={"outlined"}
									onClick={() => {
										if (onlySelectedRow == null) return;
										openWorkspace(onlySelectedRow);
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openWorkspace(workspace: WorkspaceView) {
		openDialog({
			title: workspace.workspaceName,
			content: <WorkspacesView onlyWorkspaceIdToShow={workspace.workspaceId} />,
		});
	}
};
