import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { dateTimeColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SurveyFormRevisionApi } from "src/api/generated/erp/surveys/api/surveyFormRevisionApi.ts";
import { SurveyFormRevisionView } from "src/api/generated/erp/db/types/tables/surveyFormRevisionView.ts";
import { getSurveyFormRevisionStateLabels } from "src/api/generated/erp/db/types/enums/surveyFormRevisionState.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { SurveyFormRevisionForm } from "src/components/views/erp/surveys/management/revisions/SurveyFormRevisionForm.tsx";
import { faFilePen, faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface SurveyFormRevisionsDataGridProps {
	surveyFormId: number;
	onSelectionChanged: (selectedRow: SurveyFormRevisionView | undefined) => void;
}

export const SurveyFormRevisionsDataGrid = ({ surveyFormId, onSelectionChanged }: SurveyFormRevisionsDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();

	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			SurveyFormRevisionApi.getSurveyFormRevisions({
				surveyFormId: surveyFormId,
			}),
		initialParams: {},
		getRowId: (row) => row.surveyFormRevisionId,
		gridId: "C826058BD88A761C",
		selectFirstRowOnLoad: true,
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
	});

	return (
		<ControlledAsyncCrudDataGrid<SurveyFormRevisionView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
					width: 100,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 200,
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					width: 150,
					enumLabels: getSurveyFormRevisionStateLabels(),
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 180,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
					width: 100,
				}),
				dateTimeColumn({
					field: "releasedAt",
					headerName: i18n.t("released_at"),
					width: 180,
				}),
				textColumn({
					field: "releasedByUserName",
					headerName: i18n.t("released_by"),
					width: 100,
				}),
				integerColumn({
					field: "surveyFormRevisionId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("preview")}
						icon={faFilePen}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={() => {
							openFormOnDialog({
								openDialog,
								title: i18n.t("preview"),
								component: SurveySubmissionForm,
								size: "lg",
								props: {
									surveyFormId,
									surveyFormRevisionId: onlySelectedRow!.surveyFormRevisionId,
									disableSubmit: true,
								},
								confirmCloseIfEdited: false,
							});
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				row.state === "INITIAL" && (
					<AsyncMenuButton
						key={"release"}
						label={i18n.t("release")}
						icon={faShare}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await SurveyFormRevisionApi.releaseRevision({
								surveyFormRevisionId: row.surveyFormRevisionId,
							});
							await refreshData();
						}}
					/>
				),
				row.state === "RELEASED" && (
					<AsyncMenuButton
						key={"revertRelease"}
						label={i18n.t("revert_release")}
						icon={faUndo}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await SurveyFormRevisionApi.revertReleaseRevision({
								surveyFormRevisionId: row.surveyFormRevisionId,
							});
							await refreshData();
						}}
					/>
				),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("survey_form_revision"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<SurveyFormRevisionForm
						surveyFormId={surveyFormId}
						surveyFormRevisionId={row?.surveyFormRevisionId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={
				onlySelectedRow?.state !== "INITIAL" ?
					{
						type: "disabled",
						tooltip: i18n.t("only_initial_revisions_can_be_deleted"),
					}
				:	{
						type: "enabled",
						action: ({ row }) =>
							SurveyFormRevisionApi.delete_({ surveyFormRevisionId: row.surveyFormRevisionId }),
					}
			}
			{...dataGridProps}
		/>
	);
};
