import { PartConfiguration } from "src/api/generated/erp/db/types/tables/partConfiguration.ts";
import { AavoGridColDef } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { PartConfigurationBomLineView } from "src/api/generated/erp/db/types/tables/partConfigurationBomLineView.ts";
import { enumColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPartRevisionLineAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/partRevisionLineAcquisitionMethod.ts";

export function getPartConfigurationBomDataGridColumns(
	partConfiguration: PartConfiguration,
): AavoGridColDef<PartConfigurationBomLineView>[] {
	const hasBomPriceCalculation = partConfiguration.priceCalculationMethod === "BOM";

	return [
		integerColumn({
			field: "referenceNo",
			headerName: i18n.t("reference_no"),
			width: 80,
		}),
		{
			field: "part",
			headerName: i18n.t("part"),
			width: 300,
			valueGetter: (_, row) =>
				row.bomLinePartConfigurationId != null ? row.linePartConfigurationDescription : row.linePartLongName,
		},
		floatColumn({
			field: "quantity",
			headerName: i18n.t("quantity"),
			width: 80,
		}),
		textColumn({
			field: "linePartUnitName",
			headerName: i18n.t("unit"),
			width: 80,
		}),
		hasBomPriceCalculation &&
			floatColumn({
				field: "unitPrice",
				headerName: i18n.t("unit_price"),
			}),
		hasBomPriceCalculation &&
			floatColumn({
				field: "totalPrice",
				headerName: i18n.t("total_price"),
			}),
		floatColumn({
			field: "bomLineUnitMaterialCost",
			headerName: i18n.t("material_cost"),
		}),
		floatColumn({
			field: "bomLineUnitWorkCost",
			headerName: i18n.t("work_cost"),
		}),
		floatColumn({
			field: "bomLineUnitCost",
			headerName: i18n.t("unit_cost"),
		}),
		floatColumn({
			field: "bomLineTotalCost",
			headerName: i18n.t("total_cost"),
		}),
		enumColumn({
			field: "acquisitionMethod",
			headerName: i18n.t("acquisition_method"),
			enumLabels: getPartRevisionLineAcquisitionMethodLabels(),
		}),
		textColumn({
			field: "note",
			headerName: i18n.t("note"),
			width: 200,
		}),
		textColumn({
			field: "operationLongName",
			headerName: i18n.t("operation"),
			width: 300,
		}),
	];
}
