import {FormCommonProps} from "src/components/common/forms/types.ts";
import {PaymentTerm} from "src/api/generated/erp/db/types/tables/paymentTerm.ts";
import {AavoForm, AavoFormContentParams} from "src/components/common/forms/AavoForm.tsx";
import {PaymentTermsApi} from "src/api/generated/erp/sales/basedata/api/paymentTermsApi.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import {requireRule} from "src/components/common/forms/validation.ts";

export interface PaymentTermFormProps extends FormCommonProps<number> {
	paymentTerm?: PaymentTerm;
}

export const PaymentTermForm = (props: PaymentTermFormProps) => {
	const { paymentTerm, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={
				paymentTerm != null ?
					{ ...paymentTerm }
				:	{
						paymentTermDesc: "",
						discountDays: 0,
						discountPercent: 0,
					}
			}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: PaymentTerm): Promise<number> {
		if (values.paymentTermId == null) {
			return await PaymentTermsApi.insert({ paymentTerm: values });
		} else {
			await PaymentTermsApi.update({ paymentTerm: values });
			return values.paymentTermId;
		}
	}
};

interface FormContentProps extends PaymentTermFormProps, AavoFormContentParams<PaymentTerm> {}

const FormContent = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField
				control={control}
				name={"paymentTerm"}
				label={i18n.t("payment_term")}
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"paymentTermDesc"} label={i18n.t("description")} />
			<FormNumberField
				control={control}
				name={"paymentDays"}
				label={i18n.t("payment_days")}
				rules={requireRule()}
			/>
			<FormNumberField control={control} name={"discountDays"} label={i18n.t("discount_days")} />
			<FormNumberField control={control} name={"discountPercent"} label={i18n.t("discount_percent")} />
		</>
	);
};
