import React, { MouseEventHandler, MutableRefObject, useCallback, useEffect, useId, useMemo } from "react";
import {
	DataGridProProps,
	gridPreferencePanelStateSelector,
	GridPreferencePanelsValue,
	GridRowClassNameParams,
	GridRowId,
	GridToolbarFilterButton,
	useGridApiContext,
	useGridApiRef,
	useGridSelector,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "src/components/common/dataGrid/styles/StyledDataGrid.tsx";
import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "../contextMenu/AavoContextMenu";
import {
	flattenAavoDataGridColumns,
	getDataGridRowsByIds,
	mergeRowClassNames,
} from "src/components/common/dataGrid/utils.ts";
import { dataGridRowClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import { Box, useTheme } from "@mui/material";
import { faColumns3, faFilter, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { AavoDataGridApi } from "src/components/common/dataGrid/AavoDataGridApi.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AavoDataGridContext } from "./AavoDataGridContext";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { HorizontalBox } from "../box/HorizontalBox.tsx";
import { actionBarSxContentOnly } from "../actionBar/actionBarStyles.ts";
import { AavoMenuButton } from "../menu/AavoMenuButton.tsx";
import { VerticalBox } from "../box/VerticalBox.tsx";
import { isTouchDevice } from "src/utils/isTouchDevice.ts";
import { AsyncMenuButton } from "../contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { useDataGridColumnStateSaving } from "src/components/common/dataGrid/useDataGridColumnStateSaving.ts";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";

export interface AavoDataGridProps<RowData extends object>
	extends Omit<
			DataGridProProps<RowData>,
			| "onRowSelectionModelChange"
			| "rowSelectionModel"
			| "initialState"
			| "getRowClassName"
			| "columns"
			| "apiRef"
			| "getRowId"
		>,
		Required<Pick<DataGridProProps<RowData>, "initialState" | "getRowId">> {
	columns: AavoGridColDef<RowData>[];
	selectedRows?: GridRowId[];
	onRowSelectionChanged?: (selectedRowIds: GridRowId[]) => void;
	refreshData?: () => Promise<unknown>;
	actionBarComponents?: React.ReactNode;
	actionBarMenuComponents?: React.ReactNode;
	actionBarMenuComponents2?: React.ReactNode; // A bit hacky way to make data grid wrapper hooks able to add default menu components.
	rowContextMenuComponents?: (
		params: AavoDataGridRowContextMenuParams<RowData>,
	) => React.ReactNode | Promise<React.ReactNode>;
	getRowClassNames?: (params: GridRowClassNameParams<RowData>) => string[] | string | undefined;
	apiRef?: React.MutableRefObject<AavoDataGridApi<RowData> | null>;
	actionBarMenuButtonRef?: React.Ref<HTMLButtonElement>;
	checkboxSelectOnTouchDevice?: boolean;
	disableColumnSorting?: boolean;
	disableColumnOrderPersist?: boolean;
}

export type AavoGridColDef<RowData extends object> =
	// false and undefined are used to hide columns
	| false
	| undefined
	| (GridColDef<RowData> & {
			position?: number;
			afterColumn?: Exclude<keyof RowData, symbol | number>;
	  })
	| AavoGridColDef<RowData>[];

export interface AavoDataGridRowContextMenuParams<RowData extends object> {
	row: RowData;
	allSelectedRows: RowData[];
	multipleRowsSelected: boolean;
	onlySingleRowSelected: boolean;
}

export const AavoDataGrid = <RowData extends object>(props: AavoDataGridProps<RowData>) => {
	const {
		apiRef: forwardedApiRef,
		selectedRows: selectedRowsProp,
		onRowSelectionChanged,
		hideFooter = true,
		rowContextMenuComponents,
		slotProps,
		getRowClassNames,
		columns,
		getRowId,
		sx,
		refreshData,
		actionBarComponents,
		actionBarMenuComponents,
		actionBarMenuComponents2,
		actionBarMenuButtonRef,
		slots,
		autosizeOnMount = false,
		// TODO try autosizing again with MUI Data Grid V8
		// Currently autosizing fails to disable column virtualization when called on mount with setTimeout
		autosizeOptions = {
			includeOutliers: true,
			includeHeaders: true,
		},
		checkboxSelectOnTouchDevice = false,
		initialState,
		disableColumnSorting = false,
		onColumnOrderChange,
		onColumnWidthChange,
		disableColumnReorder = false,
		disableColumnOrderPersist = disableColumnReorder,
		...other
	} = props;

	const gridApiRef = useGridApiRef() as MutableRefObject<GridApiPro>;
	const theme = useTheme();

	const [openContextMenu, contextMenuState] = useContextMenu();

	const [rowSelectionModel, setRowSelectionModel] = useMaybeControlledState<GridRowId[]>({
		controlledValue: selectedRowsProp,
		onChange: onRowSelectionChanged,
		defaultValue: [],
	});

	const getGridApi = useCallback(() => {
		const current = gridApiRef.current;
		if (current === null) throw new Error("GridApiRef has no value");

		return current;
	}, [gridApiRef]);

	const getRowClassName = useCallback(
		(params: GridRowClassNameParams): string => {
			let fromProps = getRowClassNames?.(params) ?? [];
			if (typeof fromProps === "string") fromProps = [fromProps];
			return mergeRowClassNames(
				...fromProps,
				params.indexRelativeToCurrentPage % 2 === 0 ? dataGridRowClassNames.even : dataGridRowClassNames.odd,
			);
		},
		[getRowClassNames],
	);

	const onContextMenu: MouseEventHandler<any> = useCallback(
		(event) => {
			if (event.type === "contextmenu" && rowContextMenuComponents !== undefined) {
				event.preventDefault();
				let rowId = event.currentTarget.getAttribute("data-id");
				const gridApi = getGridApi();
				if (rowId == null || gridApiRef.current === undefined) return;
				const row = gridApi.getRow(rowId);
				if (row == null) return;

				rowId = getRowId(row); // Set rowId again to ensure it has correct type.
				let selectedRowIds = rowSelectionModel;
				const clickedRowIsSelected = rowSelectionModel.includes(rowId);
				if (!clickedRowIsSelected) {
					selectedRowIds = [rowId];
					setRowSelectionModel(selectedRowIds);
				}
				const selectedRows = getDataGridRowsByIds<RowData>(getGridApi(), selectedRowIds);

				openContextMenu({
					content: () =>
						rowContextMenuComponents({
							row: row,
							allSelectedRows: selectedRows,
							multipleRowsSelected: selectedRows.length > 1,
							onlySingleRowSelected: selectedRows.length === 1,
						}),
					mouseEvent: event,
				});
			}
		},
		[
			rowContextMenuComponents,
			getGridApi,
			gridApiRef,
			rowSelectionModel,
			openContextMenu,
			setRowSelectionModel,
			getRowId,
		],
	);

	const slotPropsWrapped: GridProSlotProps = useMemo(
		() => ({
			...slotProps,
			row: {
				...slotProps?.row,
				onContextMenu: onContextMenu,
			},
			baseSelect: {
				...slotProps?.baseSelect,
				MenuProps: {
					...slotProps?.baseSelect?.MenuProps,
					// Don't terminate editing when selecting value with Enter on select column
					onKeyDown: (e) => {
						if (e.key === "Enter") {
							e.stopPropagation();
						}
					},
				},
			},
		}),
		[slotProps, onContextMenu],
	);

	const getRowModelsTyped = () => {
		const gridApi = getGridApi();
		return gridApi.getRowModels() as Map<GridRowId, RowData>;
	};

	useEffect(() => {
		if (forwardedApiRef)
			forwardedApiRef.current = {
				...gridApiRef.current,
				...forwardedApiRef.current,
				getRowModels: getRowModelsTyped,
				getRows: () => {
					const asMap = getRowModelsTyped();
					return Array.from(asMap.values());
				},
			};
	});

	const flattenColumns = flattenAavoDataGridColumns(columns);
	const columnsMaybeWithoutSorting = disableColumnSorting ? disableSortingOnColumns(flattenColumns) : flattenColumns;

	const allActionBarComponents = [
		actionBarMenuComponents,
		actionBarMenuComponents2,
		<CommonMenuActionBarComponents key={"common"} />,
	];

	const propsFromUseColumnStateSaving = useDataGridColumnStateSaving({
		columns: columnsMaybeWithoutSorting,
		initialState: initialState,
		autosizeOptions: autosizeOptions,
		onColumnOrderChange: onColumnOrderChange,
		onColumnWidthChange: onColumnWidthChange,
		disableColumnOrderPersist: disableColumnOrderPersist,
	});

	return (
		<VerticalBox flex={1} sx={sx}>
			<AavoDataGridContext.Provider
				value={{
					refreshData,
					actionBarComponents,
					actionBarMenuButtonRef,
					actionBarMenuComponents: allActionBarComponents,
				}}
			>
				<StyledDataGrid
					// TODO remove cast when bugfix to MUI is released
					// See https://github.com/mui/mui-x/issues/16135
					apiRef={gridApiRef}
					pageSizeOptions={PAGE_SIZE_OPTIONS}
					rowSelectionModel={rowSelectionModel}
					onRowSelectionModelChange={(rowSelectionModel) => setRowSelectionModel([...rowSelectionModel])}
					hideFooter={hideFooter}
					slotProps={slotPropsWrapped}
					getRowClassName={getRowClassName}
					getRowId={getRowId}
					rowHeight={theme.typography.fontSize * 2.5}
					columnHeaderHeight={theme.typography.fontSize * 2.125}
					autosizeOnMount={autosizeOnMount}
					initialState={initialState}
					checkboxSelection={checkboxSelectOnTouchDevice && isTouchDevice()}
					disableColumnReorder={disableColumnReorder}
					slots={{
						toolbar: DataGridActionBar,
						openFilterButtonIcon: OpenFilterButtonIcon,
						...slots,
					}}
					{...propsFromUseColumnStateSaving}
					{...other}
				/>
			</AavoDataGridContext.Provider>
			<AavoContextMenu state={contextMenuState} />
		</VerticalBox>
	);
};

const DataGridActionBar = () => {
	const { refreshData, actionBarComponents, actionBarMenuComponents, actionBarMenuButtonRef } =
		useContextOrThrow(AavoDataGridContext);

	return (
		<HorizontalBox
			sx={{
				flexShrink: 0,
				paddingX: 0.5,
				paddingY: 1,
			}}
		>
			<AavoActionBar
				sx={{
					padding: 0,
					flex: 1,
				}}
			>
				{refreshData && (
					<AsyncButton
						icon={faRefresh}
						color={"primary"}
						onClick={async () => {
							if (refreshData) await refreshData();
						}}
					/>
				)}
				<GridToolbarFilterButton
					slotProps={{
						button: {
							children: "Filter",
							className: "MuiIconButton-root",
							sx: {
								fontSize: "0 !important",
								padding: 0,
								minWidth: 0,
								"& .MuiButton-startIcon": {
									margin: 0,
									"& .MuiBadge-root": {
										fontSize: "1.5rem",
									},
								},
							},
						},
					}}
				/>
				{actionBarComponents}
			</AavoActionBar>
			{actionBarMenuComponents != undefined && (
				<Box sx={actionBarSxContentOnly}>
					<AavoMenuButton buttonRef={actionBarMenuButtonRef} menuContent={actionBarMenuComponents} />
				</Box>
			)}
		</HorizontalBox>
	);
};

const OpenFilterButtonIcon = () => {
	return <FontAwesomeIcon icon={faFilter} />;
};

const CommonMenuActionBarComponents = () => {
	const apiRef = useGridApiContext();

	const columnButtonId = useId();
	const columnPanelId = useId();

	const preferencePanel = useGridSelector(apiRef, gridPreferencePanelStateSelector);

	return [
		<AsyncMenuButton
			key={"manageColumns"}
			id={columnButtonId}
			icon={faColumns3}
			label={i18n.t("columns")}
			onClick={() => {
				if (preferencePanel.open && preferencePanel.openedPanelValue === GridPreferencePanelsValue.columns) {
					apiRef.current.hidePreferences();
				} else {
					apiRef.current.showPreferences(GridPreferencePanelsValue.columns, columnPanelId, columnButtonId);
				}
			}}
		/>,
	];
};

const PAGE_SIZE_OPTIONS = [25, 50, 100, 150];

function disableSortingOnColumns(columns: GridColDef[]): GridColDef[] {
	return columns.map((column) => {
		return {
			...column,
			sortable: false,
		};
	});
}
