import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { CatalogPartRevisionConfiguratorSelectionsApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionConfiguratorSelectionsApi.ts";
import {
	CatalogPartRevisionConfiguratorSelectionsView,
	CatalogPartRevisionConfiguratorSelectionsViewProps,
} from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsView.tsx";

export interface CatalogPartRevisionConfiguratorSelectionsAsyncViewProps
	extends Omit<
		CatalogPartRevisionConfiguratorSelectionsViewProps,
		"catalogPartRevision" | "productFamilySelectionOptions" | "currentSelections"
	> {
	catalogPartRevisionId: number;
}

export const CatalogPartRevisionConfiguratorSelectionsAsyncView = (
	props: CatalogPartRevisionConfiguratorSelectionsAsyncViewProps,
) => {
	const { catalogPartRevisionId, productFamilyVersionId } = props;

	return (
		<AsyncFetchRender
			fetch={() =>
				CatalogPartRevisionConfiguratorSelectionsApi.getSelectionsViewInitData({
					catalogPartRevisionId,
					productFamilyVersionId,
				})
			}
			content={({ catalogPartRevision, selectionOptions, currentSelections }) => (
				<CatalogPartRevisionConfiguratorSelectionsView
					catalogPartRevision={catalogPartRevision}
					productFamilySelectionOptions={selectionOptions}
					currentSelections={currentSelections}
					{...props}
				/>
			)}
		/>
	);
};
