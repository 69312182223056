import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import {
	AsyncSelectField,
	AsyncSelectFieldProps,
	FetchAsyncOptionParams,
} from "src/components/common/inputFields/AsyncSelectField";
import { GridValueOptionsParams } from "@mui/x-data-grid/models/params/gridValueOptionsParams";

export interface AavoDataGridAsyncSingleSelectEditorProps<
	TRowData extends GridValidRowModel,
	TOption,
	TKey extends string | number,
> extends GridRenderEditCellParams<TRowData, TKey>,
		Pick<AsyncSelectFieldProps<TOption, TKey>, "getOptionKey" | "getOptionLabel"> {
	fetchOptions: (params: FetchAsyncColumnOptionsParams<TRowData, TKey>) => Promise<TOption[]>;
}

interface FetchAsyncColumnOptionsParams<TRowData extends GridValidRowModel, TKey>
	extends FetchAsyncOptionParams<TKey>,
		GridValueOptionsParams<TRowData> {}

export const AavoDataGridAsyncSingleSelectEditor = <
	TRowData extends GridValidRowModel,
	TOption,
	TKey extends string | number,
>({
	api,
	row,
	id,
	value,
	field,
	hasFocus,
	fetchOptions,
	getOptionKey,
	getOptionLabel,
}: AavoDataGridAsyncSingleSelectEditorProps<TRowData, TOption, TKey>) => {
	// Default value may be selected option (if object) or current key (otherwise)
	const valueIsOption = value != null && typeof value === "object";
	const defaultValue = valueIsOption ? getOptionKey(value) : value;

	return (
		<AsyncSelectField<TOption, TKey>
			defaultValue={defaultValue}
			label={""}
			autoFocus={hasFocus}
			openOnFocus={true}
			fetchOptions={(params) =>
				fetchOptions({
					...params,
					field: field,
					id: id,
					row: row,
				})
			}
			getOptionKey={getOptionKey}
			getOptionLabel={getOptionLabel}
			onChange={(newValue) => {
				api.setEditCellValue({ id, field, value: newValue });
			}}
			sx={{
				width: "100%",
				alignSelf: "stretch",
				display: "flex",
			}}
			TextFieldProps={{
				variant: "standard",
				InputProps: {
					style: {
						alignContent: "center",
					},
				},
			}}
		/>
	);
};
