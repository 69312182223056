import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
	ConfiguratorTableDataGridRow,
	ConfiguratorTableDataGridState,
	ConfiguratorTableDataGridStateAction,
} from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/configuratorTableDataGridState.ts";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { alpha } from "@mui/material/styles";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import React from "react";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { InsertRowsAboveIcon } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/icons/InsertRowsAboveIcon.tsx";
import { InsertRowsBelowIcon } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/icons/InsertRowsBelowIcon.tsx";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { ROW_HEADER_NAME_FIELD } from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/ConfiguratorTableTabDataGrid.constants.tsx";

export interface ConfiguratorTableTabDataGridCellRendererProps
	extends GridRenderCellParams<ConfiguratorTableDataGridRow> {
	state: ConfiguratorTableDataGridState;
	dispatchState: (action: ConfiguratorTableDataGridStateAction) => void;
}

export const ConfiguratorTableTabDataGridCellRenderer = ({
	row,
	colDef,
	formattedValue,
	api,
	state,
	dispatchState,
}: ConfiguratorTableTabDataGridCellRendererProps) => {
	const [openContextMenu, contextMenuState] = useContextMenu();

	const isRowHeaderCell = colDef.field === ROW_HEADER_NAME_FIELD;

	return (
		<VerticalBox
			sx={mergeSx(
				{
					height: "100%",
					width: "100%",
					justifyContent: "center",
					padding: "0 0.5rem",
					borderBottom: "1px solid",
					borderRight: "1px solid",
					borderColor: "grey.400",
				},
				isRowHeaderCell && {
					fontWeight: "bold",
					fontSize: "1.20rem",
					borderColor: "grey.600",
					backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
				},
			)}
			onContextMenu={(e) => onContextMenu(e)}
		>
			{formattedValue}
			<AavoContextMenu state={contextMenuState} />
		</VerticalBox>
	);

	function onContextMenu(e: React.MouseEvent<HTMLDivElement>) {
		const rowIndex = state.rows.findIndex((r) => r.rowId === row.rowId);
		if (rowIndex === -1) return;

		const selectedRowsMap = api.getSelectedRows() as Map<string, ConfiguratorTableDataGridRow>;
		const selectedRows = Array.from(selectedRowsMap.values());

		let actualSelectedRows = selectedRows;
		if (selectedRows.length < 2 && !selectedRows.includes(row)) {
			api.selectRow(row.rowId, true, true);
			actualSelectedRows = [row];
		}
		if (e.ctrlKey) {
			api.selectRow(row.rowId);
			actualSelectedRows = [...selectedRows, row];
		}

		openContextMenu({
			mouseEvent: e,
			content: (
				<RowContextMenuContent
					dispatchState={dispatchState}
					selectedRows={actualSelectedRows}
					rowIndex={rowIndex}
				/>
			),
		});
	}
};

interface RowContextMenuContentProps {
	rowIndex: number;
	selectedRows: ConfiguratorTableDataGridRow[];
	dispatchState: (action: ConfiguratorTableDataGridStateAction) => void;
}

const RowContextMenuContent = ({ rowIndex, selectedRows, dispatchState }: RowContextMenuContentProps) => {
	const showInputDialog = useInputDialog();

	return [
		<AsyncMenuButton
			key={"addRowsAbove"}
			label={i18n.t("insert_rows_above")}
			iconEl={<InsertRowsAboveIcon />}
			onClick={() => insertRows("above")}
		/>,
		<AsyncMenuButton
			key={"addRowsBelow"}
			label={i18n.t("insert_rows_below")}
			iconEl={<InsertRowsBelowIcon />}
			onClick={() => insertRows("below")}
		/>,
		selectedRows.length > 0 && (
			<AsyncMenuButton
				key={"deleteRows"}
				label={i18n.t("delete_rows")}
				icon={faTrash}
				onClick={() => {
					dispatchState({ type: "deleteRows", rowIds: selectedRows.map((row) => row.rowId) });
				}}
			/>
		),
	];

	async function insertRows(direction: "above" | "below") {
		const rowCount = await showInputDialog({
			type: "integer",
			title: i18n.t("insert_rows"),
			fieldLabel: i18n.t("number_of_rows"),
			defaultValue: null,
			required: true,
		});
		if (rowCount == undefined) return;

		const startIndex = direction === "above" ? rowIndex : rowIndex + 1;
		const addRowsParams = Array.from({ length: rowCount }).map((_row, index) => {
			return {
				rowName: "",
				index: startIndex + index,
			};
		});
		dispatchState({ type: "addRows", params: addRowsParams });
	}
};
