import { DataGridFormDefinition } from "src/components/common/dataGrid/types";
import { GridRowId } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { logError } from "src/errorHandling/errorLogging";
import type { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { FormResult } from "src/components/common/forms/types.ts";

export const openFormForDataGridRow = async <RowData extends object>({
	row,
	openDialog,
	form,
	refreshData,
	getApi,
}: {
	openDialog: OpenGenericDialogFunc;
	form: DataGridFormDefinition<RowData> | undefined;
	row: RowData | undefined;
	refreshData: () => Promise<unknown>;
	getApi: () => GridApiPro;
}) => {
	if (form === undefined) return;

	const dialogTitle = typeof form.dialogTitle === "function" ? form.dialogTitle(row) : form.dialogTitle;
	const dialogSize = typeof form.dialogSize === "function" ? form.dialogSize(row) : form.dialogSize;
	const extraParamsResult = form.getExtraParams === undefined ? undefined : await form.getExtraParams(row);
	if (extraParamsResult === "interrupted") return;

	openDialog(({ closeDialog, onContentEdited, onDataDirtyStateChanged }) => ({
		size: dialogSize,
		title: dialogTitle,
		content: form.component({
			row: row,
			extraParams: extraParamsResult,
			closeDialog: closeDialog,
			onFormEdited: onContentEdited,
			onFormDirtyStateChanged: onDataDirtyStateChanged,
			onCompleted: async (result) => {
				try {
					await closeDialog({
						confirmIfEdited: result.type === "cancel",
					});
					await onCloseFormDialog(result);
				} catch (e) {
					logError(e);
				}
			},
		}),
	}));

	async function onCloseFormDialog(result: FormResult<GridRowId | undefined>) {
		if (result.type === "cancel") return;

		await refreshData();
		const editedRowId = result.value;
		if (editedRowId === undefined) return;

		// Select and scroll to the edited row.
		// This is quite a hard task, because new row may not yet be in state of MUI data grid
		// First we use setTimeout with 0ms delay to wait for the row to be added to the grid
		// And then select it and try to scroll
		// However, virtual scroller area may not yet be updated,
		// so we wait for arbitrary 500ms and try to scroll again
		// Second scrolling may remove selection, so we select row again.

		setTimeout(() => {
			scrollToRow(editedRowId);
			selectRow(editedRowId);
		}, 0);
		setTimeout(() => {
			scrollToRow(editedRowId);
			selectRow(editedRowId);
		}, 500);
	}

	function selectRow(rowId: GridRowId) {
		getApi().selectRow(rowId, true, true);
	}

	function scrollToRow(rowId: GridRowId) {
		const api = getApi();
		const rowIndex = api.getAllRowIds().indexOf(rowId);
		const rowsMeta = api.state.rowsMeta;
		const position = rowsMeta.positions[rowIndex];
		api.scroll({
			// Scroll to end if row not found.
			top: position ?? rowsMeta.currentPageTotalHeight,
		});
	}
};
