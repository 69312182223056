import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { dateTimeColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { faDownload, faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ObjectConfigurationHistoryView } from "src/api/generated/erp/db/types/tables/objectConfigurationHistoryView.ts";
import { ObjectConfigurationHistoryApi } from "src/api/generated/erp/configurator/objectConfigurationHistory/api/objectConfigurationHistoryApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { ConfigurableObjectType } from "src/api/generated/erp/db/types/enums/configurableObjectType.ts";
import { ExistingPartConfigurationReadonlyForm } from "../configuratorForm/ExistingPartConfigurationReadonlyForm";

export interface ObjectConfigurationHistoryDataGridProps {
	objectType: ConfigurableObjectType;
	objectId: number;
	refreshSourceView: () => Promise<unknown>;
}

export const ObjectConfigurationHistoryDataGrid = ({
	objectType,
	objectId,
	refreshSourceView,
}: ObjectConfigurationHistoryDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () => ObjectConfigurationHistoryApi.getObjectConfigurationHistory({ objectType, objectId }),
		getRowId: (row) => row.objectConfigurationHistoryId,
		gridId: "9FD6C53E10F096CE",
		initialParams: {},
	});

	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();

	return (
		<ControlledAsyncDataGrid<ObjectConfigurationHistoryView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "configurationId",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 140,
				}),
				textColumn({
					field: "createdByAppUserName",
					headerName: i18n.t("created_by"),
					width: 140,
				}),
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("part_revision"),
					width: 120,
				}),
				textColumn({
					field: "comment",
					headerName: i18n.t("comment"),
					width: 300,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("configuration_description"),
					width: 500,
				}),
			]}
			onRowDoubleClick={async ({ row }) => {
				openConfiguration(row.configurationId);
			}}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("open")}
						icon={faExternalLink}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={async () => {
							if (!onlySelectedRow) return;
							openConfiguration(onlySelectedRow.configurationId);
						}}
					/>
					<AsyncButton
						label={i18n.t("restore")}
						icon={faDownload}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={async () => {
							if (!onlySelectedRow) return;

							const confirmed = await showConfirmDialog({
								message: i18n.t("confirm_restore_configuration"),
							});
							if (!confirmed) return;

							await ObjectConfigurationHistoryApi.restoreObjectConfigurationFromHistory({
								objectConfigurationHistoryEntryId: onlySelectedRow.objectConfigurationHistoryId,
							});
							await Promise.all([refreshSourceView(), refreshData()]);
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);

	function openConfiguration(partConfigurationId: number) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("configuration"),
			content: (
				<ExistingPartConfigurationReadonlyForm
					objectType={objectType}
					objectId={objectId}
					partConfigurationId={partConfigurationId}
					closeDialog={closeDialog}
				/>
			),
		}));
	}
};
