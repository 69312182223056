import { SalesPriceList } from "src/api/generated/erp/db/types/tables/salesPriceList.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { SalesPriceListDataGridApi } from "src/api/generated/erp/sales/pricing/api/salesPriceListDataGridApi.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import i18n from "i18next";
import { enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { getSalesPriceListStateLabels } from "src/api/generated/erp/db/types/enums/salesPriceListState.ts";
import { SalesPriceListView } from "src/api/generated/erp/db/types/tables/salesPriceListView.ts";
import { SalesPriceListForm } from "src/components/views/erp/sales/pricing/salesPriceLists/SalesPriceListForm.tsx";
import { SalesPriceListEditApi } from "src/api/generated/erp/sales/pricing/api/salesPriceListEditApi.ts";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { SalesPartPriceGroupApi } from "src/api/generated/erp/sales/basedata/api/salesPartPriceGroupApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faBan, faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { SalesPriceListActionApi } from "src/api/generated/erp/sales/pricing/api/salesPriceListActionApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { SalesPartPriceGroup } from "src/api/generated/erp/db/types/tables/salesPartPriceGroup.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {
	getSalesPriceListViewActiveFromReferenceDateTypeLabels
} from "src/api/generated/erp/db/types/enums/salesPriceListViewActiveFromReferenceDateType.ts";

export interface SalesPriceListsDataGridProps {
	onSelectionChanged: (selectedRow: SalesPriceList | undefined) => void;
}

export const SalesPriceListsDataGrid = ({ onSelectionChanged }: SalesPriceListsDataGridProps) => {
	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel({
		fetchData: (params) => SalesPriceListDataGridApi.getData(params),
		initialParams: {
			salesPartPriceGroupFilter: genericNullableValue<number>(),
			searchQuery: "",
			includeDeactivated: false,
		},
		getRowId: (row) => row.salesPriceListId,
		onSelectionChanged: (selectedRows) => onSelectionChanged(selectedRows[0]),
		gridId: "6A6E852DF1A8DC1B",
	});

	const showConfirmDialog = useConfirmDialog();

	return (
		<ControlledAsyncCrudDataGrid<SalesPriceListView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "salesPartPriceGroupName",
					headerName: i18n.t("sales_price_group"),
					width: 150,
				}),
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 150,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 150,
				}),
				enumColumn({
					field: "activeFromReferenceDateType",
					headerName: i18n.t("active_from"),
					enumLabels: getSalesPriceListViewActiveFromReferenceDateTypeLabels(),
					width: 150,
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getSalesPriceListStateLabels(),
					width: 150,
				}),
			]}
			actionBarComponents={
				<>
					<LazySelectField
						label={i18n.t("sales_price_group")}
						onChange={(value) => refreshData({ salesPartPriceGroupFilter: value })}
						fetchOptions={() => SalesPartPriceGroupApi.getSalesPartPriceGroups()}
						getOptionKey={(option: SalesPartPriceGroup) => option.salesPartPriceGroupId}
						getOptionLabel={(option) => option.name}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(value) => refreshData({ searchQuery: value })}
					/>
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"showDeactivated"}
					label={i18n.t("show_deactivated")}
					checked={currentParams.includeDeactivated}
					onChange={(value) => refreshData({ includeDeactivated: value })}
				/>,
			]}
			rowContextMenuComponents={({ row }) => [
				row.state === "INITIAL" && (
					<AsyncMenuButton
						key={"release"}
						label={i18n.t("release")}
						icon={faShare}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;
							await SalesPriceListActionApi.releaseSalesPriceList({
								salesPriceListId: row.salesPriceListId,
							});
							await refreshData();
						}}
					/>
				),
				row.state === "RELEASED" && (
					<AsyncMenuButton
						key={"deactivate"}
						label={i18n.t("deactivate")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;
							await SalesPriceListActionApi.deactivateSalesPriceList({
								salesPriceListId: row.salesPriceListId,
							});
							await refreshData();
						}}
					/>
				),
				row.state === "DEACTIVATED" && (
					<AsyncMenuButton
						key={"revertDeactivation"}
						label={i18n.t("revert_deactivation")}
						icon={faUndo}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;
							await SalesPriceListActionApi.revertDeactivateSalesPriceList({
								salesPriceListId: row.salesPriceListId,
							});
							await refreshData();
						}}
					/>
				),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("price_list"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<SalesPriceListForm
						salesPriceListId={row?.salesPriceListId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				SalesPriceListEditApi.delete_({
					salesPriceListIds: items.map((item) => item.salesPriceListId),
				})
			}
			{...dataGridProps}
		/>
	);
};
