// This file is automatically generated. Do not edit manually.

import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty";
import { ConfigurationComponentView } from "src/api/generated/erp/db/types/tables/configurationComponentView";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorManagingApi {
	export async function getProductFamilyVersionManagingViewInitData(args: {
		productFamilyVersionId: number;
	}): Promise<ConfiguratorManagingApi_ProductFamilyVersionManagingViewInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/getProductFamilyVersionManagingViewInitData",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
			},
		});
		return response as ConfiguratorManagingApi_ProductFamilyVersionManagingViewInitData;
	}

	export async function getProductFamilyVersionConfiguratorProperties(args: {
		productFamilyVersionId: number;
		onlyComputedProperties?: boolean;
		searchQuery?: string;
	}): Promise<Array<ConfigurationProperty>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/getProductFamilyVersionConfiguratorProperties",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
				onlyComputedProperties: args.onlyComputedProperties,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ConfigurationProperty>;
	}
}

export interface ConfiguratorManagingApi_ProductFamilyVersionManagingViewInitData {
	components: Array<ConfigurationComponentView>;
	productFamilyVersion: ConfigurationProductFamilyVersion;
}
