// This file is automatically generated. Do not edit manually.

import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartRevisionEditApi {
	export async function getFormInitData(args: {
		catalogPartId: number;
		catalogPartRevisionId: number | null | undefined;
	}): Promise<CatalogPartRevisionEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/getFormInitData",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as CatalogPartRevisionEditApi_FormInitData;
	}

	export async function insert(args: {
		catalogPartId: number;
		revisionNumber: number;
		note: string;
		configurationProductFamilyVersionId: number | null | undefined;
		importContent: CatalogPartRevisionEditApi_ImportRevisionContentConfig | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/insert/v2",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
				revisionNumber: args.revisionNumber,
				note: args.note,
				configurationProductFamilyVersionId: args.configurationProductFamilyVersionId,
				importContent: args.importContent,
			},
		});
		return response as number;
	}

	export async function update(args: { catalogPartRevision: CatalogPartRevision }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/update",
			method: "POST",
			data: {
				catalogPartRevision: args.catalogPartRevision,
			},
		});
		return response as void;
	}

	export async function delete_(args: { catalogPartRevisionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogPartRevisions/delete",
			method: "POST",
			data: {
				catalogPartRevisionId: args.catalogPartRevisionId,
			},
		});
		return response as void;
	}
}

export interface CatalogPartRevisionEditApi_FormInitData {
	nextCatalogPartRevisionNumber: number;
	partIsConfigurable: boolean;
	previousRevision: CatalogPartRevisionView | null | undefined;
	productFamilyVersionOptions: Array<ConfigurationProductFamilyVersion>;
	revision: CatalogPartRevisionView | null | undefined;
}

export interface CatalogPartRevisionEditApi_ImportRevisionContentConfig {
	importBom: boolean;
	importConfiguratorSelections: boolean;
	importDocuments: boolean;
	sourceRevisionId: number;
}
