import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { PartConfigurationBomPricesApi } from "src/api/generated/erp/configurator/api/partConfigurationBomPricesApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { getPartConfigurationBomDataGridColumns } from "src/components/views/erp/configurator/inspecting/configurationBomUtils.ts";
import { PartConfiguration } from "src/api/generated/erp/db/types/tables/partConfiguration.ts";
import { PartConfigurationBomLineView } from "src/api/generated/erp/db/types/tables/partConfigurationBomLineView.ts";

export interface PartConfigurationBomPricesOfCategoryDataGridProps {
	partConfiguration: PartConfiguration;
	salesPartCategoryId: number | null;
}

export const PartConfigurationBomPricesOfCategoryDataGrid = ({
	partConfiguration,
	salesPartCategoryId,
}: PartConfigurationBomPricesOfCategoryDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () =>
			PartConfigurationBomPricesApi.getBomLinesInPartCategory({
				partConfigurationId: partConfiguration.partConfigurationId,
				salesPartCategoryId: salesPartCategoryId,
			}),
		getRowId: (row) => row.partConfigurationBomLineId,
		gridId: "A6F5D8E9C4F4809E",
		initialParams: {},
	});

	return (
		<ControlledAsyncDataGrid<PartConfigurationBomLineView>
			columns={getPartConfigurationBomDataGridColumns(partConfiguration)}
			{...dataGridProps}
		/>
	);
};
