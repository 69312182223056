import i18n from "i18next";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { IsoDateString } from "src/types/dateTime.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { faPenField } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import type { CloseDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { ProjectActivityForm } from "src/components/views/erp/project/projectActivity/ProjectActivityForm.tsx";
import { ProjectActivityApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityApi.ts";

export interface CreateNewProjectActivityFormProps extends FormCommonProps<number> {
	subProjectId: number;
	closeDialog: CloseDialogFunc;
}

export interface FormValues {
	templateProjectId: number;
	templateActivityId: number;
	plannedStartDate: IsoDateString;
}

export const CreateNewProjectActivityForm = ({
	subProjectId,
	onFormEdited,
	onCompleted,
	closeDialog,
}: CreateNewProjectActivityFormProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AavoForm
			defaultValues={{}}
			submitLabel={i18n.t("ok")}
			submit={submit}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			render={(params) => {
				const { control, watch, setValue } = params;
				const templateProjectId = watch("templateProjectId");
				return (
					<>
						<AsyncButton
							icon={faPenField}
							label={i18n.t("create_without_template")}
							onClick={createWithoutTemplate}
							sx={{
								paddingY: 0.5,
							}}
						/>
						<FormAsyncSelectField
							control={control}
							name={"templateProjectId"}
							label={i18n.t("template_project")}
							getOptionKey={(o: Project) => o.projectId}
							getOptionLabel={(o) => concatWithPipe(o.projectId, o.projectDescription)}
							fetchOptions={({ searchQuery, currentSelection }) =>
								ProjectQueryApi.getProjectSelectionOptions({
									includeTemplates: true,
									searchQuery,
									currentSelection,
								})
							}
							rules={requireRule()}
							onChange={() => {
								setValue("templateActivityId", null);
							}}
						/>
						<FormLazySelectField
							control={control}
							name={"templateActivityId"}
							label={i18n.t("template_activity")}
							disabled={
								templateProjectId == null ? i18n.t("select_template_project_first") : false
							}
							fetchOptions={() =>
								ProjectActivityApi.getTemplateActivityOptions({
									projectId: templateProjectId,
								})
							}
							getOptionKey={(o) => o.activityId}
							getOptionLabel={(o) =>
								concatWithPipe(
									o.subProjectId,
									o.subProjectDescription,
									o.activityId,
									o.activityName,
								)
							}
							rules={requireRule()}
						/>
						<FormDateField
							control={control}
							name={"plannedStartDate"}
							label={i18n.t("planned_start")}
							rules={requireRule()}
						/>
					</>
				);
			}}
		/>
	);

	async function createWithoutTemplate() {
		await closeDialog();
		openFormOnDialog({
			openDialog,
			component: ProjectActivityForm,
			title: i18n.t("activity"),
			size: "md",
			props: {
				subProjectId: subProjectId,
				activityId: null,
			},
			onSubmit: (activityId: number) => onCompleted({ type: "success", value: activityId }),
		});
	}

	async function submit({ templateActivityId, plannedStartDate }: FormValues) {
		return await ProjectActivityApi.cloneActivity({
			templateActivityId: templateActivityId,
			targetSubProjectId: subProjectId,
			newActivityName: null,
			newActivityPlannedStartDate: plannedStartDate,
		});
	}
};
