import { CreateNewSubProjectFormRenderParams } from "src/components/views/erp/project/subProject/CreateNewSubProjectForm.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import i18n from "i18next";
import { useState } from "react";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { SalvosProjectApi } from "src/api/generated/tenants/salvos/erp/project/salvosProjectApi.ts";
import { faCalendarAlt, faMagic } from "@fortawesome/pro-regular-svg-icons";
import { SubProjectContextMenuProps } from "src/components/views/erp/project/subProject/SubProjectContextMenu.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { ProjectActivityResourcesApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityResourcesApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { SalvosSubProjectSchedulingForm } from "src/tenantCustomizations/tenants/salvos/project/SalvosSubProjectSchedulingForm.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import {
	getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabel,
	SalvosSubProjectPlannedStartDateProvider_ReferenceType,
	SalvosSubProjectPlannedStartDateProvider_ReferenceTypeValues,
} from "src/api/generated/tenants/salvos/erp/project/salvosSubProjectPlannedStartDateProvider";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { toIntOrNull } from "src/utils/strings.tsx";
import { useStoredState } from "src/utils/useStoredState.ts";

export namespace SalvosProjectCustomizations {
	export const SubProjectContextMenuComponents = ({ subProject, refreshData }: SubProjectContextMenuProps) => {
		const { openDialog } = useGenericDialog();
		const showFormInputDialog = useFormInput();
		return [
			subProject.subProjectState === "INITIAL" && (
				<AsyncMenuButton
					key={"releaseAndPlan"}
					label={i18n.t("release_and_plan")}
					icon={faMagic}
					onClick={onReleaseAndPlanClick}
				/>
			),
			<AsyncMenuButton
				key={"schedule"}
				label={i18n.t("schedule.verb")}
				icon={faCalendarAlt}
				onClick={() => {
					openFormOnDialog({
						openDialog,
						component: SalvosSubProjectSchedulingForm,
						title: i18n.t("schedule.verb"),
						size: "sm",
						props: {
							subProject,
						},
						onSubmit: refreshData,
						confirmCloseIfEdited: false,
					});
				}}
			/>,
		];

		async function onReleaseAndPlanClick() {
			const formInput = await showFormInputDialog<{ resourceId: number }>({
				title: i18n.t("release_and_plan"),
				content: ({ control }) => (
					<FormLazySelectField
						control={control}
						name={"resourceId"}
						label={i18n.t("resource")}
						fetchOptions={ProjectActivityResourcesApi.getProjectActivityResourceOptions}
						getOptionKey={(o) => o.activityResourceId}
						getOptionLabel={(o) => o.resourceName}
						rules={requireRule()}
					/>
				),
				submitLabel: i18n.t("ok"),
			});
			if (!formInput) return;

			await SalvosProjectApi.releaseSubProjectAndScheduleActivities({
				subProjectId: subProject.subProjectId,
				resourceId: formInput.resourceId,
			});
			await refreshData?.();
		}
	};

	export const CreateSubProjectFormComponents = ({
		targetProjectId,
		watch,
		setValue,
	}: CreateNewSubProjectFormRenderParams) => {
		const [schedulingMethod, setSchedulingMethod] =
			useStoredState<SalvosSubProjectPlannedStartDateProvider_ReferenceType>({
				key: "9BFAC81DEF40229",
				defaultValue: "PLANNED_DELIVERY_DATE",
			});

		const [advanceDurationInDays, setAdvanceDurationInDays] = useState<number>(0);

		const templateProjectId = watch("templateSubProjectId");

		return (
			<AsyncFetchRender
				fetch={async () => {
					const advanceDurationsByReferenceTypes = await SalvosProjectApi.getSubProjectSchedulingAdvanceDurations({
						projectId: targetProjectId,
					});
					setAdvanceDurationInDays(advanceDurationsByReferenceTypes[schedulingMethod] ?? 0);
					return advanceDurationsByReferenceTypes;
				}}
				noContainer
				content={(advanceDurationsByReferenceTypes) => (
					<>
						<SelectField
							label={i18n.t("scheduling_method")}
							options={SalvosSubProjectPlannedStartDateProvider_ReferenceTypeValues}
							getOptionKey={(o) => o}
							getOptionLabel={getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabel}
							value={schedulingMethod}
							disableClearable
							onChange={(value) => {
								setSchedulingMethod(value);
								const advanceDuration = advanceDurationsByReferenceTypes[value];
								setAdvanceDurationInDays(advanceDuration);
							}}
						/>
						<AavoTextField
							label={i18n.t("advance_duration_days")}
							type={"number"}
							value={advanceDurationInDays.toString()}
							onChange={(value) => setAdvanceDurationInDays(toIntOrNull(value) ?? 0)}
						/>
						<AsyncButton
							label={i18n.t("schedule.verb")}
							icon={faCalendarAlt}
							disabled={templateProjectId == null ? i18n.t("select_template_sub_project_first") : false}
							onClick={async () => {
								const calculatedPlannedStartDate =
									await SalvosProjectApi.calculateSubProjectPlannedStartDate({
										projectId: targetProjectId,
										subProjectId: templateProjectId,
										reference: schedulingMethod,
										advanceDurationInDays: advanceDurationInDays,
									});
								setValue("plannedStartDate", calculatedPlannedStartDate);
							}}
						/>
					</>
				)}
			/>
		);
	};
}
