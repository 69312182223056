// This file is automatically generated. Do not edit manually.

import { SalesPart } from "src/api/generated/erp/db/types/tables/salesPart";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { SalesPartCategory } from "src/api/generated/erp/db/types/tables/salesPartCategory";
import { SalesPartPriceGroup } from "src/api/generated/erp/db/types/tables/salesPartPriceGroup";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartEditApi {
	export async function getInitData(args: {
		partId: number | null | undefined;
		salesPartId: number | null | undefined;
	}): Promise<SalesPartEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartEdit/getInitData",
			method: "POST",
			data: {
				partId: args.partId,
				salesPartId: args.salesPartId,
			},
		});
		return response as SalesPartEditApi_InitData;
	}

	export async function setToDefaultSalesPart(args: { salesPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartEdit/setToDefaultSalesPart",
			method: "POST",
			data: {
				salesPartId: args.salesPartId,
			},
		});
		return response as void;
	}

	export async function insert(args: { salesPart: SalesPart; accountingCodeIds: Array<number> }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartEdit/insert",
			method: "POST",
			data: {
				salesPart: args.salesPart,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as number;
	}

	export async function update(args: { salesPart: SalesPart; accountingCodeIds: Array<number> }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartEdit/update",
			method: "POST",
			data: {
				salesPart: args.salesPart,
				accountingCodeIds: args.accountingCodeIds,
			},
		});
		return response as number;
	}

	export async function delete_(args: { salesPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartEdit/delete",
			method: "POST",
			data: {
				salesPartId: args.salesPartId,
			},
		});
		return response as void;
	}
}

export interface SalesPartEditApi_InitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	erpUnitOptions: Array<ErpUnit>;
	part: PartView | null | undefined;
	salesPart: SalesPart | null | undefined;
	salesPartCategoryOptions: Array<SalesPartCategory>;
	salesPriceGroupOptions: Array<SalesPartPriceGroup>;
	siteOptions: Array<Site>;
	vatCodeOptions: Array<VatCode>;
}
