import { AddNewWorkspaceConnectionsViewProps } from "src/components/views/workspaces/workspaceConnections/connectionTypes/WorkspaceConnectedItemTypeConfiguration.ts";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { ProjectsDataGridApi } from "src/api/generated/erp/project/project/api/projectsDataGridApi";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { dateColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getProjectStateLabels } from "src/api/generated/erp/db/types/enums/projectState.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { WorkspaceConnectionsApi } from "src/api/generated/workspaces/api/workspaceConnectionsApi.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";

export const ConnectProjectsToWorkspaceDataGrid = ({
	workspaceId,
	onCompleted,
}: AddNewWorkspaceConnectionsViewProps) => {
	const { userSites } = useGlobalInitData();

	return (
		<ServerSideDataGridModel
			gridId={"F4AAE366B8C9B116"}
			initialParams={{
				searchQuery: "",
				onlyDefaultSiteProjects: true,
				onlyProjectId: undefined,
				completedProjects: true,
				cancelledProjects: false,
			}}
			fetchData={(params) => ProjectsDataGridApi.getDataGridData(params)}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.projectId}
			render={({ refreshData, dataGridProps, selectedRows, currentParams }) => (
				<AavoDataGrid<ProjectView>
					columns={[
						integerColumn({
							field: "projectId",
							headerName: i18n.t("id"),
						}),
						textColumn({
							field: "projectDescription",
							headerName: i18n.t("description"),
							width: 300,
						}),
						enumColumn({
							field: "projectState",
							headerName: i18n.t("state"),
							enumLabels: getProjectStateLabels(),
						}),
						dateColumn({
							field: "plannedStartDate",
							headerName: i18n.t("planned_begin_date"),
							width: 150,
						}),
						dateColumn({
							field: "plannedEndDate",
							headerName: i18n.t("planned_end_date"),
							width: 150,
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<AsyncButton
								icon={faLink}
								label={i18n.t("connect")}
								variant={"outlined"}
								disabled={selectedRows.length === 0}
								onClick={async () => {
									await WorkspaceConnectionsApi.insertConnections({
										workspaceId: workspaceId,
										connectedItemIds: selectedRows.map((r) => r.projectId),
										connectedItemType: "PROJECT",
									});
									await onCompleted();
								}}
							/>
							{userSites.length > 1 && (
								<AavoCheckbox
									label={i18n.t("only_default_site")}
									checked={currentParams.onlyDefaultSiteProjects}
									onChange={(value) => refreshData({ onlyDefaultSiteProjects: value })}
								/>
							)}
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
