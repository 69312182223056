import { ControlChartGroupTreeViewApi } from "src/api/generated/spc/controlChart/api/controlChartGroupTreeViewApi.ts";
import React, { useState } from "react";
import {
	ChartGroupModel,
	ChartGroupTreeItemModel,
	ChartParentGroupModel,
	mapChartGroupTreeData,
} from "src/components/views/spc/basedata/controlCharts/treeView/ControlChartGroupTreeViewItemMapping.ts";
import { AavoTreeView } from "src/components/common/tree/AavoTreeView.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { flatMapTreeItems } from "src/components/common/tree/AavoTreeView.utils.ts";
import { filterTreeItems } from "src/components/common/tree/filtering/treeItemFilter.tsx";
import { TreeItem2, TreeItem2Content, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import {
	faAngleDown,
	faAngleRight,
	faCircleSmall,
	faEdit,
	faLayerGroup,
	faPen,
	faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { alpha, styled } from "@mui/material/styles";
import { Typography, useTheme } from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { ControlChartGroupTreeViewActionBar } from "src/components/views/spc/basedata/controlCharts/treeView/ControlChartGroupTreeViewActionBar.tsx";
import { UseTreeItem2Status } from "@mui/x-tree-view/useTreeItem2";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { ControlChartGroupForm } from "src/components/views/spc/basedata/controlCharts/ControlChartGroupForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ControlChartParentGroupForm } from "src/components/views/spc/basedata/controlCharts/ControlChartParentGroupForm.tsx";

interface ControlChartGroupTreeViewProps {
	onSelectionChanged?: (selectedGroup: ChartGroupTreeItemModel | null) => void;
}

export const ControlChartGroupTreeView = ({ onSelectionChanged }: ControlChartGroupTreeViewProps) => {
	return (
		<AsyncFetchRender
			fetch={async () => {
				const data = await ControlChartGroupTreeViewApi.getTreeData();
				return mapChartGroupTreeData(data.parentGroups, data.groups);
			}}
			content={(data, refresh) => (
				<ControlChartGroupTreeViewContent
					parentGroupTreeItems={data}
					refresh={refresh}
					onSelectionChanged={onSelectionChanged}
				/>
			)}
		/>
	);
};

interface ControlChartGroupTreeViewContentProps extends ControlChartGroupTreeViewProps {
	parentGroupTreeItems: ChartGroupTreeItemModel[];
	refresh: () => Promise<ChartGroupTreeItemModel[]>;
}

const ControlChartGroupTreeViewContent = ({
	parentGroupTreeItems,
	refresh: refreshProp,
	onSelectionChanged,
}: ControlChartGroupTreeViewContentProps) => {
	const [filteredTreeItems, setFilteredTreeItems] = useState(parentGroupTreeItems);
	const [searchQuery, setSearchQuery] = useState("");
	const [expandedItems, setExpandedItems] = useState<string[]>([]);
	const [selectedItem, setSelectedItem] = useState<ChartGroupTreeItemModel | null>(null);

	const allItems = flatMapTreeItems(parentGroupTreeItems);

	const setSelectedItemWrapped = (itemId: string | null) => {
		const item = allItems.find((a) => a.id === itemId) ?? null;
		setSelectedItem(item);
		onSelectionChanged?.(item);
	};

	return (
		<ControlChartGroupTreeViewContext.Provider
			value={{
				setSelectedItem: setSelectedItemWrapped,
				refresh: refresh,
				expandItem: expandItem,
			}}
		>
			<VerticalBox flex={1}>
				<AavoTreeView
					items={filteredTreeItems}
					slots={{
						item: CustomTreeItemWithStyles,
					}}
					expandedItems={expandedItems}
					multiSelect={false}
					onExpandedItemsChange={setExpandedItems}
					selectedItems={selectedItem?.id}
					onSelectedItemsChange={(_, itemId) => setSelectedItemWrapped(itemId)}
					refresh={refresh}
					actionBarComponents={
						<ControlChartGroupTreeViewActionBar
							selectedItem={selectedItem}
							refresh={refresh}
							setSearchQuery={setSearchQuery}
							setSelectedItemWrapped={setSelectedItemWrapped}
							filter={filter}
						/>
					}
				/>
			</VerticalBox>
		</ControlChartGroupTreeViewContext.Provider>
	);

	async function refresh() {
		const parentItems = await refreshProp();
		filter({ searchQueryFilter: searchQuery, currentParentGroupItems: parentItems });
	}

	function expandItem(itemId: string) {
		setExpandedItems((prevState) => [...prevState, itemId]);
	}

	function filter({
		searchQueryFilter = searchQuery,
		currentParentGroupItems = parentGroupTreeItems,
	}: {
		searchQueryFilter?: string;
		currentParentGroupItems?: ChartGroupTreeItemModel[];
	}) {
		const hasNotBeenFiltered = searchQueryFilter.trim() === "";
		const filteredItems =
			hasNotBeenFiltered ? currentParentGroupItems : (
				filterTreeItems({
					rootTreeItems: currentParentGroupItems,
					isSearchMatch: (item) => item.label.toLowerCase().includes(searchQueryFilter.toLowerCase()),
				})
			);
		setFilteredTreeItems(filteredItems);
		const allFilteredTreeItems = flatMapTreeItems(filteredItems);

		setExpandedItems((prevState) => [
			...prevState,
			...allFilteredTreeItems.filter((item) => item.expand).map((item) => item.id.toString()),
		]);

		const selectedItemExists = allFilteredTreeItems.some((item) => item.id === selectedItem?.id);
		if (!selectedItemExists) {
			setSelectedItem(null);
		}
	}
};

interface ControlChartGroupTreeViewContextValue {
	setSelectedItem: (itemId: string | null) => void;
	refresh: () => Promise<void>;
	expandItem: (itemId: string) => void;
}

const ControlChartGroupTreeViewContext = React.createContext<ControlChartGroupTreeViewContextValue | undefined>(
	undefined,
);
const useControlChartGroupTreeViewContext = () => useContextOrThrow(ControlChartGroupTreeViewContext);

const CustomTreeItem = React.memo((props: TreeItem2Props) => {
	const { itemId } = props;
	const { publicAPI } = useTreeItem2(props);
	const item = publicAPI.getItem(itemId) as ChartGroupTreeItemModel;

	return (
		<TreeItem2
			{...props}
			slots={{
				content: CustomTreeItemContent,
				label: CustomTreeItemLabel,
				expandIcon: CustomCollapseExpandIcon,
				collapseIcon: CustomCollapseExpandIcon,
			}}
			slotProps={{
				label: {
					item: item,
				} as any,
				content: {
					item: item,
				} as any,
				expandIcon: {
					icon: faAngleRight,
				} as any,
				collapseIcon: {
					icon: faAngleDown,
				} as any,
			}}
		/>
	);
});

const CustomTreeItemWithStyles = styled(CustomTreeItem)(() => ({
	"& .MuiTreeItem-content": {
		paddingLeft: "0.25rem",
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
	},
	"& .MuiTreeItem-iconContainer": {
		width: "2rem",
		"& svg": {
			fontSize: "1.2rem",
		},
	},
}));

const CustomTreeItemLabel = ({ item }: { item: ChartGroupTreeItemModel }) => {
	const theme = useTheme();
	return (
		<HorizontalBox
			sx={{
				alignItems: "center",
				gap: 1,
				backgroundColor: item.directSearchMatch ? alpha(theme.palette.secondary.light, 0.4) : undefined,
				transition: "background-color 0.2s",
				paddingY: 0.25,
				paddingX: 1,
				borderRadius: 1.5,
			}}
		>
			<FontAwesomeIcon
				icon={item.type === "chartParentGroup" ? faCircleSmall : faLayerGroup}
				color={item.directSearchMatch ? undefined : theme.palette.primary.main}
			/>
			<Typography>{item.label}</Typography>
		</HorizontalBox>
	);
};

const CustomCollapseExpandIcon = ({ icon }: { icon: IconDefinition }) => {
	return (
		<HorizontalBox
			sx={{
				marginTop: -1.75,
				marginBottom: -1.75,
				marginRight: 1,
				alignItems: "center",
			}}
		>
			<FontAwesomeIcon icon={icon} />
		</HorizontalBox>
	);
};

const CustomTreeItemContent = ({
	item,
	...other
}: {
	item: ChartGroupTreeItemModel;
	status: UseTreeItem2Status;
} & unknown) => {
	const [showContextMenu, contextMenuState] = useContextMenu();
	const { setSelectedItem } = useControlChartGroupTreeViewContext();
	return (
		<>
			<TreeItem2Content
				onContextMenu={(event) => {
					event.preventDefault();
					setSelectedItem(item.id);
					showContextMenu({
						mouseEvent: event,
						content:
							item.type === "chartParentGroup" ? <ControlChartParentGroupContextMenu item={item} />
							: item.type === "chartGroup" ? <ControlChartGroupContextMenu item={item} />
							: undefined,
					});
				}}
				{...other}
			/>
			<AavoContextMenu state={contextMenuState} />
		</>
	);
};

interface ControlChartParentGroupContextMenuProps {
	item: ChartParentGroupModel;
}

const ControlChartParentGroupContextMenu = ({ item }: ControlChartParentGroupContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const { refresh, expandItem } = useControlChartGroupTreeViewContext();

	return [
		<AsyncMenuButton
			key={"edit"}
			icon={faEdit}
			label={i18n.t("edit")}
			onClick={async () => {
				openFormOnDialog({
					openDialog,
					size: "sm",
					title: i18n.t("parent_group"),
					component: ControlChartParentGroupForm,
					props: {
						parentGroupId: item.parentGroupId,
					},
					onSubmit: refresh,
				});
			}}
		/>,
		<AsyncMenuButton
			icon={faPlus}
			label={i18n.t("add_chart_group")}
			onClick={async () => {
				openDialog(({ closeDialog, onContentEdited }) => ({
					size: "sm",
					title: i18n.t("add_chart_group"),
					content: (
						<ControlChartGroupForm
							parentGroupId={item.parentGroupId}
							onCompleted={async () => {
								await closeDialog();
								await refresh();
								expandItem(item.id);
							}}
							onFormEdited={onContentEdited}
						/>
					),
				}));
			}}
		/>,
	];
};

interface ControlChartGroupContextMenuProps {
	item: ChartGroupModel;
}

const ControlChartGroupContextMenu = ({ item }: ControlChartGroupContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const { refresh } = useControlChartGroupTreeViewContext();

	return [
		<AsyncMenuButton
			icon={faPen}
			label={i18n.t("edit")}
			onClick={async () => {
				openFormOnDialog({
					openDialog,
					size: "sm",
					title: i18n.t("edit_chart_group"),
					component: ControlChartGroupForm,
					props: {
						parentGroupId: item.parentGroupId,
						controlChartGroupId: item.chartGroupId,
					},
					onSubmit: refresh,
				});
			}}
		/>,
	];
};
