import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPlus, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ControlChartParentGroupForm } from "src/components/views/spc/basedata/controlCharts/ControlChartParentGroupForm.tsx";
import { ControlChartParentGroupEditApi } from "src/api/generated/spc/controlChart/api/controlChartParentGroupEditApi.ts";
import { ControlChartGroupEditApi } from "src/api/generated/spc/controlChart/api/controlChartGroupEditApi.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import { ChartGroupTreeItemModel } from "src/components/views/spc/basedata/controlCharts/treeView/ControlChartGroupTreeViewItemMapping.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";

export interface ControlChartGroupTreeViewActionBarProps {
	selectedItem: ChartGroupTreeItemModel | null;
	refresh: () => Promise<void>;
	setSearchQuery: (searchQuery: string) => void;
	setSelectedItemWrapped: (itemId: string | null) => void;
	filter: (filter: { searchQueryFilter?: string; currentParentGroupItems?: ChartGroupTreeItemModel[] }) => void;
}

export const ControlChartGroupTreeViewActionBar = ({
	selectedItem,
	refresh,
	setSearchQuery,
	setSelectedItemWrapped,
	filter,
}: ControlChartGroupTreeViewActionBarProps) => {
	const { openDialog } = useGenericDialog();
	const openConfirmDialog = useConfirmDialog();
	const debounceSearchQuery = useDebounce();

	return (
		<>
			<AsyncButton
				icon={faPlus}
				onClick={async () => {
					openFormForNewParentGroup();
				}}
				tooltip={i18n.t("new_parent_group")}
			/>
			<AsyncButton
				icon={faTrashCan}
				onClick={async () => {
					await deleteGroup();
				}}
				disabled={selectedItem == null}
			/>
			<DocumentsOfObjectButton
				objectRef={
					selectedItem?.type === "chartGroup" ?
						nullableAavoObjectRef("CHART_GROUP", selectedItem.chartGroupId)
					: selectedItem?.type === "chartParentGroup" ?
						nullableAavoObjectRef("CONTROL_CHART_PARENT_GROUP", selectedItem.parentGroupId)
					:	undefined
				}
			/>
			<AavoTextField
				label={i18n.t("search")}
				onSubmit={(searchString) => {
					setSearchQuery(searchString);
					filter({ searchQueryFilter: searchString });
				}}
				onChange={(searchString) => {
					debounceSearchQuery(500, () => {
						setSearchQuery(searchString);
						filter({ searchQueryFilter: searchString });
					});
				}}
			/>
			<AavoCheckbox
				label={i18n.t("show_all")}
				onChange={(value) => {
					if (value) setSelectedItemWrapped(null);
				}}
				checked={selectedItem === null}
			/>
		</>
	);

	function openFormForNewParentGroup() {
		openFormOnDialog({
			openDialog,
			size: "sm",
			title: i18n.t("parent_group"),
			component: ControlChartParentGroupForm,
			props: {
				parentGroupId: undefined,
			},
			onSubmit: refresh,
		});
	}

	async function deleteGroup() {
		if (selectedItem == null) return;
		const confirmed = await openConfirmDialog({
			title: i18n.t("are_you_sure"),
		});
		if (!confirmed) return;
		if (selectedItem.type === "chartParentGroup") {
			await ControlChartParentGroupEditApi.delete_({
				parentGroupId: selectedItem.parentGroupId,
			});
		}
		if (selectedItem.type === "chartGroup") {
			await ControlChartGroupEditApi.delete_({
				chartGroupIds: [selectedItem.chartGroupId],
			});
		}
		await refresh();
	}
};
