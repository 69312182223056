import { WorkspaceConnectedItemTypeConfiguration } from "src/components/views/workspaces/workspaceConnections/connectionTypes/WorkspaceConnectedItemTypeConfiguration.ts";
import { faEuroSign } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ConnectCustomerOrdersToWorkspaceDataGrid } from "src/components/views/workspaces/workspaceConnections/connectionTypes/customerOrder/ConnectCustomerOrdersToWorkspaceDataGrid.tsx";
import { CustomerOrdersPage } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPage";

export const workspaceCustomerOrderConnectionsConfiguration: WorkspaceConnectedItemTypeConfiguration = {
	typeLabel: i18n.t("customer_order"),
	icon: faEuroSign,
	isEnabled: (tenantConfig) => tenantConfig.erp.salesEnabled,
	addNewConnectionsView: (props) => {
		return <ConnectCustomerOrdersToWorkspaceDataGrid {...props} />;
	},
	openItemDialog: ({ itemId, openDialog }) => {
		openDialog({
			title: i18n.t("customer_order"),
			size: "fullscreen",
			content: (
				<CustomerOrdersPage onlyCustomerOrderToShowId={itemId} />
			),
		});
	}
};