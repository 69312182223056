import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {coloredBooleanColumn, enumColumn, floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {SalesPartView} from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {faBoxOpenFull, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import {OpenObjectChangeLogButton} from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {SalesPartForm} from "src/components/views/erp/sales/basedata/salesParts/SalesPartForm.tsx";
import {SalesPartEditApi} from "src/api/generated/erp/sales/basedata/api/salesPartEditApi.ts";
import {RefreshableElementProps} from "src/utils/useRefreshRef.ts";
import {SalesPartApi} from "src/api/generated/erp/sales/basedata/api/salesPartApi.ts";
import {useServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
    getSalesPartTypeLabel,
    getSalesPartTypeLabels,
    SalesPartType,
    SalesPartTypeValues,
} from "src/api/generated/erp/db/types/enums/salesPartType.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {SelectSiteField} from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    SalesPartContentLinesDataGrid
} from "src/components/views/erp/sales/basedata/salesParts/SalesPartContentLinesDataGrid.tsx";

export interface SalesPartsDataGridBaseProps extends RefreshableElementProps {
	partId: number | null;
	showTypeFiltering?: boolean;
	showSiteFiltering?: boolean;
}

export const SalesPartsDataGrid = ({
	partId,
	showSiteFiltering = true,
	showTypeFiltering = true,
	refreshRef,
}: SalesPartsDataGridBaseProps) => {
	const confirm = useConfirmDialog();
	const { defaultSiteId } = useGlobalInitData();
	const { openDialog } = useGenericDialog();
	const { dataGridProps, refreshData, currentParams, onlySelectedRow } = useServerSideDataGridModel({
		gridId: "9AF12557221C287D0",
		fetchData: (params) =>
			SalesPartApi.searchSalesParts({
				...params,
			}),
		refreshRef: refreshRef,
		getRowId: (row) => row.salesPartId,
		initialParams: {
			partId: partId,
			searchQuery: "",
			siteId: genericNullableValue<number>(defaultSiteId),
			showInactiveSalesParts: false,
			salesPartType: genericNullableValue<SalesPartType>(),
		},
	});

	return (
		<ControlledAsyncCrudDataGrid<SalesPartView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "salesPartNo",
					headerName: i18n.t("sales_part_no"),
					width: 120,
				}),
				textColumn({
					field: "salesPartDescription",
					headerName: i18n.t("description"),
					width: 200,
				}),
				textColumn({
					field: "salesPartCategoryName",
					headerName: i18n.t("sales_part_category"),
					width: 130,
				}),
				enumColumn({
					field: "salesPartType",
					headerName: i18n.t("type"),
					enumLabels: getSalesPartTypeLabels(),
				}),
				textColumn({
					field: "priceGroupName",
					headerName: i18n.t("sales_price_group"),
					width: 120,
				}),
				floatColumn({
					field: "salesPrice",
					headerName: i18n.t("sales_price"),
				}),
				textColumn({
					field: "salesPriceUnit",
					headerName: i18n.t("sales_price_unit"),
				}),
				textColumn({
					field: "salesUnit",
					headerName: i18n.t("sales_unit"),
				}),
				floatColumn({
					field: "priceFactor",
					headerName: i18n.t("price_factor"),
				}),
				floatColumn({
					field: "salesFactor",
					headerName: i18n.t("sales_factor"),
				}),
				textColumn({
					field: "vatCodeName",
					headerName: i18n.t("vat_code"),
				}),
				coloredBooleanColumn({
					field: "isDefault",
					headerName: i18n.t("default"),
					falseColor: "grey",
				}),
				coloredBooleanColumn({
					field: "active",
					headerName: i18n.t("active"),
					falseColor: "red",
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("SALES_PART", onlySelectedRow?.salesPartId)}
					/>
					{partId == null && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={async (value) => {
								await refreshData({ searchQuery: value });
							}}
						/>
					)}
					{showTypeFiltering && (
						<SelectField
							label={i18n.t("type")}
							options={SalesPartTypeValues}
							getOptionKey={(o) => o}
							getOptionLabel={(o) => getSalesPartTypeLabel(o)}
							onChange={async (value) => {
								await refreshData({ salesPartType: value });
							}}
						/>
					)}
					{showSiteFiltering && (
						<SelectSiteField value={currentParams.siteId} onChange={(siteId) => refreshData({ siteId })} />
					)}
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"onlyActiveCheckbox"}
					label={i18n.t("show_inactive_sales_parts")}
					checked={currentParams.showInactiveSalesParts}
					onChange={async (checked) => {
						await refreshData({ showInactiveSalesParts: checked });
					}}
				/>,
			]}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				<OpenObjectChangeLogButton
					key={"changeLog"}
					objectRef={{
						objectType: "SALES_PART",
						objectId: row.salesPartId,
					}}
				/>,
				onlySingleRowSelected && row.active === true && !row.isDefault && (
					<AsyncMenuButton
						key={"setToDefault"}
						icon={faCheck}
						label={i18n.t("set_to_default")}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("are_you_sure"),
								message: i18n.t("do_you_want_to_set_this_sales_part_as_default"),
							});
							if (!confirmed) return;
							await SalesPartEditApi.setToDefaultSalesPart({
								salesPartId: row.salesPartId,
							});
							await refreshData();
						}}
					/>
				),
				row.salesPartType === "PACKAGE_" && (
					<AsyncMenuButton
						key={"packageContentLines"}
						icon={faBoxOpenFull}
						label={i18n.t("package_lines")}
						onClick={async () => {
							openDialog({
								title: i18n.t("package_lines"),
								content: <SalesPartContentLinesDataGrid parentSalesPartId={row.salesPartId} />,
								size: "lg",
							});
						}}
					/>
				),
			]}
			remove={async ({ row }) =>
				SalesPartEditApi.delete_({
					salesPartId: row.salesPartId,
				})
			}
			form={{
				dialogSize: "md",
				dialogTitle: i18n.t("sales_part"),
				addRowEnabled: true,
				editEnabled: true,
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<SalesPartForm
							salesPartId={row?.salesPartId ?? null}
							partId={row?.partId ?? partId ?? null}
							onCompleted={onCompleted}
							onFormEdited={onFormEdited}
						/>
					);
				},
			}}
			{...dataGridProps}
		/>
	);
};
