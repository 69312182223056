import i18n from "i18next";
import { faFlag } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { PurchaseRequestsDataGrid } from "src/components/views/erp/purchase/purchaseRequests/PurchaseRequestsDataGrid.tsx";

export interface OpenPurchaseRequestButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	purchaseRequestId: number;
}

export const OpenPurchaseRequestButton = ({
	purchaseRequestId,
	variant = "menu",
	...props
}: OpenPurchaseRequestButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("open_purchase_request")}
			icon={faFlag}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("purchase_request"),
					content: <PurchaseRequestsDataGrid onlyPurchaseRequestId={purchaseRequestId} />,
				});
			}}
			{...props}
		/>
	);
};
