import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
	ObjectChangeLogDataGridApi,
	ObjectChangeLogDataGridApi_ColumnDefinitionDto,
	ObjectChangeLogDataGridApi_ObjectModificationEventDto,
} from "src/api/generated/changeLogging/objectChangeLogDataGridApi.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { dateTimeColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ObjectModificationLogEventLine } from "src/api/generated/erp/db/types/tables/objectModificationLogEventLine.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { localizeUnsafe } from "src/utils/localizationUtils.ts";

export interface ObjectChangeLogDataGridProps {
	objectRef: AavoObjectRef;
}

export const ObjectChangeLogDataGrid = ({ objectRef }: ObjectChangeLogDataGridProps) => {
	return (
		<ServerSideDataGridModel
			gridId={"E86626432E6D505C4"}
			fetchData={(params) =>
				ObjectChangeLogDataGridApi.getObjectChangeLog({
					objectId: objectRef.objectId,
					objectType: objectRef.objectType,
					...params,
				})
			}
			initialParams={{
				columnNameFilter: genericNullableValue<string>(""),
			}}
			getDataModelResult={(data) => data.gridData}
			getRowId={(row) => row.objectModificationLogEventId}
			render={({ data: { columns }, dataGridProps: { initialState, ...otherDataGridProps }, refreshData }) => {
				const allRowIds = otherDataGridProps.rows.map((event) => otherDataGridProps.getRowId(event));
				return (
					<AavoDataGrid<ObjectChangeLogDataGridApi_ObjectModificationEventDto>
						initialState={{
							...initialState,
							detailPanel: {
								expandedRowIds: allRowIds,
							},
						}}
						columns={[
							dateTimeColumn({
								field: "modifiedAt",
								headerName: i18n.t("modified_at"),
								width: 200,
							}),
							textColumn({
								field: "modifiedByUserName",
								headerName: i18n.t("modified_by"),
								width: 200,
							}),
						]}
						getDetailPanelContent={({ row }) => (
							<EventLinesContent lines={row.modificationEventLines} columns={columns} />
						)}
						getDetailPanelHeight={() => "auto"}
						actionBarComponents={
							<>
								<SelectField
									label={i18n.t("changed_field")}
									options={columns}
									getOptionKey={(option) => option.columnName}
									getOptionLabel={(option) => localizeUnsafe(option.columnLabelLocalizationKey)}
									onChange={async (value) => await refreshData({ columnNameFilter: value })}
								/>
							</>
						}
						{...otherDataGridProps}
					/>
				);
			}}
		/>
	);
};

interface EventLinesContentProps {
	lines: ObjectModificationLogEventLine[];
	columns: ObjectChangeLogDataGridApi_ColumnDefinitionDto[];
}

const EventLinesContent = ({ lines, columns }: EventLinesContentProps) => {
	const columnLocalizationKeyByName: Record<string, string> = columns.reduce(
		(acc, column) => ({
			...acc,
			[column.columnName]: column.columnLabelLocalizationKey,
		}),
		{},
	);
	return (
		<LabelValueView
			sx={{
				paddingLeft: "3.75rem",
				borderBottom: "1px solid grey",
			}}
			fontVariant={"body2"}
			items={lines.map((line) => {
				const columnLocalizationKey = columnLocalizationKeyByName[line.columnName];
				if (columnLocalizationKey == null) {
					logError(`Column ${line.columnName} not found`);
				}
				const labelText = columnLocalizationKey == null ? "-" : localizeUnsafe(columnLocalizationKey);
				const arrow = "\u21FE";
				return {
					label: labelText,
					value: `${line.oldValue} ${arrow} ${line.newValue}`,
				};
			})}
		/>
	);
};
