// This file is automatically generated. Do not edit manually.

import { PartConfigurationProperty } from "src/api/generated/erp/db/types/tables/partConfigurationProperty";
import { IsoDateTimeString } from "src/types/dateTime";
import { ConfigurationPriceCalculationMethod } from "src/api/generated/erp/db/types/enums/configurationPriceCalculationMethod";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfigurationApi {
	export async function getPartConfiguration(args: {
		partConfigurationId: number;
	}): Promise<PartConfigurationApi_PartConfigurationDto> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/getPartConfiguration",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as PartConfigurationApi_PartConfigurationDto;
	}

	export async function getPartConfigurationProperties(args: {
		partConfigurationId: number;
	}): Promise<Array<PartConfigurationProperty>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuration/getPartConfigurationProperties",
			method: "POST",
			data: {
				partConfigurationId: args.partConfigurationId,
			},
		});
		return response as Array<PartConfigurationProperty>;
	}
}

export interface PartConfigurationApi_PartConfigurationDto {
	isEditable: boolean;
	basePrice: number;
	capacityQuantity: number | null | undefined;
	catalogPartId: number;
	catalogPartRevisionId: number;
	configurationLogPathBase64: string;
	createdAt: IsoDateTimeString;
	createdByAppUserName: string;
	createdByUserId: number;
	description: string;
	discountPercentage: number;
	editedManually: boolean;
	lastUpdated: IsoDateTimeString;
	materialCost: number;
	partConfigurationId: number;
	partDescription_1: string;
	partDescription_2: string;
	partId: number;
	partLongName: string | null | undefined;
	partNo: string;
	partRevisionId: number;
	priceCalculationMethod: ConfigurationPriceCalculationMethod;
	productFamilyVersionId: number | null | undefined;
	revisionNumber: number;
	salesPrice: number;
	siteId: number;
	totalCost: number;
	weight: number | null | undefined;
	workCost: number;
}
