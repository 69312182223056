import i18n from "i18next";
import { faTruckLoading } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {
	DeliverySourceDeliveringView
} from "src/components/views/erp/delivery/delivery/DeliverySourceDeliveringView.tsx";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";

export interface OpenDeliveryViewButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	deliverySource: DeliverySource | null;
	onlyDeliveryId?: number;
}

export const OpenDeliveryViewButton = ({
	deliverySource,
	onlyDeliveryId,
	variant = "menu",
}: OpenDeliveryViewButtonProps) => {
	const { openDialog } = useGenericDialog();

	const { tenantConfig } = useTenantCustomizations();
	if (!tenantConfig.erp.salesEnabled) return null;

	return (
		<AsyncButton
			label={i18n.t("open_delivery")}
			icon={faTruckLoading}
			variant={variant}
			disabled={deliverySource == null}
			onClick={() => {
				if (deliverySource == null) return;
				openDialog({
					title: i18n.t("delivery"),
					content: (
						<DeliverySourceDeliveringView
							deliverySource={deliverySource}
							onlyDeliveryId={onlyDeliveryId}
						/>
					),
				});
			}}
		/>
	);
};
