import { UnresolvedSpecialCausesDataGridApi } from "src/api/generated/spc/controlChart/api/unresolvedSpecialCausesDataGridApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { dateTimeColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getSpecialCauseDescriptionForCode } from "src/components/views/spc/specialCauseUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { useContext } from "react";
import { RawRecordView } from "src/api/generated/postgres/db/types/public_/tables/rawRecordView.ts";
import {
	FocusControlChartGraphViewToRecordButton,
	UploadDocumentsToSpcRecordButton,
} from "src/components/views/spc/controlChart/controlChartInspectingView/rawRecordDataGridUtils.tsx";
import { parseDayJsNullable } from "src/utils/dayjsUtils.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { SpcRecordActionForm } from "src/components/views/spc/controlChart/controlChartInspectingView/SpcRecordActionForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ControlChartInspectingViewContext } from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingViewContext.ts";

export interface UnresolvedSpecialCausesDataGridProps {
	controlChartId: number;
}

export const UnresolvedSpecialCausesDataGrid = ({ controlChartId }: UnresolvedSpecialCausesDataGridProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const { openDialog } = useGenericDialog();

	const { graphViewApiRef, eventsDataGridRefreshRef } = useContext(ControlChartInspectingViewContext) ?? {};

	const { dataGridProps, onlySelectedRow, selectedRows, refreshData } = useServerSideDataGridModel({
		gridId: "FD2EC55B3BC14A9BD",
		fetchData: (params) =>
			UnresolvedSpecialCausesDataGridApi.getGridData({
				controlChartId,
				...params,
			}),
		initialParams: {},
		getRowId: (row) => row.recordId,
	});

	return (
		<ControlledAsyncDataGrid<RawRecordView>
			disableColumnFilter
			columns={[
				dateTimeColumn({
					field: "observationTime",
					headerName: i18n.t("recorded_at"),
					sortable: false,
					width: 150,
				}),
				textColumn({
					field: "specialCauseCode",
					headerName: i18n.t("special_cause"),
					valueGetter: (_, row) =>
						row.specialCauseCode == null ?
							""
						:	getSpecialCauseDescriptionForCode(row.specialCauseCode),
					sortable: false,
					width: 600,
				}),
				integerColumn({
					field: "recordId",
					headerName: i18n.t("id"),
					sortable: false,
				}),
			]}
			actionBarComponents={
				<>
					{tenantConfig.documentsEnabled && (
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("SPC_RECORD", onlySelectedRow?.recordId)}
							/>
							<UploadDocumentsToSpcRecordButton
								disabled={!onlySelectedRow}
								recordId={onlySelectedRow?.recordId}
							/>
						</>
					)}
					<FocusControlChartGraphViewToRecordButton
						disabled={!onlySelectedRow}
						recordObservationTime={parseDayJsNullable(onlySelectedRow?.observationTime)}
						graphViewApiRef={graphViewApiRef}
					/>
					<AavoButton
						label={i18n.t("spc_action")}
						icon={faCheck}
						variant={"outlined"}
						disabled={selectedRows.length === 0}
						onClick={() => {
							openDialog(({ closeDialog, onContentEdited }) => ({
								title: i18n.t("spc_action"),
								size: "sm",
								content: (
									<SpcRecordActionForm
										recordIds={selectedRows.map((row) => row.recordId)}
										onFormEdited={onContentEdited}
										onCompleted={async () => {
											await closeDialog();
											await Promise.all([
												refreshData(),
												graphViewApiRef?.current?.refresh(),
												eventsDataGridRefreshRef?.refresh(),
											]);
										}}
									/>
								),
							}));
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
