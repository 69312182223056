// This file is automatically generated. Do not edit manually.

import { ShopOrderBatchState } from "src/api/generated/erp/db/types/enums/shopOrderBatchState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { IsoDateString } from "src/types/dateTime";
import { IsoDateTimeString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBatchDataGridApi {
	export async function getShopOrderBatches(args: {
		searchQuery?: string;
		batchState?: ShopOrderBatchState | null | undefined;
		onlyShopOrderBatchId?: number | null | undefined;
		includeReleased?: boolean;
		includeCancelled?: boolean;
		onlyDefaultSite?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<ShopOrderBatchDataGridApi_ShopOrderBatchDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBatch/dataGrid/getShopOrderBatches",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				batchState: args.batchState,
				onlyShopOrderBatchId: args.onlyShopOrderBatchId,
				includeReleased: args.includeReleased,
				includeCancelled: args.includeCancelled,
				onlyDefaultSite: args.onlyDefaultSite,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<ShopOrderBatchDataGridApi_ShopOrderBatchDto>;
	}
}

export interface ShopOrderBatchDataGridApi_ShopOrderBatchDto {
	earliestDemandDate: IsoDateString;
	tenantCustomColumn1: number | null | undefined;
	tenantCustomColumn2: number | null | undefined;
	totalCapacityQuantity: number;
	totalQuantity: number;
	batchCode: string | null | undefined;
	batchName: string;
	batchState: ShopOrderBatchState;
	cancelledAt: IsoDateTimeString | null | undefined;
	createdAt: IsoDateTimeString;
	createdByUserId: number;
	createdByUserName: string;
	customerOrderId: number | null | undefined;
	orderReference: string | null | undefined;
	plannedBeginDate: IsoDateTimeString | null | undefined;
	productionLineDescription: string;
	productionLineId: number;
	productionLineName: string;
	productionLineNo: string | null | undefined;
	releasedAt: IsoDateTimeString | null | undefined;
	releasedByUserId: number | null | undefined;
	releasedByUserName: string | null | undefined;
	shopOrderBatchId: number;
	shopOrderBatchLastUpdated: IsoDateTimeString;
	siteId: number;
	siteName: string;
}
