import { useRef } from "react";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { Button, Tooltip } from "@mui/material";
import i18n from "i18next";
import { useFileInputDnd } from "src/components/common/inputFields/fileInput/useFileInputDnd.tsx";
import { ImportMultipleDocumentsForm } from "src/components/views/documents/import/ImportMultipleDocumentsForm.tsx";
import { GenericDialogContext } from "src/components/common/dialogs/GenericDialogContext.ts";

import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export interface DocumentDropZoneProps {
	objectRef?: AavoObjectRef;
	onSubmit: () => void;
}

export const DocumentDropZone = (
	{
		objectRef,
		onSubmit
	}: DocumentDropZoneProps) => {
	const ref = useRef(null);

	const { openDialog } = useContextOrThrow(GenericDialogContext);

	const openImportDialogForFiles = (files: File[]) => {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("import_documents"),
			size: "lg",
			content: <ImportMultipleDocumentsForm
				objectRef={objectRef}
				onCompleted={async () => {
					await closeDialog();
					onSubmit();
				}}
				files={files} />
		}));
	};

	const { isDraggingOver } = useFileInputDnd({
		ref,
		onDrop: openImportDialogForFiles
	});

	return <Tooltip title={i18n.t("click_or_drag_files_here")}>
		<Button ref={ref}
				component="label"
				color={"inherit"}
				sx={{
					border: "2px dashed gray",
					borderRadius: "3px",
					paddingX: "0.5rem",
					display: "flex",
					alignItems: "center",
					backgroundColor: isDraggingOver ? "lightgray" : "transparent"
				}}
		>
			{i18n.t("import_files")}
			<input
				type="file"
				multiple
				hidden
				onChange={(e) => {
					const files = Array.from(e.target.files ?? []);
					openImportDialogForFiles(files);
				}}
			/>
		</Button>
	</Tooltip>;
};
