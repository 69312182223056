import { useEffect } from "react";
import { Box, Card, Snackbar, Typography } from "@mui/material";
import { AavoViewsContext } from "src/components/views/legacy/AavoViewsContext.tsx";
import { AavoToastContent } from "./types";
import { isOpenToastEvent, SHOW_TOAST_EVENT_ACTION_SPECIFIER, ViewActionEvent } from "../../views/legacy/events";
import { primitiveToString } from "src/types/primitive.ts";

import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { AavoIconButton } from "../buttons/AavoIconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

export interface AavoToastProps {
	content: AavoToastContent | undefined;
	closeToast: () => void;
	showToast: (content: AavoToastContent) => void;
}

export const AavoToast = ({ content, closeToast, showToast }: AavoToastProps) => {
	const { state: aavoViewsState } = useContextOrThrow(AavoViewsContext);

	useEffect(() => {
		aavoViewsState.viewActionEvents.events.forEach((e) => {
			if (isOpenToastEvent(e)) {
				showToast(getToastContentFromEvent(e));
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aavoViewsState.viewActionEvents]);

	return (
		<Snackbar
			open={content != undefined}
			onClose={() => {
				closeToast();
			}}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			autoHideDuration={2500}
		>
			<Card
				sx={{
					padding: 2,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						justifyContent: "space-between",
						alignItems: "center",
						gap: 1,
					}}
				>
					<Typography variant={"h6"}>{content?.title}</Typography>
					<AavoIconButton
						icon={faTimes}
						sx={{
							padding: 0,
						}}
						onClick={() => {
							closeToast();
						}}
					/>
				</Box>
				{content?.message && (
					<>
						<Typography
							sx={{
								paddingTop: 1,
								wordWrap: "break-word",
							}}
							color={(theme) => theme.palette.grey[600]}
						>
							{content.message}
						</Typography>
					</>
				)}
			</Card>
		</Snackbar>
	);
};

const TOAST_TITLE_PARAM_KEY = "__MSG_TITLE__";
const TOAST_MSG_PARAM_KEY = "__MSG_CONTENT__";
const getToastContentFromEvent = (e: ViewActionEvent): AavoToastContent => {
	const titleParam = e.allParams.find((param) => {
		return param.key === TOAST_TITLE_PARAM_KEY;
	});
	const msgParam = e.allParams.find((param) => {
		return param.key === TOAST_MSG_PARAM_KEY;
	});
	if (titleParam === undefined || msgParam === undefined) {
		throw Error(
			`${SHOW_TOAST_EVENT_ACTION_SPECIFIER} action event did not contain ${TOAST_TITLE_PARAM_KEY} or ${TOAST_MSG_PARAM_KEY} parameters.`,
		);
	}
	return {
		title: primitiveToString(titleParam.value),
		message: primitiveToString(msgParam.value),
	};
};
