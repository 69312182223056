import { ThemeProvider } from "@mui/material/styles";
import { AAVO_MUI_THEME } from "src/theme/theme.ts";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { AavoToastContextProvider } from "src/components/common/toast/AavoToastContextProvider.tsx";
import { ConfirmDialogProvider } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContextProvider.tsx";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ErrorDialogContextProvider } from "src/components/common/dialogs/errorDialog/ErrorDialogContextProvider.tsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as DatePickerLocalizationProvider } from "@mui/x-date-pickers-pro";
import { getLanguageSetting } from "./localSettings/utils";
import { BrowserRouter } from "react-router-dom";
import { MessageDialogContextProvider } from "src/components/common/dialogs/messageDialog/MessageDialogContextProvider.tsx";
import Box from "@mui/material/Box";
import { ErrorBoundary } from "src/sentry/ErrorBoundary.tsx";
import * as Sentry from "@sentry/react";
import { AavoRoutes } from "./AavoRoutes";
import { FeatureChangeHintContextProvider } from "src/components/common/featureChangeHint/FeatureChangeHintContextProvider.tsx";
import { PushNotificationsContextProvider } from "./notifications/PushNotificationsContextProvider";
import { AavoViewsContextProvider } from "./components/views/legacy/AavoViewsContextProvider";
import { TenantCustomizationsContextProvider } from "./tenantCustomizations/TenantCustomizationsContextProvider";

const cache = createCache({
	key: "css",
	prepend: true,
});

export const App = () => (
	<Box
		sx={{
			height: "100%",
			display: "flex",
		}}
	>
		<ErrorBoundary>
			<Sentry.Profiler>
				<ThemeProvider theme={AAVO_MUI_THEME}>
					<CacheProvider value={cache}>
						{/*
						 Customized errors views are disabled here, 
						 because they may use some context which is not yet provided.
						 Another ErrorDialogContextProvider with customized views are provided later in the component tree.
						 */}
						<ErrorDialogContextProvider disableCustomizedErrorViews>
							<ConfirmDialogProvider>
								<MessageDialogContextProvider>
									<PushNotificationsContextProvider>
										<TenantCustomizationsContextProvider>
											<FeatureChangeHintContextProvider>
												<DndProvider backend={HTML5Backend}>
													<DatePickerLocalizationProvider
														dateAdapter={AdapterDayjs}
														adapterLocale={getLanguageSetting()}
													>
														<BrowserRouter
															future={{
																v7_relativeSplatPath: true,
																v7_startTransition: true
															}}
														>
															<AavoViewsContextProvider>
																<AavoToastContextProvider>
																	<AavoRoutes />
																</AavoToastContextProvider>
															</AavoViewsContextProvider>
														</BrowserRouter>
													</DatePickerLocalizationProvider>
												</DndProvider>
											</FeatureChangeHintContextProvider>
										</TenantCustomizationsContextProvider>
									</PushNotificationsContextProvider>
								</MessageDialogContextProvider>
							</ConfirmDialogProvider>
						</ErrorDialogContextProvider>
					</CacheProvider>
				</ThemeProvider>
			</Sentry.Profiler>
		</ErrorBoundary>
	</Box>
);
