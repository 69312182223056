import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { TasksView } from "src/components/views/tasks/TasksView.tsx";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";

export const TasksAssignedToMePage = () => {
	const { appUserId } = useGlobalInitData();
	const incompleteTasksRefreshRef = useRefreshRef();
	const completedTasksRefreshRef = useRefreshRef();
	return (
		<AavoMosaic
			viewId={"9164D192592F6562"}
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("incomplete"),
						content: (
							<TasksView
								refreshRef={incompleteTasksRefreshRef}
								onTaskCompleted={() => completedTasksRefreshRef.refresh()}
								taskQueryParams={{
									states: ["RELEASED", "STARTED"],
									assignedToUserId: appUserId,
								}}
								showAssignedTo={false}
								showStateFilter={false}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("completed"),
						content: (
							<TasksView
								refreshRef={completedTasksRefreshRef}
								taskQueryParams={{
									states: ["COMPLETED"],
									assignedToUserId: appUserId,
								}}
								onTaskCompletionReverted={() =>
									incompleteTasksRefreshRef.refresh()
								}
								showAssignedTo={false}
								showGanttButton={false}
							/>
						),
					},
				],
			}}
		/>
	);
};
