import React, { useState } from "react";
import { GridRowClassNameParams, GridRowId, GridRowParams } from "@mui/x-data-grid-pro";
import { openFormForDataGridRow } from "src/components/common/dataGrid/dataGridFormUtils";
import {
	DataGridValidationContextProvider,
	DataGridValidationErrors,
} from "src/components/common/dataGrid/crud/validation/DataGridValidationContextProvider.tsx";
import { AavoDataGrid, AavoDataGridProps } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { dataGridRowClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { GenericDialogContext } from "src/components/common/dialogs/GenericDialogContext.ts";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import {
	CrudActionButtonDefinition,
	CrudActionButtonDefinitionWithShortcuts,
	removeActionShortcuts,
} from "src/components/common/crud/crudActionButtonDefinition.ts";
import i18n from "i18next";
import { CrudActionButtons } from "../../crud/CrudActionButtons";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";
import { DataGridFormDefinition } from "src/components/common/dataGrid/types.tsx";
import { GridRowIdGetter, GridRowModelUpdate } from "@mui/x-data-grid/models/gridRows";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { isNotNullOrEmptyObject } from "src/utils/objectUtils.ts";
import { flattenAavoDataGridColumns, getDataGridRowsByIds } from "src/components/common/dataGrid/utils.ts";
import { uniq } from "underscore";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";

export interface CrudDataGridProps<RowData extends object> extends Omit<AavoDataGridProps<RowData>, "getRowId"> {
	refreshData: () => Promise<unknown>;
	add?: CrudActionButtonDefinitionWithShortcuts<void, Partial<RowData>>;
	save?: CrudActionButtonDefinitionWithShortcuts<
		{
			items: RowData[];
		},
		RowData[]
	>;
	remove?: CrudActionButtonDefinitionWithShortcuts<
		{
			row: RowData;
			items: RowData[];
		},
		unknown
	>;
	form?: DataGridFormDefinition<RowData>;
	getRowId: GridRowIdGetter<RowData>;
	crudDataGridApiRef?: React.MutableRefObject<CrudDataGridApi | null>;
	dataDirtyStateChanged?: DataDirtyStateChangeHandler;
}

export interface CrudDataGridApi {
	updateRows: GridApiPro["updateRows"];
}

type NewRowData<RowData extends object> = {
	__generated_id: string;
	isNewRow: true;
} & Partial<RowData>;

export const CrudDataGrid = <RowData extends object>({
	refreshData: refreshDataProp,
	getRowId: getRowIdProp,
	add: addProp,
	save: saveProp,
	remove: removeProp,
	form,
	actionBarComponents,
	apiRef: forwardedApiRef,
	selectedRows: selectedRowsProp,
	onRowSelectionChanged: onRowSelectionChangedProp,
	rowCount,
	editMode = "row",
	onCellEditStart,
	onCellEditStop,
	onRowEditStart,
	onRowEditStop,
	getRowClassNames: getRowClassNamesProp,
	crudDataGridApiRef,
	onRowDoubleClick,
	columns,
	dataDirtyStateChanged,
	...other
}: CrudDataGridProps<RowData>) => {
	const add = removeActionShortcuts(addProp);
	const save = removeActionShortcuts(saveProp);
	const remove = removeActionShortcuts(removeProp);

	validateActionParameters();

	const { openDialog } = useContextOrThrow(GenericDialogContext);
	const showConfirmDialog = useConfirmDialog();
	const gridApiRef = useForwardedRef(forwardedApiRef);

	const [rowUnderEdit, setRowUnderEdit] = useState<GridRowId | undefined>(undefined);
	const [editedRows, setEditedRows] = useState<GridRowId[]>([]);
	const [unsavedNewRows, setUnsavedNewRows] = useState<GridRowId[]>([]);
	const [selectedRowIds, setSelectedRowIds] = useMaybeControlledState<GridRowId[]>({
		controlledValue: selectedRowsProp,
		onChange: onRowSelectionChangedProp,
		defaultValue: [],
	});
	const isDirty = editedRows.length > 0 || unsavedNewRows.length > 0;

	const [validationErrors, setValidationErrors] = React.useState<DataGridValidationErrors>({});

	const getGridApi = (): GridApiPro => {
		const current = gridApiRef.current;
		if (isNotNullOrEmptyObject(current)) {
			return current;
		} else {
			const error = "GridApiRef has no value";
			console.error(error);
			throw new Error(error);
		}
	};

	const getRowId = wrapGetRowId();

	const refreshData = async (refreshParams?: { warnIfDirty?: boolean }) => {
		const { warnIfDirty = true } = refreshParams ?? {};
		if (warnIfDirty && isDirty) {
			const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
			if (!confirmed) return;
		}
		await refreshDataProp();
		setUnsavedNewRows([]);
		setEditedRows([]);
		setValidationErrors({});
	};

	const hasAnyValidationErrors = () =>
		Object.values(validationErrors).some((rowErrors) =>
			Object.values(rowErrors).some((fieldError) => fieldError !== undefined),
		);

	const rowCountWithUnsavedRows = rowCount === undefined ? undefined : rowCount + unsavedNewRows.length;

	if (crudDataGridApiRef != null)
		crudDataGridApiRef.current = {
			updateRows: (updates: GridRowModelUpdate[]) => {
				const gridApi = getGridApi();
				gridApi.updateRows(updates);
				setEditedRows((prev) => {
					return uniq([...prev, ...updates.map((u) => getRowId(u as RowData | NewRowData<RowData>))]);
				});
			},
		};

	const editingEnabled = add.type === "enabled" || save.type === "enabled";
	const mappedColumns =
		editingEnabled ? columns : (
			flattenAavoDataGridColumns(columns).map((column) => ({
				...column,
				editable: false,
			}))
		);

	return (
		<DataGridValidationContextProvider setErrors={setValidationErrors}>
			<AavoDataGrid
				rowCount={rowCountWithUnsavedRows}
				apiRef={gridApiRef}
				columns={mappedColumns}
				getRowId={getRowId}
				editMode={editMode}
				refreshData={async () => {
					await onRefresh();
				}}
				actionBarComponents={
					<>
						<CrudActionButtons
							add={wrapAddAction()}
							save={wrapSaveAction()}
							remove={wrapRemoveAction()}
							edit={getEditAction()}
						/>
						{actionBarComponents}
					</>
				}
				onCellEditStart={(params, event, details) => {
					onCellEditStart?.(params, event, details);
					handleRowEditStarted(getRowId(params.row), isNewRow(params.row));
					dataDirtyStateChanged?.({ isDirty: true });
				}}
				onCellEditStop={(params, event, details) => {
					onCellEditStop?.(params, event, details);
					setRowUnderEdit(undefined);
				}}
				onRowEditStart={(params, event, details) => {
					onRowEditStart?.(params, event, details);
					handleRowEditStarted(getRowId(params.row), isNewRow(params.row));
					dataDirtyStateChanged?.({ isDirty: true });
				}}
				onRowEditStop={(params, event, details) => {
					onRowEditStop?.(params, event, details);
					setRowUnderEdit(undefined);
				}}
				getRowClassNames={(params) => getRowClassNames(params)}
				selectedRows={selectedRowIds}
				onRowSelectionChanged={setSelectedRowIds}
				onRowDoubleClick={async (params: GridRowParams<RowData>, event, details) => {
					if (onRowDoubleClick != null) {
						onRowDoubleClick?.(params, event, details);
					} else if (form?.editEnabled === true) {
						await openFormForRow(params.row);
					}
				}}
				{...other}
			/>
		</DataGridValidationContextProvider>
	);

	function validateActionParameters() {
		const hasAddAction = add !== undefined && add.type !== "hidden";
		const hasSaveAction = save !== undefined && save.type !== "hidden";
		const hasSaveOrAddAction = hasAddAction || hasSaveAction;
		if (form !== undefined && hasSaveOrAddAction) {
			console.warn(
				"Form was defined to table, together with add or save action. " +
					"Add and save action definition does not make sense if the form is defined and thus can cause errors.",
			);
		}
	}

	function wrapGetRowId() {
		return (row: RowData | NewRowData<RowData>) => {
			if (isNewRow(row)) {
				return row.__generated_id;
			} else {
				return getRowIdProp(row);
			}
		};
	}

	function wrapAddAction(): CrudActionButtonDefinition {
		if (form !== undefined) {
			if (!form.addRowEnabled) return { type: "hidden" };

			return {
				type: "enabled",
				action: async () => {
					await openFormForRow(undefined);
				},
			};
		}

		if (add?.type === "enabled") {
			return {
				type: add.type,
				action: async () => {
					const newRow = await add.action();
					if (newRow !== "interrupt") {
						addNewLocalUnsavedRow(newRow);
					}
				},
			};
		}

		return {
			type: "hidden",
		};
	}

	function wrapSaveAction(): CrudActionButtonDefinition {
		if (form !== undefined) return { type: "hidden" };

		if (save?.type !== "enabled") return save ?? { type: "hidden" };

		if (hasAnyValidationErrors())
			return {
				type: "disabled",
				tooltip: i18n.t("fix_errors_before_saving"),
			};

		if (!unsavedNewRows.length && !editedRows.length && rowUnderEdit === undefined)
			return {
				type: "disabled",
				tooltip: i18n.t("no_unsaved_or_edited_rows"),
			};

		return {
			type: save.type,
			action: async () => {
				const changedRows = getLocallyChangedRows();
				await save.action({ items: changedRows });
				dataDirtyStateChanged?.({ isDirty: false });
				await refreshData({ warnIfDirty: false });
			},
		};
	}

	function wrapRemoveAction(): CrudActionButtonDefinition {
		if (remove?.type !== "enabled") return remove ?? { type: "hidden" };

		if (selectedRowIds.length === 0)
			return {
				type: "disabled",
				tooltip: i18n.t("select_at_least_one_row"),
			};

		return {
			...remove,
			action: async () => {
				const gridApi = getGridApi();
				const selectedRows = getDataGridRowsByIds<RowData>(gridApi, selectedRowIds);
				const unsavedSelectedRows = selectedRows.filter((r) => isNewRow(r));
				const savedSelectedRows = selectedRows.filter((r) => !isNewRow(r));

				// Execute delete on remove server.
				if (savedSelectedRows.length > 0) {
					const result = await remove.action({
						row: savedSelectedRows[0]!,
						items: savedSelectedRows,
					});
					if (result === "interrupt") return;
					await refreshData();
				}

				// Delete rows locally from grid.
				const localRowsToRemove = [...unsavedSelectedRows, ...savedSelectedRows];
				gridApi.updateRows(
					localRowsToRemove.map((r) => {
						return {
							_action: "delete",
							...r,
						};
					}),
				);

				// Remove deleted rows form dirty row states.
				const deletedRowIds = selectedRowIds;
				setEditedRows((prev) => prev.filter((r) => !deletedRowIds.includes(r)));
				setUnsavedNewRows((prev) => prev.filter((r) => !deletedRowIds.includes(r)));
			},
		};
	}

	function getEditAction(): CrudActionButtonDefinition {
		if (form === undefined || !form.editEnabled) return { type: "hidden" };

		if (selectedRowIds.length !== 1)
			return {
				type: "disabled",
				tooltip: i18n.t("select_a_single_row"),
			};

		return {
			type: "enabled",
			action: async () => {
				const gridApi = getGridApi();
				const firstRowId = selectedRowIds[0];
				if (firstRowId != null) {
					const firstRow = gridApi.getRow<RowData>(firstRowId);
					if (firstRow != null) {
						await openFormForRow(firstRow);
					}
				}
			},
		};
	}

	async function onRefresh() {
		const gridApi = getGridApi();
		// Bug in MUIGridPro requires that editing of unsaved rows is stopped manually before refresh.
		unsavedNewRows
			.filter((rowId) => gridApi.getRowMode(rowId) === "edit")
			.forEach((rowId) => {
				gridApi.stopRowEditMode({
					id: rowId,
					ignoreModifications: true,
				});
			});
		await refreshData();
	}

	async function openFormForRow(row: RowData | undefined) {
		if (form === undefined) return;

		await openFormForDataGridRow({
			row: row,
			form: form,
			getApi: getGridApi,
			openDialog: openDialog,
			refreshData: refreshData,
		});
	}

	function addNewLocalUnsavedRow(newRow: Partial<RowData>) {
		const gridApi = getGridApi();

		const newRowGeneratedId = crypto.randomUUID();
		gridApi.updateRows([
			{
				...newRow,
				isNewRow: true,
				__generated_id: newRowGeneratedId,
			},
		]);
		setUnsavedNewRows((prev) => {
			return [...prev, newRowGeneratedId];
		});
		startToEditNewRow(newRowGeneratedId);
	}

	function startToEditNewRow(rowId: GridRowId) {
		const gridApi = getGridApi();

		const firstEditableField = gridApi.getAllColumns().find((c) => c.editable)?.field;
		if (!firstEditableField) return;

		if (editMode === "row")
			gridApi.startRowEditMode({
				id: rowId,
				fieldToFocus: firstEditableField,
			});
		else if (editMode === "cell")
			gridApi.startCellEditMode({
				id: rowId,
				field: firstEditableField,
			});

		handleRowEditStarted(rowId, true);
	}

	function handleRowEditStarted(rowId: GridRowId, isNewRow: boolean) {
		setRowUnderEdit(rowId);
		if (!isNewRow) {
			setEditedRows((prev) => {
				if (prev.includes(rowId)) {
					return prev;
				} else {
					return [...prev, rowId];
				}
			});
		}
	}

	function getLocallyChangedRows(): RowData[] {
		const gridApi = getGridApi();
		return uniq([...editedRows, ...unsavedNewRows])
			.map((id) => {
				return gridApi.getRowWithUpdatedValues(id, "") as RowData;
			})
			.filter((r) => r !== null && r !== undefined);
	}

	function getRowClassNames(params: GridRowClassNameParams): string[] {
		const ret = [];

		const fromProp = getRowClassNamesProp?.(params) ?? [];
		if (typeof fromProp === "string") ret.push(fromProp);
		else ret.push(...fromProp);

		if (params.row.isNewRow) {
			ret.push(dataGridRowClassNames.unsavedNew);
		} else if (editedRows.includes(params.id)) {
			ret.push(dataGridRowClassNames.edited);
		}
		return ret;
	}

	function isNewRow(value: RowData | NewRowData<RowData>): value is NewRowData<RowData> {
		return (value as NewRowData<RowData>).isNewRow;
	}
};
