import { DocumentActiveRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentActiveRevisionView.ts";
import { DocumentDropZone } from "src/components/views/documents/import/DocumentDropZone.tsx";
import { DocumentSearchDataGridBase } from "./DocumentSearchDataGridBase";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { DocumentDeleteApi } from "src/api/generated/documents/api/documentDeleteApi.ts";
import { ConfirmDialogContext } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";

export interface DocumentSearchDataGridProps {
	onSelectedDocumentChanged: (document: DocumentActiveRevisionView | undefined) => void;
}

export const DocumentSearchDataGrid = ({
	onSelectedDocumentChanged,
}: DocumentSearchDataGridProps) => {
	const refreshRef = useRefreshRef();

	const showConfirmationDialog = useContextOrThrow(ConfirmDialogContext);

	return (
		<DocumentSearchDataGridBase
			refreshRef={refreshRef}
			onRowSelectionChanged={(rows) => {
				onSelectedDocumentChanged(rows[0]);
			}}
			actionBarComponents={<DocumentDropZone onSubmit={() => refreshRef.refresh()} />}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"delete"}
						label={i18n.t("delete_document")}
						icon={faTrash}
						onClick={async () => {
							const confirmed = await showConfirmationDialog({
								message: i18n.t("confirm_delete_document", {
									documentName: row.description1,
								}),
							});
							if (!confirmed) return;

							await DocumentDeleteApi.deleteDocument({ documentId: row.documentId });
							refreshRef.refresh();
						}}
					/>
				),
			]}
		/>
	);
};
