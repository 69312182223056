import i18n from "i18next";
import { faBriefcase } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { WorkspacesView } from "src/components/views/workspaces/WorkspacesView.tsx";
import { WorkspaceConnectedItemRef } from "src/api/generated/workspaces/connections/workspaceConnectedItemRef.ts";

export interface OpenConnectedWorkspacesButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	connectedItemRef: WorkspaceConnectedItemRef;
}

export const OpenConnectedWorkspacesButton = ({
	connectedItemRef,
	variant = "menu",
}: OpenConnectedWorkspacesButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("workspaces")}
			icon={faBriefcase}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("workspaces"),
					content: (
						<WorkspacesView
							onlyWorkspacesConnectedToItem={connectedItemRef}
						/>
					),
				});
			}}
		/>
	);
};
