import { AavoToastContext } from "src/components/common/toast/AavoToastContext.tsx";
import { AavoToast } from "./AavoToast";
import React, { useMemo, useState } from "react";
import { AavoToastContent } from "./types";

export const AavoToastContextProvider = ({ children }: React.PropsWithChildren) => {
	const [toastContent, setToastContent] = useState<AavoToastContent | undefined>(undefined);

	const contextValue = useMemo(
		() => ({
			showToast: (content: AavoToastContent) => {
				setToastContent(content);
			},
			closeToast: () => {
				setToastContent(undefined);
			},
		}),
		[setToastContent],
	);

	return (
		<AavoToastContext.Provider value={contextValue}>
			<AavoToast content={toastContent} closeToast={contextValue.closeToast} showToast={contextValue.showToast} />
			{children}
		</AavoToastContext.Provider>
	);
};
