import { AddNewWorkspaceConnectionsViewProps } from "src/components/views/workspaces/workspaceConnections/connectionTypes/WorkspaceConnectedItemTypeConfiguration.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCustomerOrderStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { WorkspaceConnectionsApi } from "src/api/generated/workspaces/api/workspaceConnectionsApi.ts";
import {
	CustomerOrderDataGridApi,
	CustomerOrderDataGridApi_CustomerOrderDto,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";

export const ConnectCustomerOrdersToWorkspaceDataGrid = ({
	workspaceId,
	onCompleted,
}: AddNewWorkspaceConnectionsViewProps) => {
	const { defaultSiteId } = useGlobalInitData();

	return (
		<ServerSideDataGridModel
			gridId={"B0C3406EC906CAFC"}
			initialParams={{
				searchQuery: "",
				siteId: genericNullableValue<number>(defaultSiteId),
			}}
			fetchData={(params) => CustomerOrderDataGridApi.searchCustomerOrders(params)}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.customerOrderId}
			render={({ refreshData, dataGridProps, selectedRows, currentParams }) => (
				<AavoDataGrid<CustomerOrderDataGridApi_CustomerOrderDto>
					columns={[
						integerColumn({
							field: "customerOrderId",
							headerName: i18n.t("number_shortened"),
						}),
						textColumn({
							field: "siteName",
							headerName: i18n.t("site_short"),
						}),
						textColumn({
							field: "customerName",
							headerName: i18n.t("customer"),
							width: 150,
						}),
						textColumn({
							field: "orderReference",
							headerName: i18n.t("reference"),
							width: 150,
						}),
						textColumn({
							field: "customerPoNo",
							headerName: i18n.t("customer_po_no"),
						}),
						enumColumn({
							field: "customerOrderState",
							headerName: i18n.t("state"),
							enumLabels: getCustomerOrderStateLabels(),
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<SelectSiteField
								value={currentParams.siteId}
								onChange={(value) => refreshData({ siteId: value })}
							/>
							<AsyncButton
								icon={faLink}
								label={i18n.t("connect")}
								variant={"outlined"}
								disabled={selectedRows.length === 0}
								onClick={async () => {
									await WorkspaceConnectionsApi.insertConnections({
										workspaceId: workspaceId,
										connectedItemIds: selectedRows.map((r) => r.customerOrderId),
										connectedItemType: "CUSTOMER_ORDER",
									});
									await onCompleted();
								}}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
