import React from "react";
import { ConfirmDialogProps } from "src/components/common/dialogs/confirmDialog/ConfirmDialog";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const ConfirmDialogContext = React.createContext<ShowConfirmDialogFunc | undefined>(undefined);

export type ShowConfirmDialogFunc = (options?: ShowConfirmDialogFuncParams) => Promise<boolean>;

export interface ShowConfirmDialogFuncParams
	extends Pick<ConfirmDialogProps, "title" | "message" | "cancelButtonText" | "confirmButtonText"> {}

export const useConfirmDialog = () => useContextOrThrow(ConfirmDialogContext);
