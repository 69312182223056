import {
	AddNewWorkspaceConnectionsViewProps
} from "src/components/views/workspaces/workspaceConnections/connectionTypes/WorkspaceConnectedItemTypeConfiguration.ts";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { CatalogPartDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartDataGridApi.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView.ts";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { WorkspaceConnectionsApi } from "src/api/generated/workspaces/api/workspaceConnectionsApi.ts";

export const ConnectCatalogPartsToWorkspaceDataGrid = ({ workspaceId, onCompleted }: AddNewWorkspaceConnectionsViewProps) => {
	return (
		<ServerSideDataGridModel
			gridId={"47E46DC5FA705E0C"}
			initialParams={{
				searchQuery: "",
				partCategoryId: null,
				showInactiveParts: false,
				showPartsWithInitialRevision: false,
				responsiblePersonId: null,
				catalogPartId: null
			}}
			fetchData={CatalogPartDataGridApi.searchCatalogParts}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.catalogPartId}
			render={({ refreshData, dataGridProps, selectedRows }) => (
				<AavoDataGrid<CatalogPartView>
					columns={[
						textColumn({
							field: "partNo",
							headerName: i18n.t("part_no")
						}),
						textColumn({
							field: "description_1",
							headerName: i18n.t("description")
						}),
						textColumn({
							field: "description_2",
							headerName: i18n.t("description_2")
						}),
						textColumn({
							field: "categoryName",
							headerName: i18n.t("category")
						})
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<AsyncButton
								icon={faLink}
								label={i18n.t("connect")}
								variant={"outlined"}
								disabled={selectedRows.length === 0}
								onClick={async () => {
									await WorkspaceConnectionsApi.insertConnections({
										workspaceId: workspaceId,
										connectedItemIds: selectedRows.map((r) => r.catalogPartId),
										connectedItemType: "CATALOG_PART"
									});
									await onCompleted();
								}}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};