import {
	AddNewWorkspaceConnectionsViewProps,
	WorkspaceConnectedItemTypeConfiguration,
} from "src/components/views/workspaces/workspaceConnections/connectionTypes/WorkspaceConnectedItemTypeConfiguration.ts";
import i18n from "i18next";
import { faFolder } from "@fortawesome/pro-regular-svg-icons";
import { ConnectCatalogPartsToWorkspaceDataGrid } from "src/components/views/workspaces/workspaceConnections/connectionTypes/catalogPart/ConnectCatalogPartsToWorkspaceDataGrid.tsx";
import { CatalogPartsPage } from "src/components/views/erp/parts/catalogPart/CatalogPartsPage.tsx";

export const workspaceCatalogPartConnectionsConfiguration: WorkspaceConnectedItemTypeConfiguration = {
	typeLabel: i18n.t("catalog_part"),
	icon: faFolder,
	isEnabled: () => true,
	addNewConnectionsView: (props: AddNewWorkspaceConnectionsViewProps) => {
		return <ConnectCatalogPartsToWorkspaceDataGrid {...props} />;
	},
	openItemDialog: ({ itemId, openDialog }) => {
		openDialog({
			title: i18n.t("catalog_part"),
			size: "fullscreen",
			content: <CatalogPartsPage catalogPartId={itemId} />,
		});
	}
};
