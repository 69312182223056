import { DeepPartial } from "react-hook-form";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import { ProductionLineGroup } from "src/api/generated/erp/db/types/tables/productionLineGroup.ts";
import {
	ProductionLineGroupEditApi,
	ProductionLineGroupEditApi_FormInitData,
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineGroupEditApi.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";

export interface ProductionLineGroupFormProps extends FormCommonProps<number> {
	productionLineGroupId: number | undefined;
	newGroupSiteId: number;
}

interface FormValues extends ProductionLineGroup {}

export const ProductionLineGroupForm = (props: ProductionLineGroupFormProps) => {
	const { productionLineGroupId, newGroupSiteId, onCompleted, onFormEdited } = props;

	return (
		<AsyncForm
			fetch={() =>
				ProductionLineGroupEditApi.getFormInitData({
					productionLineGroupId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		productionLineGroup,
	}: ProductionLineGroupEditApi_FormInitData): DeepPartial<FormValues> {
		return (
			productionLineGroup ?? {
				siteId: newGroupSiteId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (productionLineGroupId == null) {
			return await ProductionLineGroupEditApi.insert({ productionLineGroup: values });
		} else {
			await ProductionLineGroupEditApi.update({ productionLineGroup: values });
			return productionLineGroupId;
		}
	}
};

interface FormContentProps
	extends ProductionLineGroupFormProps,
		AsyncFormContentParams<ProductionLineGroupEditApi_FormInitData, FormValues> {}

const FormContent = ({ productionLineGroupId, control, data: { operationOptions } }: FormContentProps) => {
	const { userSites } = useGlobalInitData();

	const isExistingRecord = productionLineGroupId != null;

	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				disabled={isExistingRecord}
				options={userSites}
				getOptionKey={(option) => option.siteId}
				getOptionLabel={(option) => option.siteName}
				disableClearable
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
			<FormSelectField
				control={control}
				name={"operationId"}
				label={i18n.t("production_line_group_operation")}
				options={operationOptions}
				getOptionKey={(option) => option.operationId}
				getOptionLabel={(option) => concatWithPipe(option.operationNo, option.operationDescription)}
			/>
		</>
	);
};
