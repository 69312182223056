import { WorkspaceConnectedItemTypeConfiguration } from "src/components/views/workspaces/workspaceConnections/connectionTypes/WorkspaceConnectedItemTypeConfiguration.ts";
import i18n from "i18next";
import { faProjectDiagram } from "@fortawesome/pro-regular-svg-icons";
import { ConnectProjectsToWorkspaceDataGrid } from "./ConnectProjectsToWorkspaceDataGrid";
import { ProjectTreeViewContainer } from "src/components/views/erp/project/treeView/ProjectTreeViewContainer.tsx";

export const workspaceProjectConnectionsConfiguration: WorkspaceConnectedItemTypeConfiguration = {
	typeLabel: i18n.t("project"),
	icon: faProjectDiagram,
	isEnabled: (tenantConfig) => tenantConfig.erp.projectEnabled,
	addNewConnectionsView: (props) => {
		return <ConnectProjectsToWorkspaceDataGrid {...props} />;
	},
	openItemDialog: ({ itemId, openDialog }) => {
		openDialog({
			title: i18n.t("project"),
			size: "fullscreen",
			content: <ProjectTreeViewContainer onlyProjectId={itemId} />,
		});
	},
};
