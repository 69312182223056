import { ProductionLineCapacityDataGridApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineCapacityDataGridApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { CapacityDay } from "src/api/generated/erp/db/types/tables/capacityDay.ts";
import { dateColumn, floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import dayjs from "dayjs";
import { ProductionLineCapacityDayForm } from "src/components/views/erp/production/baseData/productionLines/capacity/ProductionLineCapacityDayForm.tsx";
import { formatDayJs } from "src/utils/dayjsUtils.ts";
import { ProductionLineCapacityEditApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineCapacityEditApi.ts";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { GenerateProductionLineCapacityForm } from "src/components/views/erp/production/baseData/productionLines/capacity/GenerateProductionLineCapacityForm.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";

export interface ProductionLineCapacityDataGridProps {
	productionLineId: number;
}

export const ProductionLineCapacityDataGrid = ({ productionLineId }: ProductionLineCapacityDataGridProps) => {
	const { dataGridProps, refreshData } = useServerSideDataGridModel({
		fetchData: ({ dataModelRequest }) =>
			ProductionLineCapacityDataGridApi.getDataGridData({
				productionLineId,
				dataModelRequest,
			}),
		initialParams: {},
		gridId: "D8E1F091F012772A",
		getRowId: (row) => row.capacityId,
	});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncCrudDataGrid<CapacityDay>
			columns={[
				dateColumn({
					field: "productionDate",
					headerName: i18n.t("date"),
					width: 150,
				}),
				textColumn({
					field: "dayOfWeek" as any,
					headerName: i18n.t("day_of_week"),
					valueGetter: (_, row) => formatDayJs(dayjs(row.productionDate), "ddd"),
					filterable: false,
					sortable: false,
					width: 120,
				}),
				floatColumn({
					field: "quantity",
					headerName: i18n.t("capacity"),
				}),
				textColumn({
					field: "comment",
					headerName: i18n.t("comment"),
					width: 180,
				}),
				floatColumn({
					field: "actualWorkHours",
					headerName: i18n.t("actual_work_hours"),
					width: 180,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("capacity"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<ProductionLineCapacityDayForm
						capacityDay={row}
						productionLineId={productionLineId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) =>
				ProductionLineCapacityEditApi.delete_({ capacityDayIds: items.map((c) => c.capacityId) })
			}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("generate_days")}
						icon={faCalendar}
						variant={"outlined"}
						onClick={() => {
							openFormOnDialog({
								openDialog,
								component: GenerateProductionLineCapacityForm,
								size: "sm",
								title: i18n.t("generate_days"),
								props: {
									productionLineId,
								},
								onSubmit: () => refreshData(),
								confirmCloseIfEdited: false
							});
						}}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};
