import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EMAIL } from "src/urls";
import { Box, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles";
import { alignCenter, columnFlex, flex1, gap1, justifyCenter } from "src/styles/sx";
import i18n from "i18next";
import { AavoButton } from "../../common/buttons/AavoButton";
import { CurrentUserApi } from "src/api/currentUserApi.ts";
import { useAsync } from "react-async-hook";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";

export const EmailVerifiedPage = () => {
	const [params] = useSearchParams();
	const [loading, setLoading] = useState<boolean>(false);
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const email = params.get(EMAIL);

	const resetPassword = async () => {
		setLoading(true);
		try {
			if (email != null) await CurrentUserApi.resetPassword(email);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		} finally {
			setLoading(false);
		}
	};

	useAsync(async () => {
		await resetPassword();
	}, [email]);

	return (
		<Box sx={mergeSx(columnFlex, flex1, justifyCenter, alignCenter, gap1)}>
			<Typography color={"primary"} variant={"h5"}>
				{i18n.t("account_successfully_verified")}
			</Typography>
			<Typography>{i18n.t("password_link_sent_info", { email: email })}</Typography>
			<AavoButton variant={"contained"} loading={loading} onClick={resetPassword}>
				{i18n.t("resend_the_link")}
			</AavoButton>
		</Box>
	);
};

export default EmailVerifiedPage;
