// This file is automatically generated. Do not edit manually.

import { WorkspaceConnectedItemRef } from "src/api/generated/workspaces/connections/workspaceConnectedItemRef";
import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory";
import { IsoDateTimeString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspacesTreeViewApi {
	export async function getTreeViewData(args: {
		showArchived?: boolean;
		onlyWorkspaceIdToShow?: number | null | undefined;
		onlyWorkspacesConnectedToItem?: WorkspaceConnectedItemRef | null | undefined;
	}): Promise<WorkspacesTreeViewApi_TreeViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/treeView/getTreeViewData",
			method: "POST",
			data: {
				showArchived: args.showArchived,
				onlyWorkspaceIdToShow: args.onlyWorkspaceIdToShow,
				onlyWorkspacesConnectedToItem: args.onlyWorkspacesConnectedToItem,
			},
		});
		return response as WorkspacesTreeViewApi_TreeViewData;
	}
}

export interface WorkspacesTreeViewApi_TreeViewData {
	categoryOptions: Array<WorkspaceCategory>;
	workspaces: Array<WorkspacesTreeViewApi_WorkspaceDto>;
}

export interface WorkspacesTreeViewApi_WorkspaceDto {
	adminPermissions: boolean;
	archivedAt: IsoDateTimeString | null | undefined;
	categoryId: number;
	categoryName: string | null | undefined;
	connectionsEnabled: boolean | null | undefined;
	createdByUserId: number;
	createdByUserName: string | null | undefined;
	createdDate: IsoDateTimeString;
	description: string;
	documentsEnabled: boolean | null | undefined;
	isArchived: boolean | null | undefined;
	notes: string | null | undefined;
	parentId: number | null | undefined;
	pointOfTime: IsoDateTimeString | null | undefined;
	responsiblePersonId: number;
	responsiblePersonName: string | null | undefined;
	tasksEnabled: boolean | null | undefined;
	workspaceId: number;
	workspaceName: string;
}
