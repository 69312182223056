import i18n from "i18next";
import { LazyMultiSelectField } from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { booleanColumn, dateTimeColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { DocumentForm } from "src/components/views/documents/DocumentForm.tsx";
import { DocumentsOfObjectDataGridApi } from "src/api/generated/documents/api/documentsOfObjectDataGridApi.ts";
import { DocumentsOfObjectsDataGridContextMenu } from "src/components/views/documents/objectDocuments/DocumentsOfObjectsDataGridContextMenu.tsx";
import { faExternalLink, faFileArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { DocumentDropZone } from "src/components/views/documents/import/DocumentDropZone.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { DocumentFileApi } from "src/api/generated/documents/api/documentFileApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { thumbnailColumn } from "src/components/views/documents/utils/thumbnails.tsx";
import {
	GridRowWithFileDropping,
	GridRowWithFileDroppingProps,
} from "src/components/common/dataGrid/dnd/GridRowWithFileDropping.tsx";
import { DropFileToDocumentView } from "src/components/views/documents/import/DropFileToDocumentView.tsx";
import { GenericDialogContext } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { ConnectDocumentsToObjectView } from "src/components/views/documents/connections/ConnectDocumentsToObjectView.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { ImportDocumentsButton } from "src/components/views/documents/import/ImportDocumentsButton.tsx";
import { LinkWithPlusIcon } from "src/components/common/icons/LinkWithPlusIcon";
import { getOnlyMember } from "src/utils/arrayUtils.ts";
import { GridProSlotsComponent } from "@mui/x-data-grid-pro";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

import { DocumentsViewEditable } from "../types.ts";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";

export interface DocumentsOfObjectsDataGridProps {
	objectRefs: AavoObjectRef[];
	editable?: DocumentsViewEditable;
	selectedDocuments?: DocumentConnectionView[];
	onSelectionChanged?: (documents: DocumentConnectionView[]) => void;
	onDocumentFileChanged?: () => void;
	gridId?: string;
	refreshRef?: RefreshableElementRef;
}

export const DocumentsOfObjectsDataGrid = ({
	objectRefs,
	editable = true,
	selectedDocuments,
	onSelectionChanged,
	onDocumentFileChanged,
	gridId = "60AEF12D15AD6E54",
	refreshRef,
}: DocumentsOfObjectsDataGridProps) => {
	const userPermissions = useUserPermissions();
	const { openDialog } = useContextOrThrow(GenericDialogContext);
	const isMobile = useMediaQueryBreakpointDown("sm");

	const { dataGridProps, currentParams, refreshData, selectedRows, onlySelectedRow } = useServerSideDataGridModel({
		gridId: gridId,
		fetchData: (params) =>
			DocumentsOfObjectDataGridApi.getDocumentsOfObjects({
				objectRefs,
				...params,
			}),
		getRowId: (row) => row.documentId,
		initialParams: {
			categoryFilter: Array<number>(),
			searchQuery: "",
		},
		afterRefresh: () => {
			onDocumentFileChanged?.();
		},
		selectedRows: selectedDocuments,
		onSelectionChanged: onSelectionChanged,
		refreshRef: refreshRef,
	});

	const selectedDocumentIds = selectedRows.map((row) => row.documentId);

	const onlyObjectRef = getOnlyMember(objectRefs);

	return (
		<ControlledAsyncCrudDataGrid<DocumentConnectionView>
			disableMultipleRowSelection={false}
			columns={[
				integerColumn({
					headerName: i18n.t("number_shortened"),
					field: "documentId",
					width: 60,
				}),
				textColumn({
					headerName: i18n.t("description_1"),
					field: "description1",
					width: 300,
				}),
				textColumn({
					headerName: i18n.t("type"),
					field: "primaryFileExtension",
					width: 80,
				}),
				integerColumn({
					headerName: i18n.t("revision"),
					field: "revisionNumber",
					width: 60,
				}),
				thumbnailColumn({
					headerName: i18n.t("image"),
					field: "thumbnail",
				}),
				textColumn({
					headerName: i18n.t("description_2"),
					field: "description2",
					width: 250,
				}),
				textColumn({
					headerName: i18n.t("category"),
					field: "categoryName",
					width: 200,
				}),
				textColumn({
					headerName: i18n.t("note"),
					field: "note",
					width: 200,
				}),
				textColumn({
					headerName: i18n.t("created_by"),
					field: "createdByUserName",
				}),
				dateTimeColumn({
					headerName: i18n.t("created_at"),
					field: "createdAt",
					width: 150,
				}),
				booleanColumn({
					headerName: i18n.t("pinned"),
					field: "isPinned",
				}),
				booleanColumn({
					headerName: i18n.t("object_main_document"),
					field: "isObjectMainDocument",
					width: 200,
				}),
			]}
			slots={{
				row: GridRowWithFileDropping as GridProSlotsComponent["row"],
			}}
			slotProps={{
				row: {
					canDrop: editable === true,
					onFilesDropped: (row: DocumentConnectionView, files) => onFilesDroppedToRow(row, files),
				} as GridRowWithFileDroppingProps,
			}}
			onRowDoubleClick={({ row }) => {
				openDocumentPreview(row);
			}}
			actionBarComponents={
				<>
					<AavoButton
						icon={faExternalLink}
						disabled={!onlySelectedRow}
						tooltip={i18n.t("open")}
						onClick={() => {
							if (onlySelectedRow === undefined) return;
							openDocumentPreview(onlySelectedRow);
						}}
					/>
					<AsyncButton
						icon={faFileArrowDown}
						tooltip={i18n.t("download")}
						disabled={selectedDocumentIds.length === 0}
						onClick={async () => {
							const fileHandle = await DocumentFileApi.downloadDocumentsAsSingleFile({
								documentIds: selectedDocumentIds,
							});
							downloadFile(fileHandle);
						}}
					/>
					<LazyMultiSelectField
						label={i18n.t("category")}
						value={currentParams.categoryFilter}
						fetchOptions={() =>
							DocumentsOfObjectDataGridApi.getDocumentCategoryOptionsForObjects({
								objectRefs,
							})
						}
						getOptionKey={(option) => option.documentCategoryId}
						getOptionLabel={(option) => option.name}
						onChange={async (values: number[]) => {
							await refreshData({ categoryFilter: values });
						}}
						sx={{
							minWidth: 120,
							display: { xs: "none", sm: "block" },
						}}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
					{editable === true && onlyObjectRef && !userPermissions.documents.denyConnectExistingDocument && (
						<AsyncButton
							iconEl={<LinkWithPlusIcon />}
							label={i18n.t("link")}
							iconOnlyIfDown={"sm"}
							variant={"outlined"}
							onClick={async () => {
								openDialog(({ closeDialog }) => ({
									title: i18n.t("link_documents"),
									content: (
										<ConnectDocumentsToObjectView
											objectRef={onlyObjectRef}
											onDocumentsConnected={async () => {
												await closeDialog();
												await refreshData();
											}}
										/>
									),
								}));
							}}
						/>
					)}
					{editable === true &&
						onlyObjectRef &&
						(isMobile ?
							<ImportDocumentsButton objectRef={onlyObjectRef} onSubmit={() => refreshData} />
						:	<DocumentDropZone objectRef={onlyObjectRef} onSubmit={refreshData} />)}
				</>
			}
			form={{
				dialogSize: "lg",
				dialogTitle: i18n.t("document_connection"),
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<DocumentForm
							objectRef={onlyObjectRef}
							documentId={row?.documentId}
							onCompleted={onCompleted}
							onFormEdited={onFormEdited}
						/>
					);
				},
				addRowEnabled: editable === true,
				editEnabled: editable === true,
			}}
			remove={{
				type: editable === true ? "enabled" : "hidden",
				action: async ({ items }) => {
					await DocumentsOfObjectDataGridApi.deleteDocumentConnections({
						documentConnections: items,
					});
				},
			}}
			rowContextMenuComponents={(params) => (
				<DocumentsOfObjectsDataGridContextMenu
					objectRefs={objectRefs}
					refresh={refreshData}
					editable={editable}
					{...params}
				/>
			)}
			{...dataGridProps}
		/>
	);

	function onFilesDroppedToRow(row: DocumentConnectionView, files: File[]) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("new_file"),
			size: "sm",
			content: (
				<DropFileToDocumentView
					file={files[0]!}
					documentId={row.documentId}
					afterSubmit={async () => {
						await closeDialog();
						await refreshData();
					}}
				/>
			),
		}));
	}

	function openDocumentPreview(document: DocumentConnectionView) {
		openDialog(() => ({
			title: document.description1,
			content: <DocumentPreviewView documentId={document.documentId} />,
		}));
	}
};
