import i18n from "i18next";
import {
	faArrowsRotate,
	faBan,
	faBars,
	faCheck,
	faPlay,
	faSave,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { TaskActionsApi } from "src/api/generated/tasks/api/taskActionsApi.ts";
import { AsyncButton } from "../../../common/buttons/AsyncButton.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AddDocumentsToObjectButton } from "src/components/views/documents/AddDocumentsToObjectButton.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { DocumentsOfObjectSourceButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectSourceButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SyncedToCloudIcon } from "src/components/common/icons/SyncedToCloudIcon.tsx";
import { TasksViewProps } from "src/components/views/tasks/TasksView.tsx";
import { UndoTaskChangesButton } from "src/components/views/tasks/mobile/UndoTaskChangesButton.tsx";
import { Box } from "@mui/material";
import { Task } from "src/api/generated/postgres/db/types/tasks/tables/task.ts";
import { taskIsSaved } from "src/components/views/tasks/taskUtils.ts";
import { TaskCreationApi } from "src/api/generated/tasks/api/taskCreationApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { AavoIconButton } from "../../../common/buttons/AavoIconButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";

export interface SingleTaskMobileViewFooterProps
	extends Pick<TasksViewProps, "onTaskCompleted" | "onTaskCompletionReverted"> {
	unmodifiedTask: Task | null;
	task: Task;
	changesSaved: boolean;
	refreshTask: () => Promise<unknown>;
	isDirty: boolean;
	setNotDirty: () => void;
	onNewTaskSaved: () => Promise<unknown>;
}

export const SingleTaskMobileViewFooter = ({
	task,
	changesSaved,
	refreshTask,
	onTaskCompleted,
	onTaskCompletionReverted,
	unmodifiedTask,
	isDirty,
	setNotDirty,
	onNewTaskSaved,
}: SingleTaskMobileViewFooterProps) => {
	const [openContextMenu, contextMenuState] = useContextMenu();

	return (
		<VerticalBox
			sx={mergeSx({
				paddingY: 1,
				paddingRight: 2,
				paddingLeft: 1,
				gap: 1,
				"& .MuiButton-root": {
					fontSize: "1rem",
				},
			})}
		>
			<HorizontalBox
				sx={{
					gap: 1,
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				{taskIsSaved(task) ?
					<>
						<Box alignSelf={"center"} marginLeft={1.5}>
							{changesSaved ?
								<SyncedToCloudIcon />
							:	<FontAwesomeIcon icon={faArrowsRotate} spin />}
						</Box>
						<UndoTaskChangesButton
							unmodifiedTask={unmodifiedTask}
							refreshTask={refreshTask}
							isDirty={isDirty}
							setNotDirty={setNotDirty}
						/>
					</>
				:	<AsyncButton
						icon={faSave}
						variant={"contained"}
						onClick={async () => {
							await TaskCreationApi.createTask({ task: task });
							await onNewTaskSaved();
						}}
					/>
				}
				<Box
					sx={{
						flex: 1,
					}}
				/>
				<DocumentsOfObjectSourceButton
					sourceRef={nullableAavoObjectRef(task.sourceType, task.sourceId)}
					variant={"outlined"}
					showLabel
				/>
				<DocumentsOfObjectButton
					objectRef={{ objectType: "TASK", objectId: task.taskId }}
					variant={"contained"}
				/>
				<AddDocumentsToObjectButton
					objectRef={{
						objectType: "TASK",
						objectId: task.taskId,
					}}
					variant={"contained"}
				/>
			</HorizontalBox>
			<HorizontalBox
				sx={{
					gap: 1,
					justifyContent: "flex-end",
					flexWrap: "wrap",
				}}
			>
				<AavoIconButton
					icon={faBars}
					color={"black"}
					onClick={(e) => {
						openContextMenu({
							mouseEvent: e,
							content: <TaskMenuContent task={task} refreshTask={refreshTask} />,
						});
					}}
				/>
				<Box flex={1} />
				{task.taskState === "RELEASED" && (
					<AsyncButton
						label={i18n.t("start")}
						icon={faPlay}
						variant={"outlined"}
						color={"primary"}
						onClick={async () => {
							await TaskActionsApi.startTask({ taskId: task.taskId });
							await refreshTask();
						}}
					/>
				)}
				{task.taskState === "STARTED" && (
					<AsyncButton
						label={i18n.t("revert_start")}
						icon={faUndo}
						variant={"outlined"}
						color={"primary"}
						onClick={async () => {
							await TaskActionsApi.revertStartTask({ taskId: task.taskId });
							await refreshTask();
						}}
					/>
				)}
				{task.taskState === "STARTED" && (
					<AsyncButton
						label={i18n.t("complete")}
						icon={faCheck}
						variant={"outlined"}
						color={"primary"}
						onClick={async () => {
							await TaskActionsApi.completeTask({ taskId: task.taskId });
							await refreshTask();
							onTaskCompleted?.(task);
						}}
					/>
				)}
				{task.taskState === "COMPLETED" && (
					<AsyncButton
						label={i18n.t("revert_complete")}
						icon={faUndo}
						variant={"outlined"}
						color={"primary"}
						onClick={async () => {
							await TaskActionsApi.revertCompleteTask({ taskId: task.taskId });
							await refreshTask();
							onTaskCompletionReverted?.(task);
						}}
					/>
				)}
			</HorizontalBox>
			<AavoContextMenu state={contextMenuState} />
		</VerticalBox>
	);
};

interface TaskMenuContentProps {
	task: Task;
	refreshTask: () => Promise<unknown>;
}

const TaskMenuContent = ({ task, refreshTask }: TaskMenuContentProps) => {
	const showConfirmDialog = useConfirmDialog();
	return [
		task.taskState !== "CANCELLED" && (
			<AsyncMenuButton
				key={"cancelTask"}
				label={i18n.t("cancel_task")}
				icon={faBan}
				color={"primary"}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_cancel_task"),
						cancelButtonText: i18n.t("no"),
					});
					if (!confirmed) return;
					await TaskActionsApi.cancelTasks({ taskIds: [task.taskId] });
					await refreshTask();
				}}
			/>
		),
	];
};
