import { FormCommonProps } from "../../../../common/forms/types.ts";
import {
	CustomerOrderFormApi,
	CustomerOrderFormApi_InitData,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderFormApi.ts";
import { DeepPartial } from "react-hook-form";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView.ts";
import { FormSelectField } from "../../../../common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { nonNegativeIntegerRule, requireRule } from "../../../../common/forms/validation.ts";
import { CustomerApi } from "src/api/generated/erp/sales/customer/api/customerApi.ts";
import { FormAsyncSelectField } from "../../../../common/forms/fields/FormAsyncSelectField.tsx";
import { FetchAsyncOptionParams } from "../../../../common/inputFields/AsyncSelectField.tsx";
import { AavoButton } from "../../../../common/buttons/AavoButton.tsx";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FormTextField } from "../../../../common/forms/fields/FormTextField.tsx";
import { FormDateField } from "../../../../common/forms/fields/FormDateField.tsx";
import dayjs from "dayjs";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import { FormCheckbox } from "../../../../common/forms/fields/FormCheckbox.tsx";
import { FormAsyncUserSelectField } from "../../../users/FormAsyncUserSelectField.tsx";
import { getCurrencyLabels } from "src/api/generated/erp/db/types/enums/currency.ts";
import { getVatHandlingLabels } from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { Divider } from "@mui/material";
import { CountryValues, getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { useState } from "react";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { CustomerForm } from "../customer/CustomerForm.tsx";
import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import {
	getDefaultDeliveryAddress,
	getDefaultInvoiceAddress,
	getDeliveryAddresses,
	getInvoiceAddresses,
} from "src/components/views/erp/sales/customerAddressUtils.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormAddressAutofill } from "src/components/common/forms/fields/FormAddressAutofill.tsx";

export interface CustomerOrderFormProps extends FormCommonProps<number> {
	customerOrderId?: number;
}

interface FormValues extends CustomerOrderView {
	updatePlannedDeliveryDateToLines: boolean;
	updatePlannedDeliveryDateToTasks: boolean;
	updatePlannedDeliveryDateAcquisitionObjects: boolean;
	updatePlannedDeliveryDateToBillingPlan: boolean;
	updateSalesCommissionLines: boolean;
	deliveryAddressId: number;
}

export const CustomerOrderForm = (props: CustomerOrderFormProps) => {
	const { customerOrderId, onCompleted, onFormEdited } = props;

	const { defaultSiteId, appUserId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();

	return (
		<AsyncForm
			fetch={() => CustomerOrderFormApi.getInitData({ customerOrderId: customerOrderId })}
			getDefaultValues={getDefaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submitForm}
			columns={3}
			render={(params) => <CustomerOrderFormContent {...params} {...props} />}
		/>
	);

	function getDefaultValues({
		customerOrder,
		customer,
		defaultVatCodeId,
		customerOrderTypeOptions,
		deliveryTermOptions,
		deliveryMethodOptions,
	}: CustomerOrderFormApi_InitData): DeepPartial<FormValues> {
		if (customerOrder != null) {
			return {
				...customerOrder,
				updatePlannedDeliveryDateToLines: true,
				updatePlannedDeliveryDateToTasks: true,
				updatePlannedDeliveryDateAcquisitionObjects: true,
				updatePlannedDeliveryDateToBillingPlan: false,
				updateSalesCommissionLines: true,
			} as const;
		} else {
			const deliveryMethod = deliveryMethodOptions[0];
			return {
				siteId: defaultSiteId,
				plannedDeliveryDate: dayJsToDateIsoString(dayjs()),
				responsiblePersonId: appUserId,
				currency: "EUR",
				vatHandling: "NORMAL_VAT",
				inputPricesWithVat: tenantConfig.erp.inputPricesWithVatByDefault ?? false,
				paymentTermId: customer?.paymentTermId,
				customerPriceGroupId: customer?.customerPriceGroupId,
				deliveryTermsId: deliveryTermOptions[0]?.deliveryTermsId,
				deliveryMethodId: deliveryMethod?.deliveryMethodId,
				vatCodeId: defaultVatCodeId,
				customerOrderTypeId: customerOrderTypeOptions[0]?.customerOrderTypeId,
				transportDuration: deliveryMethod?.transportDuration,
				deliveryCountry: CountryValues[0],
			} as const;
		}
	}

	async function submitForm(formValues: FormValues): Promise<number> {
		const {
			updatePlannedDeliveryDateToLines,
			updatePlannedDeliveryDateToTasks,
			updatePlannedDeliveryDateToBillingPlan,
			updatePlannedDeliveryDateAcquisitionObjects,
			updateSalesCommissionLines,
			...customerOrder
		} = formValues;

		if (customerOrderId == null) {
			return await CustomerOrderFormApi.insert({
				customerOrder,
			});
		} else {
			await CustomerOrderFormApi.update({
				customerOrder: customerOrder,
				updatePlannedDeliveryDateToLines,
				updatePlannedDeliveryDateAcquisitionObjects,
				updatePlannedDeliveryDateToTasks,
				updatePlannedDeliveryDateToBillingPlan,
				updateSalesCommissionLines,
			});
			return customerOrderId;
		}
	}
};

interface CustomerOrderFormContentProps
	extends CustomerOrderFormProps,
		AsyncFormContentParams<CustomerOrderFormApi_InitData, FormValues> {}

const CustomerOrderFormContent = ({
	customerOrderId,
	control,
	watch,
	setValue,
	formState: { dirtyFields },
	data: {
		customerOrder,
		customerAddresses,
		customerOrderTypeOptions,
		vatCodeOptions,
		transportRouteOptions,
		deliveryTermOptions,
		deliveryMethodOptions,
		contractualTermOptions,
		canModifyBillingOptions,
		paymentTermOptions,
		salespersonOptions,
		customerPriceGroupOptions,
		siteOptions,
		hasOrderLines,
		hasApprovedCommissionLines,
		hasUnapprovedCommissionLines,
	},
}: CustomerOrderFormContentProps) => {
	const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<CustomerAddress[] | undefined>(
		customerAddresses == null ? undefined : getDeliveryAddresses(customerAddresses),
	);

	const [invoiceAddressOptions, setInvoiceAddressOptions] = useState<CustomerAddress[] | undefined>(
		customerAddresses == null ? undefined : getInvoiceAddresses(customerAddresses),
	);

	const { logErrorAndShowOnDialog } = useErrorDialog();

	const { openDialog } = useGenericDialog();

	const customerOrderState = customerOrder?.customerOrderState;
	const deliveryDateChanged = dirtyFields.plannedDeliveryDate;
	const updatePlannedDeliveryDateToLines = watch("updatePlannedDeliveryDateToLines");
	const billingPlanEnabled = watch("billingPlanEnabled");

	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				options={siteOptions}
				getOptionKey={(site) => site.siteId}
				getOptionLabel={(site) => site.siteName}
				rules={requireRule()}
				disabled={customerOrderId !== undefined}
				disableClearable
			/>
			<FormAsyncSelectField
				control={control}
				name={"customerId"}
				label={i18n.t("customer")}
				fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
					CustomerApi.getCustomerSelectionOptions({
						currentSelection: currentSelection,
						searchQuery: searchQuery,
					})
				}
				getOptionKey={(x) => x.customerId}
				getOptionLabel={(x) => x.customerName}
				rules={requireRule()}
				disabled={customerOrderId !== undefined}
				onChange={(customer) => onCustomerChanged(customer?.customerId ?? null)}
			/>
			<AavoButton
				label={i18n.t("create_customer")}
				icon={faUsers}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("create_customer"),
						size: "md",
						content: (
							<CustomerForm
								onCompleted={async (result) => {
									if (result.type === "success") {
										const customerId = result.value;
										setValue("customerId", customerId);
										await onCustomerChanged(customerId);
									}
									await closeDialog();
								}}
							/>
						),
					}));
				}}
				hidden={customerOrderId !== undefined}
			/>
			<FormTextField control={control} name={"orderReference"} label={i18n.t("reference")} />
			<FormTextField control={control} name={"customerPoNo"} label={i18n.t("customer_purchase_order_number")} />
			<FormTextField control={control} name={"externalOrderId"} label={i18n.t("external_order_id")} />
			<FormDateField
				control={control}
				name={"plannedDeliveryDate"}
				label={i18n.t("planned_delivery_date")}
				rules={requireRule()}
				sx={{
					gridColumnStart: 1,
				}}
			/>
			{customerOrderId != null && deliveryDateChanged && (
				<FormCheckbox
					control={control}
					name={"updatePlannedDeliveryDateToLines"}
					label={i18n.t("update_also_to_lines")}
					spanGridColumns
				/>
			)}
			{customerOrderId != null && deliveryDateChanged && (
				<FormCheckbox
					control={control}
					name={"updatePlannedDeliveryDateToTasks"}
					label={i18n.t("update_also_to_tasks")}
					spanGridColumns
				/>
			)}
			{customerOrderId != null &&
				deliveryDateChanged &&
				updatePlannedDeliveryDateToLines &&
				customerOrderState !== "INITIAL" && (
					<FormCheckbox
						control={control}
						name={"updatePlannedDeliveryDateAcquisitionObjects"}
						label={i18n.t("update_delivery_date_to_initial_shop_orders_and_purchase_orders")}
						spanGridColumns
					/>
				)}
			{customerOrderId != null && billingPlanEnabled && deliveryDateChanged && (
				<FormCheckbox
					control={control}
					name={"updatePlannedDeliveryDateToBillingPlan"}
					label={i18n.t("update_billing_plan_planned_approval_dates")}
					spanGridColumns
				/>
			)}
			{customerOrderId != null && deliveryDateChanged && hasUnapprovedCommissionLines && (
				<FormCheckbox
					control={control}
					name={"updateSalesCommissionLines"}
					label={i18n.t("update_sales_commission_lines_schedule")}
					spanGridColumns
				/>
			)}
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
				startNewGridRow
			/>
			<FormSelectField
				control={control}
				name={"salespersonId"}
				label={i18n.t("salesperson")}
				options={salespersonOptions}
				disabled={hasApprovedCommissionLines && i18n.t("order_has_approved_commission_installments")}
				getOptionKey={(o) => o.salespersonId}
				getOptionLabel={(o) => o.name}
			/>
			<FormEnumSelectField
				control={control}
				name={"currency"}
				label={i18n.t("currency")}
				options={getCurrencyLabels()}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"deliveryTermsId"}
				label={i18n.t("delivery_term")}
				options={deliveryTermOptions}
				getOptionKey={(o) => o.deliveryTermsId}
				getOptionLabel={(o) => `${o.deliveryTermsCode} - ${o.deliveryTermsDescription}`}
				rules={requireRule()}
				disableClearable
			/>
			<FormTextField
				control={control}
				name={"deliveryTermsDestination"}
				label={i18n.t("delivery_terms_destination")}
			/>
			<FormSelectField
				control={control}
				name={"deliveryMethodId"}
				label={i18n.t("delivery_method")}
				options={deliveryMethodOptions}
				getOptionKey={(o) => o.deliveryMethodId}
				getOptionLabel={(o) => o.deliveryMethodCode}
				rules={requireRule()}
				onChange={(_, deliveryMethod: DeliveryMethod | null) => {
					setValue("transportDuration", deliveryMethod?.transportDuration ?? null);
				}}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"invoiceAddressId"}
				label={i18n.t("invoice_address")}
				rules={requireRule()}
				options={invoiceAddressOptions ?? []}
				disabled={invoiceAddressOptions == null}
				getOptionKey={(o) => o.customerAddressId}
				getOptionLabel={(o) => concatWithPipe(o.address_1, o.address_2, o.city)}
			/>
			<FormSelectField
				control={control}
				name={"paymentTermId"}
				label={i18n.t("payment_term")}
				options={paymentTermOptions}
				getOptionKey={(o) => o.paymentTermId}
				getOptionLabel={(o) => o.paymentTerm ?? ""}
				rules={requireRule()}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"customerOrderTypeId"}
				label={i18n.t("customer_order_type")}
				options={customerOrderTypeOptions}
				getOptionKey={(o) => o.customerOrderTypeId}
				getOptionLabel={(o) => o.name}
				rules={requireRule()}
				disableClearable
			/>
			<FormDateField control={control} name={"contractDate"} label={i18n.t("contract_date")} />
			<FormSelectField
				control={control}
				name={"contractualTermId"}
				label={i18n.t("contractual_term")}
				options={contractualTermOptions}
				getOptionKey={(o) => o.contractualTermId}
				getOptionLabel={(o) => o.code}
			/>
			<FormSelectField
				control={control}
				name={"customerPriceGroupId"}
				label={i18n.t("price_group")}
				options={customerPriceGroupOptions}
				getOptionKey={(o) => o.customerPriceGroupId}
				getOptionLabel={(o) => o.name}
			/>
			<FormEnumSelectField
				control={control}
				name={"vatHandling"}
				label={i18n.t("vat_handling")}
				options={getVatHandlingLabels()}
				rules={requireRule()}
				disabled={!canModifyBillingOptions}
				disableClearable
			/>
			<FormTextField control={control} name={"contactName"} label={i18n.t("contact_person")} startNewGridRow />
			<FormTextField control={control} name={"contactEmail"} label={i18n.t("contact_email")} />
			<FormTextField control={control} name={"contactPhone"} label={i18n.t("contact_phone")} />
			<FormCheckbox
				control={control}
				name={"capacityReservation"}
				label={i18n.t("capacity_reservation")}
				startNewGridRow
				disabled={customerOrderState != "INITIAL"}
			/>
			<FormCheckbox
				control={control}
				name={"inputPricesWithVat"}
				label={i18n.t("input_prices_with_vat")}
				disabled={hasOrderLines}
			/>
			<FormCheckbox
				control={control}
				name={"billingPlanEnabled"}
				label={i18n.t("billing_plan_enabled")}
				disabled={!canModifyBillingOptions}
				startNewGridRow
			/>
			<FormSelectField
				control={control}
				name={"vatCodeId"}
				label={i18n.t("vat_code")}
				options={vatCodeOptions}
				getOptionKey={(o) => o.vatCodeId}
				getOptionLabel={(o) => o.vatCodeName}
				sx={{
					visibility:
						billingPlanEnabled && watch("vatHandling") !== "REVERSE_CHARGE_VAT" ? "visible" : "hidden",
				}}
				disabled={!canModifyBillingOptions}
				disableClearable
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} spanGridColumns multiline />
			<FormTextField
				control={control}
				name={"additionalAddress"}
				label={i18n.t("additional_address")}
				spanGridColumns
			/>
			<FormTextField control={control} name={"relatedUrl"} label={i18n.t("document_link")} spanGridColumns />
			<Divider
				sx={{
					gridColumn: "1 / -1",
					color: "primary.main",
				}}
			>
				{i18n.t("delivery_address")}
			</Divider>

			{customerOrderId == null && (
				<FormSelectField
					control={control}
					name={"deliveryAddressId"}
					label={i18n.t("delivery_address")}
					rules={requireRule()}
					options={deliveryAddressOptions ?? []}
					disabled={deliveryAddressOptions == null}
					getOptionKey={(o: CustomerAddress) => o.customerAddressId}
					getOptionLabel={(o) => concatWithPipe(o.address_1, o.city)}
					onChange={(_, address) => {
						onDeliveryAddressChanged(address);
					}}
					disableClearable
				/>
			)}
			<FormAddressAutofill
				control={control}
				name={"deliveryAddress_1"}
				label={i18n.t("address_1")}
				rules={requireRule()}
				onSelectLocation={(location) => {
					setValue("deliveryAddress_2", location.address_line2);
					setValue("deliveryPostalCode", location.postcode);
					setValue("deliveryCity", location.address_level2);
					return location.address_line1;
				}}
			/>
			<FormTextField control={control} name={"deliveryAddress_2"} label={i18n.t("address_2")} />
			<FormTextField
				control={control}
				name={"deliveryPostalCode"}
				label={i18n.t("postal_code")}
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"deliveryCity"} label={i18n.t("city")} rules={requireRule()} />
			<FormEnumSelectField
				control={control}
				name={"deliveryCountry"}
				label={i18n.t("country")}
				options={getCountryLabels()}
				disableClearable
			/>
			<FormTextField control={control} name={"deliveryName"} label={i18n.t("address_description")} />
			<FormTextField
				control={control}
				name={"transportDuration"}
				label={i18n.t("transport_duration")}
				rules={nonNegativeIntegerRule()}
			/>
			<FormSelectField
				control={control}
				name={"transportRouteId"}
				label={i18n.t("transport_route")}
				options={transportRouteOptions}
				getOptionKey={(o) => o.transportRouteId}
				getOptionLabel={(o) => o.name}
			/>
			<FormTextField control={control} name={"deliveryContact"} label={i18n.t("contact_information")} />
		</>
	);

	async function onCustomerChanged(customerId: number | null) {
		try {
			if (customerId == null) {
				setInvoiceAddressOptions(undefined);
				setDeliveryAddressOptions(undefined);
				return;
			}
			const { customer, deliveryAddresses, invoiceAddresses } = await CustomerApi.getCustomerWithAddresses({
				customerId: customerId,
			});

			setDeliveryAddressOptions(deliveryAddresses);
			setInvoiceAddressOptions(invoiceAddresses);

			const defaultDeliveryAddress = getDefaultDeliveryAddress(deliveryAddresses);
			setValue("deliveryAddressId", defaultDeliveryAddress.customerAddressId);
			onDeliveryAddressChanged(defaultDeliveryAddress);

			const defaultInvoiceAddress = getDefaultInvoiceAddress(invoiceAddresses);
			setValue("invoiceAddressId", defaultInvoiceAddress.customerAddressId);

			setValue("customerPriceGroupId", customer.customerPriceGroupId);
			setValue("paymentTermId", customer.paymentTermId);
			setValue("contactName", defaultDeliveryAddress.contact ?? "");
			setValue("contactEmail", defaultDeliveryAddress.email ?? "");
			setValue("contactPhone", defaultDeliveryAddress.phone ?? "");
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	function onDeliveryAddressChanged(address: CustomerAddress | null) {
		setValue("deliveryName", address?.name ?? "");
		setValue("deliveryAddress_1", address?.address_1 ?? "");
		setValue("deliveryAddress_2", address?.address_2 ?? "");
		setValue("deliveryPostalCode", address?.postalCode ?? "");
		setValue("deliveryCity", address?.city ?? "");
		setValue("deliveryCountry", address?.country ?? "FINLAND");
		setValue("deliveryContact", address == null ? "" : `${address.contact} ${address.phone}`);
	}
};
