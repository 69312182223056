import CodeMirror from "@uiw/react-codemirror";
import { StreamLanguage } from "@codemirror/language";
import { lua } from "@codemirror/legacy-modes/mode/lua";
import { EditorView, basicSetup } from "codemirror";
import { keymap } from "@codemirror/view";
import { indentWithTab } from "@codemirror/commands";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { VerticalBox } from "../box/VerticalBox.tsx";
import { SxProps, Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";
import Typography from "@mui/material/Typography";
import type { Extension } from "@codemirror/state";

export interface AavoCodeMirrorProps {
	disabled?: boolean;
	value?: string;
	onChange?: (value: string) => void;
	defaultValue?: string;
	label?: string;
	sx?: SxProps<Theme>;
	extensions?: Extension[];
}

export const AavoCodeMirror = ({
	disabled = false,
	value: valueProp,
	onChange: onChangeProp,
	defaultValue,
	label,
	sx,
	extensions = [],
}: AavoCodeMirrorProps) => {
	const [value, setValue] = useMaybeControlledState<string>({
		controlledValue: valueProp,
		onChange: onChangeProp,
		defaultValue: defaultValue ?? "",
	});
	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
					alignItems: "stretch",
				},
				disabled && {
					"& .cm-editor": {
						backgroundColor: "grey.200",
					},
				},
				sx,
			)}
		>
			{label != null && <Typography>{label}</Typography>}
			<CodeMirror
				value={value}
				onChange={(value) => setValue(value)}
				style={{
					flex: 1,
				}}
				editable={!disabled}
				extensions={[
					basicSetup,
					StreamLanguage.define(lua),
					keymap.of([indentWithTab]),
					EditorView.editable.of(!disabled),
					...extensions,
				]}
			/>
		</VerticalBox>
	);
};
