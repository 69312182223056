import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { ObjectChangeLogDataGrid } from "src/components/views/changeLogging/ObjectChangeLogDataGrid.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";

export interface OpenObjectChangeLogButtonProps extends Pick<AsyncButtonProps, "variant"> {
	objectRef: AavoObjectRef;
}

export const OpenObjectChangeLogButton = ({ objectRef, variant = "menu" }: OpenObjectChangeLogButtonProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<AsyncButton
			icon={faHistory}
			label={i18n.t("change_log")}
			variant={variant}
			onClick={async () => {
				if (objectRef.objectId == null) return;
				openDialog({
					title: i18n.t("change_log"),
					size: "lg",
					content: <ObjectChangeLogDataGrid objectRef={objectRef} />,
				});
			}}
		/>
	);
};
