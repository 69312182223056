import { useTranslation } from "react-i18next";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeField";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi";
import { PartCategoryAttributesForm } from "src/components/views/erp/parts/partCategories/PartCategoryAttributesForm";
import { textColumn } from "src/components/common/dataGrid/columns";
import { AsyncCrudDataGrid } from "src/components/common/dataGrid/crud/AsyncCrudDataGrid";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage";
import { CenteredTypography } from "src/components/common/CenteredTypography";

export interface PartCategoryAttributesDataGridProps {
	partCategoryId: number | null;
}

export const PartCategoryAttributesDataGrid = ({
												   partCategoryId
											   }: PartCategoryAttributesDataGridProps) => {
	const { t } = useTranslation();

	if (partCategoryId === null)
		return <CenteredTypography> {t("select_part_category")} </CenteredTypography>;

	const removeAttributes = async (params: { items: ObjectAttributeField[] }) => {
		return await PartCategoryApi.removeAttributesFields({
			partCategoryId,
			attributeFieldIds: params.items.map((a) => a.id)
		});
	};

	const fetchPartCategoryAttributes = async () => {
		const partAttributes = await PartCategoryApi.getAttributeFields({
			partCategoryId
		});
		const allAttributes = await PartCategoryApi.getAllPartAttributeFields();
		return allAttributes.filter((attr) => {
			return (
				partAttributes.find((partAttr) => {
					return partAttr.fieldId === attr.id;
				}) !== undefined
			);
		});
	};

	return (
		<AsyncCrudDataGrid<ObjectAttributeField>
			fetchData={fetchPartCategoryAttributes}
			remove={{
				type: "enabled",
				action: removeAttributes
			}}
			columns={[
				textColumn({
					headerName: t("title"),
					field: "label"
				})
			]}
			getRowId={(row) => row.id}
			form={{
				dialogSize: "sm",
				dialogTitle: t("attributes"),
				component: ({ onCompleted, onFormEdited }) => {
					return (
						<PartCategoryAttributesForm
							partCategoryId={partCategoryId}
							onCompleted={(result) => {
								if (result.type === "success") {
									onCompleted({
										...result,
										value: undefined
									});
								} else {
									onCompleted(result);
								}
							}}
							onFormEdited={onFormEdited}
						/>
					);
				},
				addRowEnabled: true,
				editEnabled: false
			}}
			{...dataGridPersistentStateProps("DECAFEF20E30D9B71")}
		/>
	);
};
