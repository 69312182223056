import {
	PurchaseOrderLineEditApi,
	PurchaseOrderLineEditApi_FormInitData,
} from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderLineEditApi";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { PurchaseOrderLine } from "src/api/generated/erp/db/types/tables/purchaseOrderLine";
import { DeepPartial } from "react-hook-form";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { SupplierPartView } from "src/api/generated/erp/db/types/tables/supplierPartView.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";

export interface PurchaseOrderLineFormProps extends FormCommonProps<number> {
	purchaseOrderId: number;
	purchaseOrderLineId: number | undefined;
}

interface FormValues extends Omit<PurchaseOrderLine, "supplierPartId"> {
	supplierPart: SupplierPartView;
}

export const PurchaseOrderLineForm = ({
	purchaseOrderId,
	purchaseOrderLineId,
	onFormEdited,
	onCompleted,
}: PurchaseOrderLineFormProps) => {
	return (
		<AsyncForm
			fetch={() =>
				PurchaseOrderLineEditApi.getFormInitData({
					purchaseOrderId,
					purchaseOrderLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			columns={2}
			getUseFormProps={({ purchaseOrderLine }) => ({
				disabled: purchaseOrderLine != null &&
					["CANCELLED", "RECEIVED"].includes(purchaseOrderLine.purchaseOrderLineState)
			})}
			render={(params) => <FormContent {...params} />}
		/>
	);

	function getDefaultValues({
		purchaseOrder,
		purchaseOrderLine,
		supplierPart,
		defaultLineNumber,
	}: PurchaseOrderLineEditApi_FormInitData): DeepPartial<FormValues> {
		if (purchaseOrderLine != null) {
			return {
				...purchaseOrderLine,
				supplierPart: supplierPart ?? undefined,
			};
		} else {
			return {
				purchaseOrderId: purchaseOrderId,
				lineNumber: defaultLineNumber,
				plannedArrivalDate: purchaseOrder.plannedArrivalDate,
			};
		}
	}

	async function submit(values: FormValues) {
		const supplierPart = values.supplierPart;
		const purchaseOrderLine = {
			...values,
			supplierPartId: supplierPart.supplierPartId,
		};
		if (purchaseOrderLineId != null) {
			await PurchaseOrderLineEditApi.update({ purchaseOrderLine: purchaseOrderLine });
			return purchaseOrderLineId;
		} else {
			purchaseOrderLine.purchaseFactor = supplierPart.purchaseFactor;
			purchaseOrderLine.purchasePriceFactor = supplierPart.purchasePriceFactor;
			return await PurchaseOrderLineEditApi.insert({ purchaseOrderLine: purchaseOrderLine });
		}
	}
};

const FormContent = ({
	control,
	setValue,
	watch,
	data: { purchaseOrder, purchaseOrderLine },
}: AsyncFormContentParams<PurchaseOrderLineEditApi_FormInitData, FormValues>) => {
	const isExistingRecord = purchaseOrderLine != null;
	const supplierPart = watch("supplierPart");
	const { logErrorAndShowOnDialog } = useErrorDialog();

	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"supplierPart"}
				formValueType={"option"}
				label={i18n.t("purchase_part")}
				getOptionKey={(o: SupplierPartView) => o.supplierPartId}
				getOptionLabel={(o) =>
					concatWithPipe(o.partNo, o.partDescription_1, o.partDescription_2)
				}
				fetchOptions={({ searchQuery, currentSelection }) =>
					PurchaseOrderLineEditApi.getSupplierPartOptions({
						purchaseOrderId: purchaseOrder.purchaseOrderId,
						searchQuery: searchQuery,
						currentSelection: currentSelection,
					})
				}
				rules={requireRule()}
				disabled={isExistingRecord}
				onChange={onSupplierPartChanged}
			/>
			<FormTextField
				control={control}
				name={"purchasePartDescription"}
				label={i18n.t("description")}
				rules={requireRule()}
				spanGridColumns
			/>
			<FormNumberField
				control={control}
				name={"purchaseQuantity"}
				label={i18n.t("quantity")}
				rules={requireRule()}
				disabled={
					purchaseOrderLine != null &&
					!["INITIAL", "RELEASED"].includes(purchaseOrderLine.purchaseOrderLineState)
				}
			/>
			<FormNumberField
				control={control}
				name={"purchasePrice"}
				label={i18n.t("purchase_price") + (supplierPart && ` (${supplierPart.purchasePriceUnit})`)}
				rules={requireRule()}
			/>
			<AavoTextField label={i18n.t("purchase_unit")} value={supplierPart?.purchaseUnit} disabled />
			<AavoTextField label={i18n.t("price_unit")} value={supplierPart?.purchasePriceUnit} disabled />
			<FormDateField
				control={control}
				name={"plannedArrivalDate"}
				label={i18n.t("planned_arrival_date")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"lineNumber"}
				label={i18n.t("line_number")}
				rules={requireRule()}
				type={"integer"}
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />
		</>
	);

	async function onSupplierPartChanged(supplierPart: SupplierPartView | null) {
		try {
			const defaultPurchasePrice =
				supplierPart == null ? undefined : (
					await PurchaseOrderLineEditApi.getSupplierPartDefaultPrice({
						supplierPartId: supplierPart?.supplierPartId,
					})
				);

			setValue("purchasePrice", defaultPurchasePrice);
			setValue(
				"purchasePartDescription",
				concatWithPipe(supplierPart?.partDescription_1, supplierPart?.partDescription_2),
			);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};
