import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView.ts";
import { ProjectActivityApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityApi.ts";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { LabelValueView, LabelValueViewItem } from "src/components/common/misc/LabelValueView.tsx";
import { nullIfBlank } from "src/utils/strings.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { ProjectActivityForm } from "src/components/views/erp/project/projectActivity/ProjectActivityForm.tsx";
import { getActivityStateLabel } from "src/api/generated/erp/db/types/enums/activityState.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { TenantConfig } from "src/tenantCustomizations/TenantCustomizations.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";

export interface ProjectActivityInspectViewProps {
	activity: ProjectActivityView;
	onActivityUpdated: () => void | Promise<unknown>;
	refreshRef?: RefreshableElementRef;
}

export const ProjectActivityInspectView = ({
	activity: activityInitial,
	onActivityUpdated,
	refreshRef,
}: ProjectActivityInspectViewProps) => {
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();

	return (
		<AsyncFetchRender
			fetch={() =>
				ProjectActivityApi.getSingleActivity({
					activityId: activityInitial.activityId,
				})
			}
			defaultValue={activityInitial}
			refreshRef={refreshRef}
			content={(projectActivity, refreshActivity) => (
				<HorizontalBox
					sx={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<LabelValueView
						sx={{
							flex: 1,
							alignSelf: "flex-start",
						}}
						items={getLabelValueViewItems(projectActivity, tenantConfig)}
					/>
					<VerticalBox
						sx={{
							padding: 1,
						}}
					>
						<AsyncButton
							icon={faPen}
							onClick={() => {
								openFormOnDialog({
									openDialog,
									component: ProjectActivityForm,
									props: {
										subProjectId: projectActivity.subProjectId,
										activityId: projectActivity.activityId,
									},
									title: projectActivity.activityName,
									size: "md",
									onSubmit: () => Promise.all([refreshActivity(), onActivityUpdated?.()]),
								});
							}}
						/>
					</VerticalBox>
				</HorizontalBox>
			)}
		/>
	);
};

function getLabelValueViewItems(
	activity: ProjectActivityView,
	tenantConfig: TenantConfig,
): LabelValueViewItem[] {
	return [
		{
			label: i18n.t("name"),
			value: activity.activityName,
		},
		{
			label: i18n.t("type"),
			value: activity.activityTypeName,
		},
		{
			label: i18n.t("responsible_person"),
			value: activity.responsiblePersonName,
		},
		{
			label: i18n.t("resource"),
			value: activity.resourceName,
		},
		{
			label: i18n.t("state"),
			value: getActivityStateLabel(activity.activityState),
		},
		tenantConfig.erp.projectActivitySchedulingEnabled && {
			label: i18n.t("schedulable"),
			value: activity.isSchedulable,
		},
		{
			label: i18n.t("planned_start"),
			value: formatIsoString(activity.plannedStartDate),
		},
		{
			label: i18n.t("planned_end"),
			value: formatIsoString(activity.plannedEndDate),
		},
		{
			label: i18n.t("created_at"),
			value: formatIsoString(activity.creationDate, "L LT"),
		},
		activity.releasedDate != null && {
			label: i18n.t("released_at"),
			value: formatIsoString(activity.releasedDate, "L LT"),
		},
		activity.actualStartDate != null && {
			label: i18n.t("started_at"),
			value: formatIsoString(activity.actualStartDate, "L LT"),
		},
		activity.actualEndDate != null && {
			label: i18n.t("completed_at"),
			value: formatIsoString(activity.actualEndDate, "L LT"),
		},
		activity.cancelledDate != null && {
			label: i18n.t("cancelled_at"),
			value: formatIsoString(activity.cancelledDate, "L LT"),
		},
		{
			label: i18n.t("note"),
			value: nullIfBlank(activity.note),
			multiline: true,
		},
	];
}
