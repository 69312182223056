import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { DocumentCreationApi } from "src/api/generated/documents/api/documentCreationApi.ts";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";

export interface AddDocumentsToObjectButtonProps extends Omit<AsyncButtonProps, "onClick" | "icon"> {
	objectRef: AavoObjectRef | undefined;
	afterUpload?: () => Promise<unknown>;
}

export const AddDocumentsToObjectButton = ({ objectRef, afterUpload, ...other }: AddDocumentsToObjectButtonProps) => {
	const showInputDialog = useInputDialog();
	const { showToast } = useAavoToast();
	return (
		<AsyncButton
			icon={faCamera}
			tooltip={i18n.t("add_document")}
			color={"primary"}
			disabled={objectRef === undefined}
			onClick={async () => {
				if (objectRef === undefined) return;

				const file = await showInputDialog({
					title: i18n.t("add_document"),
					type: "singleFile",
					defaultValue: null,
					required: true,
				});

				if (file === undefined) return;
				const fileHandle = await uploadFile(file);
				await DocumentCreationApi.createDocument({
					objectRef: objectRef,
					description1: file.name,
					fileCreationType: "UPLOAD",
					uploadedFile: fileHandle,
				});
				showToast({ title: i18n.t("document_added") });
				await afterUpload?.();
			}}
			{...other}
		/>
	);
};
