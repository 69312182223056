import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { PartRevisionLineView } from "src/api/generated/erp/db/types/tables/partRevisionLineView.ts";
import {
	booleanColumn,
	enumColumn,
	floatColumn,
	singleSelectColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SitePartRevisionBomDataGridApi } from "src/api/generated/erp/parts/sitePart/api/sitePartRevisionBomDataGridApi";
import { concatWithPipe, isNullOrBlank } from "src/utils/strings.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { faFileCode, faLaptopCode, faSitemap } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { SitePartRevisionProductStructureView } from "src/components/views/erp/parts/siteParts/SitePartRevisionProductStructureView.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { getPartRevisionLineAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/partRevisionLineAcquisitionMethod.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { GridBooleanCell } from "@mui/x-data-grid-pro";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton.tsx";
import { useState } from "react";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { AavoCodeMirror } from "src/components/common/codeMirror/AavoCodeMirror.tsx";

export interface SitePartRevisionBomDataGridProps {
	partRevisionId: number;
	dataDirtyStateChanged?: DataDirtyStateChangeHandler;
}

export const SitePartRevisionBomDataGrid = ({
	partRevisionId,
	dataDirtyStateChanged,
}: SitePartRevisionBomDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();

	const [showInDesignUnit, setShowInDesignUnit] = useState(false);

	return (
		<ClientSideDataGridModel
			gridId={"C2421C8ABCCEA22C"}
			fetchData={({ searchQuery }) =>
				SitePartRevisionBomDataGridApi.getPartRevisionBom({
					partRevisionId: partRevisionId,
					searchQuery: searchQuery,
				})
			}
			initialParams={{
				searchQuery: "",
			}}
			getRows={(data) => data.partRevisionBomLines}
			getRowId={(row) => row.partRevisionLineId}
			render={({ data, dataGridProps, refreshData, onlySelectedRow }) => {
				const { partRevisionOperationOptions, partIsConfigurable } = data;
				return (
					<CrudDataGrid<PartRevisionLineView>
						dataDirtyStateChanged={dataDirtyStateChanged}
						columns={[
							textColumn({
								field: "referenceNo",
								headerName: i18n.t("reference_no"),
								width: 65,
							}),
							textColumn({
								field: "partLongName",
								headerName: i18n.t("part"),
								width: 200,
							}),
							singleSelectColumn({
								field: "partRevisionOperationId",
								headerName: i18n.t("operation"),
								valueOptions: partRevisionOperationOptions.map((option) => ({
									value: option.partRevisionOperationId,
									label: concatWithPipe(option.operationNo, option.operationDescription),
								})),
								width: 150,
								editable: true,
							}),
							showInDesignUnit && [
								textColumn({
									field: "designUnitQuantity",
									headerName: i18n.t("quantity"),
									width: 60,
								}),
								textColumn({
									field: "designUnitName",
									headerName: i18n.t("design_unit"),
									width: 60,
								}),
							],
							!showInDesignUnit && [
								textColumn({
									field: "quantity",
									headerName: i18n.t("quantity"),
									width: 60,
								}),
								textColumn({
									field: "partUnit",
									headerName: i18n.t("unit"),
									width: 60,
								}),
							],
							enumColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								enumLabels: getPartRevisionLineAcquisitionMethodLabels(),
								editable: true,
								width: 130,
							}),
							floatColumn({
								field: "totalCost",
								headerName: i18n.t("cost"),
								width: 80,
							}),
							booleanColumn({
								field: "isPhantom",
								headerName: i18n.t("phantom"),
								editable: true,
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
								editable: true,
							}),
							{
								field: "hasConfiguratorScript",
								type: "boolean",
								headerName: i18n.t("configuration_rule"),
								width: 150,
								valueGetter: (_, row) => !isNullOrBlank(row.configuratorTransformationScript),
								renderCell: (params) => (
									<HorizontalBox alignItems={"center"} gap={0.5}>
										<GridBooleanCell {...params} />
										<AavoIconButton
											icon={faLaptopCode}
											size={"small"}
											tooltip={i18n.t("configuration_rule")}
											onClick={() => {
												openTransformationScriptDialog(params.row);
											}}
										/>
									</HorizontalBox>
								),
							},
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"PART_REVISION",
										onlySelectedRow?.partRevisionId,
									)}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (value) => {
										await refreshData({ searchQuery: value });
									}}
								/>
								{partIsConfigurable && tenantConfig.erp.configuratorEnabled && (
									<AavoButton
										icon={faFileCode}
										label={i18n.t("configuration_rule")}
										disabled={onlySelectedRow == null}
										variant={"outlined"}
										onClick={() =>
											openTransformationScriptDialog(onlySelectedRow!)
										}
									/>
								)}
								<AavoButton
									icon={faSitemap}
									label={i18n.t("product_structure")}
									variant={"outlined"}
									disabled={
										onlySelectedRow == null ||
										onlySelectedRow.linePartActiveRevisionId == null
									}
									onClick={() => {
										const linePartActiveRevisionId =
											onlySelectedRow?.linePartActiveRevisionId;
										if (linePartActiveRevisionId == null) return;
										openDialog(({ onDataDirtyStateChanged }) => ({
											title: i18n.t("product_structure"),
											content: (
												<SitePartRevisionProductStructureView
													partRevisionId={linePartActiveRevisionId}
													dataDirtyStateChanged={onDataDirtyStateChanged}
												/>
											),
										}));
									}}
								/>
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showInDesignUnit"}
								label={i18n.t("show_in_design_unit")}
								checked={showInDesignUnit}
								onChange={async (value) => {
									setShowInDesignUnit(value);
								}}
							/>,
						]}
						save={{
							type: "enabled",
							action: async (params) => {
								return await SitePartRevisionBomDataGridApi.update({
									partRevisionBomLines: params.items,
								});
							},
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openTransformationScriptDialog(row: PartRevisionLineView) {
		openDialog({
			title: i18n.t("configuration_rule"),
			size: "xl",
			content: <AavoCodeMirror defaultValue={row.configuratorTransformationScript ?? ""} disabled />,
		});
	}
};
