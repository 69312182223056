import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { Typography } from "@mui/material";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { FormSingleFileInputField } from "src/components/common/forms/fields/FormSingleFileInputField.tsx";
import { SpcRecordActionEditApi } from "src/api/generated/spc/controlChart/api/spcRecordActionEditApi.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";

export interface SpcRecordActionFormProps extends FormCommonProps<void> {
	recordIds: number[];
}

interface FormValues {
	ocs: string;
	description: string;
	createdBy: string;
	file: File | null;
}

export const SpcRecordActionForm = ({
	recordIds = [],
	onFormEdited,
	onCompleted,
}: SpcRecordActionFormProps) => {
	const { userName } = useGlobalInitData();

	const firstRecordId = recordIds[0];
	if (firstRecordId == null) {
		logError("No any record provided for ControlChartActionForm");
		return null;
	}

	return (
		<AsyncForm
			fetch={() =>
				SpcRecordActionEditApi.getFormInitData({
					recordId: firstRecordId,
				})
			}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			getDefaultValues={({ action }) =>
				action != null ? action : (
					{
						createdBy: userName,
					}
				)
			}
			submit={submit}
			render={({ control, data: { action } }) => (
				<>
					<FormTextField
						control={control}
						name={"ocs"}
						label={i18n.t("reason")}
						rules={requireRule()}
						autoFocus
					/>
					<FormTextField
						control={control}
						name={"createdBy"}
						label={i18n.t("recorded_by")}
						rules={requireRule()}
					/>
					{action != null && (
						<Typography>
							{`${i18n.t("recorded_at")}: ${formatIsoString(action.createdAt, "L LT")}`}
						</Typography>
					)}
					<FormTextField
						control={control}
						name={"description"}
						label={i18n.t("description")}
						multiline
					/>
					<FormSingleFileInputField control={control} name={"file"} label={i18n.t("file")} />
				</>
			)}
		/>
	);

	async function submit(values: FormValues) {
		const fileHandle = values.file == null ? null : await uploadFile(values.file);

		await SpcRecordActionEditApi.upsertActions({
			recordIds: recordIds,
			ocs: values.ocs,
			description: values.description,
			createdBy: values.createdBy,
			file: fileHandle,
		});
	}
};
