// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { AcquisitionMethod } from "src/api/generated/erp/db/types/enums/acquisitionMethod";
import { IsoDateTimeString } from "src/types/dateTime";
import { CustomerOrderLineState } from "src/api/generated/erp/db/types/enums/customerOrderLineState";
import { CustomerOrderLineViewCustomerOrderState } from "src/api/generated/erp/db/types/enums/customerOrderLineViewCustomerOrderState";
import { CustomerOrderLineViewPurchaseOrderLineState } from "src/api/generated/erp/db/types/enums/customerOrderLineViewPurchaseOrderLineState";
import { SalesPartType } from "src/api/generated/erp/db/types/enums/salesPartType";
import { CustomerOrderLineViewShopOrderState } from "src/api/generated/erp/db/types/enums/customerOrderLineViewShopOrderState";
import { CustomerOrderLineViewSourceType } from "src/api/generated/erp/db/types/enums/customerOrderLineViewSourceType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReservableCustomerOrderLinesDataGridApi {
	export async function getViewData(args: {
		customerOrderId: number;
		plannedDeliveryDateFilter: IsoDateString | null | undefined;
		onlyFromOwnWarehouseArea: boolean;
	}): Promise<ReservableCustomerOrderLinesDataGridApi_ViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderReservableLines/getViewData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				plannedDeliveryDateFilter: args.plannedDeliveryDateFilter,
				onlyFromOwnWarehouseArea: args.onlyFromOwnWarehouseArea,
			},
		});
		return response as ReservableCustomerOrderLinesDataGridApi_ViewData;
	}
}

export interface ReservableCustomerOrderLinesDataGridApi_ViewData {
	deliveryDateOptions: Array<IsoDateString>;
	rows: Array<ReservableCustomerOrderLinesDataGridApi_Row>;
}

export interface ReservableCustomerOrderLinesDataGridApi_Row {
	hasEnoughAvailableQuantity: boolean;
	acquisitionMethod: AcquisitionMethod;
	billingPlanEnabled: boolean;
	cancelledDate: IsoDateTimeString | null | undefined;
	capacityQuantity: number;
	configurationOrPartDescription: string;
	contactEmail: string;
	contactName: string;
	costLocked: boolean;
	customerGroupId: number | null | undefined;
	customerId: number;
	customerName: string;
	customerOrderId: number;
	customerOrderIsCapacityReservation: boolean;
	customerOrderLineId: number;
	customerOrderLineLongName: string | null | undefined;
	customerOrderLineState: CustomerOrderLineState;
	customerOrderState: CustomerOrderLineViewCustomerOrderState;
	customerOrderTenantState: string | null | undefined;
	customerOrderTypeId: number;
	customerOrderTypeName: string;
	customerPriceGroupId: number | null | undefined;
	deliveredQuantity: number;
	deliveryDate: IsoDateTimeString | null | undefined;
	discountFactor: number;
	discountPercentage: number;
	enableQuantityUpdate: boolean;
	externalId: string;
	invoicedQuantity: number;
	isAwaitingConfiguration: boolean | null | undefined;
	lastPickingDate: IsoDateString | null | undefined;
	lastShippingDate: IsoDateString | null | undefined;
	lastUpdated: IsoDateTimeString;
	lineNumber: number;
	mark: string;
	orderReference: string;
	packageNumber_1: number | null | undefined;
	packageNumber_2: number | null | undefined;
	parentLineDescription: string | null | undefined;
	parentLineId: number | null | undefined;
	partCategory: string | null | undefined;
	partConfigurationDescription: string | null | undefined;
	partConfigurationId: number | null | undefined;
	partConfigurationWeight: number | null | undefined;
	partDefaultProductionLineId: number | null | undefined;
	partDescription_1: string | null | undefined;
	partDescription_2: string | null | undefined;
	partId: number | null | undefined;
	partIsConfigurable: boolean | null | undefined;
	partNo: string | null | undefined;
	partUnit: string | null | undefined;
	partUnitId: number | null | undefined;
	partWeight: number | null | undefined;
	pickedQuantity: number;
	plannedDeliveryDate: IsoDateString;
	priceFactor: number;
	priceInputWithVat: boolean;
	priceLocked: boolean;
	priceUnitFactor: number;
	purchaseOrderId: number | null | undefined;
	purchaseOrderLineId: number | null | undefined;
	purchaseOrderLineState: CustomerOrderLineViewPurchaseOrderLineState | null | undefined;
	purchaseUnit: string | null | undefined;
	releaseDate: IsoDateTimeString | null | undefined;
	reservedQuantity: number;
	salesPartCategoryId: number | null | undefined;
	salesPartDescription: string;
	salesPartId: number;
	salesPartNo: string;
	salesPartType: SalesPartType;
	salesPrice: number;
	salesPriceUnit: string | null | undefined;
	salesPriceUnitId: number;
	salesQuantityAsPriceUnits: number;
	salesQuantityAsSalesUnits: number;
	salesQuantityAsWarehouseUnits: number;
	salesUnit: string | null | undefined;
	salesUnitFactor: number;
	salesUnitId: number;
	shopOrderBatchCode: string | null | undefined;
	shopOrderId: number | null | undefined;
	shopOrderProductionLineId: number | null | undefined;
	shopOrderState: CustomerOrderLineViewShopOrderState | null | undefined;
	siteId: number;
	siteName: string;
	sitePartCategoryId: number | null | undefined;
	sourceId: number | null | undefined;
	sourceType: CustomerOrderLineViewSourceType;
	totalCost: number;
	totalPrice: number | null | undefined;
	totalPriceWithVat: number | null | undefined;
	unitCost: number;
	unitPrice: number;
	unitPriceWithVat: number;
	vatCodeId: number;
	vatCodeName: string;
	vatPercent: number;
	vatPercentFactor: number | null | undefined;
}
