import { JobQueueView } from "src/components/views/erp/production/jobQueue/base/JobQueueView.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { SelectedOperationActionsViewExtraComponentProps } from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import { ProductionLineGroupJobQueueCompletedApi } from "src/api/generated/erp/production/jobQueues/api/productionLineGroup/productionLineGroupJobQueueCompletedApi.ts";
import { ProductionLineGroupJobQueueInProgressApi } from "src/api/generated/erp/production/jobQueues/api/productionLineGroup/productionLineGroupJobQueueInProgressApi.ts";
import { ProductionLineGroupJobQueueInitialsApi } from "src/api/generated/erp/production/jobQueues/api/productionLineGroup/productionLineGroupJobQueueInitialsApi.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openProductionLineWorkCenterOperationActionViewDialog } from "src/components/views/erp/production/jobQueue/common/ProductionLineWorkCenterOperationActionView.utils.tsx";

export const ProductionLineGroupJobQueueView = () => {
	return (
		<JobQueueView
			viewId={"productionLineGroupJobQueueView"}
			initialsProps={{
				fetchData: ProductionLineGroupJobQueueInitialsApi.getInitialOperations,
				setProductionLineToOperationOnStart: true,
			}}
			inProgressProps={{
				fetchData: ProductionLineGroupJobQueueInProgressApi.getInProgressOperations,
				extraActionBarComponents: (params) => <InProgressViewExtraActionBarComponents {...params} />,
				confirmIfWorkCenterHasIncompleteOperations: true,
			}}
			completedProps={{
				fetchData: ProductionLineGroupJobQueueCompletedApi.getCompletedOperations,
			}}
		/>
	);
};

const InProgressViewExtraActionBarComponents = ({
	onlySelectedOperation,
}: SelectedOperationActionsViewExtraComponentProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<>
			<AsyncButton
				icon={faCheck}
				label={i18n.t("operation_completion")}
				variant={"outlined"}
				disabled={!onlySelectedOperation}
				onClick={() => {
					openProductionLineWorkCenterOperationActionViewDialog({
						openDialog: openDialog,
						shopOrderOperation: onlySelectedOperation!,
					});
				}}
			/>
		</>
	);
};
