// This file is automatically generated. Do not edit manually.

import { WorkspaceConnectedItem } from "src/api/generated/workspaces/connections/workspaceConnectedItem";
import { WorkspaceConnectedItemType } from "src/api/generated/postgres/db/types/workspaces/enums/workspaceConnectedItemType";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspaceConnectionsApi {
	export async function getConnectedItems(args: { workspaceId: number }): Promise<Array<WorkspaceConnectedItem>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/connections/getConnectedItems",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
			},
		});
		return response as Array<WorkspaceConnectedItem>;
	}

	export async function getConnectedItemWorkspaces(args: {
		connectedItemType: WorkspaceConnectedItemType;
		connectedItemId: number;
	}): Promise<Array<WorkspaceView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/connections/getConnectedItemWorkspaces",
			method: "POST",
			data: {
				connectedItemType: args.connectedItemType,
				connectedItemId: args.connectedItemId,
			},
		});
		return response as Array<WorkspaceView>;
	}

	export async function insertConnections(args: {
		workspaceId: number;
		connectedItemIds: Array<number>;
		connectedItemType: WorkspaceConnectedItemType;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/connections/insertConnections",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				connectedItemIds: args.connectedItemIds,
				connectedItemType: args.connectedItemType,
			},
		});
		return response as void;
	}

	export async function deleteConnections(args: {
		workspaceId: number;
		workspaceConnectedItems: Array<WorkspaceConnectedItem>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/connections/deleteConnections",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				workspaceConnectedItems: args.workspaceConnectedItems,
			},
		});
		return response as void;
	}
}
