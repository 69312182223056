import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { InvoiceReadingApi } from "src/api/generated/erp/sales/invoicing/api/invoiceReadingApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { getInvoiceTypeLabels } from "src/api/generated/erp/db/types/enums/invoiceType.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { InvoiceOperationApi } from "src/api/generated/erp/sales/invoicing/api/invoiceOperationApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import {
	faArrowRight,
	faCheck,
	faExternalLink,
	faMoneyCheck,
	faPrint,
	faTurnDown,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { getInvoiceStateLabels } from "src/api/generated/erp/db/types/enums/invoiceState.ts";
import { InvoiceView } from "src/api/generated/erp/db/types/tables/invoiceView.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { getVatHandlingLabels } from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { CreateRefundInvoiceForm } from "src/components/views/erp/sales/invoicing/CreateRefundInvoiceForm.tsx";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { InvoiceForm } from "src/components/views/erp/sales/invoicing/InvoiceForm.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { InspectInvoiceView } from "src/components/views/erp/sales/invoicing/InspectInvoiceView.tsx";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { InvoiceCreationApi } from "src/api/generated/erp/sales/invoicing/api/invoiceCreationApi.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { dataGridRowClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";

export interface InvoicesDataGridBaseProps extends RefreshableElementProps {
	customerOrderId?: number;
	hideColumns?: [keyof InvoiceView];
	hideSearchField?: boolean;
	editable: boolean;
}

export const InvoicesDataGridBase = ({
	hideSearchField,
	customerOrderId,
	refreshRef,
	editable,
}: InvoicesDataGridBaseProps) => {
	const { dataGridProps, refreshData, currentParams, onlySelectedRow, selectedRows } =
		useServerSideDataGridModel({
			gridId: "74C4010104EDA4FB",
			fetchData: InvoiceReadingApi.getInvoices,
			getRowId: (row) => row.invoiceId,
			initialParams: {
				customerOrderId: customerOrderId,
				onlyInvoicesNotInLedger: false,
				searchQuery: "",
			},
		});

	const { tenantConfig } = useTenantCustomizations();
	const hasLedgerIntegration = tenantConfig.erp.ledgerIntegrationEnabled;

	const { openDialog } = useGenericDialog();
	const openLegacyView = useOpenLegacyView();
	const confirm = useConfirmDialog();

	setRefreshRefValue(refreshRef, refreshData);

	return (
		<ControlledAsyncCrudDataGrid<InvoiceView>
			getRowClassNames={({ row }) =>
				row.invoiceState === "PAID" ? dataGridRowClassNames.green : undefined
			}
			columns={[
				integerColumn({
					field: "invoiceId",
					headerName: i18n.t("number_shortened"),
					width: 100,
				}),
				textColumn({
					field: "referenceNumber",
					headerName: i18n.t("reference_number"),
					width: 140,
				}),
				enumColumn({
					field: "invoiceType",
					headerName: i18n.t("type"),
					enumLabels: getInvoiceTypeLabels(),
				}),
				dateColumn({
					field: "invoiceDate",
					headerName: i18n.t("invoice_date"),
					width: 120,
				}),
				enumColumn({
					field: "invoiceState",
					headerName: i18n.t("state"),
					enumLabels: getInvoiceStateLabels(),
				}),
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("order_number"),
					width: 120,
				}),
				dateColumn({
					field: "deliveryDate",
					headerName: i18n.t("delivery_date"),
					width: 120,
				}),
				textColumn({
					field: "externalId",
					headerName: i18n.t("external_id"),
					width: 150,
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
					width: 150,
				}),
				textColumn({
					field: "customerBusinessId",
					headerName: i18n.t("customer_business_id"),
					width: 150,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 130,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				enumColumn({
					field: "vatHandling",
					headerName: i18n.t("vat_handling"),
					enumLabels: getVatHandlingLabels(),
					width: 120,
				}),
				floatColumn({
					field: "totalAmount",
					headerName: i18n.t("sum_without_vat"),
					width: 120,
				}),
				floatColumn({
					field: "totalVat",
					headerName: i18n.t("vat"),
				}),
				floatColumn({
					field: "totalAmountWithVat",
					headerName: i18n.t("sum_with_vat"),
					width: 120,
				}),
			]}
			form={{
				dialogSize: "md",
				dialogTitle: i18n.t("invoice"),
				component: ({ row, onCompleted, onFormEdited }) => {
					if (!row) return;
					return (
						<InvoiceForm
							invoice={row}
							onCompleted={(result) => onCompleted(result)}
							onFormEdited={onFormEdited}
						/>
					);
				},
				addRowEnabled: false,
				editEnabled: editable,
			}}
			actionBarComponents={
				<>
					{hideSearchField !== true && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={async (input) => {
								await refreshData({ searchQuery: input });
							}}
						/>
					)}
					<AavoButton
						variant={"outlined"}
						icon={faExternalLink}
						label={i18n.t("open")}
						disabled={onlySelectedRow === null}
						onClick={() => {
							if (onlySelectedRow == null) return;
							openDialog(() => ({
								title: i18n.t("invoice"),
								content: <InspectInvoiceView invoice={onlySelectedRow} />,
							}));
						}}
					/>
					<AsyncButton
						icon={faPrint}
						label={i18n.t("print")}
						variant={"outlined"}
						disabled={onlySelectedRow === null}
						onClick={async () => {
							if (onlySelectedRow == undefined) return;
							const fileHandle = await InvoiceOperationApi.printInvoice({
								invoiceId: onlySelectedRow.invoiceId,
							});
							downloadFile(fileHandle);
							await refreshData();
						}}
					/>
					{hasLedgerIntegration && editable && (
						<AsyncButton
							icon={faArrowRight}
							label={i18n.t("copy_to_ledger")}
							variant={"outlined"}
							disabled={selectedRows.length === 0}
							onClick={async () => {
								const confirmed = await confirm({
									message: i18n.t("copy_invoices_to_ledger_confirm"),
								});
								if (!confirmed) return;

								await InvoiceOperationApi.copyInvoicesToLedger({
									invoiceIds: selectedRows.map((row) => row.invoiceId),
								});
								await refreshData();
							}}
						/>
					)}
					<AavoCheckbox
						label={i18n.t("only_invoices_not_in_ledger")}
						checked={currentParams.onlyInvoicesNotInLedger}
						onChange={async (checked) => {
							await refreshData({ onlyInvoicesNotInLedger: checked });
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row, multipleRowsSelected }) => [
				!multipleRowsSelected && row.customerOrderId != null && customerOrderId == null && (
					<OpenCustomerOrderButton
						key={"open_customer_order"}
						customerOrderId={row.customerOrderId}
					/>
				),
				!multipleRowsSelected && row.invoiceType === "CHARGE" && editable && (
					<AsyncMenuButton
						key={"create_refund_invoice"}
						label={i18n.t("create_a_refund_invoice")}
						icon={faTurnDown}
						onClick={() => {
							openDialog(({ closeDialog }) => ({
								title: i18n.t("refund_invoice"),
								size: "lg",
								content: (
									<CreateRefundInvoiceForm
										chargeInvoiceId={row.invoiceId}
										onCompleted={async (result) => {
											await closeDialog();
											if (result.type === "success") {
												await refreshData();
											}
										}}
									/>
								),
							}));
						}}
					/>
				),
				!multipleRowsSelected && row.invoiceType === "CHARGE" && editable && (
					<AsyncMenuButton
						key={"cancelInvoice"}
						label={i18n.t("cancel_invoice")}
						icon={faUndo}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("cancel_invoice"),
								message: i18n.t("cancel_invoice_confirmation"),
							});
							if (!confirmed) return;

							await InvoiceCreationApi.cancelInvoice({
								chargeInvoiceId: row.invoiceId,
							});
							await refreshData();
						}}
					/>
				),
				editable && (
					<AsyncMenuButton
						key={"mark_as_copied_to_ledger"}
						icon={faCheck}
						label={i18n.t("mark_as_copied_to_ledger")}
						onClick={async () => {
							const confirmed = await confirm({
								message: i18n.t("mark_as_copied_to_ledger_confirmation"),
							});
							if (!confirmed) return;

							await InvoiceOperationApi.markInvoicesAsCopiedToLedger({
								invoiceIds: selectedRows.map((row) => row.invoiceId),
							});
							await refreshData();
						}}
					/>
				),
				!multipleRowsSelected && hasLedgerIntegration && row.externalId != null && (
					<AsyncMenuButton
						key={"payment_events"}
						icon={faMoneyCheck}
						label={i18n.t("payment_events")}
						onClick={async () => {
							openLegacyView("E34A42936386F00D", {
								invoiceId: row.invoiceId,
							});
						}}
					/>
				),
				!multipleRowsSelected && (
					<OpenObjectChangeLogButton
						key={"open_change_log"}
						objectRef={{
							objectType: "INVOICE",
							objectId: row.invoiceId,
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
