export const join_paths = (
	urls: string[],
	absolute: boolean = true
): string => {
	const initial = absolute ? "/" : "";
	if (urls.length === 0) {
		return initial;
	} else {
		return urls.reduce((prev, currentValue) => {
			if (prev === "") {
				return currentValue;
			} else if (currentValue === "") {
				return prev;
			} else if (prev.endsWith("/") || currentValue.startsWith("/")) {
				return prev + currentValue;
			} else {
				return prev + "/" + currentValue;
			}
		}, initial as string);
	}
};

export const optional = (path: string) => {
	return `${path}?`;
};

export const url_param = (path: string) => {
	return `:${path}`;
};

export const absolute = (path: string) => {
	if (path.startsWith("/")) {
		return path;
	} else {
		return `/${path}`;
	}
};
