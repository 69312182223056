import { ProjectActivityCustomerOrderView } from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView.ts";
import { ProjectActivityJobQueueActionsApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueActionsApi.ts";
import i18n from "i18next";
import type { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";

export namespace ProjectJobQueueActions {
	export async function revertStartActivities(
		activities: ProjectActivityCustomerOrderView[],
		showConfirmDialog: ShowConfirmDialogFunc,
	) {
		const confirmed = await showConfirmDialog({
			cancelButtonText: i18n.t("no"),
		});
		if (!confirmed) return;

		const activityIds = activities.map((row) => row.activityId);

		const hasStartedTasks = await ProjectActivityJobQueueActionsApi.getActivitiesHaveStartedTasks({
			activityIds,
		});
		if (hasStartedTasks) {
			const confirmed = await showConfirmDialog({
				message: i18n.t("activity_has_started_tasks_confirm_revert_start"),
				cancelButtonText: i18n.t("no"),
			});
			if (!confirmed) return false;
		}

		await ProjectActivityJobQueueActionsApi.revertStartActivities({ activityIds });
		return true;
	}

	export async function completeActivities(
		activities: ProjectActivityCustomerOrderView[],
		showConfirmDialog: ShowConfirmDialogFunc,
	) {
		const confirmed = await showConfirmDialog();
		if (!confirmed) return false;

		await ProjectActivityJobQueueActionsApi.completeActivities({
			activityIds: activities.map((row) => row.activityId),
		});
		return true;
	}
}
