import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { SelectField, SelectFieldProps } from "src/components/common/inputFields/SelectField.tsx";
import { Site } from "src/api/generated/erp/db/types/tables/site.ts";
import i18n from "i18next";
import { mergeSx } from "src/utils/styles.ts";

export interface SelectSiteFieldProps
	extends Omit<SelectFieldProps<Site, number, false>, "options" | "getOptionKey" | "getOptionLabel" | "label"> {
	label?: string;
}

export const SelectSiteField = ({ sx, ...other }: SelectSiteFieldProps) => {
	const { userSites, defaultSiteId } = useGlobalInitData();

	if (userSites.length < 2) {
		return null;
	}

	return (
		<SelectField
			label={i18n.t("site")}
			options={userSites}
			getOptionKey={(site) => site.siteId}
			getOptionLabel={(site) => site.siteName}
			defaultValue={defaultSiteId}
			sx={mergeSx(
				{
					minWidth: 100,
				},
				sx,
			)}
			{...other}
		/>
	);
};
