import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {SitePartsDataGrid} from "src/components/views/erp/parts/siteParts/SitePartsDataGrid.tsx";
import i18n from "i18next";
import {useState} from "react";
import {PartSelectionPartCategoryTreeView} from "src/components/views/erp/parts/PartSelectionPartCategoryTreeView.tsx";
import {PartCategory} from "src/api/generated/erp/db/types/tables/partCategory.ts";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {SitePartAttributesDataGrid} from "src/components/views/erp/parts/siteParts/SitePartAttributesDataGrid.tsx";
import {PartView} from "src/api/generated/erp/db/types/tables/partView.ts";
import {SitePartAcquisitionsDataGrid} from "src/components/views/erp/parts/siteParts/SitePartAcquisitionsDataGrid.tsx";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {
    PartWarehouseLocationsDataGrid
} from "src/components/views/erp/parts/siteParts/partWarehouseLocations/PartWarehouseLocationsDataGrid.tsx";
import {SitePartRevisionsDataGrid} from "src/components/views/erp/parts/siteParts/SitePartRevisionsDataGrid.tsx";
import {SitePartSuppliersDataGrid} from "./SitePartSuppliersDataGrid";
import {SalesPartsDataGrid} from "src/components/views/erp/sales/basedata/salesParts/SalesPartsDataGrid.tsx";
import {SitePartReferencesDataGrid} from "src/components/views/erp/parts/siteParts/SitePartReferencesDataGrid.tsx";

export interface SitePartsPageProps {
	sitePartId?: number | undefined;
}

export const SitePartsPage = ({ sitePartId }: SitePartsPageProps) => {
	const [selectedPartCategory, setSelectedPartCategory] = useState<PartCategory | null>(null);
	const [selectedPart, setSelectedPart] = useState<PartView | undefined>(undefined);

	const partsDataGridRefreshRef = useRefreshRef();
	const selectPartTypography = <CenteredTypography children={i18n.t("select_a_part")} />;

	return (
		<AavoMosaic
			viewId={"3304B81288816A6A"}
			layout={{
				type: "row",
				splitPercentage: 20,
				first: {
					type: "panel",
					title: i18n.t("part_categories"),
					hidden: sitePartId != null,
					content: (
						<PartSelectionPartCategoryTreeView
							selectedPartCategory={selectedPartCategory}
							setSelectedPartCategory={(partCategory) => {
								setSelectedPartCategory(partCategory);
								setSelectedPart(undefined);
								partsDataGridRefreshRef.refresh();
							}}
						/>
					),
				},
				second: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("warehouse_parts"),
						content: (
							<SitePartsDataGrid
								key={selectedPartCategory?.partCategoryId ?? "-1"}
								partCategoryId={selectedPartCategory?.partCategoryId}
								onSelectionChanged={(parts) => {
									setSelectedPart(parts[0]);
								}}
								sitePartId={sitePartId}
							/>
						),
					},
					second: {
						type: "tabs",
						items: [
							{
								type: "panel",
								title: i18n.t("revisions"),
								content:
									selectedPart == null ? selectPartTypography : (
										<SitePartRevisionsDataGrid
											key={selectedPart.partId}
											partId={selectedPart.partId}
											partViewRefreshRef={partsDataGridRefreshRef}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("warehouse_locations"),
								content:
									selectedPart == null ? selectPartTypography : (
										<PartWarehouseLocationsDataGrid
											key={selectedPart.partId}
											sitePartId={selectedPart.partId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("suppliers"),
								content:
									selectedPart == null ? selectPartTypography : (
										<SitePartSuppliersDataGrid
											key={selectedPart.partId}
											partId={selectedPart.partId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("sales_parts"),
								content:
									selectedPart == null ? selectPartTypography : (
										<SalesPartsDataGrid
											key={selectedPart.partId}
											partId={selectedPart.partId}
											showTypeFiltering={false}
											showSiteFiltering={false}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("acquisitions"),
								content:
									selectedPart == null ? selectPartTypography : (
										<SitePartAcquisitionsDataGrid key={selectedPart.partId} part={selectedPart} />
									),
							},
							{
								type: "panel",
								title: i18n.t("attributes"),
								content:
									selectedPart == null ? selectPartTypography : (
										<SitePartAttributesDataGrid
											key={selectedPart.partId}
											sitePartId={selectedPart.partId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("referencing_parts"),
								content:
									selectedPart == null ? selectPartTypography : (
										<SitePartReferencesDataGrid
											key={selectedPart.partId}
											partId={selectedPart.partId}
										/>
									),
							},
						],
					},
				},
			}}
		/>
	);
};
