import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
    dateTimeColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
    getInventoryLevelEventTypeLabel,
    getInventoryLevelEventTypeLabels,
    InventoryLevelEventType,
    InventoryLevelEventTypeValues,
} from "src/api/generated/erp/db/types/enums/inventoryLevelEventType.ts";
import {InventoryLevelEventView} from "src/api/generated/erp/db/types/tables/inventoryLevelEventView.ts";
import {
    AllInventoryLevelEventsDataGridApi
} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/allInventoryLevelEventsDataGridApi.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {
    AsyncSelectField,
    AsyncSelectFieldApi,
    FetchAsyncOptionParams,
} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {SelectSiteField} from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import {getPartLongName} from "src/components/views/erp/erpUtils.ts";
import {WarehouseLocationsApi} from "src/api/generated/erp/warehouse/basedata/api/warehouseLocationsApi.ts";
import {AavoDataGrid} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {MultiSelectField} from "src/components/common/inputFields/MultiSelectField.tsx";
import {SitePartQueryApi} from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import {AavoDatePicker} from "src/components/common/inputFields/AavoDatePicker.tsx";
import {dayJsToDateIsoStringNullable} from "src/utils/dayjsUtils.ts";
import {useRef} from "react";
import {Dayjs} from "dayjs";

export const AllInventoryLevelEventsDataGrid = () => {
	const { defaultSiteId } = useGlobalInitData();

	const partSelectFieldApiRef = useRef<AsyncSelectFieldApi<number> | null>(null);
	const warehouseLocationSelectFieldApiRef = useRef<AsyncSelectFieldApi<number> | null>(null);

	return (
		<ServerSideDataGridModel
			gridId={"940AB7D2A0EC93277"}
			fetchData={({ fromDate, toDate, ...otherParams }) =>
				AllInventoryLevelEventsDataGridApi.getGridData({
					fromDate: dayJsToDateIsoStringNullable(fromDate),
					toDate: dayJsToDateIsoStringNullable(toDate),
					...otherParams,
				})
			}
			initialParams={{
				siteId: genericNullableValue<number>(defaultSiteId),
				partId: genericNullableValue<number>(null),
				warehouseLocationId: genericNullableValue<number>(null),
				fromDate: genericNullableValue<Dayjs>(null),
				toDate: genericNullableValue<Dayjs>(null),
				eventTypes: Array<InventoryLevelEventType>(),
			}}
			getDataModelResult={(response) => response}
			getRowId={(row) => row.inventoryLevelEventId}
			render={({ dataGridProps, currentParams, refreshData }) => (
				<AavoDataGrid<InventoryLevelEventView>
					columns={[
						dateTimeColumn({
							field: "createdAt",
							headerName: i18n.t("created_at"),
							width: 180,
						}),
						textColumn({
							field: "partLongName",
							headerName: i18n.t("part"),
							width: 200,
						}),
						textColumn({
							field: "locationCode",
							headerName: i18n.t("warehouse_location"),
							width: 150,
						}),
						enumColumn({
							field: "inventoryLevelEventType",
							headerName: i18n.t("event_type"),
							enumLabels: getInventoryLevelEventTypeLabels(),
							width: 150,
						}),
						floatColumn({
							field: "eventQuantity",
							headerName: i18n.t("quantity"),
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("unit"),
						}),
						floatColumn({
							field: "unitCost",
							headerName: i18n.t("unit_cost"),
							width: 150,
						}),
						floatColumn({
							field: "totalCost",
							headerName: i18n.t("total_cost"),
							width: 150,
						}),
						floatColumn({
							field: "stockQuantityAfterEvent",
							headerName: i18n.t("stock_quantity_after_event"),
							width: 250,
						}),
						integerColumn({
							field: "counterObjectId",
							headerName: i18n.t("object_id"),
							width: 150,
						}),
						textColumn({
							field: "createdByUserName",
							headerName: i18n.t("created_by"),
							width: 150,
						}),
						integerColumn({
							field: "inventoryLevelEventId",
							headerName: i18n.t("id"),
							width: 80,
						}),
					]}
					actionBarComponents={
						<>
							<SelectSiteField
								value={currentParams.siteId}
								onChange={async (siteId) => {
									partSelectFieldApiRef.current?.setValue(null);
									warehouseLocationSelectFieldApiRef.current?.setValue(null);
									await refreshData({
										siteId: siteId,
										partId: null,
										warehouseLocationId: null,
									});
								}}
							/>
							<AsyncSelectField
								apiRef={partSelectFieldApiRef}
								label={i18n.t("part")}
								fetchOptions={({ currentSelection, searchQuery }: FetchAsyncOptionParams<number>) =>
									SitePartQueryApi.getPartSelectionOptions({
										siteId: currentParams.siteId,
										currentPartId: currentSelection,
										searchQuery: searchQuery,
										withWarehouseLocations: true,
									})
								}
								getOptionKey={(part) => part.partId}
								getOptionLabel={getPartLongName}
								onChange={async (part) => {
									warehouseLocationSelectFieldApiRef.current?.setValue(null);
									await refreshData({
										partId: part?.partId,
										warehouseLocationId: null,
									});
								}}
							/>
							<AsyncSelectField
								apiRef={warehouseLocationSelectFieldApiRef}
								label={i18n.t("warehouse_location")}
								fetchOptions={({ currentSelection, searchQuery }: FetchAsyncOptionParams<number>) =>
									WarehouseLocationsApi.getWarehouseLocationOptions({
										siteId: currentParams.siteId,
										partId: currentParams.partId,
										currentSelection: currentSelection,
										searchQuery: searchQuery,
									})
								}
								getOptionKey={(part) => part.warehouseLocationId}
								getOptionLabel={(part) => part.locationCode}
								onChange={(warehouseLocation) =>
									refreshData({ warehouseLocationId: warehouseLocation?.warehouseLocationId })
								}
							/>
							<MultiSelectField
								label={i18n.t("event_type")}
								options={InventoryLevelEventTypeValues}
								getOptionKey={(value) => value}
								getOptionLabel={(value) => getInventoryLevelEventTypeLabel(value)}
								value={currentParams.eventTypes}
								onChange={(eventTypes) => refreshData({ eventTypes: eventTypes })}
							/>
							<AavoDatePicker
								label={i18n.t("from_date")}
								value={currentParams.fromDate}
								onChange={(fromDate) => refreshData({ fromDate })}
							/>
							<AavoDatePicker
								label={i18n.t("to_date")}
								value={currentParams.toDate}
								onChange={(toDate) => refreshData({ toDate })}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
