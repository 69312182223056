// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const WorkspaceConnectedItemTypeValues = ["CATALOG_PART", "CUSTOMER_ORDER", "PROJECT"] as const;

export type WorkspaceConnectedItemType = (typeof WorkspaceConnectedItemTypeValues)[number];

export const getWorkspaceConnectedItemTypeLabels = () => ({
	CATALOG_PART: t("catalog_part"),
	CUSTOMER_ORDER: t("customer_order"),
	PROJECT: t("project"),
});

export const getWorkspaceConnectedItemTypeLabel = (value: WorkspaceConnectedItemType): string => {
	return getWorkspaceConnectedItemTypeLabels()[value];
};
