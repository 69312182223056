import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {coloredBooleanColumn, enumColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ObjectPermission} from "src/api/generated/iam/model/objectPermission.ts";
import {getObjectMemberTypeLabels} from "src/api/generated/iam/model/objectMemberType.ts";
import {WorkspacePermissionEditApi} from "src/api/generated/workspaces/api/workspacePermissionEditApi.ts";
import {WorkspacePermissionForm} from "src/components/views/workspaces/permission/WorkspacePermissionForm.tsx";
import {getObjectPermissionId} from "src/components/views/erp/surveys/management/permissions/ObjectPermissionId.ts";

export interface WorkspacePermissionsDataGridProps {
	workspaceId: number;
}

export const WorkspacePermissionsDataGrid = ({ workspaceId }: WorkspacePermissionsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				WorkspacePermissionEditApi.getWorkspacePermissions({
					workspaceId,
				})
			}
			initialParams={{}}
			getRows={(response) => response}
			getRowId={(row) =>
				getObjectPermissionId({
					memberType: row.memberType,
					memberId: row.memberId,
				})
			}
			gridId={"8C8A96460A4FFB99"}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ObjectPermission>
					disableMultipleRowSelection
					columns={[
						enumColumn({
							field: "memberType",
							headerName: i18n.t("role_slash_user"),
							enumLabels: getObjectMemberTypeLabels(),
							width: 150,
						}),
						textColumn({
							field: "memberName",
							headerName: i18n.t("name"),
							width: 200,
						}),
						coloredBooleanColumn({
							field: "admin" as any,
							headerName: i18n.t("admin"),
							valueGetter: (_, row) => row.actions.includes("WORKSPACE_ADMIN"),
							width: 150,
							falseColor: "grey",
						})
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("permission"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => (
							<WorkspacePermissionForm
								workspaceId={workspaceId}
								objectPermission={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) =>
						WorkspacePermissionEditApi.deletePermission({
							workspaceId: workspaceId,
							memberType: row.memberType,
							memberId: row.memberId,
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
