import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { MouseEvent } from "react";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ConfiguratorTableTabsApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabsApi.ts";
import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab.ts";
import Typography from "@mui/material/Typography";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { ConfiguratorTableTabForm } from "src/components/views/erp/configurator/tables/revisionContentView/ConfiguratorTableTabForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";

export interface ConfiguratorTableTabMosaicTitleProps {
	tab: ConfiguratorTableTab;
	refreshTabs: () => Promise<unknown>;
}

export const ConfiguratorTableTabMosaicTitle = ({ tab, refreshTabs }: ConfiguratorTableTabMosaicTitleProps) => {
	const [openContextMenu, contextMenuState] = useContextMenu();
	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();

	return (
		<>
			<HorizontalBox
				onContextMenu={openContextMenuWrapped}
				sx={{
					alignItems: "stretch",
					gap: 0.5,
					position: "relative",
				}}
			>
				<Typography variant={"button"} onDoubleClick={editTab}>
					{tab.tabName}
				</Typography>
			</HorizontalBox>
			<AavoContextMenu state={contextMenuState} />
		</>
	);

	function openContextMenuWrapped(e: MouseEvent) {
		openContextMenu({
			mouseEvent: e,
			content: [
				<AsyncMenuButton key={"edit"} icon={faPen} label={i18n.t("edit")} onClick={editTab} />,
				<AsyncMenuButton key={"delete"} icon={faTrash} label={i18n.t("delete")} onClick={deleteTab} />,
			],
		});
	}

	function editTab() {
		openFormOnDialog({
			openDialog,
			title: i18n.t("edit"),
			size: "sm",
			component: ConfiguratorTableTabForm,
			props: {
				configuratorTableRevisionId: tab.configuratorTableRevisionId,
				tab,
			},
			onSubmit: refreshTabs,
			confirmCloseIfEdited: false
		});
	}

	async function deleteTab() {
		const confirmed = await showConfirmDialog({
			title: i18n.t("delete_tab"),
			message: i18n.t("delete_configurator_table_tab_confirmation"),
		});
		if (!confirmed) return;

		await ConfiguratorTableTabsApi.delete_({ configuratorTableTabId: tab.configuratorTableTabId });
		await refreshTabs();
	}
};
