import { FormCommonProps } from "src/components/common/forms/types.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { SalvosProjectApi } from "src/api/generated/tenants/salvos/erp/project/salvosProjectApi.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView.ts";
import { ProjectSchedulingApi } from "src/api/generated/erp/project/api/projectSchedulingApi.ts";
import { useStoredState } from "src/utils/useStoredState.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabels,
	SalvosSubProjectPlannedStartDateProvider_ReferenceType,
} from "src/api/generated/tenants/salvos/erp/project/salvosSubProjectPlannedStartDateProvider.ts";

export interface SalvosSubProjectSchedulingFormProps extends FormCommonProps<void> {
	subProject: SubProjectView;
}

interface FormValues {
	schedulingMethod: SalvosSubProjectPlannedStartDateProvider_ReferenceType;
	advanceDurationInDays: number;
	plannedStartDate: IsoDateString;
}

interface FormInitData {
	advanceDurationsByReferenceTypes: Record<SalvosSubProjectPlannedStartDateProvider_ReferenceType, number>;
}

export const SalvosSubProjectSchedulingForm = ({
	subProject,
	onFormEdited,
	onCompleted,
}: SalvosSubProjectSchedulingFormProps) => {
	const [defaultReferenceType, setDefaultReferenceType] =
		useStoredState<SalvosSubProjectPlannedStartDateProvider_ReferenceType>({
			key: "9BFAC74DEF40229",
			defaultValue: "PLANNED_DELIVERY_DATE",
		});

	return (
		<AsyncForm<FormInitData, FormValues, void>
			fetch={async () => {
				const advanceDurationsByReferenceTypes = await SalvosProjectApi.getSubProjectSchedulingAdvanceDurations(
					{
						projectId: subProject.projectId,
					},
				);
				return {
					advanceDurationsByReferenceTypes,
				};
			}}
			getDefaultValues={({ advanceDurationsByReferenceTypes }) => ({
				schedulingMethod: defaultReferenceType,
				advanceDurationInDays: advanceDurationsByReferenceTypes[defaultReferenceType] ?? 0,
				plannedStartDate: subProject.plannedStartDate,
			})}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			submit={submit}
			render={({ control, setValue, getValues, data: { advanceDurationsByReferenceTypes } }) => (
				<>
					<FormEnumSelectField
						control={control}
						name={"schedulingMethod"}
						label={i18n.t("scheduling_method")}
						options={getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabels()}
						rules={requireRule()}
						disableClearable
						onChange={(value) => {
							setDefaultReferenceType(value);
							const advanceDuration = advanceDurationsByReferenceTypes[value];
							setValue("advanceDurationInDays", advanceDuration);
						}}
					/>
					<FormNumberField
						control={control}
						name={"advanceDurationInDays"}
						label={i18n.t("advance_duration_days")}
						rules={requireRule()}
						type={"integer"}
					/>
					<FormDateField
						control={control}
						name={"plannedStartDate"}
						label={i18n.t("planned_start")}
						rules={requireRule()}
					/>
					<AsyncButton
						label={i18n.t("schedule.verb")}
						icon={faCalendarAlt}
						onClick={async () => {
							const calculatedPlannedStartDate =
								await SalvosProjectApi.calculateSubProjectPlannedStartDate({
									projectId: subProject.projectId,
									subProjectId: subProject.subProjectId,
									reference: getValues("schedulingMethod"),
									advanceDurationInDays: getValues("advanceDurationInDays"),
								});
							setValue("plannedStartDate", calculatedPlannedStartDate);
						}}
					/>
				</>
			)}
		/>
	);

	async function submit({ plannedStartDate }: FormValues) {
		await ProjectSchedulingApi.scheduleSubProject({
			subProjectId: subProject.subProjectId,
			newPlannedStartDate: plannedStartDate,
			newPlannedEndDate: null,
			inheritToDescendants: true,
		});
	}
};
