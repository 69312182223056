import React, { useRef } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { hiddenElementKeepingState, positionAbsoluteFullSize } from "src/styles/sx.ts";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { ProductFamilyVersionConfiguratorSelectionOptions } from "src/api/generated/erp/configurator/management/catalogPart/api/productFamilyVersionConfiguratorSelectionOptions.ts";
import { CatalogPartRevisionConfiguratorSelectionsApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionConfiguratorSelectionsApi.ts";
import {
	CatalogPartRevisionConfiguratorSelectionsView,
	CatalogPartRevisionConfiguratorSelectionsViewProps,
} from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsView.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";

export interface SingleRevisionComponentSelectionsViewProps {
	revision: CatalogPartRevisionView;
	productFamilyVersionId: number;
	productFamilySelectionOptions: ProductFamilyVersionConfiguratorSelectionOptions;
	isActive: boolean;
	onSelectionsChanged: CatalogPartRevisionConfiguratorSelectionsViewProps["onSelectionsChanged"];
}

export const SingleRevisionComponentSelectionsView = React.memo(
	({
		revision,
		productFamilyVersionId,
		productFamilySelectionOptions,
		isActive,
		onSelectionsChanged,
	}: SingleRevisionComponentSelectionsViewProps) => {
		const isInitialized = useRef(false);

		if (!isActive && !isInitialized.current) {
			return null;
		}

		isInitialized.current = true;

		return (
			<VerticalBox
				key={revision.catalogPartRevisionId}
				sx={mergeSx(positionAbsoluteFullSize, !isActive && hiddenElementKeepingState)}
			>
				<AsyncFetchRender
					fetch={() =>
						CatalogPartRevisionConfiguratorSelectionsApi.convertRevisionActiveConfiguratorSelectionsForProductFamilyVersion(
							{
								catalogPartRevisionId: revision.catalogPartRevisionId,
								toProductFamilyVersionId: productFamilyVersionId,
							},
						)
					}
					content={(convertedSelections) => (
						<CatalogPartRevisionConfiguratorSelectionsView
							catalogPartRevision={revision}
							productFamilyVersionId={productFamilyVersionId}
							onDataDirtyStateChanged={() => {}}
							productFamilySelectionOptions={productFamilySelectionOptions}
							currentSelections={convertedSelections}
							onSelectionsChanged={onSelectionsChanged}
							disableCancel
							disableSubmit
						/>
					)}
				/>
			</VerticalBox>
		);
	},
);
