import { LegacyModalViewDefinition, ViewAssociation } from "./types";
import { VersionedViewActionEventList, ViewActionEvent } from "./events";
import { StartUpData } from "src/api/mainApi";
import React from "react";
import { KeyValuePair } from "src/utils/keyValuePair.ts";
import { ModalFrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";
import { Dayjs } from "dayjs";

export const AavoViewsContext = React.createContext<
	| {
			state: AavoViewsContextState;
			dispatch: Dispatch;
	  }
	| undefined
>(undefined);

export const AavoViewsDispatchContext = React.createContext<Dispatch | undefined>(undefined);

type Dispatch = (action: AavoViewsContextAction) => void;

export type AavoViewsContextState = {
	viewIdsOpened: string[];
	frontendViewsOpened: {
		creationTime: Dayjs;
		viewKey: string;
	}[];
	legacyModals: Array<LegacyModalViewState>;
	frontendViewModals: Array<FrontendViewModal>;
	test?: string;
	viewActionEvents: VersionedViewActionEventList;
	dragDropData: any;
};

export interface FrontendViewModal extends ModalFrontendViewConfig<unknown> {
	uuid: string;
	viewParams: KeyValuePair[];
	sourceViewId: string | null;
}

export interface LegacyModalViewState extends Omit<LegacyModalViewDefinition, "isUppermostView"> {
	view: ViewAssociation;
	uuid: string;
	refreshSourceView?: () => void;
}

export type AavoViewsContextAction =
	| {
			type: "addActiveViewId";
			viewId: string;
	  }
	| {
			type: "addActiveFrontendView";
			viewKey: string;
	  }
	| {
			type: "setViewActionEvents";
			events: Array<ViewActionEvent>;
			startUpData: StartUpData;
	  }
	| {
			type: "openLegacyModal";
			modalViewState: Omit<LegacyModalViewState, "uuid">;
	  }
	| {
			type: "closeLegacyModalWithUuid";
			uuid: string;
	  }
	| {
			type: "setLegacyModalWithUuidUppermost";
			uuid: string;
	  }
	| {
			type: "closeLegacyModalsWithId";
			viewId: string;
	  }
	| {
			type: "closeUppermostLegacyModal";
	  }
	| {
			type: "setDragDropData";
			dragDropData: any;
	  }
	| {
			type: "openFrontendModal";
			viewActionEvent: ViewActionEvent;
	  }
	| {
			type: "closeFrontendModalWithUuid";
			uuid: string;
	  };
