import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";
import {
    SalesPartEditApi,
    SalesPartEditApi_InitData,
} from "src/api/generated/erp/sales/basedata/api/salesPartEditApi.ts";
import {SalesPart} from "src/api/generated/erp/db/types/tables/salesPart.ts";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import {requiredNonNegativeFloatRule, requireRule} from "src/components/common/forms/validation.ts";
import {
    ConfigurationPriceCalculationMethodValues,
    getConfigurationPriceCalculationMethodLabel,
} from "src/api/generated/erp/db/types/enums/configurationPriceCalculationMethod.ts";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {
    ObjectAccountingForm,
    ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
    getObjectAccountingCodeIdsFromFormValues,
    getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import {getDefaultVatCode} from "src/components/views/erp/sales/salesUtils.ts";
import {getSalesPartTypeLabel, SalesPartTypeValues} from "src/api/generated/erp/db/types/enums/salesPartType.ts";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {SitePartQueryApi} from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {FetchAsyncOptionParams} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faBoxes} from "@fortawesome/pro-regular-svg-icons";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    SalesPartContentLinesDataGrid
} from "src/components/views/erp/sales/basedata/salesParts/SalesPartContentLinesDataGrid.tsx";

export interface SalesPartFormProps extends FormCommonProps<number> {
	salesPartId: number | null;
	partId: number | null;
}

interface FormValues extends SalesPart, ObjectAccountingFormValues {}

export const SalesPartForm = ({ salesPartId, partId, onCompleted, onFormEdited }: SalesPartFormProps) => {
	const { defaultSiteId } = useGlobalInitData();
	const { openDialog } = useGenericDialog();
	return (
		<AsyncForm<SalesPartEditApi_InitData, FormValues, number>
			columns={3}
			fetch={() =>
				SalesPartEditApi.getInitData({
					salesPartId: salesPartId,
					partId: partId,
				})
			}
			getDefaultValues={({ part, salesPart, accountingDimensions, vatCodeOptions }) => {
				if (salesPart != null)
					return {
						...salesPart,
						...getObjectAccountingFormDefaultValues(accountingDimensions),
					};
				else
					return {
						partId: partId,
						salesPartNo: part?.partNo,
						salesPartDescription: part ? `${part.partDescription_1} ${part.partDescription_2}` : "",
						salesPriceUnitId: part?.partUnitId,
						salesUnitId: part?.partUnitId,
						priceFactor: 1,
						salesFactor: 1,
						configurationPriceCalculationMethod: part?.isConfigurable ? "RULE" : "BOM",
						isDefault: true,
						active: true,
						canBeUsedOnCustomerOrderLine: true,
						canBeUsedOnCustomerOfferLine: true,
						siteId: part?.siteId ?? defaultSiteId,
						vatCodeId: getDefaultVatCode(vatCodeOptions).vatCodeId,
						salesPartType: partId != null ? "WAREHOUSE" : undefined,
						...getObjectAccountingFormDefaultValues(accountingDimensions),
					};
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ data, control, watch, setValue }) => {
				const {
					part,
					salesPart,
					salesPartCategoryOptions,
					salesPriceGroupOptions,
					erpUnitOptions,
					vatCodeOptions,
					siteOptions,
				} = data;
				return (
					<>
						{partId == null && (
							<FormSelectField
								control={control}
								name={"salesPartType"}
								label={i18n.t("type")}
								options={SalesPartTypeValues}
								getOptionKey={(o) => o}
								getOptionLabel={(o) => getSalesPartTypeLabel(o)}
								rules={requireRule()}
								onChange={async (value) => {
									setValue("isDefault", value === "WAREHOUSE");
								}}
							/>
						)}
						{partId == null && (
							<FormSelectField
								control={control}
								name={"siteId"}
								label={i18n.t("site")}
								options={siteOptions}
								getOptionKey={(site) => site.siteId}
								getOptionLabel={(site) => site.siteName}
								rules={requireRule()}
								disabled={salesPartId != undefined}
								disableClearable
							/>
						)}
						{partId == null && watch("salesPartType") === "WAREHOUSE" && (
							<FormAsyncSelectField
								control={control}
								name={"partId"}
								label={i18n.t("part")}
								fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
									SitePartQueryApi.getPartSelectionOptions({
										currentPartId: currentSelection,
										searchQuery: searchQuery,
										siteId: watch("siteId"),
									})
								}
								getOptionKey={(o) => o.partId}
								getOptionLabel={(o) => o.partLongName}
								rules={requireRule()}
							/>
						)}
						<FormTextField
							control={control}
							name="salesPartNo"
							label={i18n.t("sales_part_no")}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name="salesPartDescription"
							label={i18n.t("description")}
							rules={requireRule()}
						/>
						<FormSelectField
							control={control}
							name={"salesPartCategoryId"}
							label={i18n.t("sales_part_category")}
							options={salesPartCategoryOptions}
							getOptionKey={(o) => o.salesPartCategoryId}
							getOptionLabel={(o) => o.name ?? ""}
						/>
						<FormSelectField
							control={control}
							name={"salesPartPriceGroupId"}
							label={i18n.t("sales_price_group")}
							options={salesPriceGroupOptions}
							getOptionKey={(o) => o.salesPartPriceGroupId}
							getOptionLabel={(o) => o.name ?? ""}
						/>
						<FormTextField
							control={control}
							name="salesPrice"
							label={i18n.t("sales_price")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<FormSelectField
							control={control}
							name={"salesPriceUnitId"}
							label={i18n.t("sales_price_unit")}
							options={erpUnitOptions}
							getOptionKey={(o) => o.unitId}
							getOptionLabel={(o) => o.unitName}
							rules={requireRule()}
						/>
						<FormSelectField
							control={control}
							name={"salesUnitId"}
							label={i18n.t("sales_unit")}
							options={erpUnitOptions}
							getOptionKey={(o) => o.unitId}
							getOptionLabel={(o) => o.unitName}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name="priceFactor"
							label={i18n.t("price_factor")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<FormTextField
							control={control}
							name="salesFactor"
							label={i18n.t("sales_factor")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<FormSelectField
							control={control}
							name={"vatCodeId"}
							label={i18n.t("vat_code")}
							options={vatCodeOptions}
							getOptionKey={(o) => o.vatCodeId}
							getOptionLabel={(o) => o.vatCodeName}
							rules={requireRule()}
						/>
						{salesPart?.salesPartType === "PACKAGE_" && (
							<AsyncButton
								icon={faBoxes}
								variant={"outlined"}
								label={i18n.t("package_lines")}
								onClick={async () => {
									openDialog({
										title: i18n.t("package_lines"),
										size: "lg",
										content: (
											<SalesPartContentLinesDataGrid parentSalesPartId={salesPart.salesPartId} />
										),
									});
								}}
							/>
						)}
						<ObjectAccountingForm control={control} />
						{part?.isConfigurable && (
							<FormSelectField
								control={control}
								name={"configurationPriceCalculationMethod"}
								label={i18n.t("configuration_price_calculation_method")}
								options={ConfigurationPriceCalculationMethodValues}
								getOptionKey={(o) => o}
								getOptionLabel={(o) => getConfigurationPriceCalculationMethodLabel(o)}
							/>
						)}
						{watch("salesPartType") === "WAREHOUSE" && (
							<FormCheckbox
								control={control}
								name={"isDefault"}
								label={i18n.t("part_default_sales_part")}
								disabled={salesPart != null}
								startNewGridRow
							/>
						)}
						<FormCheckbox control={control} name={"active"} label={i18n.t("active")} startNewGridRow />
						<FormCheckbox
							control={control}
							name={"canBeUsedOnCustomerOrderLine"}
							label={i18n.t("can_be_used_on_customer_order_line")}
							startNewGridRow
						/>
						<FormCheckbox
							control={control}
							name={"canBeUsedOnCustomerOfferLine"}
							label={i18n.t("can_be_used_on_customer_offer_line")}
							startNewGridRow
						/>
						{watch("canBeUsedOnCustomerOfferLine") && !watch("canBeUsedOnCustomerOrderLine") && (
							<FormCheckbox
								control={control}
								name={"isAdjustment"}
								label={i18n.t("is_adjustment_line")}
								helperText={i18n.t("is_adjustment_line_explanation")}
								startNewGridRow
							/>
						)}
					</>
				);
			}}
			submit={(values: FormValues) => {
				const { accountingDimensionValues, ...salesPart } = values;
				const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);

				if (values.salesPartId == null) {
					return SalesPartEditApi.insert({
						salesPart,
						accountingCodeIds,
					});
				} else {
					return SalesPartEditApi.update({
						salesPart,
						accountingCodeIds,
					});
				}
			}}
		/>
	);
};
