import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {SitePartFormApi, SitePartFormApi_InitData} from "src/api/generated/erp/parts/sitePart/api/sitePartFormApi.ts";
import {Part} from "src/api/generated/erp/db/types/tables/part.ts";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import {integerRule, requireRule} from "src/components/common/forms/validation.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {getPartTypeLabels} from "src/api/generated/erp/db/types/enums/partType.ts";
import {FormEnumSelectField} from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {getPartValuationMethodLabels} from "src/api/generated/erp/db/types/enums/partValuationMethod.ts";
import {FormAsyncUserSelectField} from "src/components/views/users/FormAsyncUserSelectField.tsx";
import {Divider} from "@mui/material";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {
    AcquisitionMethod,
    AcquisitionMethodValues,
    getAcquisitionMethodLabel,
} from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import {dropProps} from "src/utils/dropProps.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {PartBatchSizeCalculationForm} from "src/components/views/erp/parts/siteParts/PartBatchSizeCalculationForm.tsx";
import {faCalculator} from "@fortawesome/pro-regular-svg-icons";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import {PartView} from "src/api/generated/erp/db/types/tables/partView.ts";
import {
    openPartReorderPointCalculationForm
} from "src/components/views/erp/parts/siteParts/PartReorderPointCalculationForm.utils.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {TenantConfig} from "src/tenantCustomizations/TenantCustomizations.ts";

export interface SitePartFormProps extends FormCommonProps<number> {
	partId: number;
}

interface FormValues extends Part {
	numberSequenceId: number;
}

export const SitePartForm = (props: SitePartFormProps) => {
	const { partId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm<SitePartFormApi_InitData, FormValues, number>
			columns={3}
			fetch={() => SitePartFormApi.getInitData({ partId: partId })}
			getDefaultValues={getDefaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <FormContent {...props} {...params} />}
			submit={submit}
		/>
	);

	function getDefaultValues({ part }: SitePartFormApi_InitData) {
		return {
			...part,
			automaticReorder: part.automaticReorder && getReorderPointDisabledReason(part) == null,
		};
	}

	async function submit(values: FormValues) {
		return await SitePartFormApi.update({
			part: values,
		});
	}
};

const FormContent = ({
	data: { part: savedPart, site, erpUnitOptions, categoryOptions, productionLineOptions },
	control,
	watch,
	setValue,
}: SitePartFormProps & AsyncFormContentParams<SitePartFormApi_InitData, FormValues>) => {
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();
	const acquisitionMethodOptions = getPartAcquisitionMethodOptions(tenantConfig);

	const partType = watch("partType");

	const disabledBecauseEditableOnlyOnCatalogPart = i18n.t("this_value_is_editable_only_on_catalog_part");

	return (
		<>
			<FormTextField control={control} name={"partNo"} label={i18n.t("part_number_shortened")} disabled />
			<FormTextField
				control={control}
				name={"partDescription_1"}
				label={i18n.t("description_1")}
				sx={{
					gridColumnStart: 1,
				}}
				rules={requireRule()}
				disabled={disabledBecauseEditableOnlyOnCatalogPart}
			/>
			<FormTextField
				control={control}
				name={"partDescription_2"}
				label={i18n.t("description_2")}
				disabled={disabledBecauseEditableOnlyOnCatalogPart}
			/>
			<FormTextField
				control={control}
				name={"manufacturerPartNumber"}
				label={i18n.t("manufacturer_code")}
				disabled={disabledBecauseEditableOnlyOnCatalogPart}
			/>
			<FormTextField
				control={control}
				name={"additionalNumber"}
				label={i18n.t("additional_code")}
				disabled={disabledBecauseEditableOnlyOnCatalogPart}
			/>
			<FormSelectField
				control={control}
				name={"categoryId"}
				label={i18n.t("category")}
				options={categoryOptions}
				getOptionKey={(option) => option.partCategoryId}
				getOptionLabel={(option) => option.categoryName}
				rules={requireRule()}
				disabled={disabledBecauseEditableOnlyOnCatalogPart}
			/>
			{
				<FormSelectField
					control={control}
					name={"partUnitId"}
					label={i18n.t("base_unit")}
					options={erpUnitOptions}
					getOptionKey={(option) => option.unitId}
					getOptionLabel={(option) => option.unitName}
					rules={requireRule()}
					disabled={disabledBecauseEditableOnlyOnCatalogPart}
				/>
			}
			<FormEnumSelectField
				control={control}
				name={"partType"}
				label={i18n.t("type")}
				options={getPartTypeLabels()}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"acquisitionMethod"}
				label={i18n.t("acquisition_method")}
				options={acquisitionMethodOptions}
				getOptionKey={(option) => option}
				getOptionLabel={(option) => getAcquisitionMethodLabel(option)}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"weight"}
				label={i18n.t("weight")}
				rules={requireRule()}
				disabled={disabledBecauseEditableOnlyOnCatalogPart}
			/>
			<FormNumberField control={control} name={"standardCost"} label={i18n.t("cost")} rules={requireRule()} />
			<FormEnumSelectField
				options={
					partType === "MANUFACTURE" ?
						dropProps(getPartValuationMethodLabels(), ["LAST_PURCHASE_PRICE", "WEIGHTED_AVERAGE_PRICE"])
					:	getPartValuationMethodLabels()
				}
				control={control}
				name={"valuationMethod"}
				label={i18n.t("valuation_method")}
			/>
			<FormNumberField
				control={control}
				name={"lastPurchasePrice"}
				label={i18n.t("last_purchase_price")}
				disabled
			/>
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"warehouseTransferFactor"}
				label={i18n.t("warehouse_transfer_factor")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"warehouseTransferUnitId"}
				label={i18n.t("warehouse_transfer_unit")}
				options={erpUnitOptions}
				getOptionKey={(option) => option.unitId}
				getOptionLabel={(option) => option.unitName}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"estimatedLeadtime"}
				label={i18n.t("lead_time")}
				rules={integerRule()}
			/>
			<FormNumberField control={control} name={"batchSize"} label={i18n.t("batch_size")} type="integer" />
			<AavoButton
				icon={faCalculator}
				label={i18n.t("calculate_batch_size")}
				tooltip={i18n.t("calculate_batch_size_with_wilson_formula")}
				onClick={openBatchSizeCalculationForm}
			/>
			<FormNumberField
				control={control}
				name={"reorderPoint"}
				label={i18n.t("reorder_point")}
				type="integer"
				startNewGridRow
				rules={{
					validate: (value, values) => {
						if (values.automaticReorder && value == null) {
							return i18n.t("reorder_point_is_required_when_automatic_reorder_is_enabled");
						}
					},
				}}
			/>
			<AavoButton
				icon={faCalculator}
				label={i18n.t("calculate_reorder_point")}
				onClick={openReorderPointCalculationForm}
			/>
			<FormCheckbox
				control={control}
				name={"automaticReorder"}
				label={i18n.t("automatic_reorder_on_reorder_point")}
				startNewGridRow
				disabled={getReorderPointDisabledReason(savedPart)}
			/>
			{partType === "MANUFACTURE" && (
				<>
					<Divider
						sx={{
							gridColumn: "1/-1",
							color: "primary.main",
						}}
					>
						{i18n.t("production")}
					</Divider>
					<FormNumberField control={control} name={"capacityQuantity"} label={i18n.t("capacity_quantity")} />
					<FormNumberField
						control={control}
						name={"estimatedProductionTimeHours"}
						label={i18n.t("production_time_hours")}
					/>
					<FormSelectField
						control={control}
						name={"defaultProductionLineId"}
						label={i18n.t("default_production_line")}
						options={productionLineOptions}
						getOptionKey={(option) => option.productionLineId}
						getOptionLabel={(option) => option.productionLineName}
					/>
					<FormCheckbox control={control} name={"automaticRelease"} label={i18n.t("automatic_release")} />
					<FormCheckbox control={control} name={"automaticClose"} label={i18n.t("automatic_close")} />
				</>
			)}
		</>
	);

	function openBatchSizeCalculationForm() {
		const partValues = watch();

		openDialog(({ closeDialog }) => ({
			title: i18n.t("calculate_batch_size"),
			size: "sm",
			content: (
				<PartBatchSizeCalculationForm
					site={site}
					part={partValues}
					onCompleted={async (result) => {
						if (result.type === "success") {
							const calcFormValues = result.value;
							setValue("batchSize", calcFormValues.batchSize);
							setValue("batchSizeCalculationParams", calcFormValues.batchSizeCalculationParams);
						}
						await closeDialog();
					}}
				/>
			),
		}));
	}

	function openReorderPointCalculationForm() {
		openPartReorderPointCalculationForm({
			openDialog,
			props: {
				calculationParams: watch("reorderPointCalculationParams") ?? undefined,
				partId: savedPart.partId,
				site: site,
			},
			onSubmit: async (calcFormValues) => {
				setValue("reorderPoint", calcFormValues.reorderPoint);
				setValue("reorderPointCalculationParams", calcFormValues.calculationParams);
			},
		});
	}
};

function getPartAcquisitionMethodOptions(tenantConfig: TenantConfig) {
	let acquisitionMethodOptions: AcquisitionMethod[] = [...AcquisitionMethodValues];
	if (!tenantConfig.erp.purchaseEnabled)
		acquisitionMethodOptions = acquisitionMethodOptions.filter(
			(method) => method !== "PURCHASE_DIRECT" && method !== "PURCHASE_TRANSIT",
		);
	if (!tenantConfig.erp.manufacturingEnabled)
		acquisitionMethodOptions = acquisitionMethodOptions.filter((method) => method !== "MANUFACTURE");
	return acquisitionMethodOptions;
}

function getReorderPointDisabledReason(part: PartView): string | undefined {
	if (part.isConfigurable) {
		return i18n.t("reorder_point_is_disabled_for_configurable_parts");
	}
}
