import { KnownApiErrorViewProps } from "src/components/common/errors/KnownApiErrorView.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { KnownApiError_ConfiguratorError } from "src/api/generated/errors/knownApiError.ts";
import { faEnvelope, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import Typography from "@mui/material/Typography";
import { ConfiguratorLogApi } from "src/api/generated/erp/configurator/api/configuratorLogApi.ts";
import { useAavoToast } from "src/components/common/toast/AavoToastContext.tsx";

export const ConfiguratorErrorView = ({
	error,
	reload,
	renderOutdatedFrontendVersionMessage,
}: KnownApiErrorViewProps<KnownApiError_ConfiguratorError>) => {
	return (
		<VerticalBox alignItems={"center"}>
			<Typography>{i18n.t("error_happened_in_configurator")}</Typography>
			<br />
			{error.isTestConfigurator ? error.logMessage : <ContactProductManagerContent error={error} />}
			{reload && (
				<AsyncButton
					label={i18n.t("reload")}
					icon={faRefresh}
					onClick={reload}
					variant={"outlined"}
					sx={{
						marginTop: 1,
					}}
				/>
			)}
			{renderOutdatedFrontendVersionMessage()}
		</VerticalBox>
	);
};

const ContactProductManagerContent = ({ error }: { error: KnownApiError_ConfiguratorError }) => {
	const { showToast } = useAavoToast();

	return (
		<>
			<Typography>{i18n.t("configurator_error_contact_product_management_for_help_text")}</Typography>
			<Typography>{error.base64LogPath}</Typography>
			<br />
			<Typography>{i18n.t("configurator_error_you_can_also_send_error_message_by_email_text")}</Typography>
			<AsyncButton
				label={i18n.t("send_by_email")}
				icon={faEnvelope}
				variant={"outlined"}
				sx={{
					marginTop: 1,
				}}
				onClick={async () => {
					const response = await ConfiguratorLogApi.sendConfiguratorErrorLogToPartResponsiblePerson({
						logPath: error.logPath,
						configuratorType: error.configuratorType,
					});
					showToast({
						message: i18n.t("configurator_error_sent_to_responsible_person", {
							responsiblePersonName: response.partResponsiblePersonName,
						}),
					});
				}}
			/>
		</>
	);
};
