import {CSSProperties, useEffect, useMemo, useRef} from "react";
import {ErrorView} from "src/components/common/errors/ErrorView.tsx";
import {WopiClientConfig} from "src/api/generated/documents/wopi/wopiClientConfig.ts";
import {RefreshableElementProps, setRefreshRefValue} from "src/utils/useRefreshRef.ts";
import {logError} from "src/errorHandling/errorLogging.ts";

interface WopiFileViewProps extends RefreshableElementProps {
    clientConfig: WopiClientConfig;
}

/**
 * Documentation for implementation
 * https://learn.microsoft.com/en-us/microsoft-365/cloud-storage-partner-program/online/hostpage
 */
export const WopiFileView = ({clientConfig, refreshRef}: WopiFileViewProps) => {
    const {accessToken, accessTokenTtl, actionUrl, error, ownerId, userId} = clientConfig;

    const formRef = useRef<HTMLFormElement>(null);

    const submitForm = () => {
        if (formRef.current != null) {
            formRef.current.submit();
        }
    };

    useEffect(() => {
        submitForm();
    }, []);

    setRefreshRefValue(refreshRef, submitForm);

    const loggedError = useMemo(() => (error != null ? logError(error) : undefined), [error]);
    if (loggedError !== undefined) return <ErrorView error={loggedError}/>;

    return (
        <>
            <form
                ref={formRef}
                className={"office_form"}
                name={"office_form"}
                target={"office_frame"}
                method={"post"}
                action={actionUrl}
            >
                <input name={"access_token"} type={"hidden"} value={accessToken}/>
                <input name={"access_token_ttl"} type={"hidden"} value={accessTokenTtl}/>
                <input name={"user_id"} type={"hidden"} value={userId}/>
                <input name={"owner_id"} type={"hidden"} value={ownerId}/>
            </form>
            <span>
				<iframe
                    name={"office_frame"}
                    className={"office_frame"}
                    title={"Office Frame"}
                    allowFullScreen={true}
                    style={iframeStyles}
                    sandbox={
                        "allow-scripts allow-same-origin allow-forms allow-popups " +
                        "allow-top-navigation allow-popups-to-escape-sandbox"
                    }
                ></iframe>
			</span>
        </>
    );
};

const iframeStyles: CSSProperties = {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    border: "none",
    display: "block",
    position: "absolute",
};
