// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesPriceListViewActiveFromReferenceDateTypeValues = [
	"LINE_CREATION_DATE",
	"PLANNED_DELIVERY_DATE",
] as const;

export type SalesPriceListViewActiveFromReferenceDateType =
	(typeof SalesPriceListViewActiveFromReferenceDateTypeValues)[number];

export const getSalesPriceListViewActiveFromReferenceDateTypeLabels = () => ({
	LINE_CREATION_DATE: t("line_creation_date"),
	PLANNED_DELIVERY_DATE: t("planned_delivery_date"),
});

export const getSalesPriceListViewActiveFromReferenceDateTypeLabel = (
	value: SalesPriceListViewActiveFromReferenceDateType,
): string => {
	return getSalesPriceListViewActiveFromReferenceDateTypeLabels()[value];
};
