import {ReclamationCause} from "src/api/generated/erp/db/types/tables/reclamationCause.ts";
import {coloredBooleanColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ReclamationCauseDataGridApi} from "src/api/generated/erp/reclamations/api/reclamationCauseDataGridApi";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faBan, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";

export const ReclamationCausesDataGrid = () => {
	const { dataGridProps, refreshData } = useClientSideDataGridModel({
		gridId: "F058C3B137B006E6",
		fetchData: ReclamationCauseDataGridApi.getAll,
		getRowId: (row) => row.reclamationCauseId,
		initialParams: {},
	});

	return (
		<ControlledAsyncCrudDataGrid<ReclamationCause>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					editable: true,
					validate: "required",
					minWidth: 300,
				}),
				coloredBooleanColumn({
					field: "isActive",
					headerName: i18n.t("enabled"),
					falseColor: "red",
				}),
				integerColumn({
					field: "reclamationCauseId",
					headerName: i18n.t("id"),
				}),
			]}
			add={{
				type: "enabled",
				action: async () => {
					return {
						name: "",
					};
				},
			}}
			save={{
				type: "enabled",
				action: async (params) => {
					return await ReclamationCauseDataGridApi.save({
						reclamationCauses: params.items,
					});
				},
			}}
			remove={{
				type: "enabled",
				action: async (params) => {
					await ReclamationCauseDataGridApi.delete_({
						reclamationCauses: params.items,
					});
				},
			}}
			rowContextMenuComponents={({ onlySingleRowSelected, row }) => [
				onlySingleRowSelected && row.isActive && (
					<AsyncMenuButton
						key={"disable"}
						label={i18n.t("disable")}
						icon={faBan}
						onClick={async () => {
							await ReclamationCauseDataGridApi.setIsActive({
								reclamationCauseId: row.reclamationCauseId,
								isActive: false,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && !row.isActive && (
					<AsyncMenuButton
						key={"enable"}
						label={i18n.t("enable")}
						icon={faCheck}
						onClick={async () => {
							await ReclamationCauseDataGridApi.setIsActive({
								reclamationCauseId: row.reclamationCauseId,
								isActive: true,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
