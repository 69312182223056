import {
	getPurchaseOrderStateLabel,
	getPurchaseOrderStateLabels,
	PurchaseOrderState,
	PurchaseOrderStateValues,
} from "src/api/generated/erp/db/types/enums/purchaseOrderState";
import { PurchaseOrdersDataGridApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrdersDataGridApi";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { PurchaseOrderView } from "src/api/generated/erp/db/types/tables/purchaseOrderView";
import {
	dateColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MultiSelectField } from "src/components/common/inputFields/MultiSelectField.tsx";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { getPurchaseOrderSourceTypeLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderSourceType.ts";
import { getPurchaseOrderTypeLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderType.ts";
import { getCurrencyLabels } from "src/api/generated/erp/db/types/enums/currency.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faBan, faCheckSquare, faEnvelope, faPrint, faShare } from "@fortawesome/pro-regular-svg-icons";
import { openLegacyDocumentSendingView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { PurchaseOrderActionApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderActionApi.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { PurchaseOrderForm } from "src/components/views/erp/purchase/purchaseOrders/PurchaseOrderForm.tsx";
import { PurchaseOrderEditApi } from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrderEditApi.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface PurchaseOrdersDataGridProps {
	onlyPurchaseOrderId?: number;
	onSelectionChanged?: (selectedRow: PurchaseOrderView | undefined) => void;
	purchaseOrderLinesRefreshRef?: RefreshableElementRef;
}

export const PurchaseOrdersDataGrid = ({
	onlyPurchaseOrderId,
	onSelectionChanged,
	purchaseOrderLinesRefreshRef,
}: PurchaseOrdersDataGridProps) => {
	const openLegacyView = useOpenLegacyView();
	const showConfirmDialog = useConfirmDialog();

	const { dataGridProps, refreshData, currentParams, onlySelectedRow } = useServerSideDataGridModel({
		fetchData: (params) =>
			PurchaseOrdersDataGridApi.getData({
				onlyPurchaseOrderId: onlyPurchaseOrderId,
				...params,
			}),
		initialParams: {
			searchQuery: "",
			stateFilter: Array<PurchaseOrderState>(),
			responsiblePersonId: genericNullableValue<number>(),
			cancelledOrders: false,
			receivedOrders: false,
			onlyDefaultSite: true,
		},
		getRowId: (row) => row.purchaseOrderId,
		gridId: "1ACD1E9E2F587563",
		onSelectionChanged: (selectedRows) => onSelectionChanged?.(selectedRows[0]),
		selectFirstRowOnLoad: onlyPurchaseOrderId != null,
	});

	return (
		<ControlledAsyncCrudDataGrid<PurchaseOrderView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "purchaseOrderId",
					headerName: i18n.t("number_shortened"),
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
				}),
				textColumn({
					field: "supplierName",
					headerName: i18n.t("supplier"),
					width: 150,
				}),
				enumColumn({
					field: "purchaseOrderState",
					headerName: i18n.t("state"),
					enumLabels: getPurchaseOrderStateLabels(),
					width: 150,
				}),
				dateColumn({
					field: "plannedArrivalDate",
					headerName: i18n.t("planned_arrival_date"),
					width: 150,
				}),
				enumColumn({
					field: "orderSource",
					headerName: i18n.t("source"),
					enumLabels: getPurchaseOrderSourceTypeLabels(),
					width: 150,
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 150,
				}),
				textColumn({
					field: "supplierContact",
					headerName: i18n.t("contact_person"),
				}),
				textColumn({
					field: "supplierContactPhone",
					headerName: i18n.t("phone"),
				}),
				textColumn({
					field: "deliveryMethodCode",
					headerName: i18n.t("delivery_method"),
				}),
				textColumn({
					field: "deliveryTermsCode",
					headerName: i18n.t("delivery_terms"),
				}),
				dateColumn({
					field: "releasedDate",
					headerName: i18n.t("released_at"),
				}),
				dateColumn({
					field: "printedDate",
					headerName: i18n.t("printed_at"),
				}),
				dateColumn({
					field: "confirmedDate",
					headerName: i18n.t("confirmed_at"),
				}),
				textColumn({
					field: "responsiblePersonName",
					headerName: i18n.t("responsible_person"),
				}),
				textColumn({
					field: "deliveryName",
					headerName: i18n.t("recipient"),
				}),
				textColumn({
					field: "deliveryAddress_1",
					headerName: i18n.t("address_1"),
				}),
				textColumn({
					field: "deliveryPostalCode",
					headerName: i18n.t("postal_code"),
				}),
				textColumn({
					field: "deliveryCity",
					headerName: i18n.t("city"),
				}),
				enumColumn({
					field: "currency",
					headerName: i18n.t("currency"),
					enumLabels: getCurrencyLabels(),
				}),
				enumColumn({
					field: "purchaseOrderType",
					headerName: i18n.t("type"),
					enumLabels: getPurchaseOrderTypeLabels(),
				}),
				textColumn({
					field: "paymentTermId",
					headerName: i18n.t("payment_term"),
				}),
				integerColumn({
					field: "orderSourceId",
					headerName: i18n.t("source_id"),
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("PURCHASE_ORDER", onlySelectedRow?.purchaseOrderId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(searchQuery) => refreshData({ searchQuery })}
					/>
					<MultiSelectField
						label={i18n.t("state")}
						options={PurchaseOrderStateValues}
						getOptionKey={(option) => option}
						getOptionLabel={(option) => getPurchaseOrderStateLabel(option)}
						onChange={(stateFilter) => refreshData({ stateFilter })}
					/>
					<AsyncSelectField<AppUser, number>
						label={i18n.t("responsible_person")}
						fetchOptions={({ searchQuery, currentSelection }) =>
							PurchaseOrdersDataGridApi.getResponsiblePersonFilterOptions({
								currentSelection: currentSelection,
								userNameSearchQuery: searchQuery,
							})
						}
						getOptionKey={(option) => option.id}
						getOptionLabel={(option) => option.name}
						onChange={(value) =>
							refreshData({
								responsiblePersonId: value?.id ?? null,
							})
						}
					/>
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"onlyDefaultSite"}
					label={i18n.t("only_default_site")}
					onChange={(value) => refreshData({ onlyDefaultSite: value })}
					checked={currentParams.onlyDefaultSite}
				/>,
				<MenuCheckbox
					key={"showReceived"}
					label={i18n.t("show_received")}
					onChange={(value) => refreshData({ receivedOrders: value })}
					checked={currentParams.receivedOrders}
				/>,
				<MenuCheckbox
					key={"showCancelled"}
					label={i18n.t("show_cancelled")}
					onChange={(value) => refreshData({ cancelledOrders: value })}
					checked={currentParams.cancelledOrders}
				/>,
			]}
			rowContextMenuComponents={({ onlySingleRowSelected, row }) => [
				onlySingleRowSelected && row.purchaseOrderState === "INITIAL" && (
					<AsyncMenuButton
						key={"release"}
						label={i18n.t("release")}
						icon={faShare}
						onClick={async () => {
							const confirmed = await showConfirmDialog({
								message: i18n.t("confirm_release_purchase_order"),
							});
							if (!confirmed) return;

							await PurchaseOrderActionApi.releasePurchaseOrder({
								purchaseOrderId: row.purchaseOrderId,
							});
							await Promise.all([refreshData(), purchaseOrderLinesRefreshRef?.refresh()]);
						}}
					/>
				),
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"print"}
						label={i18n.t("print")}
						icon={faPrint}
						onClick={async () => {
							const fileHandle = await PurchaseOrderActionApi.printPurchaseOrder({
								purchaseOrderId: row.purchaseOrderId,
							});
							downloadFile(fileHandle);
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && row.purchaseOrderState === "RELEASED" && (
					<AsyncMenuButton
						key={"confirm"}
						label={i18n.t("confirm")}
						icon={faCheckSquare}
						onClick={async () => {
							const confirmed = await showConfirmDialog({
								message: i18n.t("confirm_confirm_purchase_order"),
							});
							if (!confirmed) return;

							await PurchaseOrderActionApi.confirmPurchaseOrder({
								purchaseOrderId: row.purchaseOrderId,
							});
							await Promise.all([refreshData(), purchaseOrderLinesRefreshRef?.refresh()]);
						}}
					/>
				),
				onlySingleRowSelected && row.purchaseOrderState !== "CANCELLED" && (
					<AsyncMenuButton
						key={"cancel"}
						label={i18n.t("cancel")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await showConfirmDialog({
								message: i18n.t("confirm_cancel_purchase_order"),
								cancelButtonText: i18n.t("no"),
							});
							if (!confirmed) return;

							await PurchaseOrderActionApi.cancelPurchaseOrder({
								purchaseOrderId: row.purchaseOrderId,
							});
							await Promise.all([refreshData(), purchaseOrderLinesRefreshRef?.refresh()]);
						}}
					/>
				),
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"sendDocuments"}
						label={i18n.t("send_documents")}
						icon={faEnvelope}
						onClick={async () => {
							await openLegacyDocumentSendingView({
								openLegacyView: openLegacyView,
								objectRef: {
									objectType: "PURCHASE_ORDER",
									objectId: row.purchaseOrderId,
								},
							});
						}}
					/>
				),
				onlySingleRowSelected && (
					<OpenObjectChangeLogButton
						key={"changeLog"}
						objectRef={{
							objectType: "PURCHASE_ORDER",
							objectId: row.purchaseOrderId,
						}}
					/>
				),
			]}
			form={{
				editEnabled: true,
				addRowEnabled: true,
				dialogTitle: i18n.t("purchase_order"),
				dialogSize: "xl",
				component: ({ row, ...other }) => (
					<PurchaseOrderForm purchaseOrderId={row?.purchaseOrderId} {...other} />
				),
			}}
			remove={{
				type: "enabled",
				action: ({ items }) =>
					PurchaseOrderEditApi.delete_({
						purchaseOrderIds: items.map((row) => row.purchaseOrderId),
					}),
			}}
			{...dataGridProps}
		/>
	);
};
