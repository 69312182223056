import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import {
	dateColumn,
	enumColumn,
	floatColumn,
	integerColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { PartAcquisitionsView } from "src/api/generated/erp/db/types/tables/partAcquisitionsView.ts";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker.tsx";
import { OpenPurchaseOrderButton } from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { OpenPurchaseRequestButton } from "src/components/views/erp/utilComponents/OpenPurchaseRequestButton.tsx";
import { dayJsToDateIsoStringNullable } from "src/utils/dayjsUtils.ts";
import { Dayjs } from "dayjs";
import { SitePartAcquisitionsApi } from "src/api/generated/erp/parts/sitePart/api/sitePartAcquisitionsApi.ts";
import { PartView } from "src/api/generated/erp/db/types/tables/partView.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faDownFromLine } from "@fortawesome/pro-solid-svg-icons";
import { SitePartReorderEventsDataGrid } from "src/components/views/erp/parts/siteParts/SitePartReorderEventsDataGrid.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";

export interface SitePartAcquisitionsDataGridProps {
	part: PartView;
}

export const SitePartAcquisitionsDataGrid = ({ part }: SitePartAcquisitionsDataGridProps) => {
	const { dataGridProps, refreshData } = useServerSideDataGridModel({
		fetchData: async ({ beforeDate, ...other }) =>
			SitePartAcquisitionsApi.getPartAcquisitions({
				partId: part.partId,
				beforeDate: dayJsToDateIsoStringNullable(beforeDate),
				...other,
			}),
		initialParams: {
			beforeDate: genericNullableValue<Dayjs>(),
		},
		getRowId: (row) => `${row.acquisitionType}_${row.uniqueId}`,
		gridId: "23640ECD4E7B14D2",
	});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncDataGrid<PartAcquisitionsView>
			columns={[
				enumColumn({
					field: "acquisitionType",
					headerName: i18n.t("acquisition_type"),
					enumLabels: {
						purchase_order: i18n.t("purchase_order"),
						purchase_request: i18n.t("purchase_request"),
						shop_order: i18n.t("shop_order"),
					},
					width: 150,
				}),
				integerColumn({
					field: "id",
					headerName: i18n.t("number_shortened"),
				}),
				floatColumn({
					field: "quantity",
					headerName: i18n.t("quantity"),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: {
						initial: i18n.t("initial"),
						released: i18n.t("released"),
						confirmed: i18n.t("confirmed"),
						partially_received: i18n.t("partially_received"),
						received: i18n.t("received"),
						cancelled: i18n.t("cancelled"),
						ready: i18n.t("completed"),
						started: i18n.t("started"),
						planned: i18n.t("planned"),
					},
				}),
				dateColumn({
					field: "plannedDate",
					headerName: i18n.t("planned_acquisition_date"),
					width: 150,
				}),
			]}
			actionBarComponents={
				<>
					<AavoDatePicker
						label={i18n.t("before_date")}
						onChange={async (date) => {
							await refreshData({ beforeDate: date });
						}}
						sx={{
							minWidth: 200,
						}}
					/>
					{part.reorderPoint != null && (
						<AavoButton
							label={i18n.t("reorder_point_events")}
							icon={faDownFromLine}
							variant={"outlined"}
							onClick={() => {
								openDialog(() => ({
									title: i18n.t("reorder_point_events"),
									size: "xl",
									content: (
										<SitePartReorderEventsDataGrid sitePartId={part.partId} />
									),
								}));
							}}
						/>
					)}
				</>
			}
			rowContextMenuComponents={({ row }) => [
				row.purchaseOrderId != null && (
					<OpenPurchaseOrderButton
						key={"openPurchaseOrder"}
						purchaseOrderId={row.purchaseOrderId}
					/>
				),
				row.shopOrderId != null && (
					<OpenShopOrderButton key={"openShopOrder"} shopOrderId={row.shopOrderId} />
				),
				row.purchaseRequestId != null && (
					<OpenPurchaseRequestButton
						key={"openPurchaseRequest"}
						purchaseRequestId={row.purchaseRequestId}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
