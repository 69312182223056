import { ConfiguratorTableTabContentQueryApi_TabContent } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabContentQueryApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";
import { ConfiguratorTableTabContentEditApi } from "src/api/generated/erp/configurator/tables/api/configuratorTableTabContentEditApi.ts";
import { ConfiguratorTableTab } from "src/api/generated/erp/db/types/tables/configuratorTableTab.ts";
import i18n from "i18next";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/MessageDialogContext.tsx";
import { findDuplicateValues } from "src/utils/arrayUtils.ts";
import _ from "underscore";
import {
	ConfiguratorTableDataGridRow,
	ConfiguratorTableDataGridState,
	configuratorTableDataGridStateReducer,
} from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/configuratorTableDataGridState.ts";
import { useReducerWithHistory } from "src/utils/useReducerWithHistory.ts";
import {
	ConfiguratorTableTabDataGrid
} from "src/components/views/erp/configurator/tables/revisionContentView/tabDataGrid/ConfiguratorTableTabDataGrid.tsx";

export interface ConfiguratorTableTabDataGridStatefulProps {
	tab: ConfiguratorTableTab;
	tabContent: ConfiguratorTableTabContentQueryApi_TabContent;
	refresh: () => Promise<ConfiguratorTableTabContentQueryApi_TabContent>;
}

export const ConfiguratorTableTabDataGridStateful = ({
	tab,
	tabContent,
	refresh: refreshProp,
}: ConfiguratorTableTabDataGridStatefulProps) => {
	const showConfirmDialog = useConfirmDialog();
	const showMessageDialog = useMessageDialog();

	const [state, dispatch, { undo: undoLastChange, redo: redoLastChange }] = useReducerWithHistory(
		configuratorTableDataGridStateReducer,
		initializeDataGridState(tabContent),
		{ maxHistoryLength: 20 },
	);

	return (
		<ConfiguratorTableTabDataGrid
			tab={tab}
			state={state}
			dispatchState={dispatch}
			refresh={refresh}
			save={save}
			undoLastChange={undoLastChange}
			redoLastChange={redoLastChange}
		/>
	);

	async function refresh({ skipConfirm = false }: { skipConfirm?: boolean } = {}) {
		if (state.isDirty && !skipConfirm) {
			const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
			if (!confirmed) return;
		}
		const refreshedTab = await refreshProp();
		dispatch({ type: "setState", state: initializeDataGridState(refreshedTab) });
	}

	async function save() {
		const validationError = await validateStateBeforeSave(state);
		if (validationError) {
			await showMessageDialog({ title: i18n.t("error"), content: validationError });
			return;
		}
		await callSaveApi(tab, state);
		await refresh({ skipConfirm: true });
	}
};

function initializeDataGridState(tab: ConfiguratorTableTabContentQueryApi_TabContent): ConfiguratorTableDataGridState {
	const columns = tab.columns.map((column) => ({
		columnId: column.configuratorTableColumnId.toString(),
		columnName: column.columnName,
		position: column.position,
	}));

	const rows: ConfiguratorTableDataGridRow[] = tab.rows.map((row) => {
		return {
			rowHeaderName: row.rowName,
			rowId: row.configuratorTableRowId.toString(),
			position: row.position,
			valuesByColumnIds: tab.columns.reduce(
				(acc, column) => {
					const cell = tab.cells.find(
						(cell) =>
							cell.columnId === column.configuratorTableColumnId &&
							cell.rowId === row.configuratorTableRowId,
					);
					return {
						...acc,
						[column.configuratorTableColumnId.toString()]: cell?.value ?? "",
					};
				},
				{} as Record<number, string>,
			),
		};
	});

	return {
		columns: columns,
		rows: rows,
		isDirty: false,
	};
}

function validateStateBeforeSave(dataGridState: ConfiguratorTableDataGridState): string | undefined {
	const columnsWithoutName = dataGridState.columns.filter((column) => column.columnName.trim() === "");
	if (columnsWithoutName.length > 0) {
		return i18n.t("some_columns_have_no_name");
	}

	const rowsWithoutName = dataGridState.rows.filter((row) => row.rowHeaderName.trim() === "");
	if (rowsWithoutName.length > 0) {
		return i18n.t("some_rows_have_no_name");
	}

	const duplicateColumnNames = findDuplicateValues(dataGridState.columns, (column) => column.columnName).map(
		(col) => col.columnName,
	);

	if (duplicateColumnNames.length > 0) {
		return i18n.t("duplicate_column_names", { duplicates: _.uniq(duplicateColumnNames).join(", ") });
	}

	const duplicateRowNames = findDuplicateValues(dataGridState.rows, (row) => row.rowHeaderName).map(
		(row) => row.rowHeaderName,
	);

	if (duplicateRowNames.length > 0) {
		return i18n.t("duplicate_row_names", { duplicates: _.uniq(duplicateRowNames).join(", ") });
	}

	return undefined;
}

async function callSaveApi(tab: ConfiguratorTableTab, dataGridState: ConfiguratorTableDataGridState) {
	const columnsParam = dataGridState.columns.map((column) => ({
		name: column.columnName,
		generatedId: column.columnId,
	}));

	const rowsParam = dataGridState.rows.map((row) => ({
		name: row.rowHeaderName,
		generatedId: row.rowId,
	}));

	const cellsParam = dataGridState.rows.flatMap((row) =>
		Object.entries(row.valuesByColumnIds).map(([columnId, value]) => ({
			rowId: row.rowId,
			columnId: columnId,
			value: value,
		})),
	);

	await ConfiguratorTableTabContentEditApi.saveTabContent({
		configuratorTableTabId: tab.configuratorTableTabId,
		columns: columnsParam,
		rows: rowsParam,
		cells: cellsParam,
	});
}
