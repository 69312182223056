import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useState } from "react";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { RawHtmlContent } from "src/components/common/RawHtmlContent.tsx";
import { WorkspaceEditApi } from "src/api/generated/workspaces/api/workspaceEditApi.ts";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView.ts";

export interface WorkspaceNotesViewProps {
	workspace: WorkspaceView;
}

export const WorkspaceNotesView = ({ workspace }: WorkspaceNotesViewProps) => {
	const showFormInput = useFormInput();

	const [notes, setNotes] = useState<string>(workspace.notes ?? "");

	return (
		<HorizontalBox
			sx={{
				flex: 1,
				overflow: "auto",
				padding: 2,
				border: "1px solid",
				borderColor: "grey.300",
				margin: "0.25rem",
				borderRadius: "8px",
				minHeight: "100px",
			}}
		>
			<RawHtmlContent
				html={notes}
				sx={{
					flex: 1,
				}}
			/>
			<VerticalBox>
				<AsyncButton
					icon={faPen}
					variant={"contained"}
					onClick={openForm}
					sx={{
						fontSize: "1rem",
					}}
				/>
			</VerticalBox>
		</HorizontalBox>
	);

	async function openForm() {
		interface FormInput {
			notes: string;
		}

		const formValues = await showFormInput<FormInput>({
			title: i18n.t("memo"),
			size: "lg",
			defaultValues: {
				notes: notes,
			},
			content: ({ control }) => (
				<FormRichTextEditor
					control={control}
					name={"notes"}
					sx={{
						flex: 1,
					}}
				/>
			),
		});
		if (formValues === undefined) return;
		setNotes(formValues.notes);

		await WorkspaceEditApi.setWorkspaceNotes({
			workspaceId: workspace.workspaceId,
			notes: formValues.notes,
		});
	}
};
