import { ParsedError, parseError } from "src/errorHandling/errorParsing.ts";
import * as Sentry from "@sentry/react";

export interface LoggedError extends ParsedError {
	sentryEventId: string | undefined;
}

export const logError = (error: unknown, internalMessage?: string): LoggedError => {
	if (isLoggedError(error)) return error;

	const parsedError = parseError(error);
	if (!parsedError.treatAsError) {
		return { ...parsedError, sentryEventId: undefined };
	}

	const sentryEventId = Sentry.captureException(parsedError.error, {
		extra: {
			backendLogId: parsedError.backendLogId,
			internalMessage: internalMessage,
		},
	});
	console.error(parsedError, sentryEventId);
	return {
		...parsedError,
		sentryEventId: sentryEventId,
	};
};

const isLoggedError = (error: unknown): error is LoggedError => {
	return (error as LoggedError).sentryEventId !== undefined;
};
