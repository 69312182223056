import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {
	PurchaseOrdersToReceiveDataGridApi,
	PurchaseOrdersToReceiveDataGridApi_Row,
} from "src/api/generated/erp/purchase/purchaseOrder/api/purchaseOrdersToReceiveDataGridApi.ts";
import {
	getPurchaseOrderTypeLabel,
	getPurchaseOrderTypeLabels,
	PurchaseOrderType,
	PurchaseOrderTypeValues,
} from "src/api/generated/erp/db/types/enums/purchaseOrderType.ts";
import { genericNullableValue, genericValue } from "src/utils/genericNullableValue.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { dateColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getPurchaseOrderStateLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderState.ts";
import { getPurchaseOrderSourceTypeLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderSourceType.ts";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SupplierApi } from "src/api/generated/erp/purchase/suppliers/supplierApi.ts";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { Supplier } from "src/api/generated/erp/db/types/tables/supplier.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { PurchaseOrderReceptionView } from "src/components/views/erp/purchase/reception/PurchaseOrderReceptionView.tsx";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import { OpenPurchaseOrderButton } from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";

export const PurchaseOrdersToReceiveDataGrid = () => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps, currentParams, refreshData, onlySelectedRow } = useServerSideDataGridModel({
		fetchData: PurchaseOrdersToReceiveDataGridApi.getPurchaseOrdersToReceive,
		initialParams: {
			searchQuery: "",
			purchaseOrderType: genericValue<PurchaseOrderType>("TO_SITE"),
			supplierId: genericNullableValue<number>(),
		},
		getRowId: (row) => row.purchaseOrderId,
		gridId: "552C3B67E9E94EE5",
	});

	return (
		<ControlledAsyncDataGrid<PurchaseOrdersToReceiveDataGridApi_Row>
			columns={[
				integerColumn({
					field: "purchaseOrderId",
					headerName: i18n.t("purchase_order_no"),
				}),
				textColumn({
					field: "supplierName",
					headerName: i18n.t("supplier"),
					width: 150,
				}),
				textColumn({
					field: "supplierContact",
					headerName: i18n.t("supplier_contact"),
					width: 150,
				}),
				enumColumn({
					field: "purchaseOrderState",
					headerName: i18n.t("state"),
					enumLabels: getPurchaseOrderStateLabels(),
					width: 150,
				}),
				enumColumn({
					field: "orderSource",
					headerName: i18n.t("source"),
					enumLabels: getPurchaseOrderSourceTypeLabels(),
					width: 150,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
				}),
				enumColumn({
					field: "purchaseOrderType",
					headerName: i18n.t("type"),
					enumLabels: getPurchaseOrderTypeLabels(),
					width: 150,
				}),
				dateColumn({
					field: "nextPlannedArrivalDate",
					headerName: i18n.t("next_planned_arrival_date_short"),
					width: 150,
				}),
				textColumn({
					field: "responsiblePersonName",
					headerName: i18n.t("responsible_person"),
					width: 150,
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 200,
				}),
			]}
			onRowDoubleClick={({ row }) => openReceptionView(row)}
			actionBarComponents={
				<>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(searchQuery) => refreshData({ searchQuery })}
					/>
					<SelectField
						label={i18n.t("type")}
						options={PurchaseOrderTypeValues}
						getOptionKey={(o) => o}
						getOptionLabel={(o) => getPurchaseOrderTypeLabel(o)}
						value={currentParams.purchaseOrderType}
						onChange={(purchaseOrderType) => refreshData({ purchaseOrderType })}
						disableClearable
					/>
					<AsyncSelectField
						label={i18n.t("supplier")}
						getOptionKey={(o: Supplier) => o.supplierId}
						getOptionLabel={(o) => o.supplierName}
						fetchOptions={({ currentSelection, searchQuery }) =>
							SupplierApi.getSupplierOptions({
								searchQuery: searchQuery,
								currentSelectionId: currentSelection,
							})
						}
						onChange={(supplier) => refreshData({ supplierId: supplier?.supplierId })}
					/>
					<AsyncButton
						label={i18n.t("open_reception")}
						icon={faExternalLinkAlt}
						variant={"outlined"}
						disabled={onlySelectedRow == null}
						onClick={() => {
							if (onlySelectedRow == null) return;
							openReceptionView(onlySelectedRow);
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ onlySingleRowSelected, row }) => [
				onlySingleRowSelected && (
					<OpenPurchaseOrderButton
						key={"openPurchaseOrder"}
						purchaseOrderId={row.purchaseOrderId}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);

	function openReceptionView(row: PurchaseOrdersToReceiveDataGridApi_Row) {
		openDialog({
			title: i18n.t("reception"),
			content: <PurchaseOrderReceptionView purchaseOrderId={row.purchaseOrderId} />,
		});
	}
};
