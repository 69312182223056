import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { faCamera, faMousePointer } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import React from "react";
import { uploadFile } from "src/utils/fileUploading.ts";
import { DocumentCreationApi } from "src/api/generated/documents/api/documentCreationApi.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";
import dayjs, { Dayjs } from "dayjs";
import { ControlChartGraphViewApi } from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphView.tsx";

export const UploadDocumentsToSpcRecordButton = ({
	recordId,
	...other
}: { recordId: number | undefined } & AsyncButtonProps) => {
	const showInputDialog = useInputDialog();
	const { showToast } = useAavoToast();

	return (
		<AsyncButton
			icon={faCamera}
			disabled={recordId == null}
			tooltip={i18n.t("upload_document")}
			onClick={async () => {
				if (recordId == null) return;

				const file = await showInputDialog({
					type: "singleFile",
					title: i18n.t("upload_document"),
					fieldLabel: i18n.t("file"),
					required: true,
					defaultValue: null,
				});
				if (file === undefined) return;
				const fileHandle = await uploadFile(file);

				await DocumentCreationApi.createDocument({
					objectRef: {
						objectType: "SPC_RECORD",
						objectId: recordId,
					},
					description1: file.name,
					fileCreationType: "UPLOAD",
					uploadedFile: fileHandle,
				});
				showToast({ title: i18n.t("document_added") });
			}}
			{...other}
		/>
	);
};

export const FocusControlChartGraphViewToRecordButton = ({
	recordObservationTime,
	graphViewApiRef,
	...other
}: {
	recordObservationTime: Dayjs | undefined;
	graphViewApiRef: React.RefObject<ControlChartGraphViewApi> | undefined;
} & AsyncButtonProps) => {
	if (graphViewApiRef == null) return null;

	return (
		<AsyncButton
			icon={faMousePointer}
			variant={"outlined"}
			label={i18n.t("focus")}
			onClick={() => {
				if (!recordObservationTime) return;
				graphViewApiRef?.current?.searchWithTimeLimit({
					recordsBeforeLimit: dayjs(recordObservationTime),
				});
			}}
			{...other}
		/>
	);
};
