import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {
    DocumentsOfSingleObjectView
} from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import {SingleSourceTasksView} from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import {
    WorkspaceConnectionsDataGrid
} from "src/components/views/workspaces/workspaceConnections/WorkspaceConnectionsDataGrid.tsx";
import {WorkspaceInfoView} from "src/components/views/workspaces/infoView/WorkspaceInfoView.tsx";
import {WorkspaceView} from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView.ts";
import i18n from "i18next";

export interface WorkspaceInfoMobileViewProps {
	workspace: WorkspaceView;
}

export const WorkspaceMobileContentView = ({ workspace }: WorkspaceInfoMobileViewProps) => {
	return (
		<AavoMosaic
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("info"),
						content: <WorkspaceInfoView workspaceId={workspace.workspaceId} />,
					},
					workspace.documentsEnabled == true && {
						type: "panel",
						title: i18n.t("documents"),
						content: (
							<DocumentsOfSingleObjectView
								objectRef={{ objectId: workspace.workspaceId, objectType: "WORKSPACE" }}
								hidePreview={true}
							/>
						),
					},
					workspace.tasksEnabled == true && {
						type: "panel",
						title: i18n.t("tasks"),
						content: (
							<SingleSourceTasksView
								taskSourceRef={{ sourceId: workspace.workspaceId, sourceType: "WORKSPACE" }}
							/>
						),
					},
					workspace.connectionsEnabled == true && {
						type: "panel",
						title: i18n.t("connections"),
						content: <WorkspaceConnectionsDataGrid workspaceId={workspace.workspaceId} />,
					},
				],
			}}
		/>
	);
};
