import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {CustomerDataGridApi} from "src/api/generated/erp/sales/customer/api/customerDataGridApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
    booleanColumn,
    coloredBooleanColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getCustomerTypeLabels} from "src/api/generated/erp/db/types/enums/customerType.ts";
import {getLanguageLabels} from "src/api/generated/erp/db/types/enums/language.ts";
import {CustomerView} from "src/api/generated/erp/db/types/tables/customerView.ts";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faBan, faFileSignature, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {CustomerForm} from "src/components/views/erp/sales/customer/CustomerForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    CustomerSalesContractsView
} from "src/components/views/erp/sales/pricing/salesContracts/CustomerSalesContractsView.tsx";
import {CustomerApi} from "src/api/generated/erp/sales/customer/api/customerApi.ts";

export interface CustomersDataGridProps {
	onSelectionChanged: (customer: CustomerView | undefined) => void;
	onlyCustomerToShowId?: number;
}

export const CustomersDataGrid = ({ onSelectionChanged, onlyCustomerToShowId }: CustomersDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ServerSideDataGridModel
			gridId={"6AC95CEC97597AB2"}
			fetchData={(params) =>
				CustomerDataGridApi.getCustomersGridData({
					onlyCustomerId: onlyCustomerToShowId,
					...params,
				})
			}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.customerId}
			initialParams={{
				searchQuery: "",
				includeDeactivated: false,
			}}
			onSelectionChanged={(rows) => {
				onSelectionChanged(rows[0]);
			}}
			selectFirstRowOnLoad={onlyCustomerToShowId != null}
			render={({ dataGridProps, onlySelectedRow, refreshData, currentParams }) => (
				<CrudDataGrid<CustomerView>
					disableMultipleRowSelection={true}
					columns={[
						integerColumn({
							field: "customerId",
							headerName: i18n.t("customer_number_short"),
						}),
						textColumn({
							field: "customerGroupName",
							headerName: i18n.t("customer_group"),
							width: 150,
						}),
						textColumn({
							field: "customerName",
							headerName: i18n.t("name"),
							width: 150,
						}),
						textColumn({
							field: "businessId",
							headerName: i18n.t("business_id"),
						}),
						textColumn({
							field: "customerPriceGroupName",
							headerName: i18n.t("price_group"),
						}),
						floatColumn({
							field: "discountPercentage",
							headerName: i18n.t("discount_percentage"),
						}),
						enumColumn({
							field: "customerType",
							headerName: i18n.t("customer_type"),
							enumLabels: getCustomerTypeLabels(),
						}),
						booleanColumn({
							field: "isInternalCustomer",
							headerName: i18n.t("internal_customer"),
							width: 120,
						}),
						textColumn({
							field: "paymentTerm",
							headerName: i18n.t("payment_term"),
						}),
						enumColumn({
							field: "language",
							headerName: i18n.t("language"),
							enumLabels: getLanguageLabels(),
						}),
						textColumn({
							field: "note",
							headerName: i18n.t("note"),
						}),
						coloredBooleanColumn({
							field: "isActive",
							headerName: i18n.t("active"),
							falseColor: "red",
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<AsyncButton
								icon={faFileSignature}
								label={i18n.t("sales_contracts")}
								variant={"outlined"}
								disabled={onlySelectedRow == null}
								onClick={() => {
									if (onlySelectedRow == null) return;

									openDialog(() => ({
										title: i18n.t("sales_contracts"),
										content: <CustomerSalesContractsView customerId={onlySelectedRow.customerId} />,
									}));
								}}
							/>
							{onlySelectedRow?.customerAddressesCorrect == false && (
								<CenteredTypography
									sx={{
										fontWeight: "bold",
										color: "error.main",
									}}
								>
									{i18n.t("customer_must_have_invoice_and_delivery_address")}
								</CenteredTypography>
							)}
						</>
					}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showDeactivated"}
							label={i18n.t("show_deactivated")}
							checked={currentParams.includeDeactivated}
							onChange={async (value) => {
								await refreshData({ includeDeactivated: value });
							}}
						/>,
					]}
					rowContextMenuComponents={({ row }) => [
						row.isActive && (
							<AsyncMenuButton
								key={"deactivate"}
								icon={faBan}
								label={i18n.t("deactivate")}
								onClick={async () => {
									await CustomerApi.setCustomerIsActive({
										customerId: row.customerId,
										isActive: false,
									});
									await refreshData();
								}}
							/>
						),
						!row.isActive && (
							<AsyncMenuButton
								key={"activate"}
								icon={faUndo}
								label={i18n.t("activate")}
								onClick={async () => {
									await CustomerApi.setCustomerIsActive({
										customerId: row.customerId,
										isActive: true,
									});
									await refreshData();
								}}
							/>
						),
					]}
					form={{
						editEnabled: true,
						addRowEnabled: true,
						dialogSize: "md",
						dialogTitle: i18n.t("customer"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<CustomerForm
								customerId={row?.customerId}
								onFormEdited={onFormEdited}
								onCompleted={onCompleted}
							/>
						),
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
