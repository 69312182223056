import {coloredBooleanColumn, integerColumn, textColumn,} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ReclamationCategory} from "src/api/generated/erp/db/types/tables/reclamationCategory.ts";
import {ReclamationCategoryDataGridApi} from "src/api/generated/erp/reclamations/api/reclamationCategoryDataGridApi";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faBan, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";

export const ReclamationCategoriesDataGrid = () => {
	const { dataGridProps, refreshData } = useClientSideDataGridModel({
		gridId: "C53BB6EE84ED6C21",
		fetchData: ReclamationCategoryDataGridApi.getAll,
		getRowId: (row) => row.reclamationCategoryId,
		initialParams: {},
	});

	return (
		<ControlledAsyncCrudDataGrid<ReclamationCategory>
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					editable: true,
					validate: "required",
					minWidth: 300,
				}),
				coloredBooleanColumn({
					field: "isActive",
					headerName: i18n.t("enabled"),
					falseColor: "red",
				}),
				integerColumn({
					field: "reclamationCategoryId",
					headerName: i18n.t("id"),
				}),
			]}
			add={{
				type: "enabled",
				action: async () => {
					return {
						name: "",
					};
				},
			}}
			save={{
				type: "enabled",
				action: async (params) => {
					return await ReclamationCategoryDataGridApi.save({
						reclamationCategories: params.items,
					});
				},
			}}
			remove={{
				type: "enabled",
				action: async (params) => {
					await ReclamationCategoryDataGridApi.delete_({
						reclamationCategories: params.items,
					});
				},
			}}
			rowContextMenuComponents={({ onlySingleRowSelected, row }) => [
				onlySingleRowSelected && row.isActive && (
					<AsyncMenuButton
						key={"disable"}
						label={i18n.t("disable")}
						icon={faBan}
						onClick={async () => {
							await ReclamationCategoryDataGridApi.setIsActive({
								reclamationCategoryId: row.reclamationCategoryId,
								isActive: false,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && !row.isActive && (
					<AsyncMenuButton
						key={"enable"}
						label={i18n.t("enable")}
						icon={faCheck}
						onClick={async () => {
							await ReclamationCategoryDataGridApi.setIsActive({
								reclamationCategoryId: row.reclamationCategoryId,
								isActive: true,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
