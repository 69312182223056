// This file is automatically generated. Do not edit manually.

import { Workspace } from "src/api/generated/postgres/db/types/workspaces/tables/workspace";
import { WorkspaceCategory } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkspaceEditApi {
	export async function getFormInitData(args: {
		workspaceId: number | null | undefined;
		parentId: number | null | undefined;
	}): Promise<WorkspaceEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/getFormInitData",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				parentId: args.parentId,
			},
		});
		return response as WorkspaceEditApi_FormInitData;
	}

	export async function insert(args: { workspace: Workspace }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/insert",
			method: "POST",
			data: {
				workspace: args.workspace,
			},
		});
		return response as number;
	}

	export async function update(args: { workspace: Workspace }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/update",
			method: "POST",
			data: {
				workspace: args.workspace,
			},
		});
		return response as number;
	}

	export async function delete_(args: { workspaceId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/delete",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
			},
		});
		return response as void;
	}

	export async function copyWorkspace(args: {
		sourceWorkspaceId: number;
		targetWorkspaceName: string;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/copyWorkspace",
			method: "POST",
			data: {
				sourceWorkspaceId: args.sourceWorkspaceId,
				targetWorkspaceName: args.targetWorkspaceName,
			},
		});
		return response as number;
	}

	export async function archiveWorkspace(args: { workspaceId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/archiveWorkspace",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
			},
		});
		return response as void;
	}

	export async function revertArchiveWorkspace(args: { workspaceId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/revertArchiveWorkspace",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
			},
		});
		return response as void;
	}

	export async function setParentId(args: {
		workspaceId: number;
		parentId: number | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/setParentId",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				parentId: args.parentId,
			},
		});
		return response as void;
	}

	export async function setWorkspaceNotes(args: { workspaceId: number; notes: string }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/workspaces/edit/setWorkspaceNotes",
			method: "POST",
			data: {
				workspaceId: args.workspaceId,
				notes: args.notes,
			},
		});
		return response as void;
	}
}

export interface WorkspaceEditApi_FormInitData {
	categoryOptions: Array<WorkspaceCategory>;
	parentCategoryId: number | null | undefined;
	workspace: Workspace | null | undefined;
}
