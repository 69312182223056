import i18n from "i18next";
import {integerColumn, textColumn} from "src/components/common/dataGrid/columns";
import {SitesApi} from "src/api/generated/erp/common/sites/sitesApi";
import {SiteView} from "src/api/generated/erp/db/types/tables/siteView";
import {SiteForm} from "src/components/views/erp/common/sites/SiteForm";
import {AsyncCrudDataGrid} from "src/components/common/dataGrid/crud/AsyncCrudDataGrid";
import {dataGridPersistentStateProps} from "src/components/common/dataGrid/dataGridStateStorage";
import {OpenObjectChangeLogButton} from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";

export const SitesDataGrid = () => {
	return (
		<AsyncCrudDataGrid<SiteView>
			fetchData={SitesApi.getAll}
			remove={{
				type: "enabled",
				action: async (params) => await SitesApi.delete_({ sites: params.items }),
			}}
			columns={[
				textColumn({
					headerName: i18n.t("site"),
					field: "siteName",
				}),
				textColumn({
					headerName: i18n.t("description"),
					field: "description",
					width: 300,
				}),
				textColumn({
					headerName: i18n.t("address_1"),
					field: "address_1",
					width: 150,
				}),
				textColumn({
					headerName: i18n.t("address_2"),
					field: "address_2",
					width: 150,
				}),
				textColumn({
					headerName: i18n.t("postal_code"),
					field: "postalCode",
				}),
				textColumn({
					headerName: i18n.t("city"),
					field: "city",
				}),
				textColumn({
					headerName: i18n.t("company"),
					field: "companyName",
				}),
				integerColumn({
					headerName: i18n.t("id"),
					field: "siteId",
				}),
			]}
			getRowId={(row) => {
				return row.siteId;
			}}
			form={{
				dialogSize: "xl",
				dialogTitle: i18n.t("site"),
				component: ({ row, onCompleted, onFormEdited }) => {
					return <SiteForm siteId={row?.siteId} onCompleted={onCompleted} onFormEdited={onFormEdited} />;
				},
				addRowEnabled: true,
				editEnabled: true,
			}}
			rowContextMenuComponents={({ row }) => [
				<OpenObjectChangeLogButton objectRef={{ objectType: "SITE", objectId: row.siteId }} />,
			]}
			{...dataGridPersistentStateProps("DECAFEF7E28D9B71")}
		/>
	);
};
