import axios from "axios";
import { KnownApiError } from "src/api/generated/errors/knownApiError";

export interface ParsedError {
	error: Error;
	message: string;
	backendLogId: string | undefined;
	treatAsError: boolean;
	latestFrontendVersion: string;
	knownError: KnownApiError | undefined;
}

export const parseError = (error: unknown): ParsedError => {
	if (axios.isAxiosError(error)) {
		if (error.response?.data) {
			return parseError(error.response.data);
		}
	}

	return {
		error: castToError(error),
		message: getErrorMessage(error),
		backendLogId: getErrorBackendLogId(error),
		treatAsError: getTreatAsError(error),
		latestFrontendVersion: getLatestFrontendVersion(error),
		knownError: getKnownError(error),
	};
};

const castToError = (error: unknown): Error => {
	if (error instanceof Error) return error;
	return Error(getErrorMessage(error));
};

const getErrorMessage = (error: unknown): string => {
	if (isObjectWithMessage(error)) return error.message;

	try {
		return JSON.stringify(error);
	} catch {
		// fallback in case there's an error stringifying the error
		// like with circular references for example.
		return String(error);
	}
};

const isObjectWithMessage = (error: unknown): error is { message: string } =>
	typeof error === "object" &&
	error !== null &&
	"message" in error &&
	typeof (error as Record<string, unknown>).message === "string";

const getErrorBackendLogId = (error: unknown): string | undefined => {
	if (typeof error === "object" && error != null && "logId" in error)
		return String((error as Record<string, unknown>).logId);

	return undefined;
};

function getTreatAsError(error: unknown): boolean {
	if (typeof error === "object" && error != null && "logAsError" in error)
		return (error as Record<string, unknown>).logAsError === true;

	return true;
}

function getLatestFrontendVersion(error: unknown): string {
	if (typeof error === "object" && error != null && "latestFrontendVersion" in error)
		return String((error as Record<string, unknown>).latestFrontendVersion);

	return "";
}

function getKnownError(error: unknown): KnownApiError | undefined {
	const dataField = getErrorDataField(error);
	if (typeof dataField === "object" && dataField != null && "knownError" in dataField) {
		return (dataField as Record<string, unknown>).knownError as KnownApiError;
	}

	return undefined;
}

function getErrorDataField(error: unknown): unknown | undefined {
	if (typeof error === "object" && error != null && "data" in error) {
		return (error as Record<string, unknown>).data;
	}

	return undefined;
}
