import {useCallback} from "react";
import {WorkspaceEditApi} from "src/api/generated/workspaces/api/workspaceEditApi.ts";
import {useDrop} from "react-dnd";
import {Box, Typography} from "@mui/material";
import i18n from "i18next";
import {WORKSPACE_DND_TYPE} from "src/components/views/workspaces/treeView/WorkspacesTreeStatelessView.tsx";
import {WorkspacesTreeViewFetchParams} from "src/components/views/workspaces/treeView/WorkspaceTreeViewContext.tsx";
import {WorkspaceTreeViewItemModel} from "src/components/views/workspaces/treeView/WorkspacesTreeViewState.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";

export interface WorkspaceTreeViewRootLevelDropAreaProps {
	refresh: (params?: Partial<WorkspacesTreeViewFetchParams>) => Promise<void>;
}

export const WorkspaceTreeViewRootLevelDropArea = ({ refresh }: WorkspaceTreeViewRootLevelDropAreaProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const onDrop = useCallback(
		async (dropped: WorkspaceTreeViewItemModel) => {
			try {
				await WorkspaceEditApi.setParentId({
					workspaceId: dropped.workspaceId,
					parentId: null,
				});
				await refresh();
			} catch (e) {
				logErrorAndShowOnDialog(e);
			}
		},
		[refresh, logErrorAndShowOnDialog],
	);

	const [{ canDrop, isDraggingOver }, dropRef] = useDrop(
		() => ({
			accept: WORKSPACE_DND_TYPE,
			drop: onDrop,
			collect: (monitor) => ({
				canDrop: monitor.canDrop(),
				isDraggingOver: monitor.isOver(),
			}),
		}),
		[onDrop],
	);

	return (
		<Box
			flex={1}
			ref={dropRef}
			sx={{
				display: canDrop ? "flex" : "none",
				backgroundColor: (theme) => theme.palette.grey[300],
				opacity: isDraggingOver ? 0.5 : 1,
				padding: 1,
			}}
		>
			<Typography
				sx={{
					margin: "auto",
					fontSize: "1.5rem",
				}}
			>
				{i18n.t("drop_to_root_level")}
			</Typography>
		</Box>
	);
};
