// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { IsoDateTimeString } from "src/types/dateTime";
import { Currency } from "src/api/generated/erp/db/types/enums/currency";
import { CustomerOrderOfferState } from "src/api/generated/erp/db/types/enums/customerOrderOfferState";
import { Country } from "src/api/generated/erp/db/types/enums/country";
import { CustomerOrderOfferViewInvoiceAddressCountry } from "src/api/generated/erp/db/types/enums/customerOrderOfferViewInvoiceAddressCountry";
import { IsoDateString } from "src/types/dateTime";
import { VatHandling } from "src/api/generated/erp/db/types/enums/vatHandling";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderOfferDataGridApi {
	export async function getCustomerOrderOffers(args: {
		customerOrderOfferId: number | null | undefined;
		searchQuery: string;
		showOnlyDefaultSiteOffers: boolean;
		showCancelledOffers: boolean;
		showClosedOffers: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CustomerOrderOfferDataGridApi_CustomerOrderOfferDto>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/getCustomerOrderOffers",
			method: "POST",
			data: {
				customerOrderOfferId: args.customerOrderOfferId,
				searchQuery: args.searchQuery,
				showOnlyDefaultSiteOffers: args.showOnlyDefaultSiteOffers,
				showCancelledOffers: args.showCancelledOffers,
				showClosedOffers: args.showClosedOffers,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CustomerOrderOfferDataGridApi_CustomerOrderOfferDto>;
	}
}

export interface CustomerOrderOfferDataGridApi_CustomerOrderOfferDto {
	billingPlanIsComplete: boolean;
	billingPlanEnabled: boolean;
	cancelledAt: IsoDateTimeString | null | undefined;
	closedAt: IsoDateTimeString | null | undefined;
	contactEmail: string;
	contactName: string;
	contactPhone: string;
	contractualTermCode: string | null | undefined;
	contractualTermContent: string | null | undefined;
	contractualTermId: number | null | undefined;
	createdAt: IsoDateTimeString;
	createdBy: string;
	createdByUserId: number;
	currency: Currency;
	customerBusinessId: string | null | undefined;
	customerGroupName: string | null | undefined;
	customerId: number;
	customerName: string;
	customerOfferRequestNo: string;
	customerOrderCreatedAt: IsoDateTimeString | null | undefined;
	customerOrderId: number | null | undefined;
	customerOrderOfferId: number;
	customerOrderOfferLastUpdated: IsoDateTimeString;
	customerOrderOfferLongName: string | null | undefined;
	customerOrderOfferState: CustomerOrderOfferState;
	customerPriceGroupId: number | null | undefined;
	deliveryAddress_1: string;
	deliveryAddress_2: string;
	deliveryCity: string;
	deliveryContact: string | null | undefined;
	deliveryCountry: Country;
	deliveryDurationDays: number | null | undefined;
	deliveryMethodCode: string;
	deliveryMethodId: number;
	deliveryName: string;
	deliveryPostalCode: string;
	deliveryTermsCode: string;
	deliveryTermsDestination: string;
	deliveryTermsId: number;
	discardedAt: IsoDateTimeString | null | undefined;
	externalOrderId: string | null | undefined;
	frozen: boolean;
	inputPricesWithVat: boolean;
	invoiceAddressAddress_1: string;
	invoiceAddressAddress_2: string;
	invoiceAddressCity: string;
	invoiceAddressCountry: CustomerOrderOfferViewInvoiceAddressCountry;
	invoiceAddressId: number;
	isClosed: boolean;
	lastUpdated: IsoDateTimeString;
	loseReason: string;
	lostAt: IsoDateTimeString | null | undefined;
	note: string;
	offeredAt: IsoDateTimeString | null | undefined;
	orderReference: string;
	paymentTermDesc: string;
	paymentTermId: number;
	plannedDeliveryDate: IsoDateString;
	priceGroupName: string | null | undefined;
	printedAt: IsoDateTimeString | null | undefined;
	printoutEndText: string;
	printoutStartText: string;
	responsiblePersonEmail: string;
	responsiblePersonId: number;
	responsiblePersonName: string;
	responsiblePersonPhone: string;
	salesCommissionId: number | null | undefined;
	salespersonEmail: string | null | undefined;
	salespersonId: number | null | undefined;
	salespersonName: string | null | undefined;
	siteId: number;
	siteName: string;
	transportDuration: number | null | undefined;
	validForDays: number | null | undefined;
	validUntil: IsoDateString | null | undefined;
	vatCodeId: number;
	vatHandling: VatHandling;
}
