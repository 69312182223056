import React from "react";
import { UserEnvironmentType } from "src/api/generated/config/userEnvironmentType.ts";
import { Site } from "src/api/generated/erp/db/types/tables/site.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow";

export const GlobalInitDataContext = React.createContext<GlobalInitDataContextValue | undefined>(undefined);

export interface GlobalInitDataContextValue {
	principalId: string;
	appUserId: number;
	userName: string;
	userEmail: string;
	userPermissions: string[];
	mfaIsEnabled: boolean;
	defaultSiteId: number;
	userSites: Site[];
	changingPasswordIsPossible: boolean;
	environmentType: UserEnvironmentType;
}

export const useGlobalInitData = () => useContextOrThrow(GlobalInitDataContext);
