import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { AddShopOrdersToBatchDataGridApi } from "src/api/generated/erp/production/shopOrderBatch/api/addShopOrdersToBatchDataGridApi.ts";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getShopOrderSourceTypeLabels } from "src/api/generated/erp/db/types/enums/shopOrderSourceType.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { ShopOrderBatch } from "src/api/generated/erp/db/types/tables/shopOrderBatch.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { ConfiguratorFieldComponentsAsFilter } from "src/components/views/erp/configurator/ConfiguratorFieldComponentsAsFilter.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { ConfigurationPropertyValueFilter } from "src/api/generated/erp/configurator/propertyValueFiltering/configurationPropertyValueFilter.ts";
import { OpenPartConfigurationButton } from "src/components/views/erp/configurator/inspecting/OpenPartConfigurationButton.tsx";

export interface AddShopOrdersToBatchDataGridProps {
	shopOrderBatch: ShopOrderBatch;
	onCompleted: () => Promise<unknown>;
}

export const AddShopOrdersToBatchDataGrid = ({ shopOrderBatch, onCompleted }: AddShopOrdersToBatchDataGridProps) => {
	const tenantCustomizations = useTenantCustomizations();

	return (
		<ServerSideDataGridModel
			gridId={"7480A5AD0D5105B4F"}
			fetchData={(props) =>
				AddShopOrdersToBatchDataGridApi.getShopOrdersToAdd({
					shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
					...props,
				})
			}
			getDataModelResult={(data) => data.data}
			getRowId={(row) => row.shopOrderId}
			initialParams={{
				searchQuery: "",
				productionLineId: genericNullableValue<number>(shopOrderBatch.productionLineId),
				propertyValueFilters: Array<ConfigurationPropertyValueFilter>(),
			}}
			render={({ data, currentParams, dataGridProps, refreshData, selectedRows }) => (
				<CrudDataGrid<ShopOrderView>
					columns={[
						integerColumn({
							field: "shopOrderId",
							headerName: i18n.t("number_short"),
						}),
						textColumn({
							field: "productionLineName",
							headerName: i18n.t("production_line"),
						}),
						textColumn({
							field: "sourceRefData",
							headerName: i18n.t("reference"),
						}),
						textColumn({
							field: "configurationOrPartDescription",
							headerName: i18n.t("part"),
							width: 300,
						}),
						floatColumn({
							field: "quantity",
							headerName: i18n.t("quantity"),
							width: 70,
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("unit"),
							width: 70,
						}),
						integerColumn({
							field: "partRevision",
							headerName: i18n.t("revision"),
							width: 70,
						}),
						floatColumn({
							field: "capacityQuantity",
							headerName: i18n.t("capacity_quantity"),
							width: 140,
						}),
						dateColumn({
							field: "demandDate",
							headerName: i18n.t("demand_date"),
						}),
						dateTimeColumn({
							field: "creationDate",
							headerName: i18n.t("created_at"),
						}),
						textColumn({
							field: "createdBy",
							headerName: i18n.t("created_by"),
						}),
						enumColumn({
							field: "source",
							headerName: i18n.t("source"),
							enumLabels: getShopOrderSourceTypeLabels(),
							width: 150,
						}),
					]}
					actionBarComponents={
						<>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<SelectField
								label={i18n.t("production_line")}
								options={data.productionLineOptions}
								value={currentParams.productionLineId}
								getOptionKey={(o) => o.productionLineId}
								getOptionLabel={(o) => o.productionLineName}
								onChange={async (value) => {
									await refreshData({ productionLineId: value });
								}}
							/>
							<ConfiguratorFieldComponentsAsFilter
								components={
									tenantCustomizations.erp?.production
										?.addShopOrdersToBatchConfiguratorFilterComponents ?? []
								}
								filterValues={currentParams.propertyValueFilters}
								onSubmit={(filters) => refreshData({ propertyValueFilters: filters })}
							/>
							<AsyncButton
								label={i18n.t("connect_selected")}
								icon={faLink}
								variant={"outlined"}
								onClick={async () => {
									await AddShopOrdersToBatchDataGridApi.addSelectedRowsToBatch({
										shopOrderBatchId: shopOrderBatch.shopOrderBatchId,
										selectedShopOrderIds: selectedRows.map((r) => r.shopOrderId),
									});
									await onCompleted();
								}}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<OpenShopOrderButton key={"openShopOrder"} shopOrderId={row.shopOrderId} />,
						<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />,
						<OpenPartConfigurationButton
							key={"openConfiguration"}
							partConfigurationId={row.partConfigurationId}
						/>,
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
