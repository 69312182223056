import {ConfigurationProductFamily} from "src/api/generated/erp/db/types/tables/configurationProductFamily.ts";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
	ConfigurationProductFamiliesApi
} from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamiliesApi";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
	ConfigurationProductFamilyForm
} from "src/components/views/erp/configurator/managing/productFamilies/ConfigurationProductFamilyForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {
	ConfigurationProductFamilyCatalogPartsDataGrid
} from "src/components/views/erp/configurator/managing/productFamilies/ConfigurationProductFamilyCatalogPartsDataGrid.tsx";
import {faClipboardList} from "@fortawesome/pro-regular-svg-icons";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {ConfigurationProductFamilyView} from "src/api/generated/erp/db/types/tables/configurationProductFamilyView.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface ConfigurationProductFamiliesDataGridProps {
	onSelectionChanged: (row: ConfigurationProductFamily | undefined) => void;
}

export const ConfigurationProductFamiliesDataGrid = ({
	onSelectionChanged,
}: ConfigurationProductFamiliesDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={ConfigurationProductFamiliesApi.getConfigurationProductFamiliesGridData}
			initialParams={{
				searchQuery: "",
				categoryId: genericNullableValue<number>(null),
			}}
			getRows={(response) => response.productFamilies}
			getRowId={(row) => row.configurationProductFamilyId}
			gridId={"246531108EC72791"}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			render={({ data: { categoryOptions }, dataGridProps, onlySelectedRow, refreshData }) => (
				<CrudDataGrid<ConfigurationProductFamilyView>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 150,
						}),
						textColumn({
							field: "categoryName",
							headerName: i18n.t("category"),
							width: 300,
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("product_family"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfigurationProductFamilyForm
								productFamily={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ items }) =>
						ConfigurationProductFamiliesApi.delete_({
							configurationProductFamilyId: items[0]!.configurationProductFamilyId,
						})
					}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef(
									"CONFIGURATION_PRODUCT_FAMILY",
									onlySelectedRow?.configurationProductFamilyId,
								)}
							/>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<SelectField
								label={i18n.t("category")}
								options={categoryOptions}
								getOptionKey={(option) => option.configurationProductFamilyCategoryId}
								getOptionLabel={(option) => option.name}
								onChange={async (value) => {
									await refreshData({ categoryId: value });
								}}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<AsyncMenuButton
							icon={faClipboardList}
							label={i18n.t("parts")}
							onClick={() => {
								openDialog({
									title: i18n.t("product_family_parts"),
									size: "lg",
									content: (
										<ConfigurationProductFamilyCatalogPartsDataGrid
											productFamilyId={row.configurationProductFamilyId}
										/>
									),
								});
							}}
						/>
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
