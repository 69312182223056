import {AavoButton} from "src/components/common/buttons/AavoButton";
import {AsyncButton} from "src/components/common/buttons/AsyncButton";
import {ReclamationsDataGridBase} from "../../reclamation/ReclamationsDataGridBase";
import {useState} from "react";
import {ReclamationView} from "src/api/generated/erp/db/types/tables/reclamationView.ts";
import i18n from "i18next";
import {faExternalLinkAlt, faLink, faLinkSlash} from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {SingleReclamationView} from "src/components/views/erp/reclamation/SingleReclamationView.tsx";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";
import {ReclamationOperationApi} from "src/api/generated/erp/reclamations/api/reclamationOperationApi.ts";
import {ReclamationQueryApi} from "src/api/generated/erp/reclamations/api/reclamationQueryApi.ts";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {FetchAsyncOptionParams} from "../../../../common/inputFields/AsyncSelectField.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { CustomerOrder } from "src/api/generated/erp/db/types/tables/customerOrder.ts";

export interface CustomerOrderReclamationsDataGridProps {
	customerOrder: CustomerOrder;
}

export const CustomerOrderReclamationsDataGrid = ({
	customerOrder,
}: CustomerOrderReclamationsDataGridProps) => {
	const [selectedReclamations, setSelectedReclamations] = useState<ReclamationView[]>([]);
	const onlySelectedReclamation =
		selectedReclamations.length === 1 ? selectedReclamations[0] : undefined;

	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();
	const showInputDialog = useInputDialog();

	const refreshRef = useRefreshRef();

	return (
		<ReclamationsDataGridBase
			refreshRef={refreshRef}
			gridId={"F783C9F0539D0C8C"}
			customerOrderId={customerOrder.customerOrderId}
			siteId={customerOrder.siteId}
			selectedReclamations={selectedReclamations}
			onSelectionChanged={setSelectedReclamations}
			actionBarComponents={
				<>
					<AavoButton
						label={i18n.t("open")}
						icon={faExternalLinkAlt}
						disabled={onlySelectedReclamation == null}
						variant={"outlined"}
						onClick={() => {
							if (!onlySelectedReclamation) return;
							openDialog(() => ({
								title: onlySelectedReclamation.title,
								content: (
									<SingleReclamationView
										reclamationId={onlySelectedReclamation.reclamationId}
									/>
								),
							}));
						}}
					/>
					<AsyncButton
						label={i18n.t("connect")}
						icon={faLink}
						variant={"outlined"}
						onClick={connectNewReclamation}
					/>
				</>
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"removeConnection"}
						label={i18n.t("remove_connection")}
						icon={faLinkSlash}
						onClick={async () => {
							const confirmed = await showConfirmDialog({});
							if (!confirmed) return;
							await ReclamationOperationApi.removeConnectionToCustomerOrder({
								customerOrderId: customerOrder.customerOrderId,
								reclamationId: row.reclamationId,
							});
							await refreshRef.refresh();
						}}
					/>
				),
			]}
		/>
	);

	async function connectNewReclamation() {
		const reclamationId = await showInputDialog({
			type: "singleNumberSelect",
			title: i18n.t("connect_reclamation"),
			fieldLabel: i18n.t("reclamation"),
			required: true,
			defaultValue: null,
			fieldProps: {
				selection: {
					options: async ({
						currentSelection,
						searchQuery,
					}: FetchAsyncOptionParams<number>) => {
						const options = await ReclamationQueryApi.getReclamationOptions({
							currentSelection: currentSelection,
							searchQuery: searchQuery,
							notConnectedToCustomerOrderId: customerOrder.customerOrderId,
							siteId: customerOrder.siteId
						});
						return options.map((option) => ({
							value: option.reclamationId,
							label: `${option.reclamationId} | ${option.title}`,
						}));
					},
				},
			},
		});
		if (reclamationId === undefined) return;

		await ReclamationOperationApi.connectToCustomerOrder({
			customerOrderId: customerOrder.customerOrderId,
			reclamationId,
		});

		await refreshRef.refresh();
	}
};
