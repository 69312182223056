import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import { getTaskSourceActions } from "src/components/views/tasks/taskSources/TaskSourceActions.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
	faBan,
	faCalendar,
	faComment,
	faExternalLink,
	faExternalLinkAlt,
	faShare,
	faUser,
} from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { DocumentsOfObjectContextMenuButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectContextMenuButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { TaskActionsApi } from "src/api/generated/tasks/api/taskActionsApi.ts";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi.ts";
import { showUserSelectionDialog } from "src/components/views/users/useUserSelectionInputDialog.ts";
import { RescheduleTasksForm } from "src/components/views/tasks/RescheduleTasksForm.tsx";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import React from "react";
import { ContextMenuState, useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
	TASK_DESKTOP_FORM_SIZE,
	TaskDesktopForm,
} from "src/components/views/tasks/desktop/TaskDesktopForm.tsx";

export interface UseTaskContextMenuParams {
	refreshData: () => Promise<unknown>;
	showSourceButtons?: boolean;
}

export interface UseTaskContextMenuReturn {
	openTaskContextMenu: OpenTaskContextMenuFunc;
	taskContextMenuState: ContextMenuState;
	getTaskContextMenuContent: (tasks: TaskView[]) => Promise<React.ReactNode[]>;
}

export type OpenTaskContextMenuFunc = (mouseEvent: React.MouseEvent, tasks: TaskView[]) => void;

export const useTaskContextMenu = ({
	refreshData,
	showSourceButtons = true,
}: UseTaskContextMenuParams): UseTaskContextMenuReturn => {
	const [openContextMenu, contextMenuState] = useContextMenu();
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();
	const openLegacyView = useOpenLegacyView();
	const confirm = useConfirmDialog();
	const tenantCustomizations = useTenantCustomizations();
	const getContent = async (tasks: TaskView[]) => {
		const firstTask = tasks[0];
		if (firstTask == null) return [];

		const onlyTask = tasks.length === 1 ? firstTask : undefined;
		const taskSource =
			onlyTask && onlyTask.sourceType != null && onlyTask.sourceId != null ?
				await TaskQueryApi.getTaskSource({
					sourceRef: {
						sourceType: onlyTask.sourceType,
						sourceId: onlyTask.sourceId,
					},
				})
			:	undefined;

		const taskSourceActions =
			onlyTask?.sourceType != null ? getTaskSourceActions(onlyTask.sourceType) : undefined;

		return [
			onlyTask && (
				<AsyncMenuButton
					key={"open"}
					icon={faExternalLink}
					label={i18n.t("open")}
					onClick={async () => {
						openFormOnDialog({
							openDialog,
							component: TaskDesktopForm,
							title: onlyTask.title,
							size: TASK_DESKTOP_FORM_SIZE,
							props: {
								taskId: onlyTask.taskId,
								newTaskSourceRef: taskSource,
							},
							onSubmit: async () => {
								await refreshData();
							},
						});
					}}
				/>
			),
			showSourceButtons && onlyTask && taskSourceActions && (
				<AsyncMenuButton
					key={"open_source"}
					icon={faExternalLinkAlt}
					label={i18n.t("open_source")}
					onClick={async () => {
						if (onlyTask.sourceId == null) return;
						await taskSourceActions.openSource({
							sourceId: onlyTask.sourceId,
							openGenericDialog: openDialog,
							openLegacyView: openLegacyView,
						});
					}}
				/>
			),
			showSourceButtons && onlyTask && taskSourceActions && (
				<DocumentsOfObjectContextMenuButton
					key={"source_documents"}
					objectRef={nullableAavoObjectRef(onlyTask.sourceType, onlyTask.sourceId)}
					label={i18n.t("source_documents")}
					editable={false}
				/>
			),
			tasks.some((row) => row.taskState === "INITIAL") &&
				taskSource?.taskReleaseMode !== "CAN_NOT_RELEASE" && (
					<AsyncMenuButton
						key={"release"}
						icon={faShare}
						label={i18n.t("release")}
						onClick={async () => {
							await TaskActionsApi.releaseTasks({
								taskIds: tasks.map((t) => t.taskId),
							});
							await refreshData();
						}}
					/>
				),
			onlyTask && (
				<AsyncMenuButton
					key={"note"}
					icon={faComment}
					label={i18n.t("note")}
					onClick={async () => {
						const newNote = await showInputDialog({
							type: "string",
							title: i18n.t("note"),
							defaultValue: onlyTask.note ?? "",
							fieldProps: {
								text: {
									multiLine: true,
								},
							},
						});
						if (newNote === undefined) return;
						await TaskUpdateApi.setTaskNote({
							taskId: onlyTask.taskId,
							note: newNote,
						});
						await refreshData();
					}}
				/>
			),
			<AsyncMenuButton
				key={"setExecutor"}
				icon={faUser}
				label={i18n.t("set_executor")}
				onClick={async () => {
					const newAssignedToUserId = await showUserSelectionDialog({
						title: i18n.t("executor"),
						defaultValue: tasks[0]?.assignedToUserId ?? null,
						showInputDialog: showInputDialog,
					});
					if (newAssignedToUserId === undefined) return;

					await TaskUpdateApi.setAssignedToUserIdOfTasks({
						taskIds: tasks.map((t) => t.taskId),
						assignedToUserId: newAssignedToUserId,
					});
					await refreshData();
				}}
			/>,
			<AsyncMenuButton
				key={"schedule"}
				icon={faCalendar}
				label={i18n.t("set_schedule")}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("set_schedule"),
						size: "sm",
						content: (
							<RescheduleTasksForm
								taskIds={tasks.map((t) => t.taskId)}
								defaultValues={{
									deadlineDate: onlyTask?.deadlineDate,
									earliestStartDate: onlyTask?.earliestStartDate,
								}}
								onCompleted={async () => {
									await refreshData();
									await closeDialog();
								}}
							/>
						),
					}));
				}}
			/>,
			<AsyncMenuButton
				key={"cancel"}
				icon={faBan}
				label={i18n.t("cancel")}
				onClick={async () => {
					const confirmed = await confirm({
						message: i18n.t("cancel_tasks_confirmation"),
					});
					if (!confirmed) return;

					await TaskActionsApi.cancelTasks({
						taskIds: tasks.map((t) => t.taskId),
					});
					await refreshData();
				}}
			/>,
			...((await tenantCustomizations.taskContextMenuComponents?.({ task: firstTask })) ?? []),
		];
	};

	return {
		openTaskContextMenu: (mouseEvent, tasks: TaskView[]) => {
			openContextMenu({
				mouseEvent: mouseEvent,
				content: () => getContent(tasks),
			});
		},
		getTaskContextMenuContent: getContent,
		taskContextMenuState: contextMenuState,
	};
};
