import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";
import {IsoDateString} from "src/types/dateTime.ts";
import {
    CustomerOrderOfferCopyFormApi,
    CustomerOrderOfferCopyFormApi_FormInitData,
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferCopyFormApi.ts";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import {FormDateField} from "src/components/common/forms/fields/FormDateField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {CustomerApi} from "src/api/generated/erp/sales/customer/api/customerApi.ts";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {Customer} from "src/api/generated/erp/db/types/tables/customer.ts";
import {useState} from "react";
import {CustomerAddress} from "src/api/generated/erp/db/types/tables/customerAddress.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {concatWithPipe} from "src/utils/strings.tsx";
import {Site} from "src/api/generated/erp/db/types/tables/site.ts";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {resolveCustomerAddresses} from "src/components/views/erp/sales/customerAddressUtils.ts";
import {CustomerOrderOffer} from "../../../../../api/generated/erp/db/types/tables/customerOrderOffer.ts";

export interface CopyCustomerOrderOfferFormProps extends FormCommonProps<number> {
    sourceOffer: CustomerOrderOffer
}

interface FormValues {
    targetSiteId: number;
    targetPlannedDeliveryDate: IsoDateString;
    targetCustomerId: number;
    targetDeliveryAddressId: number;
    targetInvoiceAddressId: number;
    copyLines: boolean;
    copyTasks: boolean;
    copyDocuments: boolean;
    copyConfigurations: boolean;
    copyBillingPlan: boolean
}

export const CopyCustomerOrderOfferForm = ({
                                               sourceOffer,
                                               onFormEdited,
                                               onCompleted,
                                           }: CopyCustomerOrderOfferFormProps) => {
    const {logErrorAndShowOnDialog} = useErrorDialog();

    const [invoiceAddressOptions, setInvoiceAddressOptions] = useState<CustomerAddress[] | undefined>(undefined);

    const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<CustomerAddress[] | undefined>(undefined);

    return (
        <AsyncForm<CustomerOrderOfferCopyFormApi_FormInitData, FormValues, number>
            fetch={() => CustomerOrderOfferCopyFormApi.getFormInitData()}
            getDefaultValues={() => ({
                targetSiteId: sourceOffer.siteId,
            })}
            onCompleted={onCompleted}
            onFormEdited={onFormEdited}
            render={({control, watch, data: {siteOptions}, setValue}) => {
                return (
                    <>
                        <FormSelectField
                            control={control}
                            name={"targetSiteId"}
                            label={i18n.t("site")}
                            options={siteOptions}
                            getOptionKey={(s: Site) => s.siteId}
                            getOptionLabel={(s) => s.siteName}
                            rules={requireRule()}
                        />
                        <FormAsyncSelectField
                            control={control}
                            name={"targetCustomerId"}
                            label={i18n.t("customer")}
                            getOptionKey={(option: Customer) => option.customerId}
                            getOptionLabel={(option) => option.customerName}
                            fetchOptions={({searchQuery, currentSelection}) =>
                                CustomerApi.getCustomerSelectionOptions({
                                    searchQuery,
                                    currentSelection,
                                })
                            }
                            onChange={(customer) => onCustomerChanged(customer)}
                        />
                        <FormSelectField
                            control={control}
                            name={"targetDeliveryAddressId"}
                            label={i18n.t("delivery_address")}
                            options={deliveryAddressOptions ?? []}
                            getOptionKey={(a) => a.customerAddressId}
                            getOptionLabel={(a) => concatWithPipe(a.address_1, a.city)}
                            disabled={!deliveryAddressOptions}
                        />
                        <FormSelectField
                            control={control}
                            name={"targetInvoiceAddressId"}
                            label={i18n.t("invoice_address")}
                            options={invoiceAddressOptions ?? []}
                            getOptionKey={(a) => a.customerAddressId}
                            getOptionLabel={(a) => concatWithPipe(a.address_1, a.city)}
                            disabled={!invoiceAddressOptions}
                        />
                        <FormDateField
                            control={control}
                            name={"targetPlannedDeliveryDate"}
                            label={i18n.t("planned_delivery_date")}
                            rules={requireRule()}
                        />
                        <FormCheckbox control={control} name={"copyLines"} label={i18n.t("copy_lines")}/>
                        {watch("copyLines") && (
                            <FormCheckbox
                                control={control}
                                name={"copyConfigurations"}
                                label={i18n.t("copy_configurations")}
                            />
                        )}
                        <FormCheckbox control={control} name={"copyTasks"} label={i18n.t("copy_tasks")}/>
                        <FormCheckbox control={control} name={"copyDocuments"} label={i18n.t("copy_documents")}/>
                        {sourceOffer.billingPlanEnabled &&
                            <FormCheckbox control={control} name={"copyBillingPlan"}
                                          label={i18n.t("copy_billing_plan")}/>
                        }
                    </>
                );

                async function onCustomerChanged(customer: Customer | null) {
                    try {
                        if (customer == null) {
                            setDeliveryAddressOptions(undefined);
                            setInvoiceAddressOptions(undefined);
                            return;
                        }
                        const allAddresses = await CustomerApi.getCustomerAddresses({
                            customerId: customer.customerId,
                        });
                        const {deliveryAddresses, invoiceAddresses, defaultDeliveryAddress, defaultInvoiceAddress} =
                            resolveCustomerAddresses(allAddresses);

                        setDeliveryAddressOptions(deliveryAddresses);
                        setInvoiceAddressOptions(invoiceAddresses);
                        setValue("targetDeliveryAddressId", defaultDeliveryAddress?.customerAddressId);
                        setValue("targetInvoiceAddressId", defaultInvoiceAddress?.customerAddressId);
                    } catch (e) {
                        logErrorAndShowOnDialog(e);
                    }
                }
            }}
            submit={(values) =>
                CustomerOrderOfferCopyFormApi.copyCustomerOrderOffer({
                    sourceCustomerOrderOfferId: sourceOffer.customerOrderOfferId,
                    ...values,
                })
            }
        />
    );
};
