import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { AavoNumberField } from "src/components/common/inputFields/AavoNumberField.tsx";
import { VerticalBox } from "../common/box/VerticalBox";
import { useState } from "react";

export const TestPage = () => {
	const showInputDialog = useInputDialog();
	const [numberInput, setNumberInput] = useState<number | null>(null);
	if (!import.meta.env.DEV) return null;

	return (
		<VerticalBox>
			<AavoNumberField
				value={numberInput}
				type={"decimal"}
				onChange={(value) => {
					console.log("onChange", value);
					setNumberInput(value);
				}}
				onSubmit={(value) => {
					console.log("onSubmit", value);
				}}
			/>
			<AsyncButton
				label={"Set val"}
				onClick={async () => {
					setNumberInput(35.3535)
				}}
			/>
			<AsyncButton
				label={"Test"}
				onClick={async () => {
					const decimalInput = await showInputDialog({
						type: "decimal",
						title: "Test",
						defaultValue: null,
						required: true,
					});
					if (decimalInput == undefined) return;

					console.log(decimalInput, typeof decimalInput);
				}}
			/>
		</VerticalBox>
	);
};
