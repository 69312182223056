import { AavoToastContent } from "./types";
import React from "react";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const AavoToastContext = React.createContext<AavoToastContextValue | undefined>(undefined);

export interface AavoToastContextValue {
	showToast: (content: AavoToastContent) => void;
	closeToast: () => void;
}

export const useAavoToast = () => useContextOrThrow(AavoToastContext);
