import { MainApi } from "../api/mainApi";
import React from "react";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { StartUpDataContext } from "./StartUpDataContext";

export function StartUpDataContextProvider({ children }: React.PropsWithChildren) {
	return (
		<AsyncFetchRender
			fetch={MainApi.getStartUpData}
			content={(startUpData) => (
				<StartUpDataContext.Provider value={startUpData}>{children}</StartUpDataContext.Provider>
			)}
		/>
	);
}
