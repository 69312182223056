import { PartConfiguration } from "src/api/generated/erp/db/types/tables/partConfiguration.ts";
import i18n from "i18next";
import { formatMoney, formatNumber } from "src/utils/numberUtils.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import { calculateSalesMarginPercent } from "src/components/views/erp/sales/salesUtils.ts";

export interface PartConfigurationTopLevelDataViewProps {
	partConfiguration: PartConfiguration;
}

export const PartConfigurationTopLevelDataView = ({ partConfiguration }: PartConfigurationTopLevelDataViewProps) => {
	return (
		<LabelValueView
			sx={{
				margin: 1,
			}}
			items={[
				{
					label: i18n.t("description"),
					value: partConfiguration.description,
				},
				{
					label: i18n.t("material_cost"),
					value: formatMoney(partConfiguration.materialCost),
				},
				{
					label: i18n.t("work_cost"),
					value: formatMoney(partConfiguration.workCost),
				},
				{
					label: i18n.t("total_cost"),
					value: formatMoney(partConfiguration.totalCost),
				},
				...(partConfiguration.salesPrice !== null ?
					[
						{
							label: i18n.t("calculated_price"),
							value: formatMoney(partConfiguration.basePrice),
						},
						{
							label: i18n.t("discount"),
							value: formatNumber(partConfiguration.discountPercentage) + " %",
						},
						{
							label: i18n.t("sales_price"),
							value: formatMoney(partConfiguration.salesPrice),
						},
						{
							label: i18n.t("profit_margin"),
							value:
								formatNumber(
									calculateSalesMarginPercent({
										cost: partConfiguration.totalCost ?? 0,
										salesPrice: partConfiguration.salesPrice,
									}),
									{ maxDigits: 0 },
								) + " %",
						},
					]
				:	[]),
				{
					label: i18n.t("weight"),
					value: partConfiguration.weight != null && formatNumber(partConfiguration.weight),
				},
				{
					label: i18n.t("configuration_id"),
					value: partConfiguration.partConfigurationId,
				},
				{
					label: i18n.t("log_key"),
					value: partConfiguration.configurationLogPathBase64,
				},
			]}
		/>
	);
};
