import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {PaymentTermsApi} from "src/api/generated/erp/sales/basedata/api/paymentTermsApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
    coloredBooleanColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {PaymentTermForm} from "src/components/views/erp/sales/basedata/paymentTerms/PaymentTermForm.tsx";
import {PaymentTerm} from "src/api/generated/erp/db/types/tables/paymentTerm.ts";

export const PaymentTermsDataGrid = () => {
	return (
		<ClientSideDataGridModel
			gridId={"8BBC00558AB37E2B8"}
			fetchData={PaymentTermsApi.getAll}
			getRows={(data) => data}
			initialParams={{}}
			getRowId={(row) => row.paymentTermId}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => {
				return (
					<CrudDataGrid<PaymentTerm>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "paymentTerm",
								headerName: i18n.t("payment_term"),
							}),
							textColumn({
								field: "paymentTermDesc",
								headerName: i18n.t("description"),
								width: 200,
							}),
							integerColumn({
								field: "paymentDays",
								headerName: i18n.t("payment_days"),
							}),
							integerColumn({
								field: "discountDays",
								headerName: i18n.t("discount_days"),
							}),
							floatColumn({
								field: "discountPercent",
								headerName: i18n.t("discount_percent"),
							}),
							coloredBooleanColumn({
								field: "isDefault",
								headerName: i18n.t("default"),
								falseColor: "grey",
							}),
						]}
						remove={{
							type: "enabled",
							action: async () => {
								if (onlySelectedRow == null) return;
								await PaymentTermsApi.delete_({
									paymentTermId: onlySelectedRow.paymentTermId,
								});
							},
						}}
						form={{
							addRowEnabled: true,
							editEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("payment_term"),
							component: ({ row, onCompleted, onFormEdited }) => (
								<PaymentTermForm
									paymentTerm={row}
									onCompleted={(result) => onCompleted(result)}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faCheck}
									label={i18n.t("set_to_default")}
									variant={"outlined"}
									disabled={onlySelectedRow == null || onlySelectedRow.isDefault}
									onClick={async () => {
										if (onlySelectedRow == null) return;
										await PaymentTermsApi.setToDefault({
											paymentTermId: onlySelectedRow.paymentTermId,
										});
										await refreshData();
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
