import { ReactNode } from "react";
import { KnownApiError } from "src/api/generated/errors/knownApiError";
import { ConfiguratorErrorView } from "src/components/views/erp/configurator/errors/ConfiguratorErrorView.tsx";

export interface KnownApiErrorViewProps<TError extends KnownApiError> {
	error: TError;
	reload?: () => void;
	renderOutdatedFrontendVersionMessage: () => ReactNode;
}

export const KnownApiErrorView = <TError extends KnownApiError>(props: KnownApiErrorViewProps<TError>) => {
	switch (props.error.type) {
		case "CONFIGURATOR_ERROR":
			return <ConfiguratorErrorView {...props} />;
	}
};
