import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ObjectPermission } from "src/api/generated/iam/model/objectPermission.ts";
import { getObjectMemberTypeLabels, ObjectMemberType } from "src/api/generated/iam/model/objectMemberType.ts";
import { getObjectPermissionId } from "src/components/views/erp/surveys/management/permissions/ObjectPermissionId.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { DeepPartial } from "react-hook-form";
import {
	WorkspacePermissionEditApi,
	WorkspacePermissionEditApi_FormInitData,
} from "src/api/generated/workspaces/api/workspacePermissionEditApi.ts";

export interface WorkspacePermissionFormProps extends FormCommonProps<string> {
	workspaceId: number;
	objectPermission: ObjectPermission | undefined;
}

interface FormValues {
	memberType: ObjectMemberType;
	roleId: number;
	userId: number;
	admin: boolean;
}

export const WorkspacePermissionForm = (props: WorkspacePermissionFormProps) => {
	const { workspaceId, objectPermission, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => WorkspacePermissionEditApi.getFormInitData()}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({}: WorkspacePermissionEditApi_FormInitData): DeepPartial<FormValues> {
		if (objectPermission != null) {
			return {
				memberType: objectPermission.memberType,
				roleId: objectPermission.memberType === "ROLE" ? objectPermission.memberId : 0,
				userId: objectPermission.memberType === "USER" ? objectPermission.memberId : 0,
				admin: objectPermission.actions.includes("WORKSPACE_ADMIN"),
			};
		} else {
			return {
				memberType: "ROLE",
				admin: false,
			};
		}
	}

	async function submit(values: FormValues) {
		const memberId = values.memberType === "ROLE" ? values.roleId : values.userId;
		await WorkspacePermissionEditApi.savePermission({
			workspaceId: workspaceId,
			memberType: values.memberType,
			memberId: memberId,
			adminPermissions: values.admin,
		});
		return getObjectPermissionId({ memberType: values.memberType, memberId: memberId });
	}
};

interface FormContentProps
	extends WorkspacePermissionFormProps,
		AsyncFormContentParams<WorkspacePermissionEditApi_FormInitData, FormValues> {}

const FormContent = ({ control, watch, data: { userOptions, roleOptions } }: FormContentProps) => {
	const memberType = watch("memberType");
	return (
		<>
			<FormEnumSelectField
				control={control}
				name={"memberType"}
				label={i18n.t("type")}
				rules={requireRule()}
				options={getObjectMemberTypeLabels()}
			/>
			{memberType === "ROLE" && (
				<FormSelectField
					control={control}
					name={"roleId"}
					label={i18n.t("role")}
					options={roleOptions}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => option.name}
					rules={requireRule()}
				/>
			)}
			{memberType === "USER" && (
				<FormSelectField
					control={control}
					name={"userId"}
					label={i18n.t("user")}
					options={userOptions}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => option.name}
					rules={requireRule()}
				/>
			)}
			<FormCheckbox control={control} name={"admin"} label={i18n.t("admin")} />
		</>
	);
};
