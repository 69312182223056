import { ProjectObjectGanttData } from "src/api/generated/erp/project/gantt/projectObjectGanttData";
import HighchartsGantt, { GanttPointOptionsObject } from "highcharts/highcharts-gantt";
import dayjs from "dayjs";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { AavoGanttView } from "src/components/common/gantt/AavoGanttView.tsx";
import { getTaskColor } from "src/components/views/tasks/taskUtils.ts";
import { Typography, useTheme } from "@mui/material";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { ProjectContextMenu } from "src/components/views/erp/project/project/ProjectContextMenu.tsx";
import { ProjectActivityContextMenu } from "src/components/views/erp/project/projectActivity/ProjectActivityContextMenu.tsx";
import { SubProjectContextMenu } from "src/components/views/erp/project/subProject/SubProjectContextMenu.tsx";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView.ts";
import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView.ts";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import { useTaskContextMenu } from "src/components/views/tasks/desktop/TaskContextMenu.tsx";
import React from "react";
import i18n from "i18next";
import { ProjectSchedulingApi } from "src/api/generated/erp/project/api/projectSchedulingApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { dayJsToDateIsoString, formatDayJs, parseDateStringToDayJsUtc } from "src/utils/dayjsUtils.ts";
import { TaskUpdateApi } from "src/api/generated/tasks/api/taskUpdateApi.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { ProjectsContainerView } from "src/components/views/erp/project/ProjectsContainerView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {
	TASK_DESKTOP_FORM_SIZE,
	TaskDesktopForm,
} from "src/components/views/tasks/desktop/TaskDesktopForm.tsx";
import { ProjectActivitiesContainerView } from "src/components/views/erp/project/projectActivity/ProjectActivitiesContainerView.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";

export interface ProjectObjectGanttBaseProps {
	rootType: "PROJECT" | "SUB_PROJECT" | "ACTIVITY";
	collapseActivities?: boolean;
	fetchData: () => Promise<ProjectObjectGanttData>;
	onProjectScheduled?: () => Promise<unknown>;
	onSubProjectScheduled?: () => Promise<unknown>;
	onActivityScheduled?: () => Promise<unknown>;
	onTaskScheduled?: () => Promise<unknown>;
}

export const ProjectObjectGanttBase = ({
	rootType,
	collapseActivities,
	fetchData,
	onProjectScheduled,
	onSubProjectScheduled,
	onActivityScheduled,
	onTaskScheduled,
}: ProjectObjectGanttBaseProps) => {
	const theme = useTheme();
	const showFormInput = useFormInput();
	const { openDialog } = useGenericDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const [showContextMenu, contextMenuState] = useContextMenu();

	const [chartOptionsAsync, refreshData] = useAsyncFetch<HighchartsGantt.Options>(createChartOptions);

	const { getTaskContextMenuContent } = useTaskContextMenu({
		refreshData,
		showSourceButtons: false,
	});

	return (
		<AsyncRender
			asyncData={chartOptionsAsync}
			content={(chartOptions) => (
				<>
					<AavoGanttView options={chartOptions} />
					<AavoContextMenu state={contextMenuState} />
				</>
			)}
		/>
	);

	async function createChartOptions(): Promise<HighchartsGantt.Options> {
		const data = await fetchData();
		return {
			chart: {
				scrollablePlotArea: {
					minHeight: data.totalObjectCount * Y_AXIS_STATIC_SCALE + 300,
				},
			},
			exporting: {
				scale: 1,
				chartOptions: getChartOptionsForExporting(data),
			},
			series: [
				{
					type: "gantt",
					data: getSeriesData(data),
					tooltip: {
						headerFormat: "",
						pointFormat: `
							{point.name}<br/>
							Aloitus: {point.start:%d.%m.%Y}<br/>
							Lopetus: {point.end:%d.%m.%Y}
							`,
					},
				},
			],
			xAxis: {
				currentDateIndicator: true,
				minRange: dayjs.duration(7, "days").asMilliseconds(),
			},
			yAxis: {
				staticScale: Y_AXIS_STATIC_SCALE,
			},
			navigator: {
				enabled: true,
			},
			rangeSelector: {
				enabled: true,
				selected: 5,
			},
			plotOptions: {
				gantt: {
					dragDrop: {
						draggableX: true,
						draggableY: false,
						dragPrecisionX: dayjs.duration(1, "day").asMilliseconds(),
						liveRedraw: true,
					},
					dataLabels: {
						enabled: true,
						format: "{point.name}",
						className: "pe-none",
					},
					point: {
						events: {
							contextmenu: onContextMenu,
							dblclick: onDoubleClick,
							drop: onDrop,
						},
					},
				},
			},
		};
	}

	function getSeriesData(data: ProjectObjectGanttData): ProjectObjectGanttPointOptions[] {
		return [
			...data.projects.map(getDataPointForProject),
			...data.subProjects.map(getDataPointForSubProject),
			...data.activities.map(getDataPointForActivity),
			...data.activityTasks.map(getDataPointForTask),
		];
	}

	function getDataPointForProject(project: ProjectView): ProjectObjectGanttPointOptions {
		return {
			id: PROJECT_ID_PREFIX + project.projectId,
			parent: undefined,
			name: project.projectDescription,
			start: parseDateStringToDayJsUtc(project.plannedStartDate).valueOf(),
			end: parseDateStringToDayJsUtc(project.plannedEndDate).valueOf(),
			color: theme.palette.info.light,
			custom: {
				type: "project",
				project: project,
			},
		};
	}

	function getDataPointForSubProject(subProject: SubProjectView): ProjectObjectGanttPointOptions {
		return {
			id: SUB_PROJECT_ID_PREFIX + subProject.subProjectId,
			parent: rootType === "SUB_PROJECT" ? undefined : PROJECT_ID_PREFIX + subProject.projectId,
			name: subProject.subProjectDescription,
			start: parseDateStringToDayJsUtc(subProject.plannedStartDate).valueOf(),
			end: parseDateStringToDayJsUtc(subProject.plannedEndDate).valueOf(),
			color: theme.palette.green.light,
			dependency:
				subProject.startAfterSubProjectCompletedId == null ?
					undefined
				:	SUB_PROJECT_ID_PREFIX + subProject.startAfterSubProjectCompletedId,
			custom: {
				type: "subProject",
				subProject: subProject,
			},
		};
	}

	function getDataPointForActivity(activity: ProjectActivityView): ProjectObjectGanttPointOptions {
		return {
			id: ACTIVITY_ID_PREFIX + activity.activityId,
			parent: rootType === "ACTIVITY" ? undefined : SUB_PROJECT_ID_PREFIX + activity.subProjectId,
			name: activity.activityName,
			start: parseDateStringToDayJsUtc(activity.plannedStartDate).valueOf(),
			end: parseDateStringToDayJsUtc(activity.plannedEndDate).valueOf(),
			color: theme.palette.yellow.light,
			collapsed: collapseActivities === true,
			dependency:
				activity.releaseAfterActivityCompletedId == null ?
					undefined
				:	ACTIVITY_ID_PREFIX + activity.releaseAfterActivityCompletedId,
			custom: {
				type: "activity",
				activity: activity,
			},
		};
	}

	function getDataPointForTask(task: TaskView): ProjectObjectGanttPointOptions {
		return {
			id: TASK_ID_PREFIX + task.taskId,
			parent: ACTIVITY_ID_PREFIX + task.sourceId,
			name: task.title,
			start: parseDateStringToDayJsUtc(task.earliestStartDate ?? task.deadlineDate).valueOf(),
			end: parseDateStringToDayJsUtc(task.deadlineDate).add(1, "day").valueOf(),
			color: getTaskColor(task, theme),
			custom: {
				type: "task",
				task: task,
			},
		};
	}

	function getChartOptionsForExporting(data: ProjectObjectGanttData) {
		// A0 paper size in pixels. Not really sure which would be best values.
		const targetWidth = 3370;
		const targetHeight = 2348;
		const topLevelItemsMargin = 115; // Examined value.

		return {
			navigator: {
				enabled: false,
			},
			rangeSelector: {
				enabled: false,
			},
			xAxis: {
				currentDateIndicator: false,
			},
			chart: {
				width: targetWidth,
			},
			yAxis: {
				// Setting chart height doesn't work when scrollablePlotArea is used.
				// Fitting each object row height is good workaround.
				staticScale: (targetHeight - topLevelItemsMargin) / data.totalObjectCount,
				labels: {
					style: {
						fontSize: "24px",
					},
				},
			},
		};
	}

	function onContextMenu(event: React.MouseEvent<Element, MouseEvent>) {
		try {
			const customData = getPointCustomDataFromEvent(event);
			const objectType = customData.type;
			showContextMenu({
				mouseEvent: event,
				content:
					objectType === "project" ?
						<ProjectContextMenu project={customData.project} refreshData={refreshData} />
					: objectType === "subProject" ?
						<SubProjectContextMenu subProject={customData.subProject} refreshData={refreshData} />
					: objectType === "activity" ?
						<ProjectActivityContextMenu
							activity={customData.activity}
							refreshData={refreshData}
						/>
					: objectType === "task" ? () => getTaskContextMenuContent([customData.task])
					: null,
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	function onDoubleClick(event: React.MouseEvent<Element, MouseEvent>) {
		try {
			const customData = getPointCustomDataFromEvent(event);
			const objectType = customData.type;
			switch (objectType) {
				case "project":
					openDialog({
						title: i18n.t("project"),
						content: <ProjectsContainerView onlyProjectId={customData.project.projectId} />,
					});
					break;
				case "subProject":
					openDialog({
						title: i18n.t("sub_project"),
						content: <ProjectsContainerView onlyProjectId={customData.subProject.projectId} />,
					});
					break;
				case "activity":
					openDialog({
						title: i18n.t("activity"),
						content: (
							<ProjectActivitiesContainerView
								subProjectId={customData.activity.subProjectId}
								onlyActivityId={customData.activity.activityId}
							/>
						),
					});
					break;
				case "task":
					openFormOnDialog({
						openDialog,
						title: i18n.t("task"),
						size: TASK_DESKTOP_FORM_SIZE,
						component: TaskDesktopForm,
						props: {
							taskId: customData.task.taskId,
						},
						onSubmit: refreshData,
					});
					break;
			}
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function onDrop(event: any) {
		try {
			const point = event.target;
			const customData = point.custom as PointCustomData;
			const newStart = dayjs(point.start);
			const newEnd = dayjs(point.end);

			interface FormInputValues {
				inheritMoveToDescendants: boolean;
			}

			const formInputValues = await showFormInput<FormInputValues>({
				title: i18n.t("confirm_move_on_project_object_gantt"),
				defaultValues: {
					inheritMoveToDescendants: true,
				},
				submitLabel: i18n.t("ok"),
				content: ({ control }) => (
					<>
						<Typography ml={1}>
							{i18n.t("new_start")}: {formatDayJs(newStart)}
						</Typography>
						<Typography ml={1}>
							{i18n.t("new_end")}: {formatDayJs(newEnd)}
						</Typography>
						{customData.type !== "task" && (
							<FormCheckbox
								control={control}
								name="inheritMoveToDescendants"
								label={i18n.t("inherit_schedule_change_to_lower_level_items")}
							/>
						)}
					</>
				),
			});
			if (formInputValues == null) {
				await refreshData();
				return;
			}
			const { inheritMoveToDescendants } = formInputValues;

			switch (customData.type) {
				case "project":
					await ProjectSchedulingApi.scheduleProject({
						projectId: customData.project.projectId,
						newPlannedStartDate: dayJsToDateIsoString(newStart),
						newPlannedEndDate: dayJsToDateIsoString(newEnd),
						inheritToDescendants: inheritMoveToDescendants,
					});
					await onProjectScheduled?.();
					break;
				case "subProject":
					await ProjectSchedulingApi.scheduleSubProject({
						subProjectId: customData.subProject.subProjectId,
						newPlannedStartDate: dayJsToDateIsoString(newStart),
						newPlannedEndDate: dayJsToDateIsoString(newEnd),
						inheritToDescendants: inheritMoveToDescendants,
					});
					await onSubProjectScheduled?.();
					break;
				case "activity":
					await ProjectSchedulingApi.scheduleProjectActivity({
						activityId: customData.activity.activityId,
						newPlannedStartDate: dayJsToDateIsoString(newStart),
						newPlannedEndDate: dayJsToDateIsoString(newEnd),
						inheritToDescendants: inheritMoveToDescendants,
					});
					await onActivityScheduled?.();
					break;
				case "task": {
					await TaskUpdateApi.scheduleTasks({
						taskIds: [customData.task.taskId],
						earliestStartDate:
							customData.task.earliestStartDate == null ? null : dayJsToDateIsoString(newStart),
						deadlineDate: dayJsToDateIsoString(newEnd),
					});
					await onTaskScheduled?.();
					break;
				}
			}

			await refreshData();
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};

type ProjectObjectGanttPointOptions = GanttPointOptionsObject & {
	custom: PointCustomData;
};

type PointCustomData =
	| {
			type: "project";
			project: ProjectView;
	  }
	| {
			type: "subProject";
			subProject: SubProjectView;
	  }
	| {
			type: "activity";
			activity: ProjectActivityView;
	  }
	| {
			type: "task";
			task: TaskView;
	  };

function getPointCustomDataFromEvent(event: React.MouseEvent<Element, MouseEvent>): PointCustomData {
	const point = (event as any).point as ProjectObjectGanttPointOptions;
	return point.custom;
}

const PROJECT_ID_PREFIX = "project-";
const SUB_PROJECT_ID_PREFIX = "sub-project-";
const ACTIVITY_ID_PREFIX = "activity-";
const TASK_ID_PREFIX = "task-";

const Y_AXIS_STATIC_SCALE = 40;
