import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
    PartInventoryLevelEventsDataGridApi
} from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/partInventoryLevelEventsDataGridApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
    dateTimeColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getInventoryLevelEventTypeLabels} from "src/api/generated/erp/db/types/enums/inventoryLevelEventType.ts";
import {InventoryLevelEventView} from "src/api/generated/erp/db/types/tables/inventoryLevelEventView.ts";
import {InventoryLevelSummaryText} from "src/components/views/erp/warehouse/inventory/InventoryLevelSummaryText.tsx";

export interface PartInventoryLevelEventsDataGridProps {
	partId: number;
	partWarehouseLocationId: number | null;
}

export const PartInventoryLevelEventsDataGrid = ({
	partId,
	partWarehouseLocationId,
}: PartInventoryLevelEventsDataGridProps) => {
	return (
		<ServerSideDataGridModel
			gridId={"940AB7DA0EC9370"}
			fetchData={(params) =>
				PartInventoryLevelEventsDataGridApi.getGridData({
					partId: partId,
					partWarehouseLocationId: partWarehouseLocationId,
					...params,
				})
			}
			getDataModelResult={(data) => data.rows}
			getRowId={(row) => row.inventoryLevelEventId}
			initialParams={{}}
			render={({ data: { summary }, dataGridProps }) => {
				return (
					<CrudDataGrid<InventoryLevelEventView>
						columns={[
							integerColumn({
								field: "inventoryLevelEventId",
								headerName: i18n.t("id"),
								width: 80,
							}),
							textColumn({
								field: "locationCode",
								headerName: i18n.t("warehouse_location"),
								width: 150,
							}),
							enumColumn({
								field: "inventoryLevelEventType",
								headerName: i18n.t("event_type"),
								enumLabels: getInventoryLevelEventTypeLabels(),
								width: 150,
							}),
							floatColumn({
								field: "eventQuantity",
								headerName: i18n.t("quantity"),
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("unit"),
							}),
							floatColumn({
								field: "unitCost",
								headerName: i18n.t("unit_cost"),
								width: 150,
							}),
							floatColumn({
								field: "totalCost",
								headerName: i18n.t("total_cost"),
								width: 150,
							}),
							floatColumn({
								field: "stockQuantityAfterEvent",
								headerName: i18n.t("stock_quantity_after_event"),
								width: 250,
							}),
							integerColumn({
								field: "counterObjectId",
								headerName: i18n.t("object_id"),
							}),
							textColumn({
								field: "createdByUserName",
								headerName: i18n.t("created_by"),
							}),
							dateTimeColumn({
								field: "createdAt",
								headerName: i18n.t("created_at"),
								width: 150,
							}),
						]}
						actionBarComponents={
							<>
								<InventoryLevelSummaryText summary={summary} />
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
