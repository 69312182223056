import { InputDialogContent } from "src/components/common/dialogs/input/InputDialogContent.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { InputDialogType, InputDialogValueType, ShowInputDialogParams } from "./types";

export type OpenInputDialogFunc = <T extends InputDialogType>(
	params: ShowInputDialogParams<T>,
) => Promise<InputDialogValueType<T> | undefined>;

export const useInputDialog = (): OpenInputDialogFunc => {
	const { openDialog } = useGenericDialog();

	return <T extends InputDialogType>({
		title,
		fieldLabel = "",
		defaultValue,
		required,
		type,
		fieldProps,
	}: ShowInputDialogParams<T>) =>
		new Promise<InputDialogValueType<T> | undefined>((resolve) => {
			openDialog(({ closeDialog }) => ({
				title: title,
				size: "sm",
				onClose: async () => {
					resolve(undefined);
				},
				content: (
					<InputDialogContent<T>
						type={type}
						fieldLabel={fieldLabel}
						fieldProps={fieldProps}
						onCompleted={async (result) => {
							if (result.type === "success") {
								resolve(result.value);
							} else {
								resolve(undefined);
							}
							await closeDialog();
						}}
						defaultValue={defaultValue as InputDialogValueType<T>}
						required={required}
					/>
				),
			}));
		});
};
