// This file is automatically generated. Do not edit manually.

import { CustomerOrderLine } from "src/api/generated/erp/db/types/tables/customerOrderLine";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { IsoDateString } from "src/types/dateTime";
import { CustomerOrder } from "src/api/generated/erp/db/types/tables/customerOrder";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView";
import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderLineFormApi {
	export async function getFormInitData(args: {
		customerOrderId: number;
		customerOrderLineId: number | null | undefined;
		parentLineId: number | null | undefined;
		copySourceCustomerOrderLineId?: number | null | undefined;
	}): Promise<CustomerOrderLineFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/getFormInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				customerOrderLineId: args.customerOrderLineId,
				parentLineId: args.parentLineId,
				copySourceCustomerOrderLineId: args.copySourceCustomerOrderLineId,
			},
		});
		return response as CustomerOrderLineFormApi_InitData;
	}

	export async function getShouldConfigureBeforeInsert(args: {
		customerOrderId: number;
		salesPartId: number;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/getShouldConfigureBeforeInsert",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				salesPartId: args.salesPartId,
			},
		});
		return response as boolean;
	}

	export async function insert(args: { customerOrderLine: CustomerOrderLine }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/insert",
			method: "POST",
			data: {
				customerOrderLine: args.customerOrderLine,
			},
		});
		return response as number;
	}

	export async function update(args: {
		customerOrderLine: CustomerOrderLine;
		updateAcquisitionObjectDates: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/update",
			method: "POST",
			data: {
				customerOrderLine: args.customerOrderLine,
				updateAcquisitionObjectDates: args.updateAcquisitionObjectDates,
			},
		});
		return response as void;
	}

	export async function getSalesPartOptions(args: {
		customerOrderId: number;
		searchQuery: string;
		currentSelection: number | null | undefined;
	}): Promise<Array<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/getSalesPartOptions",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
			},
		});
		return response as Array<SalesPartView>;
	}

	export async function getSalesPartDefaultPrice(args: {
		salesPartId: number;
		customerOrderId: number;
		withVat: boolean;
		vatCodeId: number;
		plannedDeliveryDate: IsoDateString;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/getSalesPartDefaultPrice",
			method: "POST",
			data: {
				salesPartId: args.salesPartId,
				customerOrderId: args.customerOrderId,
				withVat: args.withVat,
				vatCodeId: args.vatCodeId,
				plannedDeliveryDate: args.plannedDeliveryDate,
			},
		});
		return response as number;
	}
}

export const CustomerOrderLineFormApi_InitData_AcquisitionObjectStateValues = [
	"NOT_EXISTS",
	"INITIAL",
	"SHOP_ORDER_PLANNED",
	"PURCHASE_ORDER_RELEASED",
] as const;

export type CustomerOrderLineFormApi_InitData_AcquisitionObjectState =
	(typeof CustomerOrderLineFormApi_InitData_AcquisitionObjectStateValues)[number];

export interface CustomerOrderLineFormApi_InitData {
	acquisitionObjectState: CustomerOrderLineFormApi_InitData_AcquisitionObjectState;
	customerOrder: CustomerOrder;
	customerOrderLine: CustomerOrderLineView | null | undefined;
	defaultLineNumber: number;
	parentLine: CustomerOrderLine | null | undefined;
	salesPart: SalesPartView | null | undefined;
	vatCodeOptions: Array<VatCode>;
}
