import React, { useState } from "react";
import {
	ShowConfirmDialogFunc,
	ConfirmDialogContext,
} from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton";
import { faPenToSquare, faPlus, faRefresh, faSave, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
	CrudActionButtonDefinition,
	EnabledActionButtonDefinition,
} from "src/components/common/crud/crudActionButtonDefinition";

import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import i18n from "i18next";

const DEFAULT_ACTION: CrudActionButtonDefinition = {
	type: "hidden",
};

export type CrudDataGridActionButtonsProps = {
	refresh?: CrudActionButtonDefinition;
	save?: CrudActionButtonDefinition;
	edit?: CrudActionButtonDefinition;
	add?: CrudActionButtonDefinition;
	remove?: CrudActionButtonDefinition;
};

export const CrudActionButtons = ({
	refresh = DEFAULT_ACTION,
	save = DEFAULT_ACTION,
	edit = DEFAULT_ACTION,
	add = DEFAULT_ACTION,
	remove = DEFAULT_ACTION,
}: CrudDataGridActionButtonsProps) => {
	const [allButtonsDisabled, setAllButtonsDisabled] = useState<boolean>(false);
	return (
		<>
			<ActionButton
				action={refresh}
				icon={faRefresh}
				tooltip={i18n.t("refresh")}
				allButtonsDisabled={allButtonsDisabled}
				setAllButtonsDisabled={setAllButtonsDisabled}
			/>
			<ActionButton
				action={edit}
				icon={faPenToSquare}
				tooltip={i18n.t("edit")}
				allButtonsDisabled={allButtonsDisabled}
				setAllButtonsDisabled={setAllButtonsDisabled}
			/>
			<ActionButton
				action={add}
				icon={faPlus}
				tooltip={i18n.t("add_new")}
				allButtonsDisabled={allButtonsDisabled}
				setAllButtonsDisabled={setAllButtonsDisabled}
			/>
			<ActionButton
				action={save}
				icon={faSave}
				tooltip={i18n.t("save_changes")}
				allButtonsDisabled={allButtonsDisabled}
				setAllButtonsDisabled={setAllButtonsDisabled}
			/>
			<ActionButton
				action={remove}
				icon={faTrash}
				tooltip={i18n.t("delete_selected")}
				allButtonsDisabled={allButtonsDisabled}
				setAllButtonsDisabled={setAllButtonsDisabled}
				confirmByDefault={true}
			/>
		</>
	);
};

const ActionButton = ({
	action,
	icon,
	tooltip,
	allButtonsDisabled,
	setAllButtonsDisabled,
	confirmByDefault = false,
}: {
	action: CrudActionButtonDefinition;
	icon: IconDefinition;
	tooltip: string;
	allButtonsDisabled: boolean;
	setAllButtonsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
	confirmByDefault?: boolean;
}) => {
	const confirmFunction = useContextOrThrow(ConfirmDialogContext);
	const [loading, setLoading] = useState<boolean>(false);
	const { logErrorAndShowOnDialog } = useErrorDialog();

	if (action.type === "hidden") return null;

	const onClick = async (e: React.MouseEvent) => {
		if (action.type !== "enabled") return;

		e.preventDefault();
		setLoading(true);
		setAllButtonsDisabled(true);
		try {
			// Confirm rejection does not cause error, just prevent the actual action
			const confirmed = await confirmAction(action, confirmFunction, confirmByDefault);
			if (!confirmed) return;

			await action.action();
		} catch (e) {
			logErrorAndShowOnDialog(e);
		} finally {
			setLoading(false);
			setAllButtonsDisabled(false);
		}
	};

	return (
		<AavoIconButton
			tooltip={action?.type === "disabled" ? action.tooltip : tooltip}
			color={"primary"}
			icon={icon}
			loading={loading}
			disabled={action?.type === "disabled" || allButtonsDisabled}
			onClick={onClick}
		/>
	);
};

const confirmAction = async <ActionParams = unknown, ActionResult = unknown>(
	action: EnabledActionButtonDefinition<ActionParams, ActionResult>,
	confirmFunction: ShowConfirmDialogFunc,
	confirmByDefault: boolean,
): Promise<boolean> => {
	if (action.confirm === undefined) {
		if (confirmByDefault) return await confirmFunction();
		else return true;
	} else if (action.confirm === false) {
		return true;
	} else if (action.confirm === true) {
		return await confirmFunction();
	} else {
		return await confirmFunction(action.confirm);
	}
};
