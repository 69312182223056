import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import i18n from "i18next";
import { getProjectStateLabel } from "src/api/generated/erp/db/types/enums/projectState.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { LabelValueView, LabelValueViewItem } from "src/components/common/misc/LabelValueView.tsx";
import { nullIfBlank } from "src/utils/strings.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ProjectForm } from "src/components/views/erp/project/project/ProjectForm.tsx";
import { ProjectQueryApi } from "src/api/generated/erp/project/project/api/projectQueryApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface ProjectInspectViewProps {
	project: ProjectView;
	onProjectUpdated?: () => void | Promise<unknown>;
	refreshRef?: RefreshableElementRef;
}

export const ProjectInspectView = ({
	project: projectInitial,
	onProjectUpdated,
	refreshRef,
}: ProjectInspectViewProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncFetchRender
			fetch={() => ProjectQueryApi.getProject({ projectId: projectInitial.projectId })}
			defaultValue={projectInitial}
			refreshRef={refreshRef}
			content={(project, refreshProject) => (
				<HorizontalBox
					sx={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<LabelValueView
						sx={{
							flex: 1,
							alignSelf: "flex-start",
						}}
						items={getLabelValueViewItems(project)}
					/>
					<VerticalBox
						sx={{
							padding: 1,
						}}
					>
						<AsyncButton
							icon={faPen}
							onClick={() => {
								openFormOnDialog({
									openDialog,
									component: ProjectForm,
									props: {
										projectId: project.projectId,
									},
									title: project.projectDescription,
									size: "lg",
									onSubmit: () => Promise.all([refreshProject(), onProjectUpdated?.()]),
								});
							}}
						/>
					</VerticalBox>
				</HorizontalBox>
			)}
		/>
	);
};

function getLabelValueViewItems(project: ProjectView): LabelValueViewItem[] {
	return [
		{
			label: i18n.t("name"),
			value: project.projectDescription,
		},
		{
			label: i18n.t("site"),
			value: project.siteName,
		},
		{
			label: i18n.t("group"),
			value: project.groupName,
		},
		{
			label: i18n.t("type"),
			value: project.projectType,
		},
		{
			label: i18n.t("price_list"),
			value: project.costEventPriceListName,
		},
		{
			label: i18n.t("external_id"),
			value: project.externalId,
		},
		{
			label: i18n.t("responsible_person"),
			value: project.projectResponsiblePersonName,
		},
		{
			label: i18n.t("state"),
			value: getProjectStateLabel(project.projectState),
		},
		{
			label: i18n.t("planned_start"),
			value: formatIsoString(project.plannedStartDate),
		},
		{
			label: i18n.t("planned_end"),
			value: formatIsoString(project.plannedEndDate),
		},
		{
			label: i18n.t("created_at"),
			value: formatIsoString(project.createdDate, "L LT"),
		},
		project.releasedDate != null && {
			label: i18n.t("released_at"),
			value: formatIsoString(project.releasedDate, "L LT"),
		},
		project.completedDate != null && {
			label: i18n.t("completed_at"),
			value: formatIsoString(project.completedDate, "L LT"),
		},
		project.cancelledDate != null && {
			label: i18n.t("cancelled_at"),
			value: formatIsoString(project.cancelledDate, "L LT"),
		},
		{
			label: i18n.t("comment"),
			value: nullIfBlank(project.comment),
			multiline: true,
		},
		project.isTemplate && {
			label: i18n.t("template_project"),
			value: project.isTemplate,
		},
		{
			type: "section",
			label: i18n.t("address"),
			fields: [
				{
					label: i18n.t("name"),
					value: project.addressName,
				},
				{
					label: i18n.t("address_1"),
					value: project.address_1,
				},
				{
					label: i18n.t("address_2"),
					value: project.address_2,
				},
				{
					label: i18n.t("postal_code"),
					value: project.postalCode,
				},
				{
					label: i18n.t("city"),
					value: project.city,
				},
				{
					label: i18n.t("contact"),
					value: project.addressContact,
				},
			],
		},
	];
}
