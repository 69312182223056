import i18n from "i18next";
import { ConfigurableObjectType } from "src/api/generated/erp/db/types/enums/configurableObjectType.ts";
import type { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { showAsyncDialog } from "src/components/common/dialogs/asyncDialog.ts";
import { ObjectConfigurationHistoryEntryInputForm } from "src/components/views/erp/configurator/objectConfigurationHistory/ObjectConfigurationHistoryEntryInputForm.tsx";
import { ObjectConfigurationHistoryEntryInput } from "src/api/generated/erp/configurator/objectConfigurationHistory/objectConfigurationHistoryEntryInput.ts";
import { ObjectConfigurationHistoryApi } from "src/api/generated/erp/configurator/objectConfigurationHistory/api/objectConfigurationHistoryApi.ts";

interface AskObjectConfigurationHistoryEntryInputProps {
	openDialog: OpenGenericDialogFunc;
	objectType: ConfigurableObjectType;
	objectId: number;
}

export async function askObjectConfigurationHistoryEntryInput({
	openDialog,
	objectType,
	objectId,
}: AskObjectConfigurationHistoryEntryInputProps): Promise<ObjectConfigurationHistoryEntryInput | undefined> {
	const hasHistoryEntries = await ObjectConfigurationHistoryApi.getObjectConfigurationHistoryHasAnyEntry({
		objectType,
		objectId,
	});
	// No reason to bother the user with the dialog if there are no history entries
	if (!hasHistoryEntries)
		return {
			keepCurrentEntry: true,
			comment: "",
		};

	return await showAsyncDialog(openDialog, ({ onCompleted, onFormEdited }) => ({
		title: i18n.t("configuration_history"),
		size: "sm",
		content: (
			<ObjectConfigurationHistoryEntryInputForm
				objectType={objectType}
				objectId={objectId}
				onCompleted={onCompleted}
				onFormEdited={onFormEdited}
			/>
		),
	}));
}
