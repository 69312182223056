import i18n from "i18next";
import { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { ShopOrderBomApi } from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomApi.ts";
import { ShopOrderBomActionApi } from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomActionApi.ts";

export async function cancelShopOrderBomLine({
	shopOrderLineId,
	showConfirmDialog,
}: {
	shopOrderLineId: number;
	showConfirmDialog: ShowConfirmDialogFunc;
}) {
	const confirmed = await showConfirmDialog({
		title: i18n.t("cancel"),
		message: i18n.t("confirm_cancel_shop_order_line"),
	});
	if (!confirmed) return;

	const hasReceivedPurchaseOrderLines = await ShopOrderBomActionApi.shopOrderLineHasReceivedPurhcaseOrderLines({
		shopOrderLineId: shopOrderLineId,
	});

	if (hasReceivedPurchaseOrderLines) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("shop_order_has_lines_with_received_purchase_orders"),
		});
		if (!confirmed) return;
	}

	const shopOrderLine = await ShopOrderBomApi.getShopOrderBomLine({ shopOrderLineId: shopOrderLineId });
	if (
		shopOrderLine.acquisitionMethod === "WAREHOUSE" &&
		shopOrderLine.reservedQuantity > shopOrderLine.pickedQuantity
	) {
		await showConfirmDialog({
			title: i18n.t("can_not_cancel"),
			message: i18n.t("line_has_reservation.revert_reservation_first"),
		});
		return;
	}
	if (shopOrderLine.pickedQuantity > 0 && shopOrderLine.pickedQuantity === shopOrderLine.reservedQuantity) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("shop_order_line_is_picked"),
		});
		if (!confirmed) return;
	}

	await ShopOrderBomActionApi.cancelShopOrderLine({
		shopOrderLineId: shopOrderLine.shopOrderLineId,
		lastUpdated: shopOrderLine.lastUpdated,
	});
}
