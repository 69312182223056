import { Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { getHandlebarsParams, resolveHandlebarsTemplate } from "src/utils/handlebars";
import { VIEW_ID } from "src/urls";
import { useFrontendViewsContext } from "src/components/views/frontendViews/useFrontendViewsContext.ts";
import { useMemo } from "react";
import { useStartUpData } from "src/contexts/StartUpDataContext.ts";

export const ActiveViewNameText = () => {
	const startUpData = useStartUpData();
	const { getFrontendViewByUrl } = useFrontendViewsContext();
	const location = useLocation();
	const { viewId } = useParams<typeof VIEW_ID>();

	const activeViewName = useMemo(() => {
		if (viewId !== undefined) {
			const view = startUpData.views[viewId]?.view ?? undefined;
			return view ?
					resolveHandlebarsTemplate(
						view.displayName,
						getHandlebarsParams(startUpData, view.staticParams),
					)
				:	"";
		} else if (location.pathname) {
			const frontendView = getFrontendViewByUrl(location.pathname);
			return frontendView?.title ?? "";
		} else {
			return "";
		}
	}, [getFrontendViewByUrl, location.pathname, startUpData, viewId]);

	return (
		<Typography
			variant="h6"
			component="div"
			sx={{
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				flexShrink: 1
			}}
		>
			{activeViewName}
		</Typography>
	);
};
