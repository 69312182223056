import React, { useMemo, useState } from "react";
import { logError, LoggedError } from "src/errorHandling/errorLogging.ts";
import { ErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialog.tsx";
import { ErrorDialogContext } from "./ErrorDialogContext";

export interface ErrorDialogContextProviderProps{
	children: React.ReactNode;
	disableCustomizedErrorViews?: boolean;
}

export const ErrorDialogContextProvider = ({ children, disableCustomizedErrorViews }: ErrorDialogContextProviderProps) => {
	const [error, setError] = useState<LoggedError | undefined>(undefined);

	const value = useMemo(() => {
		const showErrorDialog = (error: LoggedError) => {
			setError(error);
		};

		const logErrorAndShowOnDialog = (error: unknown, internalMessage?: string) => {
			const loggedError = logError(error, internalMessage);
			setError(loggedError);
			return loggedError;
		};

		return {
			showErrorDialog,
			logErrorAndShowOnDialog,
			withErrorHandling: async (func: () => unknown | Promise<unknown>): Promise<void> => {
				try {
					await func();
				} catch (error) {
					showErrorDialog(logError(error));
				}
			},
		};
	}, [setError]);

	return (
		<>
			<ErrorDialogContext.Provider value={value}>
				{children}
				{error && (
					<ErrorDialog
						size={"sm"}
						closeDialog={() => {
							setError(undefined);
						}}
						error={error}
						disableCustomizedErrorViews={disableCustomizedErrorViews}
					/>
				)}
			</ErrorDialogContext.Provider>
		</>
	);
};
