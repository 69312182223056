import { FormCommonProps } from "../../../../../common/forms/types.ts";
import { AavoForm } from "../../../../../common/forms/AavoForm.tsx";
import i18n from "i18next";
import { FormAsyncSelectField } from "../../../../../common/forms/fields/FormAsyncSelectField.tsx";
import { FetchAsyncOptionParams } from "../../../../../common/inputFields/AsyncSelectField.tsx";
import { PartView } from "src/api/generated/erp/db/types/tables/partView.ts";
import { QrcodeInputField } from "src/components/common/inputFields/QrcodeInputField.tsx";
import { parseWarehouseLocationBarCode } from "src/components/views/erp/warehouse/warehouseLocationBarCodeUtils.ts";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/MessageDialogContext.tsx";
import { requiredNonNegativeFloatRule, requireRule } from "src/components/common/forms/validation.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import {
	FormLazySelectField,
	FormLazySelectFieldProps,
} from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { PartWarehouseLocationQueryApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationQueryApi.ts";
import { SitePartQueryApi } from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView.ts";
import { FieldPath } from "react-hook-form";

export interface ManualInventoryEventFormBaseProps extends FormCommonProps<void> {
	submitForm: (formValues: FormValues) => Promise<void>;
	firstWarehouseLocationField?: {
		label: string;
	};
	secondWarehouseLocationField?: {
		label: string;
	};
}

export interface FormValues {
	part: PartView;
	partConfigurationId: number | null;
	warehouseLocationId: number;
	secondWarehouseLocationId: number | null;
	quantity: number;
}

export const ManualInventoryEventFormBase = ({
	onFormEdited,
	onCompleted,
	submitForm,
	firstWarehouseLocationField = {
		label: i18n.t("warehouse_location"),
	},
	secondWarehouseLocationField,
}: ManualInventoryEventFormBaseProps) => {
	const showMessageDialog = useMessageDialog();
	const { defaultSiteId } = useGlobalInitData();

	return (
		<AavoForm<FormValues>
			defaultValues={{}}
			submit={submitForm}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			layoutSx={{
				gridTemplateColumns: "1fr auto",
				gridTemplateRows: "auto auto auto",
			}}
			render={({ control, setValue, setFocus, watch }) => {
				const currentPart = watch("part");
				return (
					<>
						<FormAsyncSelectField
							label={i18n.t("part")}
							control={control}
							name={"part"}
							fetchOptions={({
								searchQuery,
								currentSelection,
							}: FetchAsyncOptionParams<number>) =>
								SitePartQueryApi.getPartSelectionOptions({
									currentPartId: currentSelection,
									searchQuery: searchQuery,
									siteId: defaultSiteId,
									withWarehouseLocations: true,
								})
							}
							getOptionKey={(option) => option.partId}
							getOptionLabel={(option) =>
								`${option.partNo} | ${option.partDescription_1} | ${option.partDescription_2}`
							}
							formValueType={"option"}
							onChange={() => {
								setValue("partConfigurationId", undefined);
								setValue("warehouseLocationId", undefined);
							}}
						/>
						{currentPart?.isConfigurable === true && (
							<FormAsyncSelectField
								control={control}
								name={"partConfigurationId"}
								label={i18n.t("part_configuration")}
								fetchOptions={({
									searchQuery,
									currentSelection,
								}: FetchAsyncOptionParams<number>) =>
									SitePartQueryApi.getPartConfigurationSelectionOptions({
										currentPartConfigurationId: currentSelection,
										partId: currentPart.partId,
										searchQuery: searchQuery,
									})
								}
								getOptionKey={(option) => option.partConfigurationId}
								getOptionLabel={(option) => option.description}
								rules={requireRule()}
							/>
						)}
						<FormLazySelectField
							control={control}
							name={"warehouseLocationId"}
							label={firstWarehouseLocationField.label}
							disabled={currentPart == null}
							rules={requireRule()}
							{...getWarehouseLocationFieldCommonProps()}
						/>
						{secondWarehouseLocationField && (
							<FormLazySelectField
								control={control}
								name={"secondWarehouseLocationId"}
								label={secondWarehouseLocationField.label}
								disabled={currentPart == null}
								{...getWarehouseLocationFieldCommonProps()}
							/>
						)}
						<FormNumberField
							control={control}
							name={"quantity"}
							label={i18n.t("quantity")}
							rules={requiredNonNegativeFloatRule()}
						/>
						<QrcodeInputField
							sx={{
								gridColumn: "2 / 2",
								gridRow: "1 / -1",
								alignItems: "flex-start",
							}}
							onQrCodeRead={async (qrCodeContent) => {
								const parseCodeResult = await parseWarehouseLocationBarCode(qrCodeContent);
								if (parseCodeResult === "invalid_code") {
									showMessageDialog({
										title: i18n.t("error"),
										content: i18n.t("invalid_warehouse_location_code", {
											code: qrCodeContent,
										}),
									});
								} else {
									setValue("part", parseCodeResult.part);
									setValue(
										"warehouseLocationId",
										parseCodeResult.warehouseLocation.warehouseLocationId,
									);
									setFocus("quantity");
								}
							}}
						/>
					</>
				);

				function getWarehouseLocationFieldCommonProps(): Pick<
					FormLazySelectFieldProps<
						FormValues,
						FieldPath<FormValues>,
						PartWarehouseLocationView,
						number,
						undefined
					>,
					"fetchOptions" | "getOptionKey" | "getOptionLabel"
				> {
					return {
						fetchOptions: async () => {
							if (currentPart == null) return [];
							return await PartWarehouseLocationQueryApi.getPartWarehouseLocations({
								sitePartId: currentPart.partId,
							});
						},
						getOptionKey: (option) => option.warehouseLocationId,
						getOptionLabel: (option) => option.locationCode,
					};
				}
			}}
		/>
	);
};
