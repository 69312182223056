import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { SingleRevisionComponentSelectionsView } from "src/components/views/erp/configurator/managing/productFamilyVersions/revisionConnections/connectRevisionsToProductFamilyVersion/SingleRevisionComponentSelectionsView.tsx";
import { RevisionsToConnectDataGrid } from "src/components/views/erp/configurator/managing/productFamilyVersions/revisionConnections/connectRevisionsToProductFamilyVersion/RevisionsToConnectDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CatalogPartRevisionConfiguratorSelectionsApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionConfiguratorSelectionsApi.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { ProductFamilyVersionConfiguratorSelectionOptions } from "src/api/generated/erp/configurator/management/catalogPart/api/productFamilyVersionConfiguratorSelectionOptions.ts";
import { CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion } from "src/api/generated/erp/configurator/management/service/catalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion.ts";
import { AavoFormActionButtons } from "src/components/common/forms/AavoFormActionButtons.tsx";
import { CatalogPartRevisionProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionProductFamilyVersionApi.ts";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";

export interface ConnectRevisionsToProductFamilyVersionViewProps {
	productFamilyVersionId: number;
	catalogPartRevisions: CatalogPartRevisionView[];
	onDataDirtyStateChanged: DataDirtyStateChangeHandler;
	onSubmit: () => Promise<unknown>;
	onCancel: () => Promise<unknown>;
}

export const ConnectRevisionsToProductFamilyVersionView = (props: ConnectRevisionsToProductFamilyVersionViewProps) => {
	const { productFamilyVersionId } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				CatalogPartRevisionConfiguratorSelectionsApi.getProductFamilyVersionConfiguratorSelectionOptions({
					productFamilyVersionId,
				})
			}
			content={(productFamilySelectionOptions) => (
				<AsyncContent {...props} productFamilySelectionOptions={productFamilySelectionOptions} />
			)}
		/>
	);
};

interface AsyncContentProps extends ConnectRevisionsToProductFamilyVersionViewProps {
	productFamilySelectionOptions: ProductFamilyVersionConfiguratorSelectionOptions;
}

const AsyncContent = ({
	productFamilyVersionId,
	catalogPartRevisions: catalogPartRevisionsInitial,
	productFamilySelectionOptions,
	onDataDirtyStateChanged,
	onSubmit,
	onCancel,
}: AsyncContentProps) => {
	const [catalogPartRevisions, setCatalogPartRevisions] =
		useState<CatalogPartRevisionView[]>(catalogPartRevisionsInitial);

	const [selectedRevision, setSelectedRevision] = useState<CatalogPartRevisionView | undefined>(undefined);

	const [selectionsByRevisionIds, setSelectionsByRevisionIds] = useState<
		Record<number, CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion>
	>({});

	return (
		<VerticalBox flex={1}>
			<AavoMosaic
				viewId={"1F65089363A8B284"}
				layout={{
					type: "row",
					splitPercentage: 25,
					first: {
						type: "panel",
						title: i18n.t("revisions_to_connect"),
						content: (
							<RevisionsToConnectDataGrid
								catalogPartRevisions={catalogPartRevisions}
								selectedRevision={selectedRevision}
								onSelectionChanged={setSelectedRevision}
								removeRevision={removeRevision}
							/>
						),
					},
					second: {
						type: "panel",
						content: (
							<VerticalBox flex={1}>
								{selectedRevision == null && (
									<CenteredTypography>{i18n.t("select_a_revision")}</CenteredTypography>
								)}
								{catalogPartRevisions.map((revision) => (
									<SingleRevisionComponentSelectionsView
										key={revision.catalogPartRevisionId}
										revision={revision}
										productFamilyVersionId={productFamilyVersionId}
										productFamilySelectionOptions={productFamilySelectionOptions}
										isActive={
											revision.catalogPartRevisionId === selectedRevision?.catalogPartRevisionId
										}
										onSelectionsChanged={(selections) =>
											onRevisionSelectionsChanged(revision, selections)
										}
									/>
								))}
							</VerticalBox>
						),
					},
				}}
			/>
			<AavoFormActionButtons onSubmit={submit} onCancel={onCancel} submitLabel={i18n.t("connect")} />
		</VerticalBox>
	);

	function onRevisionSelectionsChanged(
		revision: CatalogPartRevisionView,
		selections: CatalogPartRevisionConfiguratorSelectionsOnProductFamilyVersion,
	) {
		setSelectionsByRevisionIds((prev) => ({
			...prev,
			[revision.catalogPartRevisionId]: selections,
		}));
		onDataDirtyStateChanged({ isDirty: true });
	}

	function removeRevision(revision: CatalogPartRevisionView) {
		setCatalogPartRevisions((prev) =>
			prev.filter((r) => r.catalogPartRevisionId !== revision.catalogPartRevisionId),
		);
	}

	async function submit() {
		await CatalogPartRevisionProductFamilyVersionApi.connectRevisionsToProductFamilyVersion({
			productFamilyVersionId,
			catalogPartRevisions: catalogPartRevisions.map((revision) => ({
				catalogPartRevisionId: revision.catalogPartRevisionId,
				selections: selectionsByRevisionIds[revision.catalogPartRevisionId],
			})),
		});
		await onSubmit();
	}
};
