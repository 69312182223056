// This file is automatically generated. Do not edit manually.

import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView";
import { CustomerOrderType } from "src/api/generated/erp/db/types/tables/customerOrderType";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { Invoice } from "src/api/generated/erp/db/types/tables/invoice";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderQueryApi {
	export async function getCustomerOrder(args: { customerOrderId: number }): Promise<CustomerOrderView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as CustomerOrderView;
	}

	export async function getCustomerOrderOptions(args: {
		searchQuery?: string;
		currentSelection?: number | null | undefined;
		siteId?: number | null | undefined;
	}): Promise<Array<CustomerOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrderOptions",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				currentSelection: args.currentSelection,
				siteId: args.siteId,
			},
		});
		return response as Array<CustomerOrderView>;
	}

	export async function getCustomerOrderTypeOptions(): Promise<Array<CustomerOrderType>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrderTypeOptions",
			method: "POST",
			data: {},
		});
		return response as Array<CustomerOrderType>;
	}

	export async function getCustomerOrderResponsiblePersonOptions(args: {
		currentSelection: number | null | undefined;
		userNameSearchQuery: string;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrderResponsiblePersonOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				userNameSearchQuery: args.userNameSearchQuery,
			},
		});
		return response as Array<AppUser>;
	}

	export async function getCustomerOrderInvoices(args: { customerOrderId: number }): Promise<Array<Invoice>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrderInvoices",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as Array<Invoice>;
	}

	export async function getCustomerOrderHasApprovedCommissionLines(args: {
		customerOrderId: number;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/getCustomerOrderHasApprovedCommissionLines",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as boolean;
	}
}
