import { SubProjectView } from "src/api/generated/erp/db/types/tables/subProjectView.ts";
import { SubProjectApi } from "src/api/generated/erp/project/subProject/api/subProjectApi.ts";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { LabelValueView, LabelValueViewItem } from "src/components/common/misc/LabelValueView.tsx";
import { nullIfBlank } from "src/utils/strings.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { SubProjectForm } from "src/components/views/erp/project/subProject/SubProjectForm.tsx";
import { getSubProjectStateLabel } from "src/api/generated/erp/db/types/enums/subProjectState.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface SubProjectInspectViewProps {
	subProject: SubProjectView;
	onSubProjectUpdated: () => void | Promise<unknown>;
	refreshRef?: RefreshableElementRef;
}

export const SubProjectInspectView = ({
	subProject: subProjectInitial,
	onSubProjectUpdated,
	refreshRef,
}: SubProjectInspectViewProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncFetchRender
			fetch={() => SubProjectApi.getSubProject({ subProjectId: subProjectInitial.subProjectId })}
			defaultValue={subProjectInitial}
			refreshRef={refreshRef}
			content={(subProject, refreshSubProject) => (
				<HorizontalBox
					sx={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<LabelValueView
						sx={{
							flex: 1,
							alignSelf: "flex-start"
						}}
						items={getLabelValueViewItems(subProject)}
					/>
					<VerticalBox
						sx={{
							padding: 1,
						}}
					>
						<AsyncButton
							icon={faPen}
							onClick={() => {
								openFormOnDialog({
									openDialog,
									component: SubProjectForm,
									props: {
										projectId: subProject.projectId,
										subProjectId: subProject.subProjectId,
									},
									title: subProject.subProjectDescription,
									size: "md",
									onSubmit: () =>
										Promise.all([refreshSubProject(), onSubProjectUpdated?.()]),
								});
							}}
						/>
					</VerticalBox>
				</HorizontalBox>
			)}
		/>
	);
};

function getLabelValueViewItems(subProject: SubProjectView): LabelValueViewItem[] {
	return [
		{
			label: i18n.t("name"),
			value: subProject.subProjectDescription,
		},
		{
			label: i18n.t("type"),
			value: subProject.subProjectTypeName,
		},
		{
			label: i18n.t("responsible_person"),
			value: subProject.responsiblePersonName,
		},
		{
			label: i18n.t("state"),
			value: getSubProjectStateLabel(subProject.subProjectState),
		},
		{
			label: i18n.t("planned_start"),
			value: formatIsoString(subProject.plannedStartDate),
		},
		{
			label: i18n.t("planned_end"),
			value: formatIsoString(subProject.plannedEndDate),
		},
		{
			label: i18n.t("created_at"),
			value: formatIsoString(subProject.createdDate, "L LT"),
		},
		subProject.releasedDate != null && {
			label: i18n.t("released_at"),
			value: formatIsoString(subProject.releasedDate, "L LT"),
		},
		subProject.completedDate != null && {
			label: i18n.t("completed_at"),
			value: formatIsoString(subProject.completedDate, "L LT"),
		},
		subProject.cancelledDate != null && {
			label: i18n.t("cancelled_at"),
			value: formatIsoString(subProject.cancelledDate, "L LT"),
		},
		{
			label: i18n.t("note"),
			value: nullIfBlank(subProject.note),
			multiline: true,
		},
		{
			label: i18n.t("starting_sub_project"),
			value: subProject.startAfterSubProjectCompletedDescription,
		},
	];
}
