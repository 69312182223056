import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput";
import { useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";
import i18n from "i18next";
import { CustomerOrderCsvImportApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderCsvImportApi.ts";

export const CustomerOrderImportPage = () => {
	const [file, setFile] = useState<File | null>(null);

	const { showToast } = useAavoToast();

	return (
		<VerticalBox
			sx={{
				flex: 1,
				padding: 1,
				gap: 1,
			}}
		>
			<AavoFileInput
				value={file}
				multiple={false}
				onChange={setFile}
				placeholder={i18n.t("select_a_csv_file_to_import")}
				accept={"text/csv"}
			/>
			<AsyncButton
				label={i18n.t("import")}
				disabled={file === null}
				variant={"contained"}
				color={"primary"}
				sx={{
					alignSelf: "flex-start",
				}}
				onClick={async () => {
					if (file === null) return;
					const fileHandle = await uploadFile(file);
					try {
						await CustomerOrderCsvImportApi.importCustomersAndOrdersFromCsv({
							csvFileHandle: fileHandle,
						});
					} finally {
						setFile(null);
					}
					showToast({ message: i18n.t("customer_order_import_success_message") });
				}}
			/>
		</VerticalBox>
	);
};
