import { ReclamationWithCoordinates } from "src/api/generated/erp/reclamations/api/reclamationWithCoordinates.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import i18n from "i18next";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { TaskQueryParams } from "src/components/views/tasks/types.ts";
import { SendEmailForm } from "src/components/views/email/SendEmailForm";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { Coordinates } from "src/api/generated/common/address/coordinates.ts";
import dedent from "dedent";
import { optimizeRoute } from "src/components/common/mapbox/routes.ts";
import { buildGoogleMapsRouteUrl } from "src/components/common/mapbox/googleMapsRouteUrlBuilder.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";

export interface SendReclamationPlanButtonProps {
	reclamations: ReclamationWithCoordinates[];
	taskExecutorAppUserId: number | null;
	startLocation: Coordinates;
}

export const SendReclamationPlanButton = ({
	reclamations,
	taskExecutorAppUserId,
	startLocation,
}: SendReclamationPlanButtonProps) => {
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("send")}
			icon={faEnvelope}
			variant={"outlined"}
			onClick={async () => {
				const taskQueryParams: TaskQueryParams = {
					states: ["INITIAL", "RELEASED", "STARTED"],
					sources: reclamations.map((reclamation) => ({
						sourceType: "RECLAMATION_V2",
						sourceId: reclamation.reclamationId,
					})),
				};

				const receiverAppUserId = await showInputDialog({
					type: "singleNumberSelect",
					title: i18n.t("task_executor"),
					defaultValue: taskExecutorAppUserId,
					fieldProps: {
						selection: {
							options: async ({ searchQuery, currentSelection }) => {
								const users = await TaskQueryApi.getTaskAssignedToUserOptions({
									...taskQueryParams,
									userNameSearchQuery: searchQuery,
									currentSelection: currentSelection,
								});
								return users.map((user) => ({
									value: user.id,
									label: user.name,
								}));
							},
						},
					},
				});
				if (receiverAppUserId === undefined) return;

				const { rows: tasks } = expectNonFileResult(
					await TaskQueryApi.queryTasks({
						...taskQueryParams,
						assignedToUserId: receiverAppUserId,
					}),
				);

				const emailBody = await buildEmailBody(reclamations, tasks, startLocation);

				openDialog(({ closeDialog }) => ({
					title: i18n.t("send_plan"),
					content: (
						<SendEmailForm
							defaultValues={{
								body: emailBody,
							}}
							onCompleted={() => {
								closeDialog();
							}}
						/>
					),
				}));
			}}
		/>
	);
};

const buildEmailBody = async (
	reclamations: ReclamationWithCoordinates[],
	tasks: TaskView[],
	startLocation: Coordinates,
): Promise<string> => {
	const mapsLink = await buildGoogleMapsLink(startLocation, reclamations);
	const ret = mapsLink + "<br/><br/>" + buildEmailReclamationsPart(reclamations, tasks);
	return dedent(ret).replace(/\n/g, "");
};

const buildGoogleMapsLink = async (
	startLocation: Coordinates,
	reclamations: ReclamationWithCoordinates[],
): Promise<string> => {
	const reclamationCoords = reclamations.map((reclamation) => reclamation.addressCoords!);
	const optimizedRoute = await optimizeRoute([startLocation, ...reclamationCoords]);

	const url = buildGoogleMapsRouteUrl({
		origin: startLocation,
		destination: startLocation,
		waypoints: optimizedRoute.slice(1),
	});

	return `<a href="${url}">${i18n.t("open_route_on_google_maps")}</a>`;
};

const buildEmailReclamationsPart = (
	reclamations: ReclamationWithCoordinates[],
	tasks: TaskView[],
): string => {
	return reclamations.reduce((acc, reclamation) => {
		const reclamationTasks = tasks.filter((task) => task.sourceId === reclamation.reclamationId);
		if (reclamationTasks.length === 0) return acc;

		return acc + buildBodyForReclamation(reclamation, reclamationTasks);
	}, "");
};

const buildBodyForReclamation = (reclamation: ReclamationWithCoordinates, tasks: TaskView[]): string => {
	const address = reclamation.address;

	const customerOrderPart =
		reclamation.customerOrderId == null ?
			""
		:	`${i18n.t("customer_order")} ${reclamation.customerOrderId} | ${reclamation.customerOrderReference}`;

	const tasksPart = tasks.reduce((acc, task) => {
		return acc + buildTaskString(task);
	}, "");

	return dedent(`
		<h2>${reclamation.title}</h2>
		${address.address_1}, ${address.city}<br/>
		${withLineBreakIfNotBlank(reclamation.description)}
		${withLineBreakIfNotBlank(customerOrderPart)}
		${i18n.t("complainer")}: ${reclamation.complainerName}<br/>
		${i18n.t("email")}: ${reclamation.complainerEmail}<br/>
		${i18n.t("phone")}: ${reclamation.complainerPhone}<br/>
		<br/>
		${tasksPart}
		`);
};

const buildTaskString = (task: TaskView): string => {
	return `<h4>${task.title}</h4>
		${withLineBreakIfNotBlank(task.taskDescription)}
		${i18n.t("deadline_date")}: ${formatIsoString(task.deadlineDate)}
		<br/><br/>
	`;
};

const withLineBreakIfNotBlank = (str: string): string => {
	return str.trim() === "" ? "" : str + "<br/>";
};
