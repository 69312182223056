import { TaskSourceType } from "src/api/generated/postgres/db/types/tasks/enums/taskSourceType.ts";
import type { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { OpenLegacyViewFunction } from "src/components/views/legacy/useOpenLegacyView.ts";
import { ProjectActivityApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityApi.ts";
import { SingleReclamationView } from "../../erp/reclamation/SingleReclamationView.tsx";
import { ProjectActivitiesContainerView } from "src/components/views/erp/project/projectActivity/ProjectActivitiesContainerView.tsx";
import i18n from "i18next";
import { CustomerOrdersPage } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPage.tsx";
import { CustomerOrderOffersPage } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOffersPage.tsx";
import { WorkspacesView } from "src/components/views/workspaces/WorkspacesView.tsx";

export interface TaskSourceActions {
	openSource: (params: OpenTaskSourceParams) => Promise<void>;
}

export interface OpenTaskSourceParams {
	sourceId: number;
	openGenericDialog: OpenGenericDialogFunc;
	openLegacyView: OpenLegacyViewFunction;
}

export const getTaskSourceActions = (sourceType: TaskSourceType): TaskSourceActions => {
	switch (sourceType) {
		case "PROJECT_ACTIVITY":
			return projectActivityTaskSourceActions;

		case "CUSTOMER_ORDER":
			return customerOrderTaskSourceActions;

		case "CUSTOMER_ORDER_OFFER":
			return customerOrderOfferTaskSourceActions;

		case "SAFETY_CONTROL_SURVEY":
			return safetyControlSurveyTaskSourceActions;

		case "PRODUCT_CHANGE_REQUEST":
			return productChangeRequestTaskSourceActions;

		case "RECLAMATION":
			return reclamationTaskSourceActions;

		case "RECLAMATION_V2":
			return reclamationV2TaskSourceActions;

		case "PROCESS_IMPROVEMENT":
			return processImprovementTaskSourceActions;

		case "WORKSPACE":
			return workspaceTaskSourceActions;
	}
};

const projectActivityTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openGenericDialog }) => {
		const activity = await ProjectActivityApi.getSingleActivity({
			activityId: sourceId,
		});
		openGenericDialog({
			title: i18n.t("activity"),
			content: (
				<ProjectActivitiesContainerView
					subProjectId={activity.subProjectId}
					onlyActivityId={activity.activityId}
				/>
			),
		});
	},
};

const customerOrderTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openGenericDialog }) => {
		openGenericDialog({
			title: i18n.t("customer_order"),
			content: <CustomerOrdersPage onlyCustomerOrderToShowId={sourceId} />,
		});
	},
};

const customerOrderOfferTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openGenericDialog }) => {
		openGenericDialog({
			title: i18n.t("customer_order_offer"),
			content: <CustomerOrderOffersPage onlyCustomerOrderOfferToShowId={sourceId} />,
		});
	},
};

const safetyControlSurveyTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openLegacyView }) => {
		openLegacyView("8654300BDB2F06B9", {
			safetyControlSurveyIdFilter: sourceId,
		});
	},
};

const productChangeRequestTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openLegacyView }) => {
		openLegacyView("55779DD6319D59A4", {
			_product_change_request_id_filter: sourceId,
		});
	},
};

const reclamationTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openLegacyView }) => {
		openLegacyView("5d103f17-6dcd-4b42-b9f6-5b379a99ecf6", {
			_reclamation_id_filter: sourceId,
		});
	},
};

const processImprovementTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openLegacyView }) => {
		openLegacyView("6d2b012a-7f8e-43b3-8e00-9b57fcfff360", {
			_process_improvement_id_filter: sourceId,
		});
	},
};

const workspaceTaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openGenericDialog }) => {
		openGenericDialog({
			title: i18n.t("workspace"),
			content: <WorkspacesView onlyWorkspaceIdToShow={sourceId} />,
		});
	},
};

const reclamationV2TaskSourceActions: TaskSourceActions = {
	openSource: async ({ sourceId, openGenericDialog }) => {
		openGenericDialog(() => ({
			title: "Reclamation",
			content: <SingleReclamationView reclamationId={sourceId} />,
		}));
	},
};
