import {
	ControlChartRecordsDataGridApi,
	ControlChartRecordsDataGridApi_Row,
} from "src/api/generated/spc/controlChart/api/controlChartRecordsDataGridApi.ts";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
	booleanColumn,
	dateTimeColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { nullIfBlank } from "src/utils/strings.tsx";
import { getSpecialCauseDescriptionForCode } from "src/components/views/spc/specialCauseUtils.ts";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ControlChartsDataExportForm } from "src/components/views/spc/basedata/controlCharts/ControlChartsDataExportForm.tsx";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";

export interface ControlChartRecordsDataGridProps {
	controlChartId: number;
}

export const ControlChartRecordsDataGrid = ({ controlChartId }: ControlChartRecordsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ServerSideDataGridModel
			fetchData={({ dataModelRequest }) =>
				ControlChartRecordsDataGridApi.getGridData({
					controlChartId,
					dataModelRequest,
				})
			}
			initialParams={{}}
			getDataModelResult={(data) => data.dataModelResult}
			getRowId={(row) => row.recordId}
			gridId={"D12C16FCA1B244B87"}
			render={({ dataGridProps, data: { controlChart } }) => {
				const primaryValueDescription = nullIfBlank(controlChart.valueDescription) ?? i18n.t("value");
				return (
					<CrudDataGrid<ControlChartRecordsDataGridApi_Row>
						disableColumnFilter
						disableColumnSorting
						columns={[
							dateTimeColumn({
								field: "observationTime",
								headerName: i18n.t("recorded_at"),
								width: 140,
							}),
							booleanColumn({
								field: "enabled",
								headerName: i18n.t("enabled"),
								editable: true,
							}),
							(controlChart.timeBasedMeasurements || controlChart.subgroupSize > 1) &&
								// If value-based measurements and subgroup size is 1,
								// then the first sub value will be displayed as the primary value
								// to make editing easier.
								floatColumn({
									field: "primaryValue",
									headerName: primaryValueDescription,
								}),
							controlChart.timeBasedMeasurements && [
								dateTimeColumn({
									field: "startTime",
									headerName: i18n.t("start_time"),
								}),
								dateTimeColumn({
									field: "endTime",
									headerName: i18n.t("end_time"),
								}),
							],
							!controlChart.timeBasedMeasurements &&
								Array.from({ length: controlChart.subgroupSize }).map((_, index) =>
									createSubValueColumn(index),
								),
							textColumn({
								field: "info1",
								headerName: nullIfBlank(controlChart.info1description) ?? i18n.t("info1"),
								editable: true,
							}),
							textColumn({
								field: "info2",
								headerName: nullIfBlank(controlChart.info2description) ?? i18n.t("info2"),
								editable: true,
							}),
							textColumn({
								field: "specialCauseCode",
								headerName: i18n.t("special_cause"),
								valueGetter: (_, row) =>
									getSpecialCauseDescriptionForCode(row.specialCauseCode),
								width: 300,
							}),
							textColumn({
								field: "eventOcs",
								headerName: i18n.t("reason"),
								width: 200,
							}),
							integerColumn({
								field: "recordId",
								headerName: i18n.t("id"),
							}),
						]}
						save={async ({ items }) => {
							return await ControlChartRecordsDataGridApi.update({
								rows: items,
							});
						}}
						actionBarComponents={
							<>
								<AavoButton
									icon={faCloudDownloadAlt}
									label={i18n.t("export_data")}
									variant={"outlined"}
									onClick={() => {
										openFormOnDialog({
											openDialog,
											title: i18n.t("export_data"),
											size: "sm",
											component: ControlChartsDataExportForm,
											props: {
												controlChartIds: [controlChartId],
											},
											confirmCloseIfEdited: false,
										});
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);

				function createSubValueColumn(index: number): GridColDef<ControlChartRecordsDataGridApi_Row> {
					const headerName =
						controlChart.subgroupSize === 1 ?
							primaryValueDescription
						:	i18n.t("sub_value_n", { n: index + 1 });

					return floatColumn({
						field: `subValues.${index}` as any,
						headerName: headerName,
						editable: true,
						valueGetter: (_, row) => row.subValues[index],
						valueSetter: (value, row) => ({
							...row,
							subValues: row.subValues.map((subValue, i) => {
								if (i === index) return value;
								return subValue;
							}),
						}),
					});
				}
			}}
		/>
	);
};
