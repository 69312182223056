import {
	WorkspaceConnectedItemType,
	WorkspaceConnectedItemTypeValues,
} from "src/api/generated/postgres/db/types/workspaces/enums/workspaceConnectedItemType.ts";
import { workspaceCatalogPartConnectionsConfiguration } from "src/components/views/workspaces/workspaceConnections/connectionTypes/catalogPart/workspaceCatalogPartConnectionsConfiguration.tsx";
import { workspaceProjectConnectionsConfiguration } from "./project/workspaceProjectConnectionsConfiguration";
import { workspaceCustomerOrderConnectionsConfiguration } from "src/components/views/workspaces/workspaceConnections/connectionTypes/customerOrder/workspaceCustomerOrderConnectionsConfiguration.tsx";

export const getAllWorkspaceConnectedItemTypeConfigurations = () =>
	WorkspaceConnectedItemTypeValues.map((type) => getWorkspaceConnectedItemConfiguration(type));

export const getWorkspaceConnectedItemConfiguration = (type: WorkspaceConnectedItemType) => {
	switch (type) {
		case "CATALOG_PART":
			return workspaceCatalogPartConnectionsConfiguration;
		case "CUSTOMER_ORDER":
			return workspaceCustomerOrderConnectionsConfiguration;
		case "PROJECT":
			return workspaceProjectConnectionsConfiguration;
	}
};
