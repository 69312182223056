import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { getAllWorkspaceConnectedItemTypeConfigurations } from "./connectionTypes/workspaceConnectedItemConfigurations";

export interface AddWorkspaceConnectionsViewProps {
	workspaceId: number;
	closeDialog: () => void;
	connectionViewRefreshRef: RefreshableElementRef;
}

export const AddWorkspaceConnectionsView = ({
	workspaceId,
	closeDialog: closeThisViewDialog,
	connectionViewRefreshRef,
}: AddWorkspaceConnectionsViewProps) => {
	const connectedItemTypeConfigurations = getAllWorkspaceConnectedItemTypeConfigurations();
	const { openDialog } = useGenericDialog();
	return (
		<VerticalBox
			flex={1}
			sx={{
				margin: "0.5rem",
				gap: "1rem",
			}}
		>
			{connectedItemTypeConfigurations.map((config) => (
				<AsyncButton
					icon={config.icon}
					label={config.typeLabel}
					variant={"outlined"}
					onClick={() => {
						openDialog(({ closeDialog }) => ({
							size: "xl",
							title: config.typeLabel,
							content: config.addNewConnectionsView({
								workspaceId,
								onCompleted: async () => {
									await closeDialog();
									await connectionViewRefreshRef.refresh();
								},
							}),
						}));
						closeThisViewDialog();
					}}
				/>
			))}
		</VerticalBox>
	);
};
