import {
    ReclamationsDataGridBase,
    ReclamationsDataGridBaseProps,
} from "src/components/views/erp/reclamation/ReclamationsDataGridBase.tsx";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import i18n from "i18next";
import {faCalendarWeek} from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {ReclamationPlanningView} from "src/components/views/erp/reclamation/planning/ReclamationPlanningView.tsx";

export interface OpenReclamationsDataGridProps
	extends Omit<
		ReclamationsDataGridBaseProps,
		"states" | "actionBarComponents" | "usePagination" | "gridId"
	> {}

export const OpenReclamationsDataGrid = ({
	selectedReclamations,
	...other
}: OpenReclamationsDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ReclamationsDataGridBase
			states={["INITIAL", "RELEASED"]}
			usePagination={false}
			selectedReclamations={selectedReclamations}
			actionBarComponents={
				<>
					<AavoButton
						label={i18n.t("planning")}
						icon={faCalendarWeek}
						variant={"outlined"}
						disabled={selectedReclamations.length === 0}
						onClick={() => {
							openDialog(() => ({
								title: i18n.t("planning"),
								content: (
									<ReclamationPlanningView
										reclamationIds={selectedReclamations.map((r) => r.reclamationId)}
									/>
								),
							}));
						}}
					/>
				</>
			}
			gridId={"3C6A44837D12449C"}
			{...other}
		/>
	);
};
