import { AavoTreeView } from "src/components/common/tree/AavoTreeView.tsx";
import React, { useCallback, useRef } from "react";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import i18n from "i18next";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { TreeItem2, TreeItem2Content, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { faAngleDown, faAngleRight, faPenToSquare, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { UseTreeItem2Status } from "@mui/x-tree-view/useTreeItem2";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, useTheme } from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { alpha } from "@mui/material/styles";
import { useDebounce } from "src/utils/useDebounce.ts";
import { WorkspaceTreeViewContextMenu } from "src/components/views/workspaces/treeView/components/WorkspaceTreeViewContextMenu.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { WorkspaceForm } from "src/components/views/workspaces/WorkspaceForm.tsx";
import { useDrag, useDrop } from "react-dnd";
import { WorkspaceTreeViewRootLevelDropArea } from "src/components/views/workspaces/treeView/components/WorkspaceTreeViewRootLevelDropArea.tsx";
import { WorkspaceTreeViewItemModel } from "src/components/views/workspaces/treeView/WorkspacesTreeViewState.ts";
import { WorkspacesTreeViewContext } from "./WorkspaceTreeViewContext";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { WorkspaceMobileContentView } from "src/components/views/workspaces/WorkspaceMobileContentView.tsx";
import { toIntOrNull } from "src/utils/strings.tsx";

export interface WorkspacesTreeStatelessViewProps {
	showArchived: boolean;
	onSelectedItemChanged: (workspaceId: number | null) => void;
}

export const WorkspacesTreeStatelessView = ({
	showArchived,
	onSelectedItemChanged,
}: WorkspacesTreeStatelessViewProps) => {
	const debounceSearchQuery = useDebounce();
	const { openDialog } = useGenericDialog();

	const { refresh, state, dispatch, categoryOptions } = useContextOrThrow(WorkspacesTreeViewContext);

	return (
		<VerticalBox flex={1}>
			<AavoTreeView
				items={state.filteredTreeItems}
				getItemId={(item) => item.workspaceId.toString()}
				getItemLabel={(item) => item.workspaceName}
				slots={{
					item: CustomTreeItem,
				}}
				expandedItems={state.expandedWorkspaceIds.map((id) => id.toString())}
				onExpandedItemsChange={(itemsIds) =>
					dispatch({
						type: "setExpandedWorkspaces",
						workspaceIds: itemsIds.map(Number),
					})
				}
				selectedItems={state.selectedWorkspace?.workspaceId?.toString()}
				onSelectedItemsChange={(_, itemId) => onSelectedItemChanged(toIntOrNull(itemId))}
				multiSelect={false}
				refresh={refresh}
				actionBarComponents={
					<>
						<AsyncButton
							icon={faPlus}
							onClick={async () => {
								openFormOnDialog({
									openDialog: openDialog,
									component: WorkspaceForm,
									title: i18n.t("create_workspace"),
									props: {
										workspaceId: null,
										parentId: null,
									},
									onSubmit: () => refresh(),
									size: "md",
								});
							}}
						/>
						<AsyncButton
							icon={faPenToSquare}
							disabled={
								state.selectedWorkspace === null ? i18n.t("select_workspace")
								: !state.selectedWorkspace.adminPermissions ?
									i18n.t("no_admin_permissions")
								:	undefined
							}
							onClick={async () => {
								if (state.selectedWorkspace === null) return;

								openFormOnDialog({
									openDialog: openDialog,
									component: WorkspaceForm,
									title: i18n.t("edit"),
									props: {
										workspaceId: state.selectedWorkspace.workspaceId ?? null,
										parentId: state.selectedWorkspace.parentId ?? null,
									},
									onSubmit: () => refresh(),
									size: "md",
								});
							}}
						/>
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={(searchString) => {
								dispatch({
									type: "setSearchQuery",
									searchQuery: searchString,
								});
							}}
							onChange={(searchString) => {
								debounceSearchQuery(500, () => {
									dispatch({
										type: "setSearchQuery",
										searchQuery: searchString,
									});
								});
							}}
						/>
						<SelectField
							label={i18n.t("category")}
							options={categoryOptions}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => option.categoryName}
							onChange={async (value) => {
								dispatch({
									type: "setCategoryFilter",
									categoryId: value,
								});
							}}
							value={state.categoryIdFilter}
						/>
					</>
				}
				actionBarMenuComponents={[
					<MenuCheckbox
						key={"showArchived"}
						label={i18n.t("show_archived")}
						checked={showArchived}
						onChange={async (value) => {
							await refresh({ showArchived: value });
						}}
					/>,
					<MenuCheckbox
						key={"enableTreeEdit"}
						label={i18n.t("enable_tree_edit")}
						checked={state.treeViewEditable}
						onClick={() => {
							dispatch({
								type: "setEditable",
								editable: !state.treeViewEditable,
							});
						}}
					/>,
				]}
			/>
			{state.treeViewEditable && <WorkspaceTreeViewRootLevelDropArea refresh={refresh} />}
		</VerticalBox>
	);
};

const CustomTreeItem = React.memo((props: TreeItem2Props) => {
	const { itemId } = props;

	const { openDialog } = useGenericDialog();
	const theme = useTheme();
	const labelRef = useRef(null);
	const { state, setParentId } = useContextOrThrow(WorkspacesTreeViewContext);
	const isMobile = useMediaQueryBreakpointDown("sm");

	const { publicAPI } = useTreeItem2(props);
	const treeItem = publicAPI.getItem(itemId) as WorkspaceTreeViewItemModel;

	const [{ isDragging }, drag] = useDrag(() => ({
		type: WORKSPACE_DND_TYPE,
		item: treeItem,
		canDrag: treeItem.adminPermissions,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	const onDrop = useCallback(
		async (dropped: WorkspaceTreeViewItemModel) => {
			setParentId({ workspaceId: dropped.workspaceId, parentId: treeItem.workspaceId });
		},
		[treeItem.workspaceId, setParentId],
	);

	const [{ canDrop, isDraggingOver }, drop] = useDrop(
		() => ({
			accept: WORKSPACE_DND_TYPE,
			drop: state.treeViewEditable ? onDrop : undefined,
			collect: (monitor) => ({
				canDrop: monitor.canDrop(),
				isDraggingOver: monitor.isOver(),
			}),
		}),
		[onDrop],
	);

	if (state.treeViewEditable) drag(drop(labelRef));

	return (
		<TreeItem2
			{...props}
			slots={{
				content: CustomTreeItemContent,
				expandIcon: CustomCollapseExpandIcon,
				collapseIcon: CustomCollapseExpandIcon,
			}}
			label={
				<HorizontalBox
					sx={{
						opacity: isDragging || (canDrop && isDraggingOver) ? 0.5 : 1,
						alignItems: "center",
						gap: 1,
						backgroundColor:
							treeItem.directSearchMatch ? alpha(theme.palette.secondary.light, 0.4) : undefined,
						paddingY: 0.25,
						paddingX: 1,
						borderRadius: 1.5,
					}}
				>
					<Typography
						ref={labelRef}
						onClick={() => {
							if (!isMobile) return;
							openDialog({
								title: treeItem.workspaceName,
								content: <WorkspaceMobileContentView workspace={treeItem} />,
							});
						}}
						children={treeItem.workspaceName}
						sx={{
							color: treeItem.isArchived ? theme.palette.text.disabled : undefined,
						}}
					/>
				</HorizontalBox>
			}
			slotProps={{
				label: {
					item: treeItem,
				} as any,
				content: {
					item: treeItem,
				} as any,
				expandIcon: {
					icon: faAngleRight,
				} as any,
				collapseIcon: {
					icon: faAngleDown,
				} as any,
			}}
		/>
	);
});

const CustomTreeItemContent = React.forwardRef(
	(
		{
			item,
			...other
		}: {
			item: WorkspaceTreeViewItemModel;
			status: UseTreeItem2Status;
		} & unknown,
		ref: React.ForwardedRef<HTMLDivElement>,
	) => {
		const [showContextMenu, contextMenuState] = useContextMenu();

		return (
			<>
				<TreeItem2Content
					ref={ref}
					onContextMenu={(event) => {
						event.preventDefault();
						showContextMenu({
							mouseEvent: event,
							content: <WorkspaceTreeViewContextMenu workspace={item} />,
						});
					}}
					{...other}
				/>
				<AavoContextMenu state={contextMenuState} />
			</>
		);
	},
);

const CustomCollapseExpandIcon = ({ icon }: { icon: IconDefinition }) => {
	return (
		<HorizontalBox
			sx={{
				marginTop: -1.75,
				marginBottom: -1.75,
				marginRight: 1,
				alignItems: "center",
			}}
		>
			<FontAwesomeIcon icon={icon} />
		</HorizontalBox>
	);
};

export const WORKSPACE_DND_TYPE = "WORKSPACE";
