import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {
    SalesPartContentLineFormApi,
    SalesPartContentLineFormApi_FormInitData,
} from "src/api/generated/erp/sales/basedata/api/salesPartContentLineFormApi.ts";
import {SalesPartPackageLine} from "src/api/generated/erp/db/types/tables/salesPartPackageLine.ts";
import i18n from "i18next";
import {concatWithPipe} from "src/utils/strings.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {FetchAsyncOptionParams} from "src/components/common/inputFields/AsyncSelectField.tsx";

export interface SalesPartContentLineFormProps extends FormCommonProps<number> {
	childSalesPartPackageLineId: number | undefined;
	parentSalesPartId: number;
}

export const SalesPartContentLineForm = (props: SalesPartContentLineFormProps) => {
	const { childSalesPartPackageLineId, parentSalesPartId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				SalesPartContentLineFormApi.getFormData({
					parentSalesPartId: parentSalesPartId,
					childSalesPartPackageLineId: childSalesPartPackageLineId,
				})
			}
			getDefaultValues={({ childSalesPart, nextLineNumber }) =>
				childSalesPart != null ?
					{ ...childSalesPart }
				:	{
						lineNumber: nextLineNumber,
						parentSalesPartId: parentSalesPartId,
					}
			}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: SalesPartPackageLine) {
		if (values.salesPartPackageLineId == null) {
			return await SalesPartContentLineFormApi.insert({ salesPartPackageLine: values });
		} else {
			return await SalesPartContentLineFormApi.update({ salesPartPackageLine: values });
		}
	}
};

interface FormContentProps
	extends SalesPartContentLineFormProps,
		AsyncFormContentParams<SalesPartContentLineFormApi_FormInitData, SalesPartPackageLine> {}

const FormContent = ({ control, parentSalesPartId }: FormContentProps) => {
	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"childSalesPartId"}
				label={i18n.t("sales_part")}
				fetchOptions={({ currentSelection, searchQuery }: FetchAsyncOptionParams<number>) =>
					SalesPartContentLineFormApi.getSalesPartOptions({
						parentSalesPartId: parentSalesPartId,
						searchQuery: searchQuery,
						currentSelection: currentSelection,
					})
				}
				getOptionKey={(option) => option.salesPartId}
				getOptionLabel={(option) => concatWithPipe(option.salesPartNo, option.salesPartDescription)}
				rules={requireRule()}
			/>
			<FormNumberField control={control} label={i18n.t("line_number")} name="lineNumber" rules={requireRule()} />
			<FormNumberField name={"quantity"} label={i18n.t("quantity")} control={control} rules={requireRule()} />
		</>
	);
};
