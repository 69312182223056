import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {SitePartSupplierApi} from "src/api/generated/erp/parts/sitePart/api/sitePartSupplierApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {coloredBooleanColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {SupplierPartView} from "src/api/generated/erp/db/types/tables/supplierPartView.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {OpenObjectChangeLogButton} from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import {SitePartSupplierForm} from "src/components/views/erp/parts/siteParts/SitePartSupplierForm.tsx";
import {SitePartSupplierEditApi} from "src/api/generated/erp/parts/sitePart/api/sitePartSupplierEditApi.ts";

export interface SitePartSuppliersDataGridProps {
	partId: number;
}

export const SitePartSuppliersDataGrid = ({ partId }: SitePartSuppliersDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"63148594DDD342FF"}
			fetchData={() =>
				SitePartSupplierApi.getPartSuppliers({
					partId: partId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.supplierPartId}
			initialParams={{}}
			render={({ onlySelectedRow, dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<SupplierPartView>
						columns={[
							textColumn({
								field: "supplierName",
								headerName: i18n.t("supplier"),
								width: 200,
							}),
							textColumn({
								field: "purchaseUnit",
								headerName: i18n.t("purchase_unit"),
								width: 120,
							}),
							textColumn({
								field: "purchaseFactor",
								headerName: i18n.t("purchase_factor"),
								width: 120,
							}),
							textColumn({
								field: "purchasePriceUnit",
								headerName: i18n.t("purchase_price_unit"),
								width: 135,
							}),
							textColumn({
								field: "purchasePriceFactor",
								headerName: i18n.t("purchase_price_factor"),
								width: 135,
							}),
							textColumn({
								field: "purchasePrice",
								headerName: i18n.t("purchase_price"),
							}),
							textColumn({
								field: "packSize",
								headerName: i18n.t("pack_size"),
							}),
							textColumn({
								field: "supplierPartNo",
								headerName: i18n.t("supplier_part_no"),
								width: 150,
							}),
							textColumn({
								field: "supplierPartDescription",
								headerName: i18n.t("supplier_part_description"),
								width: 140,
							}),
							textColumn({
								field: "deliveryDuration",
								headerName: i18n.t("delivery_duration"),
							}),
							coloredBooleanColumn({
								field: "isDefault",
								headerName: i18n.t("default"),
								falseColor: "grey",
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"SUPPLIER_PART",
										onlySelectedRow?.supplierPartId,
									)}
								/>
								<AavoButton
									label={i18n.t("set_to_default")}
									icon={faCheck}
									variant={"outlined"}
									disabled={onlySelectedRow == undefined}
									onClick={async () => {
										if (onlySelectedRow == undefined) return;
										await SitePartSupplierApi.setDefaultSupplier({
											partId: partId,
											supplierId: onlySelectedRow.supplierId,
										});
										await refreshData();
									}}
								/>
							</>
						}
						rowContextMenuComponents={({ row }) => [
							<OpenObjectChangeLogButton
								key={"changeLog"}
								objectRef={{
									objectType: "SUPPLIER_PART",
									objectId: row.supplierPartId,
								}}
							/>,
						]}
						form={{
							addRowEnabled: true,
							editEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("supplier_part"),
							component: ({ row, ...other }) => (
								<SitePartSupplierForm
									partId={partId}
									supplierPartId={row?.supplierPartId}
									{...other}
								/>
							),
						}}
						remove={{
							type: "enabled",
							action: async (params) =>
								await SitePartSupplierEditApi.delete_({
									supplierParts: params.items,
								}),
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
