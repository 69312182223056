import {
	CustomerOrderInfoViewApi,
	CustomerOrderInfoViewApi_CustomerOrderInfoViewData_MainDocument,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderInfoViewApi";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";

export interface CustomerOrderInfoViewProps {
	customerOrderId: number;
}

export const CustomerOrderInfoView = ({ customerOrderId }: CustomerOrderInfoViewProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncFetchRender
			fetch={() => CustomerOrderInfoViewApi.getCustomerOrderInfoViewData({ customerOrderId })}
			content={({ mainDocument }) =>
				mainDocument && (
					<HorizontalBox flex={1} margin={1} overflow={"auto"}>
						<img
							src={mainDocument.fileHandle.fileUrl}
							alt="image"
							style={{
								marginLeft: "auto",
								marginRight: "auto",
							}}
							onClick={() => {
								openDocument(mainDocument);
							}}
						/>
					</HorizontalBox>
				)
			}
		/>
	);

	function openDocument(
		document: CustomerOrderInfoViewApi_CustomerOrderInfoViewData_MainDocument,
	) {
		openDialog(() => ({
			title: document.description,
			content: <DocumentPreviewView documentId={document.documentId} />,
		}));
	}
};
