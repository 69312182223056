import { AavoViewsDispatchContext } from "src/components/views/legacy/AavoViewsContext.tsx";
import { Primitive } from "src/types/primitive.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { useStartUpData } from "src/contexts/StartUpDataContext.ts";

export type OpenLegacyViewFunction = (
	idAnchor: string,
	viewParams: Record<string, Primitive>,
	refreshSource?: () => void
) => void;

export const useOpenLegacyView = (): OpenLegacyViewFunction => {
	const startUpData = useStartUpData();
	const dispatch = useContextOrThrow(AavoViewsDispatchContext);
	return (idAnchor: string, viewParams: Record<string, Primitive>, refreshSource?: () => void) => {
		const view = Object.values(startUpData?.views ?? {}).find((v) => {
			return v.view.idAnchor === idAnchor;
		});
		if (view === undefined) {
			throw Error(`Can not open view ${idAnchor}`);
		}
		dispatch({
			type: "openLegacyModal",
			modalViewState: {
				view: view,
				sourceViewId: null,
				viewParams: Object.entries(viewParams).map(([key, value]) => ({ key, value })),
				refreshSourceView: refreshSource
			},
		});
	};
};
