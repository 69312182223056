import { PartConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import i18n from "i18next";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorFormResult } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorForm.tsx";
import { faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CatalogPartRevisionConfiguratorSelectionsAsyncView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsAsyncView.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { ErrorView } from "src/components/common/errors/ErrorView.tsx";
import { CatalogPartTestConfiguratorResultsView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorResultsView.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";

export interface CatalogPartTestConfiguratorViewProps {
	productFamilyVersionId: number;
	catalogPartRevisionId: number;
	closeDialog?: () => void;
}

export const CatalogPartTestConfiguratorView = ({
	catalogPartRevisionId,
	productFamilyVersionId,
	closeDialog,
}: CatalogPartTestConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();

	const configuratorType: PartConfiguratorType = {
		type: "catalogPartTest",
		catalogPartRevisionId: catalogPartRevisionId,
		productFamilyVersionId: productFamilyVersionId,
		isTestConfigurator: true,
		isSubConfigurator: false,
	};

	const transformComponentsRefreshRef = useRefreshRef();

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
			footerExtraComponents={
				<AsyncButton
					icon={faSquareCheck}
					label={i18n.t("select_components")}
					color={"secondary"}
					onClick={onSelectComponentsClick}
				/>
			}
			transformComponentsRefreshRef={transformComponentsRefreshRef}
			transformComponentsErrorContent={({ error, reload }) => (
				<VerticalBox
					sx={{
						flex: 1,
						gap: 1,
						marginBottom: 1,
					}}
				>
					<ErrorView
						error={error}
						reload={reload}
						sx={{
							flex: 1,
						}}
					/>
					<HorizontalBox justifyContent={"center"}>
						<AsyncButton
							icon={faSquareCheck}
							label={i18n.t("select_components")}
							variant={"outlined"}
							onClick={onSelectComponentsClick}
						/>
					</HorizontalBox>
				</VerticalBox>
			)}
		/>
	);

	function onSubmit({ propertyValues, configurationSessionId }: PartConfiguratorFormResult) {
		openDialog(() => ({
			title: i18n.t("test_configuration"),
			content: (
				<CatalogPartTestConfiguratorResultsView
					configurationSessionId={configurationSessionId}
					configuratorType={configuratorType}
					propertyValues={propertyValues}
				/>
			),
		}));
	}

	function onSelectComponentsClick() {
		openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
			title: i18n.t("select_components_for_test_configurator"),
			size: "xl",
			content: (
				<CatalogPartRevisionConfiguratorSelectionsAsyncView
					catalogPartRevisionId={catalogPartRevisionId}
					productFamilyVersionId={productFamilyVersionId}
					onSubmitted={async () => {
						await closeDialog();
						await transformComponentsRefreshRef.refresh();
					}}
					onCancelled={async () => {
						await closeDialog({ confirmIfEdited: true });
					}}
					onDataDirtyStateChanged={onDataDirtyStateChanged}
				/>
			),
		}));
	}
};
