// This file is automatically generated. Do not edit manually.

import { SalesPartPackageLineView } from "src/api/generated/erp/db/types/tables/salesPartPackageLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartContentLinesApi {
	export async function getContentLines(args: {
		parentSalesPartId: number;
	}): Promise<Array<SalesPartPackageLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartContentLines/getContentLines",
			method: "POST",
			data: {
				parentSalesPartId: args.parentSalesPartId,
			},
		});
		return response as Array<SalesPartPackageLineView>;
	}

	export async function delete_(args: { salesPartPackageLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesPartContentLines/delete",
			method: "POST",
			data: {
				salesPartPackageLineIds: args.salesPartPackageLineIds,
			},
		});
		return response as void;
	}
}
