import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {ControlledAsyncDataGrid} from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import {
    dateColumn,
    enumColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getCustomerOrderStateLabels} from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import {ProjectCustomerOrdersApi} from "src/api/generated/erp/project/project/api/projectCustomerOrdersApi.ts";
import {faUnlink} from "@fortawesome/pro-regular-svg-icons";
import {OpenCustomerOrderButton} from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import {CustomerOrderView} from "src/api/generated/erp/db/types/tables/customerOrderView.ts";

export interface ProjectCustomerOrdersDataGridProps {
    projectId: number;
}

export const ProjectCustomerOrdersDataGrid = ({projectId}: ProjectCustomerOrdersDataGridProps) => {
    const showConfirmDialog = useConfirmDialog();

    const {dataGridProps, onlySelectedRow, refreshData} = useClientSideDataGridModel({
        fetchData: () =>
            ProjectCustomerOrdersApi.getProjectCustomerOrders({
                projectId,
            }),
        initialParams: {},
        getRowId: (row) => row.customerOrderId,
        gridId: "9870D923A74E6759",
        selectFirstRowOnLoad: true
    });

    return (
        <ControlledAsyncDataGrid<CustomerOrderView>
            columns={[
                integerColumn({
                    field: "customerOrderId",
                    headerName: i18n.t("number_shortened"),
                }),
                textColumn({
                    field: "siteName",
                    headerName: i18n.t("site_short"),
                }),
                textColumn({
                    field: "customerName",
                    headerName: i18n.t("customer"),
                    width: 150,
                }),
                textColumn({
                    field: "orderReference",
                    headerName: i18n.t("reference"),
                    width: 150,
                }),
                enumColumn({
                    field: "customerOrderState",
                    headerName: i18n.t("state"),
                    enumLabels: getCustomerOrderStateLabels(),
                }),
                dateColumn({
                    field: "plannedDeliveryDate",
                    headerName: i18n.t("planned_delivery_date_short"),
                }),
                textColumn({
                    field: "responsiblePersonName",
                    headerName: i18n.t("responsible_person"),
                }),
                textColumn({
                    field: "contactName",
                    headerName: i18n.t("contact_person"),
                }),
            ]}
            actionBarComponents={
                <>
                    <OpenCustomerOrderButton
                        customerOrderId={onlySelectedRow?.customerOrderId}
                        variant={"outlined"}
                    />
                    <AsyncButton
                        label={i18n.t("unlink")}
                        icon={faUnlink}
                        variant={"outlined"}
                        disabled={!onlySelectedRow}
                        onClick={async () => {
                            if (!onlySelectedRow) return;
                            const confirmed = await showConfirmDialog();
                            if (!confirmed) return;

                            await ProjectCustomerOrdersApi.unlinkCustomerOrderFromProject({
                                customerOrderId: onlySelectedRow.customerOrderId,
                            });
                            await refreshData();
                        }}
                    />
                </>
            }
            {...dataGridProps}
        />
    );
};
