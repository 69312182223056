import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { mergeSx } from "src/utils/styles";
import React from "react";
import { faSpinnerThird, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type AavoDialogSize = "sm" | "md" | "lg" | "xl" | "fullscreen";

export interface AavoDialogProps
	extends Omit<DialogProps, "fullWidth" | "fullScreen" | "maxWidth" | "title" | "open" | "onClose" | "dialogSize"> {
	size?: AavoDialogSize;
	title: React.ReactNode;
	actions?: React.ReactNode;
	cancelButton?: boolean;
	onClose: (reason: CloseReason) => unknown | Promise<unknown>;
}

type CloseReason = "backdropClick" | "escapeKeyDown" | "browserBackButton";

export const AavoDialog = ({
	size = "fullscreen",
	title,
	children,
	actions,
	onClose: onCloseProp,
	PaperProps,
	...other
}: AavoDialogProps) => {
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const smallModal = size === "sm";
	const fullScreen = size === "fullscreen" || (smallScreen && !smallModal);

	const [isClosing, setIsClosing] = React.useState(false);

	const onClose = async (reason: CloseReason) => {
		try {
			setIsClosing(true);
			await onCloseProp(reason);
		} finally {
			setIsClosing(false);
		}
	};

	return (
		<Dialog
			fullWidth={true}
			fullScreen={fullScreen}
			maxWidth={fullScreen ? false : size}
			sx={mergeSx(
				fullScreen && {
					padding: 1,
				},
			)}
			PaperProps={{
				sx: mergeSx(
					getPaperPropsForSize(size, smallScreen),
					{
						borderRadius: "4px",
					},
					PaperProps?.sx,
				),
				...PaperProps,
			}}
			onClose={onClose}
			open={true}
			{...other}
		>
			<DialogTitle
				variant={"h6"}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					paddingX: 2,
					paddingY: smallModal ? 1 : 0.5,
					marginBottom: smallModal ? 1 : 0,
					borderBottom: "1px solid",
					borderColor: "divider",
				}}
			>
				<Box
					sx={{
						color: "primary.main",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					{title}
				</Box>
				<IconButton
					onClick={() => {
						onClose?.("escapeKeyDown");
					}}
					sx={{
						padding: 0,
					}}
				>
					{isClosing ?
						<FontAwesomeIcon icon={faSpinnerThird} spin fixedWidth />
					:	<FontAwesomeIcon icon={faTimes} fixedWidth />}
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					display: "flex",
					margin: 0,
					padding: 0,
					overflow: "hidden",
				}}
			>
				{children}
			</DialogContent>
			<DialogActions
				disableSpacing={true}
				sx={{
					display: !actions ? "none" : undefined,
					gap: "0.5rem",
				}}
			>
				{actions}
			</DialogActions>
		</Dialog>
	);
};

const getPaperPropsForSize = (size: AavoDialogSize, isSmallScreen: boolean) => {
	if (isSmallScreen) return {};

	switch (size) {
		case "sm":
			return {
				minHeight: "200px",
			};
		case "md":
			return {
				height: "700px",
			};
		case "lg":
			return {
				height: "800px",
			};
		case "xl":
			return {
				height: "1000px",
			};
		case "fullscreen":
			return {
				height: "100%",
			};
	}
};
