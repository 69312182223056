import { FirebaseOptions } from "firebase/app";
import { AppEnvironment, getAppEnvironment } from "src/config/appEnvironment.ts";
import deepMerge from "src/utils/deepMerge.ts";

export type AppConfig = {
	firebase: {
		firebaseOptions: FirebaseOptions;
		vapidKey: string;
	};
	sentry: SentryConfig;
	mapbox: {
		accessToken: string;
	};
};

export type SentryConfig = {
	dsn: string | undefined;
	showErrorReportForUncaughtExceptions?: boolean;
	replaysSessionSampleRate?: number;
	debug?: boolean;
	environment?: string;
	tracesSampleRate?: number;
};

export const getFirebaseOptions = (appEnvironment?: AppEnvironment): FirebaseOptions =>
	getConfig(appEnvironment).firebase.firebaseOptions;

export const getFirebaseVapidKey = () => getConfig().firebase.vapidKey;

export const getSentryConfig = (): SentryConfig | undefined => {
	return getConfig().sentry;
};

export const getFrontendReleaseCode = () => {
	const appEnvironment = getAppEnvironment();
	if (appEnvironment === "dev") return "dev";
	return import.meta.env.VITE_APP_RELEASE_CODE;
};

export const getMapboxAccessToken = () => getConfig().mapbox.accessToken;

const getConfig = (appEnvironment?: AppEnvironment): any => {
	switch (appEnvironment ?? getAppEnvironment()) {
		case "dev":
			return getDevConfig();
		case "staging":
			return getStagingConfig();
		case "prod":
			return getProdConfig();
	}
};

const getDevConfig = (): AppConfig => {
	const sentryConfig =
		import.meta.env.VITE_USE_SENTRY_ON_DEV === "true" ?
			{
				dsn: "https://6aec86f5a3304dace9e4c1291450be25@o286730.ingest.sentry.io/4506704102883328",
				showErrorReportForUncaughtExceptions: true,
				tracesSampleRate: 1.0,
				replaysSessionSampleRate: 0,
				debug: false,
				environment: "dev",
			}
		:	{
				dsn: undefined,
			};

	return {
		firebase: {
			firebaseOptions: {
				apiKey: "AIzaSyC_oxjm4Wq9fBTEkQBBGpGOhWIWmLrRNVA",
				authDomain: "aavo-dev.firebaseapp.com",
				projectId: "aavo-dev",
				storageBucket: "aavo-dev.appspot.com",
				messagingSenderId: "717179742357",
				appId: "1:717179742357:web:e8c063470a169386fd939c",
			},
			vapidKey: "BNl6XpoBPa1lgWw4p3szpXGltWTEQft1AjLY1fw1F5AT-XUVCWrXARUUD7sDswn-znqW28gX1Vn7DhPZZFpFBcA",
		},
		sentry: sentryConfig,
		mapbox: {
			accessToken:
				"pk.eyJ1IjoicGF1bHVzbGltbWEiLCJhIjoiY2xya2dycWdjMGQxMjJpazQyYTZjbGZpcyJ9.A_aJDmruza7cE2_B5u6tIQ",
		},
	};
};

const getStagingConfig = (): AppConfig => {
	const prodConfig = getProdConfig();
	return deepMerge(prodConfig, {
		sentry: {
			dsn: "https://b372d0a9813ba21e707723fd4f781e55@o286730.ingest.sentry.io/4506715908407296",
		},
	});
};

const getProdConfig = (): AppConfig => {
	const configJson = import.meta.env.VITE_APP_CONFIG;
	if (!configJson) throw Error("Production configuration is not defined");

	return JSON.parse(configJson);
};
