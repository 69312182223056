import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { ConfigurationComponentForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/ConfigurationComponentForm.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ConfiguratorManagingApi } from "src/api/generated/erp/configurator/management/api/configuratorManagingApi.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import {
	ProductFamilyVersionManagingViewContext,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import {
	ProductFamilyVersionManagingViewContextProvider,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContextProvider.tsx";
import { NewComponentPalette } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/NewComponentPalette.tsx";
import { getOnlyMember } from "src/utils/arrayUtils.ts";
import { TabComponentTitle } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/tabs/TabComponentTitle.tsx";
import { NewTabComponentButton } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/tabs/NewTabComponentButton.tsx";
import { TabComponentContent } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/tabs/TabComponentContent.tsx";
import { ConfiguratorPropertiesDataGrid } from "src/components/views/erp/configurator/managing/productFamilyVersions/properties/ConfiguratorPropertiesDataGrid.tsx";
import { ProductFamilyVersionTopLevelForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionTopLevelForm.tsx";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";
import { ProductFamilyVersionManagingViewTestingTab } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewTestingTab.tsx";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";
import { Typography } from "@mui/material";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";

export interface ProductFamilyVersionManagingViewProps {
	productFamilyVersionId: number;
	onDirtyStateChanged: DataDirtyStateChangeHandler;
}

export const ProductFamilyVersionManagingView = (props: ProductFamilyVersionManagingViewProps) => {
	const { productFamilyVersionId, onDirtyStateChanged } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				ConfiguratorManagingApi.getProductFamilyVersionManagingViewInitData({
					productFamilyVersionId: productFamilyVersionId,
				})
			}
			content={({ productFamilyVersion, components }, refresh) => (
				<ProductFamilyVersionManagingViewContextProvider
					productFamilyVersion={productFamilyVersion}
					onDirtyStateChanged={onDirtyStateChanged}
					initialComponents={components}
					refresh={refresh}
				>
					<ViewContent />
				</ProductFamilyVersionManagingViewContextProvider>
			)}
		/>
	);
};

const ViewContent = () => {
	const showConfirmDialog = useConfirmDialog();
	const { showToast } = useAavoToast();

	const { productFamilyVersion, focusedDataIsDirty, setFocusedDataIsDirty, refresh, limitedEditing } =
		useContextOrThrow(ProductFamilyVersionManagingViewContext);

	return (
		<VerticalBox flex={1}>
			<UnrestrictedEditingWarning productFamilyVersion={productFamilyVersion} />
			<AavoMosaic
				viewId={"2588E909D4CC18C7"}
				layout={{
					type: "tabs",
					confirmChangeActiveItem: async () => {
						if (focusedDataIsDirty) {
							const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
							if (!confirmed) return false;
							setFocusedDataIsDirty(false);
						}
						return true;
					},
					items: [
						{
							type: "panel",
							title: i18n.t("product_family_version"),
							content: (
								<ProductFamilyVersionTopLevelForm
									productFamilyVersion={productFamilyVersion}
									onFormEdited={() => setFocusedDataIsDirty(true)}
									onCompleted={async (result) => {
										switch (result.type) {
											case "success": {
												setFocusedDataIsDirty(false);
												showToast({ title: i18n.t("changes_saved") });
												await refresh();
												break;
											}
										}
									}}
								/>
							),
						},
						{
							type: "row",
							title: i18n.t("components"),
							first: {
								type: "panel",
								title: i18n.t("preview"),
								content: <ComponentsManagingView />,
							},
							second: {
								type: "row",
								splitPercentage: 70,
								first: {
									type: "panel",
									title: i18n.t("component"),
									content: <SelectedComponentForm />,
								},
								second: {
									type: "panel",
									title: i18n.t("new_component"),
									hidden: limitedEditing,
									content: <NewComponentPalette />,
								},
							},
						},
						{
							type: "panel",
							title: i18n.t("properties"),
							content: (
								<ConfiguratorPropertiesDataGrid
									productFamilyVersionId={productFamilyVersion.configurationProductFamilyVersionId}
									editingDisabled={limitedEditing}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("testing"),
							content: (
								<ProductFamilyVersionManagingViewTestingTab
									productFamilyVersionId={productFamilyVersion.configurationProductFamilyVersionId}
								/>
							),
						},
					],
				}}
			/>
		</VerticalBox>
	);
};

interface UnrestrictedEditingWarningProps {
	productFamilyVersion: ConfigurationProductFamilyVersion;
}

const UnrestrictedEditingWarning = ({ productFamilyVersion }: UnrestrictedEditingWarningProps) => {
	if (productFamilyVersion.state === "INITIAL") return;
	if (!productFamilyVersion.unrestrictedEditing) return;

	return (
		<Typography
			variant={"subtitle1"}
			color={"error"}
			children={i18n.t("configuration_product_family_version_unrestricted_editing_warning")}
			sx={{
				marginY: 0.5,
				marginX: 1.5,
			}}
		/>
	);
};

const ComponentsManagingView = () => {
	const { allComponents, unselectAllComponents } = useContextOrThrow(ProductFamilyVersionManagingViewContext);
	const tabsWithChildren = allComponents
		.filter((c) => c.componentType === "TAB")
		.toSorted((a, b) => a.orderNum - b.orderNum)
		.map((tab) => ({
			tab,
			children: allComponents.filter((c) => c.parentTabComponentId === tab.configurationComponentId),
		}));

	return (
		<AavoMosaic
			viewId={"B5AD0F149BF2933"}
			layout={{
				type: "tabs",
				confirmChangeActiveItem: async () => {
					const result = await unselectAllComponents();
					return result !== "cancelled";
				},
				items: tabsWithChildren.map(({ tab, children }, idx) => ({
					type: "panel",
					key: tab.configurationComponentId.toString(),
					title: <TabComponentTitle tabComponent={tab} tabIndex={idx} />,
					content: <TabComponentContent tabComponent={tab} tabChildren={children} />,
				})),
			}}
			toolbarExtraComponents={<NewTabComponentButton />}
		/>
	);
};

const SelectedComponentForm = () => {
	const { selectedComponents, updateComponent, setFocusedDataIsDirty, limitedEditing } = useContextOrThrow(
		ProductFamilyVersionManagingViewContext,
	);
	const onlySelectedComponent = getOnlyMember(selectedComponents);

	return onlySelectedComponent == null ?
			<CenteredTypography children={i18n.t("select_a_component")} />
		:	<ConfigurationComponentForm
				key={onlySelectedComponent.configurationComponentId}
				component={onlySelectedComponent}
				backingPropertyId={onlySelectedComponent.backingPropertyId ?? undefined}
				setIsDirty={setFocusedDataIsDirty}
				saveComponent={updateComponent}
				limitedEditing={limitedEditing}
			/>;
};
