import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ReclamationWithCoordinates } from "src/api/generated/erp/reclamations/api/reclamationWithCoordinates.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField";
import i18n from "i18next";
import { useState } from "react";
import { containsIgnoreCase } from "src/utils/strings.tsx";
import { faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
	TASK_DESKTOP_FORM_SIZE,
	TaskDesktopForm,
} from "src/components/views/tasks/desktop/TaskDesktopForm.tsx";

export interface ReclamationPlanningReclamationListProps {
	reclamations: ReclamationWithCoordinates[];
	selectedReclamationIds: number[];
	setReclamationSelected: (reclamation: ReclamationWithCoordinates, isSelected: boolean) => void;
	refreshTasks: () => void;
}

export const ReclamationPlanningReclamationList = ({
	reclamations,
	selectedReclamationIds,
	setReclamationSelected,
	refreshTasks,
}: ReclamationPlanningReclamationListProps) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredReclamations, setFilteredReclamations] =
		useState<ReclamationWithCoordinates[]>(reclamations);
	const { openDialog } = useGenericDialog();

	return (
		<VerticalBox
			sx={{
				minWidth: 320,
			}}
		>
			<AavoTextField
				sx={{
					margin: 1,
					marginBottom: 0,
				}}
				size={"small"}
				label={i18n.t("search")}
				value={searchQuery}
				onChange={setSearchQuery}
				onSubmit={(query) => {
					setFilteredReclamations(filterReclamations(reclamations, query));
				}}
				InputProps={{
					endAdornment: searchQuery !== "" && (
						<AavoIconButton
							icon={faXmark}
							onClick={() => {
								setSearchQuery("");
								setFilteredReclamations(reclamations);
							}}
						/>
					),
				}}
			/>
			<List
				sx={{
					overflow: "auto",
				}}
			>
				{filteredReclamations.map((reclamation) => {
					const labelId = `reclamation-list-label-${reclamation.reclamationId}`;
					const selected = selectedReclamationIds.includes(reclamation.reclamationId);
					return (
						<ListItem
							key={reclamation.reclamationId}
							disablePadding
							secondaryAction={
								<AavoIconButton
									edge={"end"}
									icon={faPlus}
									tooltip={i18n.t("new_task")}
									onClick={() => {
										openFormOnDialog({
											openDialog: openDialog,
											title: i18n.t("new_task"),
											size: TASK_DESKTOP_FORM_SIZE,
											component: TaskDesktopForm,
											props: {
												taskId: undefined,
												newTaskSourceRef: {
													sourceType: "RECLAMATION_V2",
													sourceId: reclamation.reclamationId,
												},
											},
											onSubmit: () => {
												refreshTasks();
											},
											confirmCloseIfEdited: false,
										});
									}}
								/>
							}
						>
							<ListItemButton
								role={undefined}
								dense
								onClick={() => {
									setReclamationSelected(reclamation, !selected);
								}}
							>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={selected}
										tabIndex={-1}
										disableRipple
										inputProps={{ "aria-labelledby": labelId }}
									/>
								</ListItemIcon>
								<ListItemText
									id={labelId}
									primary={reclamation.title}
									secondary={
										<>
											{i18n.t("number_shortened")}: {reclamation.reclamationId}
											<br />
											{i18n.t("order")}: {reclamation.customerOrderReference ?? "-"}
											<br />
											{reclamation.address.city}
										</>
									}
								/>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
		</VerticalBox>
	);
};

const filterReclamations = (all: ReclamationWithCoordinates[], query: string) => {
	return all.filter(
		(reclamation) =>
			containsIgnoreCase(reclamation.title, query) ||
			containsIgnoreCase(reclamation.customerOrderId?.toString(), query) ||
			containsIgnoreCase(reclamation.customerOrderReference, query),
	);
};
