// This file is automatically generated. Do not edit manually.

import { ShopOrderLineView } from "src/api/generated/erp/db/types/tables/shopOrderLineView";
import { ShopOrderState } from "src/api/generated/erp/db/types/enums/shopOrderState";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBomDataGridApi {
	export async function getGridData(args: {
		searchQuery: string;
		shopOrderId: number;
		filterByShopOrderOperationId?: number | null | undefined;
		includeCancelledLines?: boolean;
	}): Promise<ShopOrderBomDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/getGridData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				shopOrderId: args.shopOrderId,
				filterByShopOrderOperationId: args.filterByShopOrderOperationId,
				includeCancelledLines: args.includeCancelledLines,
			},
		});
		return response as ShopOrderBomDataGridApi_GridData;
	}
}

export interface ShopOrderBomDataGridApi_GridData {
	lines: Array<ShopOrderLineView>;
	shopOrderState: ShopOrderState;
}
