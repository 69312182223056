import { FormResult } from "src/components/common/forms/types.ts";
import type {
	OpenGenericDialogFunc,
	OpenGenericDialogFuncProps,
} from "src/components/common/dialogs/GenericDialogContext.ts";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";

export type AsyncFormDialogPropsProvider<TResult> = (params: {
	onCompleted: (result: FormResult<TResult>) => Promise<void>;
	onFormEdited: () => void;
	onDataDirtyStateChanged: DataDirtyStateChangeHandler;
}) => OpenGenericDialogFuncProps;

export function showAsyncDialog<TResult>(
	openDialog: OpenGenericDialogFunc,
	propsProvider: AsyncFormDialogPropsProvider<TResult>,
): Promise<TResult | undefined> {
	let resolveFunc: (value: TResult | undefined) => void;

	const promise = new Promise<TResult | undefined>((resolve) => {
		resolveFunc = resolve;
	});

	openDialog(({ closeDialog, onContentEdited, onDataDirtyStateChanged }) => {
		const props = propsProvider({
			onCompleted: async (formResult) => {
				await closeDialog({
					confirmIfEdited: formResult.type === "cancel" && formResult.isEdited,
				});
				switch (formResult.type) {
					case "success":
						resolveFunc(formResult.value);
						break;
					case "cancel":
						resolveFunc(undefined);
						break;
				}
			},
			onFormEdited: onContentEdited,
			onDataDirtyStateChanged: onDataDirtyStateChanged,
		});
		return {
			...props,
			onClose: () => {
				resolveFunc(undefined);
				props.onClose?.();
			},
		};
	});

	return promise;
}
