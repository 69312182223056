import i18n from "i18next";
import { faInfo } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { CustomerOrderInfoAndCommentsView } from "src/components/views/erp/sales/customerOrder/CustomerOrderInfoAndCommentsView.tsx";

export interface OpenCustomerOrderInfoButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	customerOrderId: number | undefined | null;
}

export const OpenCustomerOrderInfoButton = ({
	customerOrderId,
	variant = "menu",
	...other
}: OpenCustomerOrderInfoButtonProps) => {
	const { openDialog } = useGenericDialog();

	const { tenantConfig } = useTenantCustomizations();
	if (!tenantConfig.erp.salesEnabled) return null;

	return (
		<AsyncButton
			label={i18n.t("customer_order_info")}
			icon={faInfo}
			variant={variant}
			disabled={customerOrderId == null}
			onClick={() => {
				if (customerOrderId == null) return;
				openDialog({
					title: i18n.t("customer_order"),
					content: <CustomerOrderInfoAndCommentsView customerOrderId={customerOrderId} />,
				});
			}}
			{...other}
		/>
	);
};
