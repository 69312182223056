import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { PartCategoryApi } from "src/api/generated/erp/parts/categories/api/partCategoryApi";
import { PartCategoriesCrudViewActionBarComponents } from "src/components/views/erp/parts/partCategories/PartCategoriesCrudViewActionBarComponents.tsx";
import { PartCategoriesTreeView } from "src/components/views/erp/parts/partCategories/PartCategoriesTreeView";
import { GenericDialogContext } from "src/components/common/dialogs/GenericDialogContext.ts";
import i18n from "i18next";
import {
	PartCategoryForm,
	PartCategoryFormProps,
} from "src/components/views/erp/parts/partCategories/PartCategoryForm.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import React from "react";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";

export interface PartCategoriesCrudViewProps {
	selectedPartCategory: PartCategory | null;
	setSelectedPartCategory: React.Dispatch<React.SetStateAction<PartCategory | null>>;
}

export const PartCategoriesCrudView = (props: PartCategoriesCrudViewProps) => {
	return (
		<AsyncFetchRender
			fetch={PartCategoryApi.getAll}
			content={(partCategories, refresh) => (
				<PartCategoriesCrudTreeView {...props} partCategories={partCategories} refresh={refresh} />
			)}
		/>
	);
};

interface PartCategoriesCrudTreeViewProps extends PartCategoriesCrudViewProps {
	partCategories: PartCategory[];
	refresh: () => Promise<PartCategory[]>;
}

const PartCategoriesCrudTreeView = ({
	partCategories,
	refresh,
	selectedPartCategory,
	setSelectedPartCategory,
}: PartCategoriesCrudTreeViewProps) => {
	const { openDialog } = useContextOrThrow(GenericDialogContext);
	return (
		<PartCategoriesTreeView
			partCategories={partCategories}
			selectedPartCategory={selectedPartCategory}
			onSelectionChanged={setSelectedPartCategory}
			refresh={refresh}
			actionBarComponents={
				<PartCategoriesCrudViewActionBarComponents
					selectedPartCategory={selectedPartCategory}
					setSelectedPartCategory={setSelectedPartCategory}
					openPartCategoryFormDialog={openPartCategoryFormDialog}
					refreshPartCategories={refresh}
				/>
			}
			partCategoryContextMenuComponents={(partCategory) => (
				<AsyncMenuButton
					key={"createSubCategory"}
					label={i18n.t("create_sub_category")}
					icon={faPlus}
					onClick={() => {
						openPartCategoryFormDialog({
							parentId: partCategory.partCategoryId,
						});
					}}
				/>
			)}
			dndConfig={{
				onDropToPartCategory: async (droppedItem, targetItem) => {
					if (targetItem.partCategoryId === droppedItem.partCategoryId) return;

					await PartCategoryApi.update({
						partCategory: {
							...droppedItem,
							parentId: targetItem.partCategoryId,
						},
					});
					await refresh();
				},
				onDropToRoot: async (droppedItem) => {
					await PartCategoryApi.update({
						partCategory: {
							...droppedItem,
							parentId: null,
						},
					});
					await refresh();
				},
			}}
		/>
	);

	function openPartCategoryFormDialog(params: Partial<PartCategoryFormProps>) {
		openDialog(({ closeDialog, onContentEdited }) => ({
			size: "sm",
			title: i18n.t("part_category"),
			content: (
				<PartCategoryForm
					{...params}
					onCompleted={async (result) => {
						await closeDialog();
						await refresh();
						params.onCompleted?.(result);
					}}
					onFormEdited={onContentEdited}
				/>
			),
		}));
	}
};
