import { tenantCustomizationValues } from "./tenants";
import { TenantConfig } from "src/tenantCustomizations/TenantCustomizations";
import React from "react";
import deepMerge from "src/utils/deepMerge.ts";
import {
	DEFAULT_TENANT_CONFIG,
	DEFAULT_TENANT_CUSTOMIZATIONS,
} from "src/tenantCustomizations/defaultTenantCustomizations.ts";
import { TenantCustomizationsContext } from "./TenantCustomizationsContext";

export interface TenantCustomizationsContextProviderProps {
	children: React.ReactNode;
}

export const TenantCustomizationsContextProvider = ({
	children,
}: TenantCustomizationsContextProviderProps) => {
	const tenantName = resolveTenantName();
	let customizations = tenantCustomizationValues[tenantName];
	if (customizations === undefined) {
		console.warn(`Unknown tenant ${tenantName}`);
		customizations = DEFAULT_TENANT_CUSTOMIZATIONS;
	}
	const tenantConfig = deepMerge(
		DEFAULT_TENANT_CONFIG,
		customizations.tenantConfig ?? {},
	) as TenantConfig;

	const resolvedCustomizations = {
		...DEFAULT_TENANT_CUSTOMIZATIONS,
		...customizations,
		tenantConfig: tenantConfig,
	};

	return (
		<TenantCustomizationsContext.Provider value={resolvedCustomizations}>
			{children}
		</TenantCustomizationsContext.Provider>
	);
};

const resolveTenantName = () => {
	const domain = window.location.hostname;
	return domain.split(".")[0]!;
};
