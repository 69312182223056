import {floatColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AavoGridColDef} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {
    ShopOrderBatchDataGridApi_ShopOrderBatchDto
} from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchDataGridApi.ts";

export const getOmegakeittiotShopOrderBatchDataGridCustomColumns =
	(): AavoGridColDef<ShopOrderBatchDataGridApi_ShopOrderBatchDto>[] => {
		return [
			floatColumn({
				field: "tenantCustomColumn1",
				headerName: i18n.t("quantity_of_parts"),
				width: 200,
				filterable: false,
			}),
			floatColumn({
				field: "tenantCustomColumn2",
				headerName: i18n.t("area"),
				width: 200,
				filterable: false,
			}),
		];
	};
