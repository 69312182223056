import React from "react";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export const MessageDialogContext = React.createContext<ShowMessageDialogFunc | undefined>(undefined);

export const useMessageDialog = () => useContextOrThrow(MessageDialogContext);

export type ShowMessageDialogFunc = (params: MessageDialogParams) => void;

export interface MessageDialogParams {
	title?: string;
	content: React.ReactNode;
}
