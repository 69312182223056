// This file is automatically generated. Do not edit manually.

import { SalesPartType } from "src/api/generated/erp/db/types/enums/salesPartType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalesPartApi {
	export async function searchSalesParts(args: {
		searchQuery?: string;
		partId: number | null | undefined;
		siteId?: number | null | undefined;
		salesPartType?: SalesPartType | null | undefined;
		showInactiveSalesParts?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salesParts/searchSalesParts",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				partId: args.partId,
				siteId: args.siteId,
				salesPartType: args.salesPartType,
				showInactiveSalesParts: args.showInactiveSalesParts,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SalesPartView>;
	}
}
