import {coloredBooleanColumn, textColumn} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {DeliveryTerm} from "src/api/generated/erp/db/types/tables/deliveryTerm.ts";
import {DeliveryTermsApi} from "src/api/generated/erp/warehouse/basedata/api/deliveryTermsApi.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";

export const DeliveryTermsDataGrid = () => {
	return (
		<ClientSideDataGridModel
			gridId={"627CE7A5C5BC2407"}
			fetchData={DeliveryTermsApi.getAll}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.deliveryTermsId}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => {
				return (
					<CrudDataGrid<DeliveryTerm>
						columns={[
							textColumn({
								field: "deliveryTermsCode",
								headerName: i18n.t("delivery_term_code"),
								editable: true,
								validate: "required",
							}),
							textColumn({
								field: "deliveryTermsDescription",
								headerName: i18n.t("description"),
								editable: true,
								width: 500,
							}),
							coloredBooleanColumn({
								field: "isDefault",
								headerName: i18n.t("default"),
								falseColor: "grey",
							}),
						]}
						save={{
							type: "enabled",
							action: async (params) =>
								await DeliveryTermsApi.save({
									deliveryTerms: params.items,
								}),
						}}
						add={{
							type: "enabled",
							action: async () => {
								return {
									deliveryTermsCode: "",
									deliveryTermsDescription: "",
									lastUpdated: new Date().toISOString(),
								};
							},
						}}
						remove={{
							type: "enabled",
							action: async (params) =>
								await DeliveryTermsApi.delete_({
									deliveryTerms: params.items,
								}),
						}}
						actionBarComponents={
							<>
								<AsyncButton
									label={i18n.t("set_to_default")}
									variant={"outlined"}
									icon={faCheck}
									disabled={onlySelectedRow == null || onlySelectedRow.isDefault}
									onClick={async () => {
										if (onlySelectedRow?.deliveryTermsId == null) return;
										await DeliveryTermsApi.setToDefault({
											deliveryTermId: onlySelectedRow?.deliveryTermsId,
										});
										await refreshData();
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
