import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
	faBan,
	faCalendarCheck,
	faCalendarTimes,
	faChartGantt,
	faCheck,
	faCopy,
	faExternalLinkAlt,
	faMoneyBill,
	faPlay,
	faPrint,
	faShare,
	faTasks,
	faThumbTack,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView.ts";
import { ProjectActivityGanttView } from "src/components/views/erp/project/gantt/ProjectActivityGanttView.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
	TASK_DESKTOP_FORM_SIZE,
	TaskDesktopForm,
} from "src/components/views/tasks/desktop/TaskDesktopForm.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { CostEventsOfObjectDataGrid } from "src/components/views/erp/common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { ProjectActivityApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityApi.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { openLegacySourceSetTasksView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { ProjectActivitiesContainerView } from "src/components/views/erp/project/projectActivity/ProjectActivitiesContainerView.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";

export interface ProjectActivityContextMenuProps {
	activity: ProjectActivityView;
	refreshData?: () => Promise<unknown>;
	tasksViewRefreshRef?: RefreshableElementRef;
}

export const ProjectActivityContextMenu = ({
	activity,
	refreshData,
	tasksViewRefreshRef,
}: ProjectActivityContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();
	const showFormInput = useFormInput();
	const showConfirmDialog = useConfirmDialog();
	const openLegacyView = useOpenLegacyView();

	const activityId = activity.activityId;

	return [
		<AsyncMenuButton
			key={"openActivity"}
			label={i18n.t("open")}
			icon={faExternalLinkAlt}
			onClick={() => {
				openDialog({
					title: i18n.t("activity"),
					content: (
						<ProjectActivitiesContainerView
							subProjectId={activity.subProjectId}
							onlyActivityId={activity.activityId}
						/>
					),
				});
			}}
		/>,
		activity.activityState === "INITIAL" && (
			<AsyncMenuButton
				key={"release"}
				label={i18n.t("release")}
				icon={faShare}
				onClick={onReleaseClick}
			/>
		),
		activity.activityState === "RELEASED" && (
			<AsyncMenuButton
				key={"revertRelease"}
				label={i18n.t("revert_release")}
				icon={faUndo}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_revert_release_project_activity"),
					});
					if (!confirmed) return;
					await ProjectActivityApi.revertReleaseProjectActivity({
						projectActivityId: activityId,
					});
					await refreshData?.();
				}}
			/>
		),
		activity.activityState === "RELEASED" && (
			<AsyncMenuButton
				key={"start"}
				label={i18n.t("start")}
				icon={faPlay}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_start_project_activity"),
					});
					if (!confirmed) return;
					await ProjectActivityApi.startProjectActivity({
						projectActivityId: activityId,
					});
					await refreshData?.();
				}}
			/>
		),
		activity.activityState === "STARTED" && (
			<AsyncMenuButton
				key={"complete"}
				label={i18n.t("mark_as_completed")}
				icon={faCheck}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_complete_project_activity"),
					});
					if (!confirmed) return;
					await ProjectActivityApi.completeProjectActivity({
						projectActivityId: activityId,
					});
					await refreshData?.();
				}}
			/>
		),
		activity.activityState === "READY" && (
			<AsyncMenuButton
				key={"revertComplete"}
				label={i18n.t("revert_complete")}
				icon={faUndo}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_revert_complete_project_activity"),
					});
					if (!confirmed) return;
					await ProjectActivityApi.revertCompleteProjectActivity({
						projectActivityId: activityId,
					});
					await refreshData?.();
				}}
			/>
		),
		["INITIAL", "RELEASED", "STARTED"].includes(activity.activityState) && (
			<AsyncMenuButton
				key={"cancel"}
				label={i18n.t("cancel")}
				icon={faBan}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_cancel_project_activity"),
					});
					if (!confirmed) return;
					await ProjectActivityApi.cancelProjectActivity({
						projectActivityId: activityId,
					});
					await refreshData?.();
				}}
			/>
		),
		<AsyncMenuButton
			key={"clone"}
			label={i18n.t("create_a_copy")}
			icon={faCopy}
			onClick={onCloneActivityClick}
		/>,
		<DocumentsOfObjectButton
			key={"documents"}
			variant={"menu"}
			label={i18n.t("documents")}
			objectRef={{ objectType: "PROJECT_ACTIVITY", objectId: activity.activityId }}
		/>,
		<AsyncMenuButton
			key={"gantt"}
			label={i18n.t("gantt")}
			icon={faChartGantt}
			onClick={() => {
				openDialog({
					title: i18n.t("gantt"),
					content: <ProjectActivityGanttView activityIds={[activity.activityId]} />,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"tasks"}
			label={i18n.t("tasks")}
			icon={faTasks}
			onClick={() => {
				openLegacySourceSetTasksView({
					openLegacyView,
					sourceSetType: "ProjectActivity",
					sourceSetId: activityId,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"addTask"}
			label={i18n.t("add_task")}
			icon={faThumbTack}
			onClick={() => {
				openFormOnDialog({
					openDialog,
					title: i18n.t("new_task"),
					size: TASK_DESKTOP_FORM_SIZE,
					component: TaskDesktopForm,
					props: {
						taskId: undefined,
						newTaskSourceRef: {
							sourceType: "PROJECT_ACTIVITY",
							sourceId: activity.activityId,
						},
					},
					onSubmit: async () => {
						await tasksViewRefreshRef?.refresh();
					},
				});
			}}
		/>,
		activity.projectHasCustomerOrders && (
			<AsyncMenuButton
				key={"importTasksFromCustomerOrder"}
				label={i18n.t("import_tasks_from_customer_order")}
				icon={faTasks}
				onClick={async () => {
					openLegacyView(
						"E134E38E6571952A",
						{
							activity_id: activityId,
						},
						() => {
							refreshData?.();
							tasksViewRefreshRef?.refresh();
						},
					);
				}}
			/>
		),
		<AsyncMenuButton
			key={"printTasks"}
			label={i18n.t("print_tasks")}
			icon={faPrint}
			onClick={async () => {
				const fileHandle = await ProjectActivityApi.printProjectActivityTasks({
					activityId: activityId,
				});
				downloadFile(fileHandle);
			}}
		/>,
		tenantConfig.erp.projectActivitySchedulingEnabled && [
			activity.isSchedulable ?
				<AsyncMenuButton
					key={"setToNonSchedulable"}
					label={i18n.t("not_schedulable")}
					icon={faCalendarTimes}
					onClick={async () => {
						await ProjectActivityApi.setActivityIsSchedulable({
							activityId: activityId,
							isSchedulable: false,
						});
						await refreshData?.();
					}}
				/>
			:	<AsyncMenuButton
					key={"setToSchedulable"}
					label={i18n.t("schedulable")}
					icon={faCalendarCheck}
					onClick={async () => {
						await ProjectActivityApi.setActivityIsSchedulable({
							activityId: activityId,
							isSchedulable: true,
						});
						await refreshData?.();
					}}
				/>,
		],
		activity.costEventPriceListId != null && (
			<AsyncMenuButton
				key={"costEvents"}
				label={i18n.t("cost_events")}
				icon={faMoneyBill}
				onClick={() => {
					openDialog({
						title: i18n.t("cost_events"),
						size: "xl",
						content: (
							<CostEventsOfObjectDataGrid
								objectRef={{
									objectType: "PROJECT_ACTIVITY",
									objectId: activity.activityId,
								}}
								costEventPriceListId={activity.costEventPriceListId!}
							/>
						),
					});
				}}
			/>
		),
		<OpenObjectChangeLogButton
			key={"changeLog"}
			objectRef={{ objectType: "PROJECT_ACTIVITY", objectId: activity.activityId }}
		/>,
	];

	async function onReleaseClick() {
		const input = await showFormInput<{ releaseTasks: boolean }>({
			title: i18n.t("release_activity"),
			defaultValues: {
				releaseTasks: true,
			},
			submitLabel: i18n.t("release"),
			content: ({ control }) => (
				<FormCheckbox control={control} name={"releaseTasks"} label={i18n.t("release_also_tasks")} />
			),
		});
		if (!input) return;

		await ProjectActivityApi.releaseProjectActivity({
			projectActivityId: activity.activityId,
			releaseTasks: input.releaseTasks,
		});
		await refreshData?.();
	}

	async function onCloneActivityClick() {
		const formInput = await showFormInput<{
			newActivityName: string;
			newActivityPlannedStartDate: IsoDateString;
		}>({
			title: i18n.t("clone_activity"),
			submitLabel: i18n.t("clone"),
			defaultValues: {
				newActivityPlannedStartDate: currentLocalDateAsIsoString(),
			},
			content: ({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"newActivityName"}
						label={i18n.t("new_activity_name")}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"newActivityPlannedStartDate"}
						label={i18n.t("planned_start")}
						rules={requireRule()}
					/>
				</>
			),
		});
		if (!formInput) return;

		await ProjectActivityApi.cloneActivity({
			templateActivityId: activityId,
			targetSubProjectId: activity.subProjectId,
			newActivityName: formInput.newActivityName,
			newActivityPlannedStartDate: formInput.newActivityPlannedStartDate,
		});
		await refreshData?.();
	}
};
