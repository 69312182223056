import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { WorkspaceInfoApi } from "src/api/generated/workspaces/api/workspaceInfoApi.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { WorkspaceNotesView } from "src/components/views/workspaces/infoView/WorkspaceNotesView.tsx";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import i18n from "i18next";
import { WorkspaceView } from "src/api/generated/postgres/db/types/workspaces/tables/workspaceView.ts";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { Box, Typography } from "@mui/material";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";

export interface WorkspaceInfoViewProps {
	workspaceId: number;
}

export const WorkspaceInfoView = ({ workspaceId }: WorkspaceInfoViewProps) => {
	return (
		<AsyncFetchRender
			fetch={async () => await WorkspaceInfoApi.getInfoViewData({ workspaceId: workspaceId })}
			content={(data) => {
				return (
					<Box
						flex={1}
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
							overflow: "auto",
						}}
					>
						<VerticalBox
							sx={{
								minHeight: "auto",
							}}
						>
							<WorkspaceInfoSection workspace={data.workspace} />
							<ConnectedDocumentsSection pinnedDocuments={data.pinnedDocuments} />
						</VerticalBox>
						<WorkspaceNotesView workspace={data.workspace} />
					</Box>
				);
			}}
		/>
	);
};

const ConnectedDocumentsSection = ({ pinnedDocuments }: { pinnedDocuments: DocumentConnectionView[] }) => {
	const { openDialog } = useGenericDialog();
	return (
		<VerticalBox
			sx={{
				minHeight: "auto",
				alignItems: "baseline",
				gap: "0.25rem",
				marginLeft: "0.5rem",
			}}
		>
			{pinnedDocuments?.map((doc) => (
				<AsyncButton
					key={doc.documentId}
					variant={"outlined"}
					icon={faPaperclip}
					label={doc.description1}
					onClick={() => {
						openDialog({
							title: doc.description1,
							content: <DocumentPreviewView documentId={doc.documentId} />,
						});
					}}
				/>
			))}
		</VerticalBox>
	);
};

const WorkspaceInfoSection = ({ workspace }: { workspace: WorkspaceView }) => (
	<VerticalBox
		sx={{
			minHeight: "auto",
		}}
	>
		<Typography sx={{ margin: "0.5rem" }} variant={"h5"} color={"primary.main"}>
			{workspace.workspaceName}
		</Typography>
		{workspace.description !== "" && (
			<Typography sx={{ marginLeft: "0.5rem" }} variant={"subtitle2"} color={"grey.600"}>
				{workspace.description}
			</Typography>
		)}
		<LabelValueView
			sx={{
				overflow: "hidden",
			}}
			items={[
				{ label: i18n.t("responsible_person"), value: workspace.responsiblePersonName },
				workspace.pointOfTime != null && {
					label: i18n.t("point_of_time"),
					value: formatIsoString(workspace.pointOfTime),
				},
				{ label: i18n.t("created_by"), value: workspace.createdByUserName },
				{ label: i18n.t("created_at"), value: formatIsoString(workspace.createdDate) },
				workspace.archivedAt != null && {
					label: i18n.t("archived_at"),
					value: formatIsoString(workspace.archivedAt),
				},
				{ label: i18n.t("number_shortened"), value: workspace.workspaceId },
			]}
		/>
	</VerticalBox>
);
