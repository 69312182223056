import {ErpAppbarSelectionsApi} from "src/api/generated/erp/common/api/erpAppbarSelectionsApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import {SelectField, SelectFieldProps} from "src/components/common/inputFields/SelectField";
import i18n from "i18next";
import {Typography} from "@mui/material";
import {TooltipWithWrapper} from "src/components/common/tooltips/TooltipWithWrapper.tsx";
import {ErrorView} from "src/components/common/errors/ErrorView.tsx";
import {useAsyncFetch} from "src/utils/async/asyncFetch.ts";
import {AavoLoading} from "src/components/common/AavoLoading.tsx";
import {AavoMenuItem} from "src/components/common/contextMenu/AavoMenuItem.tsx";

export interface ErpAppbarSelectionsProps {
    closeMenu: () => void;
}

export const ErpAppbarSelections = ({closeMenu}: ErpAppbarSelectionsProps) => {
    const [selectionsAsync] = useAsyncFetch(() => ErpAppbarSelectionsApi.getCurrentSelections());

    const {logErrorAndShowOnDialog} = useErrorDialog();

    if (selectionsAsync.loading) return <AavoLoading/>;
    if (selectionsAsync.error)
        return (
            <TooltipWithWrapper title={<ErrorView error={selectionsAsync.error}/>}>
                <Typography>{i18n.t("error")}</Typography>
            </TooltipWithWrapper>
        );

    if (!selectionsAsync.data) return null;
    const {
        currentSiteId,
        currentWorkCenterId,
        currentProductionLineId,
        siteOptions,
        workCenterOptions,
        productionLineOptions,
    } = selectionsAsync.data;

    if (
        siteOptions.length === 0 &&
        workCenterOptions.length === 0 &&
        productionLineOptions.length === 0
    )
        return null;

    const RenderSelectField = <T, >({
                                        onChange,
                                        label,
                                        value,
                                        closeMenu,
                                        ...other
                                    }: AppbarSelectFieldProps<T>) => {
        return (
            <AavoMenuItem
                sx={{
                    padding: 1,
                }}
            >
                <SelectField
                    disableClearable
                    sx={{
                        minWidth: "100px",
                        width: "100%",
                    }}
                    {...other}
                    value={value}
                    label={label}
                    onChange={async (value) => {
                        try {
                            await onChange(value);
                        } catch (e) {
                            logErrorAndShowOnDialog(e);
                        }
                        closeMenu();
                        window.location.reload();
                    }}
                />
            </AavoMenuItem>
        );
    };

    return [
        siteOptions.length > 1 && (
            <RenderSelectField
                key={"siteSelection"}
                label={i18n.t("site")}
                options={siteOptions}
                value={currentSiteId}
                getOptionKey={(o) => o.siteId}
                getOptionLabel={(o) => o.siteName}
                onChange={async (value) => {
                    await ErpAppbarSelectionsApi.setUserSite({siteId: value});
                }}
                closeMenu={closeMenu}
            />
        ),
        productionLineOptions.length > 1 && (
            <RenderSelectField
                key={"productionLineSelection"}
                label={i18n.t("production_line")}
                options={productionLineOptions}
                value={currentProductionLineId}
                getOptionKey={(o) => o.productionLineId}
                getOptionLabel={(o) => o.productionLineName}
                onChange={async (value) => {
                    await ErpAppbarSelectionsApi.setUserProductionLine({
                        productionLineId: value,
                    });
                }}
                closeMenu={closeMenu}
            />
        ),
        workCenterOptions.length > 1 && (
            <RenderSelectField
                key={"workCenterSelection"}
                label={i18n.t("workcenter")}
                options={workCenterOptions}
                value={currentWorkCenterId}
                getOptionKey={(o) => o.workcenterId}
                getOptionLabel={(o) => `${o.workcenterNo} | ${o.workcenterDescription}`}
                onChange={async (value) => {
                    await ErpAppbarSelectionsApi.setUserWorkcenter({
                        workcenterId: value,
                    });
                }}
                closeMenu={closeMenu}
            />
        ),
    ];
};

interface AppbarSelectFieldProps<T> extends Omit<SelectFieldProps<T, number, true>, "onChange"> {
    onChange: (v: number) => Promise<void>;
    closeMenu: () => void;
}
