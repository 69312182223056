import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {floatColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import i18n from "i18next";
import {concatWithPipe} from "src/utils/strings.tsx";
import {SalesPartContentLinesApi} from "src/api/generated/erp/sales/basedata/api/salesPartContentLinesApi.ts";
import {
    SalesPartContentLineForm
} from "src/components/views/erp/sales/basedata/salesParts/SalesPartContentLineForm.tsx";
import {SalesPartPackageLineView} from "src/api/generated/erp/db/types/tables/salesPartPackageLineView.ts";

export interface SalesPartContentLinesDataGridProps {
	parentSalesPartId: number;
}

export const SalesPartContentLinesDataGrid = ({ parentSalesPartId }: SalesPartContentLinesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"DC3C7EBFBF31A8209"}
			fetchData={() => SalesPartContentLinesApi.getContentLines({ parentSalesPartId: parentSalesPartId })}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.salesPartPackageLineId}
			render={({ dataGridProps }) => {
				return (
					<CrudDataGrid<SalesPartPackageLineView>
						columns={[
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("line_number"),
							}),
							textColumn({
								field: "salesPart" as any,
								headerName: i18n.t("sales_part"),
								valueGetter: (_, row) =>
									concatWithPipe(row.childSalesPartNo, row.childSalesPartDescription),
								width: 300,
							}),
							floatColumn({
								field: "quantity",
								headerName: i18n.t("quantity"),
							}),
							textColumn({
								field: "childSalesUnit",
								headerName: i18n.t("unit"),
							}),
						]}
						form={{
							addRowEnabled: true,
							editEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("content_line"),
							component: ({ row, onCompleted, onFormEdited }) => (
								<SalesPartContentLineForm
									childSalesPartPackageLineId={row?.salesPartPackageLineId}
									parentSalesPartId={parentSalesPartId}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						remove={async ({ items }) =>
							SalesPartContentLinesApi.delete_({
								salesPartPackageLineIds: items.map((item) => item.salesPartPackageLineId),
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
