import { SelectField, SelectFieldProps } from "src/components/common/inputFields/SelectField";
import { logError } from "src/errorHandling/errorLogging";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import i18n from "i18next";
import { MutableRefObject, useState } from "react";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";

export interface LazySelectFieldProps<T, Key, DisableClearable extends boolean | undefined>
	extends Omit<
		SelectFieldProps<T, Key, DisableClearable>,
		"onFocus" | "loading" | "options" | "value"
	> {
	apiRef?: MutableRefObject<LazySelectFieldApi<Key> | null>;
	fetchOptions: () => Promise<T[]>;
}

export interface LazySelectFieldApi<Key> {
	setValue: (value: Key | null) => void;
}

export const LazySelectField = <T, Key, DisableClearable extends boolean | undefined = undefined>({
	apiRef,
	fetchOptions,
	error,
	defaultValue,
	onChange,
	...other
}: LazySelectFieldProps<T, Key, DisableClearable>) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const [value, setValue] = useState<Key | null>(defaultValue ?? null);
	const [optionsAsync, refreshOptions] = useAsyncFetch<T[]>(fetchOptions, {
		fetchOnMount: defaultValue != null,
	});

	const combinedError =
		error ?? (optionsAsync.error !== undefined ? i18n.t("failed_to_fetch_options") : undefined);

	if (apiRef)
		apiRef.current = {
			setValue: setValueExternal,
		};

	return (
		<SelectField
			error={combinedError}
			options={optionsAsync.data ?? []}
			loading={optionsAsync.loading}
			onFocus={() => {
				refreshOptions().catch(logError);
			}}
			value={value}
			onChange={(newValue, option) => {
				setValue(newValue);
				onChange?.(newValue, option);
			}}
			{...other}
		/>
	);

	async function setValueExternal(newValue: Key | null) {
		if (newValue === value) return;

		try {
			if (newValue !== null) {
				await refreshOptions();
			}
			setValue(newValue);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};
