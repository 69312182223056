import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import i18n from "i18next";
import { CatalogPartTestConfiguratorView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorView.tsx";
import { faStethoscope } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart.ts";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision.ts";
import { useStoredState } from "src/utils/useStoredState.ts";
import { CatalogPartRevisionApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionApi.ts";
import { getCatalogPartRevisionStateLabel } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { ConfigurationProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyVersionApi.ts";

export interface ProductFamilyVersionManagingViewTestingTabProps {
	productFamilyVersionId: number;
}

export const ProductFamilyVersionManagingViewTestingTab = ({
	productFamilyVersionId,
}: ProductFamilyVersionManagingViewTestingTabProps) => {
	const { openDialog } = useGenericDialog();

	const [selectedCatalogPart, setSelectedCatalogPart] = useStoredState<CatalogPart | null>(
		{defaultValue: null, key: `productFamilyVersionTestConfiguratorCatalogPart#${productFamilyVersionId}`},
	);

	const [selectedCatalogPartRevision, setSelectedCatalogPartRevision] =
		useStoredState<CatalogPartRevision | null>(
			{
                defaultValue: null,
                key: `productFamilyVersionTestConfiguratorCatalogPartRevision#${productFamilyVersionId}`
            },
		);

	return (
		<VerticalBox
			sx={{
				marginY: 2,
				marginX: 1,
				alignItems: "stretch",
				gap: 2,
				width: "500px",
			}}
		>
			<LazySelectField
				label={i18n.t("test_part")}
				fetchOptions={() =>
					ConfigurationProductFamilyVersionApi.getProductFamilyVersionCatalogParts({
						productFamilyVersionId,
					})
				}
				getOptionKey={(option: CatalogPart) => option.catalogPartId}
				getOptionLabel={(option) =>
					concatWithPipe(option.partNo, option.description_1, option.description_2)
				}
				defaultValue={selectedCatalogPart?.catalogPartId ?? null}
				onChange={(_, selectedOption) => {
					setSelectedCatalogPart(selectedOption);
				}}
			/>
			<LazySelectField
				label={i18n.t("test_revision")}
				disabled={selectedCatalogPart == null ? i18n.t("select_a_part_first") : undefined}
				fetchOptions={async () => {
					if (selectedCatalogPart == null) return [];
					return await CatalogPartRevisionApi.getCatalogPartRevisions({
						catalogPartId: selectedCatalogPart.catalogPartId,
					});
				}}
				getOptionKey={(option: CatalogPartRevision) => option.catalogPartRevisionId}
				getOptionLabel={(option) =>
					concatWithPipe(
						option.revisionNumber,
						getCatalogPartRevisionStateLabel(option.state),
						option.note,
					)
				}
				defaultValue={selectedCatalogPartRevision?.catalogPartRevisionId ?? null}
				onChange={(_, selectedOption) => {
					setSelectedCatalogPartRevision(selectedOption);
				}}
			/>
			<AsyncButton
				label={i18n.t("test_configuration")}
				icon={faStethoscope}
				variant={"outlined"}
				disabled={selectedCatalogPartRevision == null ? i18n.t("select_a_test_revision") : undefined}
				sx={{
					alignSelf: "start",
				}}
				onClick={() =>
					openDialog({
						title: i18n.t("test_configuration"),
						content: (
							<CatalogPartTestConfiguratorView
								productFamilyVersionId={productFamilyVersionId}
								catalogPartRevisionId={selectedCatalogPartRevision!.catalogPartRevisionId}
							/>
						),
					})
				}
			/>
		</VerticalBox>
	);
};
