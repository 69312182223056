// This file is automatically generated. Do not edit manually.

import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { ObjectConfigurationHistoryEntryInput } from "src/api/generated/erp/configurator/objectConfigurationHistory/objectConfigurationHistoryEntryInput";
import { UnsavedCustomerOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOrderLinePartConfiguratorType";
import { UnsavedCustomerOfferLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOfferLinePartConfiguratorType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfigurationCreationApi {
	export async function createPartConfigurationForCustomerOrderLine(args: {
		customerOrderLineId: number;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
		historyEntryInput: ObjectConfigurationHistoryEntryInput;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForCustomerOrderLine",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
				historyEntryInput: args.historyEntryInput,
			},
		});
		return response as number;
	}

	export async function createPartConfigurationForUnsavedCustomerOrderLine(args: {
		configuratorType: UnsavedCustomerOrderLinePartConfiguratorType;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForUnsavedCustomerOrderLine",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
			},
		});
		return response as number;
	}

	export async function createPartConfigurationForCustomerOrderOfferLine(args: {
		customerOrderOfferLineId: number;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
		historyEntryInput?: ObjectConfigurationHistoryEntryInput;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForCustomerOrderOfferLine",
			method: "POST",
			data: {
				customerOrderOfferLineId: args.customerOrderOfferLineId,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
				historyEntryInput: args.historyEntryInput,
			},
		});
		return response as number;
	}

	export async function createPartConfigurationForUnsavedCustomerOfferLine(args: {
		configuratorType: UnsavedCustomerOfferLinePartConfiguratorType;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForUnsavedCustomerOfferLine",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
			},
		});
		return response as number;
	}

	export async function createPartConfigurationForShopOrderLine(args: {
		shopOrderLineId: number;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
		historyEntryInput?: ObjectConfigurationHistoryEntryInput;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForShopOrderLine",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
				historyEntryInput: args.historyEntryInput,
			},
		});
		return response as number;
	}

	export async function createPartConfigurationForPurchaseOrderLine(args: {
		purchaseOrderLineId: number;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
		historyEntryInput?: ObjectConfigurationHistoryEntryInput;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForPurchaseOrderLine",
			method: "POST",
			data: {
				purchaseOrderLineId: args.purchaseOrderLineId,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
				historyEntryInput: args.historyEntryInput,
			},
		});
		return response as number;
	}

	export async function createPartConfigurationForProjectActivityMaterial(args: {
		activityMaterialLineId: number;
		configurationSessionId: string;
		propertyValues: ConfigurationPropertyValues;
		historyEntryInput?: ObjectConfigurationHistoryEntryInput;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/createPartConfigurationForProjectActivityMaterial",
			method: "POST",
			data: {
				activityMaterialLineId: args.activityMaterialLineId,
				configurationSessionId: args.configurationSessionId,
				propertyValues: args.propertyValues,
				historyEntryInput: args.historyEntryInput,
			},
		});
		return response as number;
	}
}
