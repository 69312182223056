import { faFileImport } from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { FeeliaLeanSystemInventoryEventApi } from "src/api/generated/tenants/feelia/leanSystem/api/feeliaLeanSystemInventoryEventApi.ts";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";

export const FeeliaLeanSystemInventoryLevelEventImportButton = () => {
	const showInputDialog = useInputDialog();
	const { showToast } = useAavoToast();

	return (
		<AsyncMenuButton
			key={"feeliaLeanSystemInventoryLevelEventImportButton"}
			icon={faFileImport}
			label={"Tuo varastotapahtumat Leanista"}
			onClick={async () => {
				const filePath = await showInputDialog({
					type: "string",
					title: "Lataa varastotapahtumat",
					fieldLabel: "Tiedosto",
					defaultValue: "inventory.xml",
					required: true,
				});

				if (filePath === undefined) return;

				await FeeliaLeanSystemInventoryEventApi.importInventoryEvents({
					filePath: filePath,
				});

				showToast({ message: "Varastotapahtumat ladattu onnistuneesti" });
			}}
		/>
	);
};
