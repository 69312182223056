import { ConfigurableObjectType } from "src/api/generated/erp/db/types/enums/configurableObjectType";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorView.tsx";

export interface ExistingPartConfigurationReadonlyFormProps {
	objectType: ConfigurableObjectType;
	objectId: number;
	partConfigurationId: number;
	closeDialog: () => Promise<unknown>;
}

export const ExistingPartConfigurationReadonlyForm = ({
	objectType,
	objectId,
	partConfigurationId,
	closeDialog,
}: ExistingPartConfigurationReadonlyFormProps) => {
	return <PartConfiguratorView configuratorType={getConfiguratorType()} disabled={true} onCompleted={closeDialog} />;

	function getConfiguratorType(): PartConfiguratorType {
		const commonProps = {
			explicitPartConfigurationId: partConfigurationId,
			isTestConfigurator: false,
			isSubConfigurator: false,
		} as const;

		switch (objectType) {
			case "CUSTOMER_ORDER_LINE":
				return {
					type: "customerOrderLine",
					customerOrderLineId: objectId,
					...commonProps,
				};
			case "CUSTOMER_ORDER_OFFER_LINE":
				return {
					type: "customerOrderOfferLine",
					customerOrderOfferLineId: objectId,
					...commonProps,
				};
			case "PURCHASE_ORDER_LINE":
				return {
					type: "purchaseOrderLine",
					purchaseOrderLineId: objectId,
					...commonProps,
				};
			case "PROJECT_ACTIVITY_MATERIAL":
				return {
					type: "projectActivityMaterial",
					activityMaterialLineId: objectId,
					...commonProps,
				};
			case "SHOP_ORDER_LINE":
				return {
					type: "shopOrderLine",
					shopOrderLineId: objectId,
					...commonProps,
				};
		}
	}
};
