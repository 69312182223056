import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {
	IncreaseShopOrderCompletedQuantityFormApi,
	IncreaseShopOrderCompletedQuantityFormApi_FormInitData,
} from "src/api/generated/erp/production/api/shopOrder/increaseShopOrderCompletedQuantityFormApi.ts";
import {DeepPartial} from "react-hook-form";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import i18n from "i18next";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";

export interface IncreaseShopOrderCompletedQuantityFormProps extends FormCommonProps<void> {
	shopOrderId: number;
}

interface FormValues {
	quantityToCompleteInBaseUnit: number;
	partWarehouseLocationId: number;
	closeShopOrder: boolean;
}

export const IncreaseShopOrderCompletedQuantityForm = (
	props: IncreaseShopOrderCompletedQuantityFormProps,
) => {
	const { shopOrderId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => IncreaseShopOrderCompletedQuantityFormApi.getFormInitData({ shopOrderId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		shopOrder,
		defaultPartWarehouseLocationId,
	}: IncreaseShopOrderCompletedQuantityFormApi_FormInitData): DeepPartial<FormValues> {
		return {
			quantityToCompleteInBaseUnit: shopOrder.remainingQuantity,
			partWarehouseLocationId: defaultPartWarehouseLocationId ?? undefined,
			closeShopOrder: true,
		};
	}

	async function submit(values: FormValues) {
		await IncreaseShopOrderCompletedQuantityFormApi.increaseShopOrderCompletedQuantity({
			shopOrderId: shopOrderId,
			completedQuantityInWarehouseUnits: values.quantityToCompleteInBaseUnit,
			partWarehouseLocationId: values.partWarehouseLocationId,
			closeShopOrder: values.closeShopOrder,
		});
	}
};

interface FormContentProps
	extends IncreaseShopOrderCompletedQuantityFormProps,
		AsyncFormContentParams<IncreaseShopOrderCompletedQuantityFormApi_FormInitData, FormValues> {}

const FormContent = ({
	control,
	setValue,
	data: { shopOrder, partWarehouseLocationOptions },
}: FormContentProps) => {
	return (
		<>
			<FormNumberField
				control={control}
				name={"quantityToCompleteInBaseUnit"}
				label={i18n.t("completed_quantity.past") + ` (${shopOrder.partUnit})`}
				onChange={(newValue) => {
					setValue("closeShopOrder", newValue === shopOrder.remainingQuantity);
				}}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"partWarehouseLocationId"}
				label={i18n.t("warehouse_location")}
				options={partWarehouseLocationOptions}
				getOptionKey={(option) => option.partWarehouseLocationId}
				getOptionLabel={(option) => option.locationCode}
			/>
			<FormCheckbox control={control} name={"closeShopOrder"} label={i18n.t("close_shop_order")} />
		</>
	);
};
