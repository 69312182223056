import React from "react";

export interface FeatureChangeHint {
	content: React.ReactNode;
	anchorEl: HTMLElement;
}

export interface FeatureChangeHintContextValue {
	showHint: (hint: FeatureChangeHint) => void;
	hideHint: () => void;
}

export const FeatureChangeHintContext = React.createContext<FeatureChangeHintContextValue | undefined>(
	undefined,
);
