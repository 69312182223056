import {
    ReclamationCustomerOrdersDataGridApi
} from "src/api/generated/erp/reclamations/api/reclamationCustomerOrdersDataGridApi.ts";
import {CustomerOrderReclamationView} from "src/api/generated/erp/db/types/tables/customerOrderReclamationView.ts";
import {dateColumn, enumColumn, iconColumn, textColumn} from "../../../common/dataGrid/columns.tsx";
import {getCustomerOrderStateLabels} from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import {faBan, faCheck, faLinkSlash} from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import {useTheme} from "@mui/material";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {associate} from "src/utils/arrayUtils.ts";
import {OpenCustomerOrderButton} from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import {RefreshableElementProps} from "src/utils/useRefreshRef.ts";
import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {ReclamationOperationApi} from "src/api/generated/erp/reclamations/api/reclamationOperationApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";

export interface ReclamationCustomerOrdersDataGridProps extends RefreshableElementProps {
	reclamationId: number;
	onSelectionChanged: (selectedCustomerOrders: CustomerOrderReclamationView[]) => void;
}

export const ReclamationCustomerOrdersDataGrid = ({
	refreshRef,
	reclamationId,
	onSelectionChanged,
}: ReclamationCustomerOrdersDataGridProps) => {
	const theme = useTheme();
	const { tenantConfig } = useTenantCustomizations();
	const tenantStateLabelsByKeys = associate(
		tenantConfig.erp.customerOrderTenantStates,
		(state) => state.key,
		(state) => state.label,
	);

	const showConfirmDialog = useConfirmDialog();
	const userPermissions = useUserPermissions();

	const { dataGridProps, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			ReclamationCustomerOrdersDataGridApi.getReclamationCustomerOrders({
				reclamationId: reclamationId,
			}),
		initialParams: {},
		getRowId: (row) => row.customerOrderId,
		onSelectionChanged: (rows) => {
			onSelectionChanged(rows);
		},
		gridId: "FD8C804AEE67A83A",
		refreshRef: refreshRef,
	});

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderReclamationView>
			columns={[
				iconColumn({
					field: "isPrimaryCustomerOrder",
					headerName: "",
					width: 50,
					getIconProps: ({ isPrimaryCustomerOrder }) =>
						isPrimaryCustomerOrder ?
							{
								icon: faCheck,
								color: theme.palette.success.main,
								tooltip: i18n.t("primary"),
							}
						:	{
								icon: faBan,
								color: theme.palette.grey.A400,
								tooltip: i18n.t("secondary"),
							},
				}),
				textColumn({
					headerName: i18n.t("order_number"),
					field: "customerOrderId",
				}),
				textColumn({
					headerName: i18n.t("reference"),
					field: "orderReference",
					width: 300,
				}),
				dateColumn({
					headerName: i18n.t("planned_delivery_date"),
					field: "plannedDeliveryDate",
					width: 180,
				}),
				enumColumn({
					headerName: i18n.t("state"),
					field: "customerOrderState",
					enumLabels: getCustomerOrderStateLabels(),
					width: 150,
				}),
				tenantConfig.erp.useCustomerOrderTenantStates &&
					textColumn({
						headerName: tenantConfig.erp.customerOrderTenantStateColumnLabel,
						field: "tenantState",
						valueGetter: (_, row) => tenantStateLabelsByKeys[row.tenantState ?? ""] ?? "-",
					}),
			]}
			rowContextMenuComponents={({ onlySingleRowSelected, row }) => [
				onlySingleRowSelected && userPermissions.sales.readCustomerOrders && (
					<OpenCustomerOrderButton
						key={"openCustomerOrder"}
						customerOrderId={row.customerOrderId}
					/>
				),
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"removeConnection"}
						label={i18n.t("remove_connection")}
						icon={faLinkSlash}
						onClick={async () => {
							const confirmed = await showConfirmDialog({});
							if (!confirmed) return;
							await ReclamationOperationApi.removeConnectionToCustomerOrder({
								reclamationId: reclamationId,
								customerOrderId: row.customerOrderId,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
