export const isEmptyArray = (array: unknown): boolean => {
	return Array.isArray(array) && array.length === 0;
};

export function getOnlyMember<T>(array: Array<T>): T | undefined {
	if (array.length !== 1) {
		return undefined;
	}
	return array[0];
}

export const filterNulls = <T>(array: Array<T | null | undefined>): Array<T> => {
	return array.filter((v) => v !== null && v !== undefined) as Array<T>;
};

export const keepOnlyObjects = <T extends object>(array: Array<T | null | undefined | boolean>): Array<T> => {
	return array.filter((v) => v !== null && v !== undefined && v !== false) as Array<T>;
};

export const associate = <T, TKey extends string | number, TValue>(
	array: readonly T[],
	getKey: (value: T) => TKey,
	getValue: (value: T) => TValue,
): Record<TKey, TValue> => {
	return array.reduce(
		(acc, item) => {
			acc[getKey(item)] = getValue(item);
			return acc;
		},
		{} as Record<TKey, TValue>,
	);
};

export const distinctBy = <T, TKey extends string | number>(array: Array<T>, getKey: (value: T) => TKey): Array<T> => {
	const seen = new Set<TKey>();
	return array.filter((item) => {
		const key = getKey(item);
		if (seen.has(key)) {
			return false;
		}
		seen.add(key);
		return true;
	});
};

export const maxBy = <T>(array: Array<T>, getKey: (value: T) => number): T | null => {
	let max: T | null = null;
	array.forEach((item) => {
		if (max === null) {
			max = item;
		}
		if (getKey(item) > getKey(max)) {
			max = item;
		}
	});
	return max;
};

export const sortBy = <T>(array: T[], getKey: (value: T) => number): T[] => {
	return array.toSorted((a, b) => getKey(a) - getKey(b));
};

export const swapItems = <T>(array: Array<T>, index1: number, index2: number): Array<T> => {
	const result = [...array];
	const item1 = result[index1];
	const item2 = result[index2];
	if (item1 === undefined || item2 === undefined) {
		return array;
	}
	result[index1] = item2;
	result[index2] = item1;
	return result;
};

export const findDuplicateValues = <T>(array: Array<T>, getKey: (value: T) => string): Array<T> => {
	const seen = new Set<string>();
	const duplicates = new Set<T>();
	array.forEach((item) => {
		const key = getKey(item);
		if (seen.has(key)) {
			duplicates.add(item);
		}
		seen.add(key);
	});
	return Array.from(duplicates);
};
