import { useEffect } from "react";
import { AavoViewsContext, AavoViewsDispatchContext } from "src/components/views/legacy/AavoViewsContext.tsx";
import { LegacyAavoView } from "./LegacyAavoView";
import {
	getErrorViewIdFromParams,
	getViewOrErrorFromStartUpData,
	isErrorViewDefinition,
} from "./utils";
import i18n from "i18next";
import { ErrorView } from "src/components/common/errors/ErrorView.tsx";
import { dNone } from "src/styles/sx";
import { useParams } from "react-router-dom";
import { VIEW_ID } from "src/urls.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { useStartUpData } from "src/contexts/StartUpDataContext.ts";

export const LegacyViews = () => {
	const startUpData = useStartUpData();
	const { state } = useContextOrThrow(AavoViewsContext);
	const dispatch = useContextOrThrow(AavoViewsDispatchContext);
	const openViews = state.viewIdsOpened.map((viewId) => {
		return getViewOrErrorFromStartUpData(viewId, startUpData);
	});

	const { viewId: activeViewId } = useParams<typeof VIEW_ID>();
	useEffect(() => {
		if (activeViewId) {
			dispatch({
				type: "addActiveViewId",
				viewId: activeViewId,
			});
		}
	}, [activeViewId, dispatch]);

	return (
		<>
			{openViews.map((view) => {
				if (isErrorViewDefinition(view)) {
					const errorViewId = getErrorViewIdFromParams(view);
					const error = logError(
						`${i18n.t("error")}: ${i18n.t(
							"you_do_not_have_permissions_to_view_with_id",
							{ viewId: errorViewId },
						)}`,
						`Invalid view id ${errorViewId}. The view does not exist in startUpData.`,
					);

					return (
						<ErrorView
							key={`aavo-view-${errorViewId}`}
							sx={errorViewId !== activeViewId ? dNone : undefined}
							error={error}
						/>
					);
				}
				return (
					<LegacyAavoView
						key={`aavo-view-${view.view.id}`}
						startUpData={startUpData}
						viewDefinition={view}
						isOnForeground={activeViewId === view.view.id}
						isUppermostView={
							activeViewId === view.view.id && state.legacyModals.length === 0
						}
					/>
				);
			})}
		</>
	);
};
