import { SitePartAttributesApi } from "src/api/generated/erp/parts/sitePart/api/sitePartAttributesApi.ts";
import { objectAttributeDataGridColumns } from "src/components/views/erp/objectAttributes/objectAttributeDataGridUtils.tsx";
import { ObjectAttributeValueWithField } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeValueWithField.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";

export interface SitePartAttributesDataGridProps {
	sitePartId: number;
}

export const SitePartAttributesDataGrid = ({ sitePartId }: SitePartAttributesDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => SitePartAttributesApi.getPartAttributes({ partId: sitePartId }),
		getRowId: (row) => row.field.id,
		gridId: "BBCBC8E31B7DC3C",
		initialParams: {},
	});

	return (
		<ControlledAsyncDataGrid<ObjectAttributeValueWithField>
			columns={objectAttributeDataGridColumns}
			{...dataGridProps}
		/>
	);
};
