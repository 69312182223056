import { Box, List, ListItem, ListItemText, useTheme } from "@mui/material";
import { VerticalBox } from "../../../common/box/VerticalBox.tsx";
import i18n from "i18next";
import { SingleTaskMobileView } from "src/components/views/tasks/mobile/SingleTaskMobileView.tsx";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { TaskQueryParams, TaskWithUserNames } from "../types.ts";
import React, { useRef } from "react";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { TasksViewProps } from "src/components/views/tasks/TasksView.tsx";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { TaskQueryApi } from "src/api/generated/tasks/api/taskQueryApi.ts";
import { TaskView } from "src/api/generated/postgres/db/types/tasks/tables/taskView.ts";
import { mapAsyncState } from "src/utils/async/asyncState.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { TasksMobileViewFooter } from "src/components/views/tasks/mobile/TasksMobileViewFooter.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { getTaskStateIcon } from "src/components/views/tasks/taskStateUtils.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { emptyDataModelRequest } from "src/utils/serverSideDataModelRequestUtils.ts";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";

interface TaskQueryParamsWithLimit extends TaskQueryParams {
	taskLimit: number;
}

const PAGE_SIZE = 25;

export const TasksMobileView = ({
	refreshRef,
	taskQueryParams: initialQueryParams,
	onTaskCompleted,
	onTaskCompletionReverted,
	taskSourceRef,
}: TasksViewProps) => {
	const virtuosoRef = useRef<VirtuosoHandle>(null);
	const { openDialog } = useGenericDialog();
	const theme = useTheme();

	const {
		dataAsync,
		refresh: refreshAllTasks,
		paramValues: queryParamValues,
		setLocalData,
	} = useParameterizedAsyncData({
		fetchData: async (params: TaskQueryParamsWithLimit) => {
			const result = await TaskQueryApi.queryTasks({
				...params,
				dataModelRequest: {
					...emptyDataModelRequest,
					paginationModel: {
						page: 0,
						pageSize: params.taskLimit,
					},
				},
			});
			return expectNonFileResult(result);
		},
		initialParams: {
			...initialQueryParams,
			taskLimit: PAGE_SIZE,
		},
	});

	setRefreshRefValue(refreshRef, refreshAllTasks);

	const tasksAsync = mapAsyncState(dataAsync, (data) => data.rows);

	return (
		<AsyncRender
			asyncData={dataAsync}
			content={({ rows: tasks, totalRowCount }) => (
				<VerticalBox
					sx={{
						flex: 1,
					}}
				>
					{totalRowCount === 0 ?
						<NoTasksPlaceholder />
					:	<Virtuoso
							ref={virtuosoRef}
							style={{ flex: 1 }}
							totalCount={tasks.length}
							components={{
								List: React.forwardRef(({ children, style }, listRef) => {
									return (
										<List
											ref={listRef}
											style={{ padding: 0, margin: 0, ...style }}
											component="div"
										>
											{children}
										</List>
									);
								}),
								Item: ({ style, ...itemProps }) => {
									return (
										<ListItem
											component="div"
											style={{
												margin: 0,
												paddingBottom: 0.5,
												paddingTop: 0.5,
												...style,
											}}
											{...itemProps}
										/>
									);
								},
								Footer: () => (
									<ScrollerFooter
										tasks={tasks}
										totalRowCount={totalRowCount}
										loadNextPage={loadNextPage}
									/>
								),
							}}
							itemContent={(index) => {
								const task = tasks[index]!;
								return (
									<ListItemText
										primaryTypographyProps={{
											fontSize: "1.25rem",
										}}
										secondaryTypographyProps={{
											component: "div",
											fontSize: "1rem",
										}}
										primary={task.title}
										secondary={
											<>
												<Box
													sx={{
														display: "flex",
														gap: 1,
														alignItems: "center",
													}}
												>
													<FontAwesomeIcon {...getTaskStateIcon(task, theme)} />
													{formatIsoString(task.deadlineDate)}
												</Box>
												{task.sourceDescription}
											</>
										}
										onClick={() => {
											openDialog(({ closeDialog }) => ({
												title: i18n.t("task"),
												content: (
													<SingleTaskMobileView
														task={task}
														refreshTask={() => refreshTask(index)}
														updateLocalTask={(task) =>
															updateLocalTask(index, task)
														}
														closeTaskView={closeDialog}
														refreshTasks={async () => {
															await refreshAllTasks({});
														}}
														onTaskCompleted={async (task) => {
															await refreshAllTasks({});
															onTaskCompleted?.(task);
														}}
														onTaskCompletionReverted={async (task) => {
															await refreshAllTasks({});
															onTaskCompletionReverted?.(task);
														}}
													/>
												),
											}));
										}}
									/>
								);
							}}
						/>
					}
					<TasksMobileViewFooter
						queryParamValues={queryParamValues}
						refreshAllTasks={refreshAllTasks}
						newTaskSourceRef={taskSourceRef}
						showSourceFilter={taskSourceRef == null}
					/>
				</VerticalBox>
			)}
		/>
	);

	async function loadNextPage() {
		const currentCount = tasksAsync?.data?.length ?? 0;
		await refreshAllTasks({
			taskLimit: currentCount + PAGE_SIZE,
		});
		virtuosoRef.current?.scrollToIndex({
			index: currentCount - 1,
			behavior: "auto",
			align: "end",
		});
	}

	function updateLocalTask(index: number, task: TaskWithUserNames) {
		setLocalData((currentData) => {
			if (currentData === undefined) return currentData;
			const currentTasks = currentData.rows;
			const currentTask = currentTasks[index];
			if (currentTask === undefined) return currentData;
			if (currentTask.taskId !== task.taskId) return currentData;

			const newRows = [...currentTasks];
			newRows[index] = {
				...currentTask,
				...task,
			};
			return {
				...currentData,
				rows: newRows,
			};
		});
	}

	async function refreshTask(index: number) {
		const tasks = tasksAsync.data;
		if (tasks === undefined) return undefined;

		const taskId = tasks[index]?.taskId;
		if (taskId === undefined) return undefined;

		const refreshedTask = await TaskQueryApi.getSingleTask({ taskId });
		updateLocalTask(index, refreshedTask);
		return refreshedTask;
	}
};

interface ScrollerFooterProps {
	tasks: TaskView[];
	totalRowCount: number;
	loadNextPage: () => void;
}

const ScrollerFooter = ({ tasks, totalRowCount, loadNextPage }: ScrollerFooterProps) => {
	const hasMore = tasks.length < totalRowCount;
	return (
		<HorizontalBox
			sx={{
				justifyContent: "center",
				paddingY: 2,
			}}
		>
			{hasMore && (
				<AavoButton
					variant={"outlined"}
					label={i18n.t("load_more")}
					disabled={!hasMore}
					onClick={loadNextPage}
				/>
			)}
		</HorizontalBox>
	);
};

const NoTasksPlaceholder = () => {
	return (
		<CenteredTypography
			sx={{
				fontSize: "1.25rem",
			}}
		>
			{i18n.t("no_tasks")}
		</CenteredTypography>
	);
};
