// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConnectSalesPartsToPriceListViewApi {
	export async function getConnectableSalesParts(args: {
		salesPriceListId: number;
		siteIdFilter: number | null | undefined;
		searchQuery: string;
		showOnlyUnconnectedParts: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SalesPartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/connectSalesPartsToListView/getConnectableSalesParts",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
				siteIdFilter: args.siteIdFilter,
				searchQuery: args.searchQuery,
				showOnlyUnconnectedParts: args.showOnlyUnconnectedParts,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SalesPartView>;
	}

	export async function addLinesToPriceList(args: {
		salesPriceListId: number;
		lines: Array<ConnectSalesPartsToPriceListViewApi_AddLineDto>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/pricing/salesPriceLists/connectSalesPartsToListView/addLinesToPriceList",
			method: "POST",
			data: {
				salesPriceListId: args.salesPriceListId,
				lines: args.lines,
			},
		});
		return response as void;
	}
}

export interface ConnectSalesPartsToPriceListViewApi_AddLineDto {
	activeFrom: IsoDateString;
	price: number;
	salesPartId: number;
}
