import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { faArchive, faCopy, faInfo, faPlus, faTrash, faUndo, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { WorkspaceEditApi } from "src/api/generated/workspaces/api/workspaceEditApi.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { WorkspaceForm } from "src/components/views/workspaces/WorkspaceForm.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { WorkspacesTreeViewContext } from "src/components/views/workspaces/treeView/WorkspaceTreeViewContext.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { WorkspaceTreeViewItemModel } from "src/components/views/workspaces/treeView/WorkspacesTreeViewState.ts";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { WorkspaceMobileContentView } from "src/components/views/workspaces/WorkspaceMobileContentView.tsx";
import { WorkspacePermissionsDataGrid } from "src/components/views/workspaces/permission/WorkspacePermissionsDataGrid.tsx";

export interface WorkspaceTreeViewContextMenuProps {
	workspace: WorkspaceTreeViewItemModel;
}

export const WorkspaceTreeViewContextMenu = ({ workspace }: WorkspaceTreeViewContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const openInputDialog = useInputDialog();
	const { refresh, dispatch } = useContextOrThrow(WorkspacesTreeViewContext);
	const isMobile = useMediaQueryBreakpointDown("sm");
	return [
		isMobile && (
			<AsyncMenuButton
				icon={faInfo}
				label={i18n.t("info")}
				onClick={() =>
					openDialog({
						title: workspace.workspaceName,
						content: <WorkspaceMobileContentView workspace={workspace} />,
					})
				}
			/>
		),
		isMobile && (
			<AsyncMenuButton
				icon={faUsers}
				label={i18n.t("permissions")}
				onClick={() =>
					openDialog({
						title: i18n.t("permissions"),
						content: <WorkspacePermissionsDataGrid workspaceId={workspace.workspaceId} />,
					})
				}
			/>
		),
		<AsyncMenuButton
			key={"createWorkspace"}
			label={i18n.t("create_sub_workspace")}
			icon={faPlus}
			onClick={async () => {
				openFormOnDialog({
					openDialog: openDialog,
					component: WorkspaceForm,
					title: i18n.t("create_workspace"),
					props: {
						workspaceId: null,
						parentId: workspace.workspaceId,
					},
					onSubmit: async () => {
						await refresh();
						dispatch({
							type: "setWorkspaceIsExpanded",
							workspaceId: workspace.workspaceId,
							isExpanded: true,
						});
					},
					size: "md",
				});
			}}
		/>,
		workspace.adminPermissions && (
			<AsyncMenuButton
				key={"copy"}
				label={i18n.t("copy")}
				icon={faCopy}
				onClick={async () => {
					const newWorkspaceName = await openInputDialog({
						type: "string",
						title: i18n.t("new_name"),
						fieldLabel: i18n.t("name"),
						defaultValue: i18n.t("copy_postfix", { name: workspace.workspaceName }),
						required: true,
					});
					if (!newWorkspaceName) return;
					await WorkspaceEditApi.copyWorkspace({
						sourceWorkspaceId: workspace.workspaceId,
						targetWorkspaceName: newWorkspaceName,
					});
					await refresh();
				}}
			/>
		),
		workspace.adminPermissions && workspace.isArchived && (
			<AsyncMenuButton
				key={"delete"}
				label={i18n.t("delete")}
				icon={faTrash}
				onClick={async () => {
					await WorkspaceEditApi.delete_({ workspaceId: workspace.workspaceId });
					await refresh();
				}}
			/>
		),
		workspace.adminPermissions && !workspace.isArchived && (
			<AsyncMenuButton
				key={"archive"}
				label={i18n.t("archive")}
				icon={faArchive}
				onClick={async () => {
					await WorkspaceEditApi.archiveWorkspace({ workspaceId: workspace.workspaceId });
					await refresh();
				}}
			/>
		),
		workspace.isArchived && (
			<AsyncMenuButton
				key={"revertArchive"}
				label={i18n.t("revert_archive")}
				icon={faUndo}
				onClick={async () => {
					await WorkspaceEditApi.revertArchiveWorkspace({ workspaceId: workspace.workspaceId });
					await refresh();
				}}
			/>
		),
	];
};
