import { FormCommonProps } from "src/components/common/forms/types.ts";
import { ConfigurationProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyVersionApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm";

export interface ProductFamilyVersionTopLevelFormProps extends FormCommonProps<void> {
	productFamilyVersion: ConfigurationProductFamilyVersion;
}

interface FormValues extends ConfigurationProductFamilyVersion {}

export const ProductFamilyVersionTopLevelForm = (props: ProductFamilyVersionTopLevelFormProps) => {
	const { productFamilyVersion, onCompleted, onFormEdited } = props;
	return (
		<AavoForm<FormValues, void>
			defaultValues={productFamilyVersion}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			hideCancel
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: FormValues) {
		await ConfigurationProductFamilyVersionApi.updateProductFamilyVersion({
			productFamilyVersionId: productFamilyVersion.configurationProductFamilyVersionId,
			description: values.description,
			configurationTopLevelScript: values.configurationTopLevelScript,
			unrestrictedEditing: values.unrestrictedEditing,
		});
	}
};

interface FormContentProps extends ProductFamilyVersionTopLevelFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control, productFamilyVersion }: FormContentProps) => {
	const limitedEditing = productFamilyVersion.state === "PUBLISHED" && !productFamilyVersion.unrestrictedEditing;

	return (
		<>
			<FormTextField control={control} name={"description"} label={i18n.t("version_description")} multiline />
			{productFamilyVersion.state === "PUBLISHED" && (
				<FormCheckbox
					control={control}
					name={"unrestrictedEditing"}
					label={i18n.t("allow_unrestricted_editing")}
				/>
			)}
			<FormConfiguratorLuaEditor
				control={control}
				name={"configurationTopLevelScript"}
				label={i18n.t("configuration_top_level_function")}
				productFamilyVersionId={productFamilyVersion.configurationProductFamilyVersionId}
				catalogPartRevisionId={null}
				disabled={limitedEditing}
			/>
		</>
	);
};
