import { PartConfiguratorView } from "../PartConfiguratorView";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { UnsavedCustomerOfferLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOfferLinePartConfiguratorType.ts";
import { CustomerOrderOfferLine } from "src/api/generated/erp/db/types/tables/customerOrderOfferLine";
import { FormResult } from "src/components/common/forms/types.ts";

export interface CustomerOfferLineBeforeCreateConfiguratorViewProps {
	customerOfferLine: CustomerOrderOfferLine; // Unsaved customerOfferLine
	onCompleted: (result: FormResult<number>) => void;
}

export const CustomerOfferLineBeforeCreateConfiguratorView = ({
	customerOfferLine,
	onCompleted,
}: CustomerOfferLineBeforeCreateConfiguratorViewProps) => {
	const configuratorType: UnsavedCustomerOfferLinePartConfiguratorType = {
		type: "unsavedCustomerOfferLine",
		customerOfferId: customerOfferLine.customerOrderOfferId,
		plannedDeliveryDate: customerOfferLine.plannedDeliveryDate,
		salesPartId: customerOfferLine.salesPartId,
		salesQuantityAsSalesUnits: customerOfferLine.salesQuantityAsSalesUnits,
		shopOrderBatchCode: customerOfferLine.shopOrderBatchCode,
		isTestConfigurator: false,
		isSubConfigurator: false,
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					const { propertyValues, configurationSessionId } = result.value;
					const partConfigurationId =
						await ConfigurationCreationApi.createPartConfigurationForUnsavedCustomerOfferLine({
							configuratorType: configuratorType,
							propertyValues: propertyValues,
							configurationSessionId: configurationSessionId,
						});
					onCompleted({ type: "success", value: partConfigurationId });
				} else {
					onCompleted(result);
				}
			}}
		/>
	);
};
