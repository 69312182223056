import i18n from "i18next";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons";
import {
	ObjectConfigurationHistoryDataGrid,
	ObjectConfigurationHistoryDataGridProps,
} from "src/components/views/erp/configurator/objectConfigurationHistory/ObjectConfigurationHistoryDataGrid.tsx";

export interface OpenConfigurationHistoryButtonProps
	extends Omit<AsyncButtonProps, "onClick">,
		ObjectConfigurationHistoryDataGridProps {}

export const OpenConfigurationHistoryButton = ({
	variant = "menu",
	objectType,
	objectId,
	refreshSourceView,
}: OpenConfigurationHistoryButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("configuration_history")}
			icon={faLayerGroup}
			variant={variant}
			onClick={() => {
				openDialog(() => ({
					title: i18n.t("configuration_history"),
					size: "xl",
					content: (
						<ObjectConfigurationHistoryDataGrid
							objectType={objectType}
							objectId={objectId}
							refreshSourceView={refreshSourceView}
						/>
					),
				}));
			}}
		/>
	);
};
