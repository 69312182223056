import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {
	ControlledAsyncCrudDataGrid,
	ControlledAsyncCrudDataGridProps,
} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { DocumentActiveRevisionView } from "src/api/generated/postgres/db/types/documents/tables/documentActiveRevisionView.ts";
import {
	dateTimeColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { thumbnailColumn } from "src/components/views/documents/utils/thumbnails.tsx";
import { LazyMultiSelectField } from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import {
	faExternalLink,
	faFile,
	faFileArrowDown,
	faLink,
} from "@fortawesome/pro-regular-svg-icons";
import { DocumentFileApi } from "src/api/generated/documents/api/documentFileApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import React from "react";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { DocumentRevisionsView } from "src/components/views/documents/revisions/DocumentRevisionsView.tsx";
import { GenericDialogContext } from "src/components/common/dialogs/GenericDialogContext.ts";
import { DocumentConnectionsDataGrid } from "src/components/views/documents/connections/DocumentConnectionsDataGrid.tsx";
import { EditDocumentWithWopiButtonMenuItem } from "../wopi/EditDocumentWithWopiButtonMenuItem";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { DocumentSearchApi } from "src/api/generated/documents/api/documentSearchApi.ts";
import { AavoDataGridRowContextMenuParams } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export interface DocumentSearchDataGridBaseProps
	extends RefreshableElementProps,
		Pick<ControlledAsyncCrudDataGridProps<DocumentActiveRevisionView>, "actionBarComponents"> {
	filterDocumentsConnectedToObject?: AavoObjectRef;
	rowContextMenuComponents?: (
		params: AavoDataGridRowContextMenuParams<DocumentActiveRevisionView>,
	) => React.ReactNode[];
	onRowSelectionChanged?: (rows: DocumentActiveRevisionView[]) => void;
}

export const DocumentSearchDataGridBase = ({
	actionBarComponents,
	rowContextMenuComponents,
	refreshRef,
	onRowSelectionChanged,
	filterDocumentsConnectedToObject,
}: DocumentSearchDataGridBaseProps) => {
	const { dataGridProps, currentParams, refreshData, onlySelectedRow } =
		useServerSideDataGridModel({
			gridId: "55AEF9D909AD6E54",
			fetchData: DocumentSearchApi.searchDocuments,
			initialParams: {
				documentIdOrDescription1Query: "",
				description2Query: "",
				categoryIdFilter: Array<number>(),
				filterDocumentsConnectedToObject: filterDocumentsConnectedToObject,
			},
			getRowId: (row) => row.documentId,
			onSelectionChanged: onRowSelectionChanged,
		});

	const { openDialog } = useContextOrThrow(GenericDialogContext);

	setRefreshRefValue(refreshRef, refreshData);

	return (
		<ControlledAsyncCrudDataGrid<DocumentActiveRevisionView>
			columns={[
				integerColumn({
					headerName: i18n.t("number_shortened"),
					field: "documentId",
					width: 60,
				}),
				textColumn({
					headerName: i18n.t("description_1"),
					field: "description1",
					width: 300,
				}),
				textColumn({
					headerName: i18n.t("type"),
					field: "primaryFileExtension",
					width: 80,
				}),
				thumbnailColumn({
					headerName: i18n.t("image"),
					field: "thumbnail",
					sortable: false,
				}),
				textColumn({
					headerName: i18n.t("description_2"),
					field: "description2",
					width: 250,
				}),
				textColumn({
					headerName: i18n.t("category"),
					field: "categoryName",
					width: 200,
				}),
				integerColumn({
					headerName: i18n.t("revision"),
					field: "revisionNumber",
					width: 60,
				}),
				textColumn({
					headerName: i18n.t("note"),
					field: "note",
					width: 200,
					sortable: false,
				}),
				textColumn({
					headerName: i18n.t("created_by"),
					field: "createdByUserName",
				}),
				dateTimeColumn({
					headerName: i18n.t("created_at"),
					field: "createdAt",
					width: 150,
				}),
			]}
			actionBarComponents={
				<>
					<LazyMultiSelectField
						label={i18n.t("category")}
						value={currentParams.categoryIdFilter}
						fetchOptions={() => DocumentQueryApi.getDocumentCategoryOptions()}
						getOptionKey={(option) => option.documentCategoryId}
						getOptionLabel={(option) => option.name}
						onChange={async (values: number[]) => {
							await refreshData({ categoryIdFilter: values });
						}}
						sx={{
							minWidth: 120,
						}}
					/>
					<AavoTextField
						label={i18n.t("description_1")}
						onSubmit={async (value) => {
							await refreshData({ documentIdOrDescription1Query: value });
						}}
					/>
					<AavoTextField
						label={i18n.t("description_2")}
						onSubmit={async (value) => {
							await refreshData({ description2Query: value });
						}}
					/>
					<AavoButton
						label={i18n.t("open")}
						icon={faExternalLink}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						sx={{
							display: { md: "none" },
						}}
						onClick={() => {
							if (onlySelectedRow == null) return;
							openDialog(() => ({
								title: onlySelectedRow.description1,
								content: (
									<DocumentPreviewView documentId={onlySelectedRow.documentId} />
								),
							}));
						}}
					/>
					{actionBarComponents}
				</>
			}
			rowContextMenuComponents={(contextMenuParams) => {
				const { row, allSelectedRows, onlySingleRowSelected } = contextMenuParams;
				const parentComponents = rowContextMenuComponents?.(contextMenuParams) ?? [];
				return [
					onlySingleRowSelected && (
						<EditDocumentWithWopiButtonMenuItem
							key={"edit_with_wopi"}
							fileExtension={row.primaryFileExtension ?? undefined}
							fileUuid={row.primaryFileUuid ?? undefined}
						/>
					),
					<AsyncMenuButton
						key={"download"}
						icon={faFileArrowDown}
						label={i18n.t("download")}
						onClick={async () => {
							const documentIds = allSelectedRows
								.filter((row) => row.primaryFileName != null)
								.map((row) => row.documentId);
							if (documentIds.length === 0) return;

							const fileHandle = await DocumentFileApi.downloadDocumentsAsSingleFile({
								documentIds,
							});
							downloadFile(fileHandle);
						}}
					/>,
					onlySingleRowSelected && (
						<AsyncMenuButton
							key={"revisions"}
							label={i18n.t("revisions")}
							icon={faFile}
							onClick={async () => {
								openDialog(() => ({
									title: `${i18n.t("revisions")} (${row.description1})`,
									content: (
										<DocumentRevisionsView document={row} editable={true} />
									),
								}));
							}}
						/>
					),
					onlySingleRowSelected && (
						<AsyncMenuButton
							key={"connections"}
							label={i18n.t("connected_items")}
							icon={faLink}
							onClick={async () => {
								openDialog(() => ({
									title: `${i18n.t("connected_items")} (${row.description1})`,
									size: "md",
									content: (
										<DocumentConnectionsDataGrid documentId={row.documentId} />
									),
								}));
							}}
						/>
					),
					...parentComponents,
				];
			}}
			{...dataGridProps}
		/>
	);
};
