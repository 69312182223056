import i18n from "i18next";
import {faTruckLoading} from "@fortawesome/pro-regular-svg-icons";
import {AsyncButton, AsyncButtonProps} from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {WarehouseTransferDataGrid} from "src/components/views/erp/warehouse/transfer/WarehouseTransferDataGrid.tsx";

export interface OpenWarehouseTransferButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	warehouseTransferId: number | null | undefined;
}

export const OpenWarehouseTransferButton = ({
	warehouseTransferId,
	variant = "menu",
	...props
}: OpenWarehouseTransferButtonProps) => {
	const { openDialog } = useGenericDialog();

	if (warehouseTransferId == null) return null;

	return (
		<AsyncButton
			label={i18n.t("open_warehouse_transfer")}
			icon={faTruckLoading}
			variant={variant}
			onClick={async () => {
				if (warehouseTransferId == null) return;
				openDialog({
					title: i18n.t("warehouse_transfer"),
					content: <WarehouseTransferDataGrid onlyTransferId={warehouseTransferId} />,
				});
			}}
			{...props}
		/>
	);
};
