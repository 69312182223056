import {faCubes} from "@fortawesome/pro-solid-svg-icons";
import i18n from "i18next";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import {CustomerOrderLineView} from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    CustomerOrderLinesDataGrid
} from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLinesDataGrid.tsx";

export interface OpenCustomerOrderChildLinesButtonProps {
	customerOrderLine: Pick<
		CustomerOrderLineView,
		"customerOrderId" | "customerOrderLineId" | "salesPartType"
	>;
}

export const OpenCustomerOrderChildLinesButton = ({
	customerOrderLine,
}: OpenCustomerOrderChildLinesButtonProps) => {
	const { openDialog } = useGenericDialog();

	if (customerOrderLine?.salesPartType !== "PACKAGE_") return;

	return (
		<AavoButton
			label={i18n.t("package_lines")}
			icon={faCubes}
			onClick={() => {
				openDialog(() => ({
					title: i18n.t("package_lines"),
					size: "xl",
					content: (
						<CustomerOrderLinesDataGrid
							customerOrderId={customerOrderLine.customerOrderId}
							parentLineId={customerOrderLine.customerOrderLineId}
						/>
					),
				}));
			}}
		/>
	);
};
