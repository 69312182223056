import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import {
	SalesPriceListEditApi,
	SalesPriceListEditApi_FormInitData,
} from "src/api/generated/erp/sales/pricing/api/salesPriceListEditApi";
import { SalesPriceList } from "src/api/generated/erp/db/types/tables/salesPriceList.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField";
import {
	getSalesPriceListActiveFromReferenceDateTypeLabels
} from "src/api/generated/erp/db/types/enums/salesPriceListActiveFromReferenceDateType.ts";

export interface SalesPriceListFormProps extends FormCommonProps<number> {
	salesPriceListId: number | undefined;
}

interface FormValues extends SalesPriceList {}

export const SalesPriceListForm = (props: SalesPriceListFormProps) => {
	const { salesPriceListId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => SalesPriceListEditApi.getFormInitData({ salesPriceListId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		salesPriceList,
		salesPartPriceGroupOptions,
	}: SalesPriceListEditApi_FormInitData): DeepPartial<FormValues> {
		if (salesPriceList) {
			return salesPriceList;
		} else {
			return {
				salesPartPriceGroupId: salesPartPriceGroupOptions[0]?.salesPartPriceGroupId,
				activeFromReferenceDateType: "LINE_CREATION_DATE"
			};
		}
	}

	async function submit(values: FormValues) {
		if (salesPriceListId != null) {
			await SalesPriceListEditApi.update({ salesPriceList: values });
			return salesPriceListId;
		} else {
			return await SalesPriceListEditApi.insert({ salesPriceList: values });
		}
	}
};

interface FormContentProps
	extends SalesPriceListFormProps,
		AsyncFormContentParams<SalesPriceListEditApi_FormInitData, FormValues> {}

const FormContent = ({ control, data: { salesPartPriceGroupOptions } }: FormContentProps) => {
	return (
		<>
			<FormSelectField
				control={control}
				name={"salesPartPriceGroupId"}
				label={i18n.t("sales_price_group")}
				options={salesPartPriceGroupOptions}
				getOptionKey={(option) => option.salesPartPriceGroupId}
				getOptionLabel={(option) => option.name}
				disableClearable
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
			<FormEnumSelectField
				control={control}
				name={"activeFromReferenceDateType"}
				label={i18n.t("active_from_reference_date")}
				options={getSalesPriceListActiveFromReferenceDateTypeLabels()}
				disableClearable
			/>
			<FormTextField control={control} name={"description"} label={i18n.t("description")} multiline />
		</>
	);
};
