// This file is automatically generated. Do not edit manually.

import { ObjectAttributeValueWithField } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeValueWithField";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartAttributesApi {
	export async function getCatalogPartAttributes(args: {
		catalogPartId: number;
	}): Promise<Array<ObjectAttributeValueWithField>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/attributes/getCatalogPartAttributes",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as Array<ObjectAttributeValueWithField>;
	}

	export async function saveCatalogPartAttributes(args: {
		catalogPartId: number;
		attributes: Array<ObjectAttributeValueWithField>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/attributes/saveCatalogPartAttributes",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
				attributes: args.attributes,
			},
		});
		return response as void;
	}
}
