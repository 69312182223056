import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { DefaultValues, FieldValues, UseFormReturn } from "react-hook-form";
import React from "react";
import { FormInputContent } from "src/components/common/dialogs/formInput/FormInputContent.tsx";
import { AavoDialogSize } from "src/components/common/dialogs/AavoDialog.tsx";

export type OpenFormInputDialogFunc = <TFieldValues extends FieldValues>(
	params: ShowFormInputDialogParams<TFieldValues>,
) => Promise<TFieldValues | undefined>;

export interface ShowFormInputDialogParams<TFieldValues extends FieldValues> {
	title: string;
	size?: AavoDialogSize;
	defaultValues?: DefaultValues<TFieldValues>;
	content: (params: ShowFormInputDialogContentParams<TFieldValues>) => React.ReactNode;
	submitLabel?: string;
	onSubmit?: (values: TFieldValues) => Promise<unknown>;
	confirmCloseIfEdited?: boolean;
	disabled?: boolean;
}

export interface ShowFormInputDialogContentParams<TFieldValues extends FieldValues>
	extends UseFormReturn<TFieldValues> {}

export const useFormInput = (): OpenFormInputDialogFunc => {
	const { openDialog } = useGenericDialog();

	return <TFieldValues extends FieldValues>(params: ShowFormInputDialogParams<TFieldValues>) => {
		const { title, size = "sm", confirmCloseIfEdited = false } = params;
		return new Promise<TFieldValues | undefined>((resolve) => {
			openDialog(({ closeDialog, onContentEdited }) => ({
				title: title,
				size: size,
				onClose: async () => {
					resolve(undefined);
				},
				content: (
					<FormInputContent
						{...params}
						onFormEdited={confirmCloseIfEdited ? onContentEdited : undefined}
						onCompleted={async (result) => {
							switch (result.type) {
								case "success":
									resolve(result.value);
									await closeDialog();
									break;
								case "cancel":
									resolve(undefined);
									await closeDialog({ confirmIfEdited: confirmCloseIfEdited });
									break;
							}
						}}
					/>
				),
			}));
		});
	};
};
