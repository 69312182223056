import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { ProductFamilyVersionConnectedRevisionsDataGridApi } from "src/api/generated/erp/configurator/management/productFamilies/api/productFamilyVersionConnectedRevisionsDataGridApi.ts";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCatalogPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import { faIcons, faLink, faStethoscope, faTable, faTools } from "@fortawesome/pro-regular-svg-icons";
import { CatalogPartRevisionConfiguratorSelectionsAsyncView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsAsyncView.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { CatalogPartTestConfiguratorView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorView.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { CatalogPartSiteRevisionsConfiguratorManagingView } from "src/components/views/erp/configurator/managing/catalogPart/CatalogPartRevisionSiteRevisionsDataGrid.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { CatalogPartRevisionBomView } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionBomView.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";

export interface ProductFamilyVersionConnectedRevisionsDataGridProps {
	productFamilyVersionId: number;
	refreshRef: RefreshableElementRef;
}

export const ProductFamilyVersionConnectedRevisionsDataGrid = ({
	productFamilyVersionId,
	refreshRef,
}: ProductFamilyVersionConnectedRevisionsDataGridProps) => {
	const { openDialog } = useGenericDialog();
	const openLegacyView = useOpenLegacyView();

	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: ({ searchQuery }) =>
			ProductFamilyVersionConnectedRevisionsDataGridApi.getDataGridData({
				productFamilyVersionId,
				searchQuery,
			}),
		initialParams: { searchQuery: "" },
		getRowId: (row) => row.catalogPartRevisionId,
		gridId: "34791A5AB7FC9FCB",
		refreshRef: refreshRef,
	});

	return (
		<ControlledAsyncDataGrid<CatalogPartRevisionView>
			columns={[
				textColumn({
					field: "part" as any,
					headerName: i18n.t("part"),
					valueGetter: (_, row) =>
						concatWithPipe(
							row.partNo,
							row.catalogPartDescription_1,
							row.catalogPartDescription_2,
						),
					width: 200,
				}),
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
					width: 50,
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getCatalogPartRevisionStateLabels(),
				}),
				integerColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 150,
				}),
				textColumn({
					field: "categoryName",
					headerName: i18n.t("category"),
					width: 100,
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={(searchQuery) => refreshData({ searchQuery })}
					/>
					<AsyncButton
						key={"testConfiguration"}
						label={i18n.t("test_configuration")}
						icon={faStethoscope}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						onClick={() => {
							openDialog({
								title: i18n.t("test_configuration"),
								content: (
									<CatalogPartTestConfiguratorView
										productFamilyVersionId={productFamilyVersionId}
										catalogPartRevisionId={onlySelectedRow!.catalogPartRevisionId}
									/>
								),
							});
						}}
					/>
					<AsyncButton
						key={"configuratorComponents"}
						label={i18n.t("components")}
						icon={faTools}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						onClick={() => {
							openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
								title: i18n.t("configurator_components"),
								content: (
									<CatalogPartRevisionConfiguratorSelectionsAsyncView
										catalogPartRevisionId={onlySelectedRow!.catalogPartRevisionId}
										productFamilyVersionId={productFamilyVersionId}
										onDataDirtyStateChanged={onDataDirtyStateChanged}
										onSubmitted={async () => {
											await closeDialog();
										}}
										onCancelled={async () => {
											await closeDialog({ confirmIfEdited: true });
										}}
									/>
								),
							}));
						}}
					/>
					<AsyncButton
						key={"sites"}
						label={i18n.t("sites")}
						icon={faLink}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={() => {
							openDialog({
								title: i18n.t("site_parts"),
								content: (
									<CatalogPartSiteRevisionsConfiguratorManagingView
										catalogPartRevision={onlySelectedRow!}
									/>
								),
							});
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && (
					<DocumentsOfObjectButton
						key={"documents"}
						variant={"menu"}
						objectRef={nullableAavoObjectRef("CATALOG_PART_REVISION", row.catalogPartRevisionId)}
						label={i18n.t("documents")}
					/>
				),
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"productStructure"}
						label={i18n.t("product_structure")}
						icon={faTable}
						disabled={!onlySelectedRow}
						onClick={() => {
							if (onlySelectedRow == null) return;
							openDialog(({ onDataDirtyStateChanged }) => ({
								title: i18n.t("product_structure"),
								content: (
									<CatalogPartRevisionBomView
										catalogPartRevisionId={onlySelectedRow.catalogPartRevisionId}
										dataDirtyStateChanged={onDataDirtyStateChanged}
									/>
								),
							}));
						}}
					/>
				),
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"attributeRules"}
						label={i18n.t("attribute_rules")}
						icon={faIcons}
						onClick={() => {
							openLegacyView("A443917350E74173", {
								catalogPartRevisionId: row.catalogPartRevisionId,
								categoryId: row.categoryId,
							});
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
