import {
	WorkspacesTreeViewApi,
	WorkspacesTreeViewApi_TreeViewData,
	WorkspacesTreeViewApi_WorkspaceDto,
} from "src/api/generated/workspaces/api/workspacesTreeViewApi.ts";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { useEffect, useReducer } from "react";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import {
	initializeWorkspaceTreeState,
	workspacesTreeViewReducer,
} from "src/components/views/workspaces/treeView/WorkspacesTreeViewState.ts";
import {
	SetParentIdParams,
	WorkspacesTreeViewContext,
	WorkspacesTreeViewFetchParams,
} from "./WorkspaceTreeViewContext";
import { WorkspacesTreeStatelessView } from "src/components/views/workspaces/treeView/WorkspacesTreeStatelessView.tsx";
import { WorkspaceEditApi } from "src/api/generated/workspaces/api/workspaceEditApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { WorkspaceConnectedItemRef } from "src/api/generated/workspaces/connections/workspaceConnectedItemRef.ts";

export interface WorkspacesTreeViewProps {
	onlyWorkspaceIdToShow?: number;
	onlyWorkspacesConnectedToItem?: WorkspaceConnectedItemRef;
	onSelectedWorkspaceChanged: (item: WorkspacesTreeViewApi_WorkspaceDto | null) => void;
}

export const WorkspacesTreeView = (props: WorkspacesTreeViewProps) => {
	const { onlyWorkspaceIdToShow, onlyWorkspacesConnectedToItem } = props;
	const { dataAsync, refresh, paramValues } = useParameterizedAsyncData<
		WorkspacesTreeViewApi_TreeViewData,
		WorkspacesTreeViewFetchParams
	>({
		fetchData: async (params) =>
			await WorkspacesTreeViewApi.getTreeViewData({
				showArchived: params.showArchived ?? false,
				onlyWorkspaceIdToShow: onlyWorkspaceIdToShow,
				onlyWorkspacesConnectedToItem: onlyWorkspacesConnectedToItem,
			}),
		initialParams: {
			showArchived: false,
		},
	});

	return (
		<AsyncRender
			asyncData={dataAsync}
			content={(data) => <AsyncContent data={data} refresh={refresh} fetchParamValues={paramValues} {...props} />}
		/>
	);
};

interface AsyncContentProps extends WorkspacesTreeViewProps {
	data: WorkspacesTreeViewApi_TreeViewData;
	refresh: (params?: Partial<WorkspacesTreeViewFetchParams>) => Promise<WorkspacesTreeViewApi_TreeViewData>;
	fetchParamValues: WorkspacesTreeViewFetchParams;
}

const AsyncContent = ({
	data: { workspaces: initialWorkspaces, categoryOptions },
	refresh: refreshProp,
	fetchParamValues: { showArchived },
	onSelectedWorkspaceChanged: onSelectedWorkspaceChangedProp,
	onlyWorkspaceIdToShow,
	onlyWorkspacesConnectedToItem,
}: AsyncContentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [state, dispatchState] = useReducer(
		workspacesTreeViewReducer,
		initialWorkspaces,
		initializeWorkspaceTreeState,
	);

	useEffect(() => {
		if (onlyWorkspaceIdToShow != null) {
			selectWorkspace(onlyWorkspaceIdToShow);
			dispatchState({
				type: "revealWorkspace",
				workspaceId: onlyWorkspaceIdToShow,
			});
		} else if (onlyWorkspacesConnectedToItem != null) {
			selectWorkspace(state.rootTreeItems[0]?.workspaceId ?? null)
			dispatchState({
				type: "setExpandedWorkspaces",
				workspaceIds: state.allTreeItems.map((w) => w.workspaceId),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<WorkspacesTreeViewContext.Provider
			value={{
				state: state,
				dispatch: dispatchState,
				refresh: refresh,
				categoryOptions: categoryOptions,
				setParentId: setParentId,
			}}
		>
			<WorkspacesTreeStatelessView onSelectedItemChanged={selectWorkspace} showArchived={showArchived ?? false} />
		</WorkspacesTreeViewContext.Provider>
	);

	function selectWorkspace(workspaceId: number | null) {
		dispatchState({
			type: "setSelectedWorkspace",
			workspaceId: workspaceId,
		});
		const selectedWorkspace =
			workspaceId == null ? null : state.allTreeItems.find((w) => w.workspaceId === workspaceId);
		onSelectedWorkspaceChangedProp(selectedWorkspace ?? null);
	}

	async function setParentId({ workspaceId, parentId }: SetParentIdParams) {
		try {
			await WorkspaceEditApi.setParentId({
				workspaceId: workspaceId,
				parentId: parentId,
			});
			await refresh();
			if (parentId != null)
				dispatchState({
					type: "setWorkspaceIsExpanded",
					workspaceId: parentId,
					isExpanded: true,
				});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function refresh(params?: Partial<WorkspacesTreeViewFetchParams>) {
		const { workspaces } = await refreshProp({ showArchived: params?.showArchived ?? showArchived });
		dispatchState({
			type: "refreshData",
			workspaces: workspaces,
		});
		const updatedSelectedWorkspace = workspaces.find((w) => w.workspaceId === state.selectedWorkspace?.workspaceId);
		if (updatedSelectedWorkspace) {
			onSelectedWorkspaceChangedProp(updatedSelectedWorkspace);
		}
	}
};
