// This file is automatically generated. Do not edit manually.

import { PaymentTerm } from "src/api/generated/erp/db/types/tables/paymentTerm";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PaymentTermsApi {
	export async function getAll(): Promise<Array<PaymentTerm>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/PaymentTerms/getAll",
			method: "POST",
			data: {},
		});
		return response as Array<PaymentTerm>;
	}

	export async function insert(args: { paymentTerm: PaymentTerm }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/PaymentTerms/insert",
			method: "POST",
			data: {
				paymentTerm: args.paymentTerm,
			},
		});
		return response as number;
	}

	export async function update(args: { paymentTerm: PaymentTerm }): Promise<PaymentTerm> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/PaymentTerms/update",
			method: "POST",
			data: {
				paymentTerm: args.paymentTerm,
			},
		});
		return response as PaymentTerm;
	}

	export async function delete_(args: { paymentTermId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/PaymentTerms/delete",
			method: "POST",
			data: {
				paymentTermId: args.paymentTermId,
			},
		});
		return response as void;
	}

	export async function setToDefault(args: { paymentTermId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/PaymentTerms/setToDefault",
			method: "POST",
			data: {
				paymentTermId: args.paymentTermId,
			},
		});
		return response as void;
	}
}
