// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalesPriceListActiveFromReferenceDateTypeValues = ["LINE_CREATION_DATE", "PLANNED_DELIVERY_DATE"] as const;

export type SalesPriceListActiveFromReferenceDateType =
	(typeof SalesPriceListActiveFromReferenceDateTypeValues)[number];

export const getSalesPriceListActiveFromReferenceDateTypeLabels = () => ({
	LINE_CREATION_DATE: t("line_creation_date"),
	PLANNED_DELIVERY_DATE: t("planned_delivery_date"),
});

export const getSalesPriceListActiveFromReferenceDateTypeLabel = (
	value: SalesPriceListActiveFromReferenceDateType,
): string => {
	return getSalesPriceListActiveFromReferenceDateTypeLabels()[value];
};
