import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { SalespersonApi } from "src/api/generated/erp/sales/basedata/api/salespersonApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { booleanColumn, enumColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SalespersonView } from "src/api/generated/erp/db/types/tables/salespersonView.ts";
import { getSalespersonPaymentMethodLabels } from "src/api/generated/erp/db/types/enums/salespersonPaymentMethod.ts";
import { SalespersonForm } from "src/components/views/erp/sales/basedata/salespersons/SalespersonForm.tsx";
import { SalespersonEditApi } from "src/api/generated/erp/sales/basedata/api/salespersonEditApi.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faBan, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";

export const SalespersonsDataGrid = () => {
	const showConfirmDialog = useConfirmDialog();

	const { dataGridProps, refreshData, currentParams } = useClientSideDataGridModel({
		fetchData: ({ showInactive }) =>
			SalespersonApi.getSalespersons({
				includeInactive: showInactive,
			}),
		initialParams: {
			showInactive: false,
		},
		getRowId: (row) => row.salespersonId,
		gridId: "E7A19E5D8FAE51",
	});

	return (
		<ControlledAsyncCrudDataGrid<SalespersonView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "email",
					headerName: i18n.t("email"),
					width: 200,
				}),
				textColumn({
					field: "phone",
					headerName: i18n.t("phone"),
					width: 150,
				}),
				enumColumn({
					field: "paymentMethod",
					headerName: i18n.t("payment_method"),
					enumLabels: getSalespersonPaymentMethodLabels(),
				}),
				booleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("salesperson"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<SalespersonForm salesperson={row} onCompleted={onCompleted} onFormEdited={onFormEdited} />
				),
			}}
			remove={({ items }) =>
				SalespersonEditApi.delete_({
					salespersonIds: items.map((item) => item.salespersonId),
				})
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"showInactive"}
					label={i18n.t("show_inactive")}
					checked={currentParams.showInactive}
					onChange={(value) => refreshData({ showInactive: value })}
				/>,
			]}
			rowContextMenuComponents={({ row }) => [
				row.isActive && (
					<AsyncMenuButton
						key={"deactivate"}
						label={i18n.t("deactivate")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await SalespersonEditApi.setSalespersonIsActive({
								salespersonId: row.salespersonId,
								isActive: false,
							});
							await refreshData();
						}}
					/>
				),
				!row.isActive && (
					<AsyncMenuButton
						key={"activate"}
						label={i18n.t("activate")}
						icon={faUndo}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await SalespersonEditApi.setSalespersonIsActive({
								salespersonId: row.salespersonId,
								isActive: true,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
