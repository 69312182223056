import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import {
	SalesPartCategoryEditApi,
	SalesPartCategoryEditApi_FormInitData,
} from "src/api/generated/erp/sales/basedata/api/salesPartCategoryEditApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DeepPartial } from "react-hook-form";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import { SalesPartCategory } from "src/api/generated/erp/db/types/tables/salesPartCategory.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	ObjectAttributesEmbeddedForm,
	ObjectAttributesEmbeddedFormValues,
} from "src/components/views/erp/objectAttributes/ObjectAttributesEmbeddedForm.tsx";
import {
	getObjectAttributesEmbeddedFormDefaultValues,
	getObjectAttributesFromEmbeddedFormValues,
} from "src/components/views/erp/objectAttributes/ObjectAttributesEmbeddedForm.utils.tsx";

export interface SalesPartCategoryFormProps extends FormCommonProps<number> {
	salesPartCategoryId: number | undefined;
}

interface FormValues extends SalesPartCategory, ObjectAccountingFormValues, ObjectAttributesEmbeddedFormValues {}

export const SalesPartCategoryForm = (props: SalesPartCategoryFormProps) => {
	const { salesPartCategoryId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => SalesPartCategoryEditApi.getFormInitData({ salesPartCategoryId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
					<ObjectAccountingForm control={control} />
					<ObjectAttributesEmbeddedForm control={control} />
				</>
			)}
		/>
	);

	function getDefaultValues({
		salesPartCategory,
		accountingDimensions,
		attributeValues,
		attributeFields,
	}: SalesPartCategoryEditApi_FormInitData): DeepPartial<FormValues> {
		const accountingValues = getObjectAccountingFormDefaultValues(accountingDimensions);
		const attributeFormValues = getObjectAttributesEmbeddedFormDefaultValues(attributeValues, attributeFields);
		if (salesPartCategory != null) {
			return {
				...salesPartCategory,
				...accountingValues,
				...attributeFormValues,
			};
		} else {
			return {
				...accountingValues,
				...attributeFormValues,
			};
		}
	}

	async function submit(values: FormValues) {
		const { accountingDimensionValues, ...salesPartCategory } = values;
		const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);
		const attributes = getObjectAttributesFromEmbeddedFormValues(values);
		if (salesPartCategoryId != null) {
			await SalesPartCategoryEditApi.update({
				salesPartCategory,
				accountingCodeIds,
				attributes,
			});
			return salesPartCategoryId;
		} else {
			return await SalesPartCategoryEditApi.insert({
				salesPartCategory,
				accountingCodeIds,
				attributes,
			});
		}
	}
};
