import { ObjectAttribute } from "src/api/generated/erp/db/types/tables/objectAttribute.ts";
import { ObjectAttributesEmbeddedFormValues } from "src/components/views/erp/objectAttributes/ObjectAttributesEmbeddedForm.tsx";
import { ObjectAttributeValueWithFieldId } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeValueWithFieldId.ts";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeField";

export function getObjectAttributesEmbeddedFormDefaultValues(
	attributes: ObjectAttribute[],
	attributeFields: ObjectAttributeField[],
): ObjectAttributesEmbeddedFormValues {
	const attributeFieldsWithValues = attributeFields.map((attributeField) => ({
		field: attributeField,
		attributeValue:
			attributes.find((attribute) => attribute.attributeFieldId === attributeField.id)?.attributeValue ?? null,
	}));

	return { attributes: attributeFieldsWithValues };
}

export function getObjectAttributesFromEmbeddedFormValues(
	values: ObjectAttributesEmbeddedFormValues,
): ObjectAttributeValueWithFieldId[] {
	return values.attributes.map(({ attributeValue, field }) => ({
		attributeFieldId: field.id,
		attributeValue: attributeValue,
	}));
}
