import i18n from "i18next";
import { AavoButton, AavoButtonProps } from "src/components/common/buttons/AavoButton.tsx";
import { faRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { deletePushNotificationRegistrations } from "src/notifications/firebaseMessaging.ts";
import { PushNotificationsContext } from "src/notifications/PushNotificationsContext.tsx";
import { CurrentUserApi } from "src/api/currentUserApi.ts";
import { useNavigate } from "react-router-dom";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";

export interface LogOutButtonProps
	extends Omit<AavoButtonProps, "loading" | "endIcon" | "onClick" | "icon"> {
	showIcon: boolean;
}

export const LogOutButton = ({ showIcon, ...other }: LogOutButtonProps) => {
	const pushNotificationsContext = useContextOrThrow(PushNotificationsContext);
	const navigate = useNavigate();

	return (
		<AavoButton
			label={i18n.t("log_out")}
			icon={showIcon ? faRightFromBracket : undefined}
			variant={"text"}
			onClick={async () => {
				try {
					await CurrentUserApi.logOut();
					await deletePushNotificationRegistrations();
					await pushNotificationsContext.deleteRegistration();
				} finally {
					navigate("/");
				}
			}}
			{...other}
		/>
	);
};
