import { AxiosResponse } from "axios";

const UNAUTHENTICATED_STATUS_CODE = 278;

export const onUnauthenticatedUser = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
	if (response.status === UNAUTHENTICATED_STATUS_CODE) {
		if (!response.data.url) {
			throw new Error(
				"Invalid response with status code 278(=unauthenticated). Response body missing field 'url'",
			);
		} else {
			window.location.replace(response.data.url);
			// window.location.replace is not immediate, so the caller will get broken result if we do nothing here.
			// If we throw error, the user will see error message for a short while.
			// We deal with this by returning never-resolving promise, so that time between location change
			// is used somehow.
			// See https://github.com/axios/axios/issues/715#issuecomment-290993181
			return new Promise(() => {});
		}
	}
	return response;
};
