import { Autocomplete, AutocompleteProps, CircularProgress, TextField, TextFieldProps } from "@mui/material";
import { InputError } from "./types";
import i18n from "i18next";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { useDebounce } from "src/utils/useDebounce.ts";
import { CustomPopper, CustomPopperProps } from "src/components/common/popper/CustomPopper.tsx";
import { MutableRefObject, RefObject, useMemo, useRef, useState } from "react";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { getSingleSelectAutocompleteInputWidthStyle } from "src/components/common/inputFields/selectFieldUtils.ts";
import { isTouchDevice } from "src/utils/isTouchDevice.ts";
import deepMerge from "src/utils/deepMerge.ts";
import { mergeSx } from "src/utils/styles.ts";

export interface AsyncSelectFieldProps<T, Key extends string | number>
	extends Omit<
		AutocompleteProps<T, false, false, false>,
		| "onChange"
		| "options"
		| "renderInput"
		| "getOptionKey"
		| "getOptionLabel"
		| "freeSolo"
		| "multiple"
		| "filterOptions"
		| "isOptionEqualToValue"
		| "value"
		| "ref"
		| "defaultValue"
		| "disableClearable"
	> {
	apiRef?: MutableRefObject<AsyncSelectFieldApi<Key> | null>;
	textFieldRef?: RefObject<HTMLDivElement>;
	fetchOptions: AsyncSelectFieldFetchOptionsFunc<Key, T>;
	onChange: (v: T | null) => void | Promise<unknown>;
	defaultValue?: Key | null;
	getOptionKey: (o: T) => Key;
	getOptionLabel: (o: T) => string;
	label: string;
	error?: InputError;
	placeholder?: string;
	popperProps?: Partial<CustomPopperProps>;
	TextFieldProps?: Partial<TextFieldProps>;
	showClearButton?: boolean;
}

export type AsyncSelectFieldFetchOptionsFunc<Key, T> = (params: FetchAsyncOptionParams<Key>) => Promise<T[]>;

export interface FetchAsyncOptionParams<Key> {
	searchQuery: string;
	currentSelection: Key | null;
}

export interface AsyncSelectFieldApi<Key> {
	setValue: (value: Key | null) => void;
}

export const AsyncSelectField = <T, Key extends string | number>({
	apiRef,
	textFieldRef,
	label,
	defaultValue: defaultValueKey,
	onChange,
	fetchOptions: fetchOptionsProp,
	autoFocus,
	error,
	placeholder,
	noOptionsText,
	getOptionKey,
	getOptionLabel,
	popperProps,
	blurOnSelect,
	sx,
	TextFieldProps,
	showClearButton = true,
	slotProps,
	onOpen,
	onClose,
	...other
}: AsyncSelectFieldProps<T, Key>) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [valueKey, setValueKey] = useState<Key | null>(defaultValueKey ?? null);
	const valueBeforeOpen = useRef(defaultValueKey ?? null);

	const [optionsAsync, fetchOptions] = useAsyncFetch<T[], Partial<FetchAsyncOptionParams<Key>>>(
		async (params) => {
			try {
				return await fetchOptionsProp({
					searchQuery: params?.searchQuery ?? searchQuery,
					currentSelection: params?.currentSelection ?? valueKey,
				});
			} catch (e) {
				logErrorAndShowOnDialog(e);
				return [];
			}
		},
		{
			fetchOnMount: defaultValueKey != null,
		},
	);

	const debounceInput = useDebounce();

	const value: T | null | undefined = useMemo(() => {
		if (valueKey === undefined) return undefined;
		if (valueKey == null) return null;

		if (optionsAsync.data === undefined) return null;
		const matchingOption = optionsAsync.data.find((o) => getOptionKey(o) === valueKey);
		if (matchingOption != null) return matchingOption;

		console.error("AsyncSelectField: value not found in options", valueKey);
		return null;
	}, [optionsAsync.data, valueKey, getOptionKey]);

	if (apiRef)
		apiRef.current = {
			setValue: setValueExternally,
		};

	return (
		<Autocomplete
			options={optionsAsync.data || []}
			filterOptions={(o) => o}
			value={value}
			getOptionKey={getOptionKey}
			getOptionLabel={getOptionLabel}
			blurOnSelect={blurOnSelect}
			onChange={async (_, value) => {
				try {
					const newValueKey = value == null ? null : getOptionKey(value);
					setValueKey(newValueKey);
					await onChange(value);
				} catch (e) {
					logErrorAndShowOnDialog(e);
				}
			}}
			isOptionEqualToValue={(option, value) => {
				if (value == null) return false;
				return getOptionKey(option) === getOptionKey(value);
			}}
			noOptionsText={noOptionsText !== undefined ? noOptionsText : i18n.t("no_options")}
			onInputChange={(_, newInput, reason) => {
				onInputChanged(newInput, reason === "input");
			}}
			inputValue={searchQuery}
			onOpen={(e) => {
				valueBeforeOpen.current = valueKey;
				setValueKey(null);
				onInputChanged("", true);
				onOpen?.(e);
			}}
			onClose={(e, reason) => {
				const shouldSetPreviousValue = reason === "escape" || (reason === "blur" && !blurOnSelect);
				if (shouldSetPreviousValue) {
					setValueKey(valueBeforeOpen.current);
				}
				onClose?.(e, reason);
			}}
			PopperComponent={(defaultPopperProps) => (
				<CustomPopper {...defaultPopperProps} {...popperProps} />
			)}
			slotProps={slotProps}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						{...TextFieldProps}
						ref={textFieldRef}
						label={label}
						error={error !== undefined}
						helperText={error}
						autoFocus={autoFocus}
						placeholder={placeholder || i18n.t("search_options")}
						inputProps={deepMerge(
							params.inputProps,
							{
								style: {
									...getSingleSelectAutocompleteInputWidthStyle(label, params),
								},
							},
							TextFieldProps?.inputProps,
						)}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{optionsAsync.loading && <CircularProgress size={20} color={"inherit"} />}
									{params.InputProps.endAdornment}
								</>
							),
							...TextFieldProps?.InputProps,
						}}
					/>
				);
			}}
			sx={mergeSx(
				{
					minWidth: 150,
					"& .MuiAutocomplete-clearIndicator": {
						display: showClearButton ? undefined : "none",
						visibility: isTouchDevice() ? "visible" : undefined,
					},
				},
				sx,
			)}
			{...other}
		/>
	);

	function onInputChanged(newInput: string, shouldRefreshOptions: boolean) {
		setSearchQuery(newInput);
		if (shouldRefreshOptions)
			debounceInput(200, async () => {
				await fetchOptions({
					searchQuery: newInput,
				});
			});
	}

	async function setValueExternally(newValueKey: Key | null) {
		if (newValueKey === valueKey) return;

		try {
			await fetchOptions({
				currentSelection: newValueKey,
			});
			setValueKey(newValueKey);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};
