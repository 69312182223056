import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView.ts";
import { ObjectSurveySubmissionApi } from "src/api/generated/erp/surveys/api/objectSurveySubmissionApi.ts";

export interface OpenShopOrderOperationSurveyButtonProps {
	shopOrderOperation:
		| Pick<ShopOrderOperationView, "shopOrderOperationId" | "surveyFormId" | "surveyFormName" | "sourceRefData">
		| undefined;
	onSubmit?: () => Promise<unknown>;
}

export const OpenShopOrderOperationSurveyButton = ({
	shopOrderOperation,
	onSubmit,
}: OpenShopOrderOperationSurveyButtonProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const { openDialog } = useGenericDialog();

	const surveysEnabled = tenantConfig.erp.surveysEnabled;

	if (!surveysEnabled) return null;

	return (
		<AsyncButton
			label={i18n.t("survey")}
			icon={faQuestion}
			variant={"outlined"}
			disabled={shopOrderOperation == null || shopOrderOperation.surveyFormId == null}
			onClick={async () => {
				const { shopOrderOperationId, surveyFormId, surveyFormName, sourceRefData } = shopOrderOperation!;
				if (surveyFormId == null) return;

				const existingSubmission = await ObjectSurveySubmissionApi.getObjectSurveySubmission({
					objectType: "SHOP_ORDER_OPERATION",
					objectId: shopOrderOperationId,
				});
				openFormOnDialog({
					openDialog,
					title: surveyFormName,
					size: "lg",
					component: SurveySubmissionForm,
					props: {
						surveyFormId,
						surveySubmissionId: existingSubmission?.surveySubmissionId,
						objectRef: {
							objectType: "SHOP_ORDER_OPERATION",
							objectId: shopOrderOperationId,
							objectDescription: sourceRefData ?? "",
						},
					},
					onSubmit: onSubmit,
				});
			}}
		/>
	);
};
