import { CustomerOrderOfferView } from "src/api/generated/erp/db/types/tables/customerOrderOfferView.ts";
import { useState } from "react";
import { CustomerOrderOfferDataGrid } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { CustomerOrderOfferLinesDataGrid } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferLinesDataGrid.tsx";
import { SingleSourceTasksView } from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CustomerOfferBillingPlanDataGrid } from "src/components/views/erp/sales/billingPlan/customerOffer/CustomerOfferBillingPlanDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { CustomerOfferSalesCommissionView } from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/offer/CustomerOfferSalesCommissionView.tsx";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";

export interface CustomerOrderOffersPageProps {
	onlyCustomerOrderOfferToShowId?: number;
}

export const CustomerOrderOffersPage = ({ onlyCustomerOrderOfferToShowId }: CustomerOrderOffersPageProps) => {
	const [selectedOffer, setSelectedOffer] = useState<CustomerOrderOfferView | undefined>(undefined);

	const tasksEnabled = useTenantCustomizations().tenantConfig.tasks.enabled ?? false;

	const userPermissions = useUserPermissions();

	const customerOrderOffersRefreshRef = useRefreshRef();

	const selectOfferTypography = <CenteredTypography children={i18n.t("select_offer")} />;

	return (
		<AavoMosaic
			viewId={"87C42AB6D3358E47"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("offers"),
					content: (
						<CustomerOrderOfferDataGrid
							onlyCustomerOrderOfferToShowId={onlyCustomerOrderOfferToShowId}
							onSelectionChanged={setSelectedOffer}
							refreshRef={customerOrderOffersRefreshRef}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("offer_lines"),
							content:
								selectedOffer == null ? selectOfferTypography : (
									<CustomerOrderOfferLinesDataGrid
										key={selectedOffer.customerOrderOfferId}
										customerOrderOfferId={selectedOffer.customerOrderOfferId}
										parentLineId={undefined}
									/>
								),
						},
						tasksEnabled && {
							type: "panel",
							title: i18n.t("tasks"),
							content:
								selectedOffer == null ? selectOfferTypography : (
									<SingleSourceTasksView
										key={selectedOffer.customerOrderOfferId}
										taskSourceRef={{
											sourceType: "CUSTOMER_ORDER_OFFER",
											sourceId: selectedOffer.customerOrderOfferId,
										}}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("billing_plan"),
							hidden: !selectedOffer?.billingPlanEnabled,
							content:
								selectedOffer == null ? selectOfferTypography : (
									<CustomerOfferBillingPlanDataGrid
										key={selectedOffer.customerOrderOfferId}
										customerOfferId={selectedOffer.customerOrderOfferId}
										customerOrderOffersRefreshRef={customerOrderOffersRefreshRef}
									/>
								),
						},
						selectedOffer?.salesCommissionId != null &&
							userPermissions.sales.manageCustomerOfferSalesCommissions && {
								type: "panel",
								title: i18n.t("commissions"),
								content: (
									<CustomerOfferSalesCommissionView
										key={selectedOffer.salesCommissionId}
										salesCommissionId={selectedOffer.salesCommissionId}
										customerOffer={selectedOffer}
									/>
								),
							},
					],
				},
			}}
		/>
	);
};
