import { Box, InputLabel } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { SxProps, Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";
import { getLargestActiveBreakpoint, useMediaQueryForAllBreakpointsUp } from "src/utils/breakpointUtils.ts";
import { Breakpoint } from "@mui/system";
import { filterNulls } from "src/utils/arrayUtils.ts";
import React, { Suspense } from "react";
import { PageLoading } from "src/components/common/PageLoading.tsx";
import type { ReactQuillProps, Quill } from "react-quill";

export interface AavoRichTextEditorProps extends ReactQuillProps {
	label?: string;
	sx?: SxProps<Theme>;
	jasperReportsCompatibility?: boolean;
}

const ReactQuill = React.lazy(async () => {
	const ret = await import("react-quill");
	setupQuill(ret.Quill);
	return ret;
});

export const AavoRichTextEditor = ({
	label,
	sx,
	jasperReportsCompatibility = false,
	...other
}: AavoRichTextEditorProps) => {
	const breakpoints = useMediaQueryForAllBreakpointsUp();
	const useIfBreakpoint = <T,>(breakpoint: Breakpoint, value: T[]): T[] | null => {
		return breakpoints[breakpoint] ? value : null;
	};

	const useIfNotJasperReportsCompatibility = <T,>(value: T[] | null): T[] | null => {
		return jasperReportsCompatibility ? null : value;
	};

	const toolbarOptions = filterNulls([
		["bold", "italic", "underline", "strike"],

		useIfNotJasperReportsCompatibility(["blockquote"]),

		useIfBreakpoint("xs", [{ size: fontSizeArr }, { list: "ordered" }, { list: "bullet" }]),
		useIfNotJasperReportsCompatibility(useIfBreakpoint("md", ["link", "image"])),
		useIfBreakpoint("md", [{ script: "sub" }, { script: "super" }]),
		useIfNotJasperReportsCompatibility(useIfBreakpoint("md", [{ indent: "-1" }, { indent: "+1" }])),

		useIfBreakpoint("sm", [{ color: [] }, { background: [] }]),
		useIfNotJasperReportsCompatibility(useIfBreakpoint("md", [{ font: [] }])),
		useIfNotJasperReportsCompatibility(useIfBreakpoint("sm", [{ align: [] }])),

		useIfBreakpoint("sm", ["clean"]),
	]);

	return (
		<Suspense fallback={<PageLoading />}>
			<Box sx={mergeSx(styles, sx)}>
				{label && (
					<InputLabel
						sx={{
							fontSize: "0.75rem",
							margin: 0,
							marginTop: "-1rem",
						}}
					>
						{label}
					</InputLabel>
				)}
				<ReactQuill
					key={getLargestActiveBreakpoint(breakpoints)} // Rotating screen causes editor to crash without remount.
					theme="snow"
					style={{
						flex: 1,
						minHeight: 0,
					}}
					modules={{
						toolbar: toolbarOptions,
					}}
					{...other}
				/>
			</Box>
		</Suspense>
	);
};

const fontSizeArr = ["8px", "9px", "10px", "12px", "14px", "16px", "20px", "24px", "32px"];

function setupQuill(quill: typeof Quill) {
	const bold = quill.import("formats/bold");
	bold.tagName = "b"; // Quill uses <strong> by default
	quill.register(bold, true);

	const italic = quill.import("formats/italic");
	italic.tagName = "i"; // Quill uses <em> by default
	quill.register(italic, true);

	const strike = quill.import("formats/strike");
	strike.tagName = "strike"; // Use <strike> to support JasperReports
	quill.register(strike, true);

	const size = quill.import("attributors/style/size");
	size.whitelist = fontSizeArr;
	quill.register(size, true);
}

const styles: SxProps<Theme> = {
	display: "flex",
	flexDirection: "column",
	alignItems: "stretch",
	"& .quill": {
		display: "flex",
		flexDirection: "column",
	},
	"& .ql-editor": {
		overflow: "auto",
	},
	"& .ql-toolbar": {
		minHeight: "unset",
	},
	...fontSizeArr.reduce(
		(acc, size) => ({
			...acc,
			// [`.ql-picker.ql-size .ql-picker-item[data-value="${size}"]::before`]: {
			[`& .ql-picker.ql-size .ql-picker-item[data-value="${size}"]::before`]: {
				content: `"${size}"`,
				fontSize: size,
			},
			[`& .ql-picker.ql-size .ql-picker-label[data-value="${size}"]::before`]: {
				content: `"${size}"`,
			},
		}),
		{},
	),
};
