// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const SalvosSubProjectPlannedStartDateProvider_ReferenceTypeValues = [
	"PRODUCTION_PLAN",
	"PLANNED_DELIVERY_DATE",
] as const;

export type SalvosSubProjectPlannedStartDateProvider_ReferenceType =
	(typeof SalvosSubProjectPlannedStartDateProvider_ReferenceTypeValues)[number];

export const getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabels = () => ({
	PRODUCTION_PLAN: t("production_plan"),
	PLANNED_DELIVERY_DATE: t("planned_delivery_date"),
});

export const getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabel = (
	value: SalvosSubProjectPlannedStartDateProvider_ReferenceType,
): string => {
	return getSalvosSubProjectPlannedStartDateProvider_ReferenceTypeLabels()[value];
};
