import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorView } from "../PartConfiguratorView";
import { PartConfiguratorFormResult } from "../PartConfiguratorForm";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { PartConfigurationInspectingView } from "../../inspecting/PartConfigurationInspectingView";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { askObjectConfigurationHistoryEntryInput } from "src/components/views/erp/configurator/objectConfigurationHistory/ObjectConfigurationHistoryEntryInputForm.utils.tsx";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { useAavoToast } from "src/components/common/toast/AavoToastContext";

export interface CustomerOrderOfferLineConfiguratorViewProps {
	customerOrderOfferLineId: number;
	closeDialog: () => Promise<unknown>;
	refreshSourceView?: () => void;
	disabled?: boolean;
}

export const CustomerOrderOfferLineConfiguratorView = ({
	customerOrderOfferLineId,
	closeDialog,
	refreshSourceView,
	disabled
}: CustomerOrderOfferLineConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();
	const { showToast } = useAavoToast();
	const userPermissions = useUserPermissions();
	const onlySalespersonOwnOfferData = userPermissions.sales.onlySalespersonOwnOfferData;

	const configuratorType: PartConfiguratorType = {
		type: "customerOrderOfferLine",
		customerOrderOfferLineId: customerOrderOfferLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
		explicitPartConfigurationId: null
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					await onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
			disabled={disabled}
		/>
	);

	async function onSubmit({ propertyValues, configurationSessionId }: PartConfiguratorFormResult) {
		const historyEntryInput = await askObjectConfigurationHistoryEntryInput({
			openDialog,
			objectType: "CUSTOMER_ORDER_OFFER_LINE",
			objectId: customerOrderOfferLineId,
		});
		if (historyEntryInput === undefined) return;

		const partConfigurationId = await ConfigurationCreationApi.createPartConfigurationForCustomerOrderOfferLine({
			customerOrderOfferLineId: customerOrderOfferLineId,
			propertyValues: propertyValues,
			configurationSessionId: configurationSessionId,
			historyEntryInput: historyEntryInput,
		});

		await closeDialog();
		refreshSourceView?.();

		if (onlySalespersonOwnOfferData) {
			showToast({ title: i18n.t("configuration_created") });
		} else {
			openDialog(() => ({
				title: i18n.t("configuration"),
				content: <PartConfigurationInspectingView partConfigurationId={partConfigurationId} />,
			}));
		}
	}
};
