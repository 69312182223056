import { createContext } from "react";

export interface PartConfiguratorDocumentContextValue {
	getComponentHasDocument: (componentId: number) => boolean;
	componentIdWithActiveDocument: number | null;
	setComponentIdWithActiveDocument: (componentId: number) => void;
}

export const PartConfiguratorDocumentContext = createContext<PartConfiguratorDocumentContextValue | undefined>(
	undefined,
);
