import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	ShopOrderBatchEditApi,
	ShopOrderBatchEditApi_FormInitData,
} from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchEditApi.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateTimeField } from "src/components/common/forms/fields/FormDateTimeField.tsx";
import { ShopOrderBatch } from "src/api/generated/erp/db/types/tables/shopOrderBatch.ts";
import i18n from "i18next";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { confirmMaybeRescheduleShopOrderBatch } from "src/components/views/erp/production/shopOrder/utils/confirmRescheduleShopOrders.tsx";
import dayjs from "dayjs";
import { parseIsoDateNullable } from "src/utils/dayjsUtils.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";

export interface ShopOrderBatchFormProps extends FormCommonProps<number> {
	shopOrderBatchId?: number;
}

export const ShopOrderBatchForm = ({ shopOrderBatchId, onFormEdited, onCompleted }: ShopOrderBatchFormProps) => {
	const showConfirm = useConfirmDialog();
	const { defaultSiteId } = useGlobalInitData();
	return (
		<AsyncForm
			fetch={() => ShopOrderBatchEditApi.getFormInitData({ shopOrderBatchId: shopOrderBatchId })}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			getDefaultValues={({ shopOrderBatch }) =>
				shopOrderBatch ?? {
					siteId: defaultSiteId,
				}
			}
			submit={submitForm}
			render={({ data: { siteOptions, productionLineOptions, shopOrderBatch }, control, watch, setValue }) => {
				const siteId = watch("siteId");

				return (
					<>
						<FormSelectField
							control={control}
							name={"siteId"}
							label={i18n.t("site")}
							options={siteOptions}
							getOptionKey={(option) => option.siteId}
							getOptionLabel={(option) => option.siteName}
							rules={requireRule()}
							disabled={shopOrderBatchId != null}
							onChange={() => {
								setValue("productionLineId", null);
							}}
						/>
						<FormSelectField
							control={control}
							name={"productionLineId"}
							label={i18n.t("production_line")}
							disabled={siteId == null}
							options={productionLineOptions.filter((x) => x.siteId === siteId)}
							getOptionKey={(option) => option.productionLineId}
							getOptionLabel={(option) => option.productionLineName}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name={"batchName"}
							label={i18n.t("batch")}
							rules={requireRule()}
						/>
						<FormDateTimeField
							control={control}
							name={"plannedBeginDate"}
							label={i18n.t("planned_begin_date")}
							rules={shopOrderBatch?.plannedBeginDate != null ? requireRule() : undefined}
						/>
					</>
				);
			}}
		/>
	);

	async function submitForm(
		values: ShopOrderBatch,
		{ data }: AsyncFormContentParams<ShopOrderBatchEditApi_FormInitData, ShopOrderBatch>,
	) {
		if (shopOrderBatchId == null) {
			return await ShopOrderBatchEditApi.insert({ shopOrderBatch: values });
		} else {
			const possibleRescheduleConfirmed = confirmMaybeRescheduleShopOrderBatch({
				shopOrderBatchId: shopOrderBatchId,
				oldPlannedBeginDate: parseIsoDateNullable(data?.shopOrderBatch?.plannedBeginDate),
				newPlannedBeginDate: dayjs(values.plannedBeginDate),
				showConfirmDialog: showConfirm,
			});
			if (!possibleRescheduleConfirmed) return "interrupted";

			await ShopOrderBatchEditApi.update({
				shopOrderBatchId: shopOrderBatchId,
				batchName: values.batchName,
				plannedBeginDate: values.plannedBeginDate,
				productionLineId: values.productionLineId,
			});
			return shopOrderBatchId;
		}
	}
};
