import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { mergeSx } from "src/utils/styles.ts";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { AavoNumberField, AavoNumberFieldProps } from "../../inputFields/AavoNumberField.tsx";

export interface FormNumberFieldProps<TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>
	extends Omit<AavoNumberFieldProps, "value" | "ref" | "name">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {}

export const FormNumberField = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	control,
	name,
	rules,
	onChange,
	onSubmit,
	spanGridColumns,
	startNewGridRow,
	disabled,
	sx,
	...other
}: FormNumberFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<AavoNumberField
			inputRef={field.ref}
			error={fieldState.error?.message}
			value={field.value}
			onChange={(v: number | null, e) => {
				field.onChange(v);
				onChange?.(v, e);
			}}
			onSubmit={(v, e) => {
				onSubmit?.(v, e);
			}}
			onBlur={field.onBlur}
			disabled={disabled || field.disabled}
			name={field.name}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			{...other}
		/>
	);
};
