import { Waypoint } from "@mapbox/mapbox-sdk/services/directions";
import * as turf from "@turf/turf";
import mbxOptimization from "@mapbox/mapbox-sdk/services/optimization";
import { Coordinates } from "src/api/generated/common/address/coordinates.ts";
import { getMapboxAccessToken } from "src/config/appConfig";

export const optimizeRoute = async (waypoints: Coordinates[]): Promise<Coordinates[]> => {
	const optimization = await getOptimization(
		waypoints.map((waypoint) => ({
			coordinates: [waypoint.lng, waypoint.lat],
		})),
	);
	return optimization.waypoints
		.sort((a: any, b: any) => a.waypoint_index - b.waypoint_index)
		.map((waypoint: any) => ({
			lat: waypoint.location[1],
			lng: waypoint.location[0],
		}));
};

interface ShowRouteOnMapParams {
	map: mapboxgl.Map;
	routeId?: string;
	waypoints: Waypoint[];
	lineColor?: string;
}

export const showRouteOnMap = async ({
	map,
	routeId = "route",
	waypoints,
	lineColor,
}: ShowRouteOnMapParams) => {
	const optimization = await getOptimization(waypoints);
	const routeGeoJSON = turf.featureCollection([turf.feature(optimization.trips[0].geometry)]);

	const existingSource = map.getSource("route");

	if (existingSource && existingSource.type === "geojson") {
		existingSource.setData(routeGeoJSON);
	} else {
		map.addSource(routeId, {
			type: "geojson",
			data: routeGeoJSON,
		});
		map.addLayer(
			{
				id: routeId,
				type: "line",
				source: routeId,
				paint: {
					"line-color": lineColor,
					"line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12],
				},
			},
			"waterway-label",
		);
	}
};

const getOptimization = async (waypoints: Waypoint[]) => {
	const optimizationClient = mbxOptimization({ accessToken: getMapboxAccessToken() });
	const optimizationResponse = await optimizationClient
		.getOptimization({
			profile: "driving",
			waypoints: waypoints,
			geometries: "geojson",
		})
		.send();

	return optimizationResponse.body;
};
