import type { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { ShopOrderApi } from "src/api/generated/erp/production/api/shopOrder/shopOrderApi.ts";
import dayjs, { Dayjs } from "dayjs";
import { ShopOrder } from "src/api/generated/erp/db/types/tables/shopOrder.ts";
import { PurchaseOrderLine } from "src/api/generated/erp/db/types/tables/purchaseOrderLine.ts";
import i18n from "i18next";
import { ShopOrderBatchShopOrdersApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchShopOrdersApi.ts";

export async function confirmMaybeRescheduleShopOrderBatch({
	shopOrderBatchId,
	oldPlannedBeginDate,
	newPlannedBeginDate,
	showConfirmDialog,
}: {
	shopOrderBatchId: number;
	oldPlannedBeginDate: Dayjs | null | undefined;
	newPlannedBeginDate: Dayjs;
	showConfirmDialog: ShowConfirmDialogFunc;
}): Promise<boolean> {
	const shopOrders = await ShopOrderBatchShopOrdersApi.getShopOrderBatchShopOrders({ shopOrderBatchId });
	const shopOrderIds = shopOrders.map((shopOrder) => shopOrder.shopOrderId);
	return confirmMaybeRescheduleShopOrders({
		shopOrderIds,
		oldPlannedBeginDate,
		newPlannedBeginDate,
		showConfirmDialog,
	});
}

export async function confirmMaybeRescheduleShopOrders({
	shopOrderIds,
	oldPlannedBeginDate,
	newPlannedBeginDate,
	showConfirmDialog,
}: {
	shopOrderIds: number[];
	oldPlannedBeginDate: Dayjs | null | undefined;
	newPlannedBeginDate: Dayjs;
	showConfirmDialog: ShowConfirmDialogFunc;
}): Promise<boolean> {
	if (oldPlannedBeginDate == null || oldPlannedBeginDate.isSame(newPlannedBeginDate)) {
		return true;
	}

	const acquisitionObjects = await ShopOrderApi.getShopOrdersAcquisitionObjects({ shopOrderIds: shopOrderIds });
	const dateDifferenceDays = newPlannedBeginDate.diff(oldPlannedBeginDate, "days");

	const lateChildShopOrders = acquisitionObjects.shopOrders.filter((shopOrder) =>
		childShopOrderScheduleHasConflict(dateDifferenceDays, shopOrder),
	);
	const openPurchaseOrderLines = filterOpenPurchaseOrderLines(acquisitionObjects.purchaseOrderLines);
	const warningMessage = createWarningMessage(lateChildShopOrders, openPurchaseOrderLines);

	if (warningMessage == null) {
		return true;
	}
	return await showConfirmDialog({
		title: i18n.t("schedule_conflict"),
		message: warningMessage,
	});
}

function childShopOrderScheduleHasConflict(dateDifferenceDays: number, childShopOrder: ShopOrder): boolean {
	const firstPossibleDemandDate: Dayjs =
		dayjs(childShopOrder.plannedEndDate) ?? dayjs(childShopOrder.plannedBeginDate) ?? dayjs();

	return (
		childShopOrder.shopOrderState !== "CANCELLED" &&
		childShopOrder.shopOrderState !== "READY" &&
		dayjs(childShopOrder.demandDate).add(dateDifferenceDays, "days").isBefore(firstPossibleDemandDate)
	);
}

function filterOpenPurchaseOrderLines(purchaseOrderLines: PurchaseOrderLine[]): PurchaseOrderLine[] {
	return purchaseOrderLines.filter(
		(line) => line.purchaseOrderLineState !== "RECEIVED" && line.purchaseOrderLineState !== "CANCELLED",
	);
}

function createWarningMessage(
	lateChildShopOrders: ShopOrder[],
	openPurchaseOrderLines: PurchaseOrderLine[],
): string | null {
	let warningMessage = "";
	if (lateChildShopOrders.length > 0) {
		warningMessage += i18n.t("planned_begin_date_conflict_shop_order_message", {
			problematicShopOrderIds: lateChildShopOrders.map((shopOrder) => shopOrder.shopOrderId).join(", "),
		});
	}
	if (openPurchaseOrderLines.length > 0) {
		warningMessage +=
			"\n" +
			i18n.t("planned_begin_date_conflict_purchase_order_message", {
				problematicPurchaseOrderIds: openPurchaseOrderLines.map((line) => line.purchaseOrderId).join(", "),
			});
	}
	return warningMessage != "" ? warningMessage : null;
}
