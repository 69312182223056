import React, { useState } from "react";
import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { ConfiguratorComponentEditApi } from "src/api/generated/erp/configurator/management/api/configuratorComponentEditApi.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { filterNulls } from "src/utils/arrayUtils.ts";
import { ConfigurationComponentView } from "src/api/generated/erp/db/types/tables/configurationComponentView.ts";
import { DataDirtyStateChangeHandler } from "src/utils/dataDirtyStateChangeHandler.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";
import { ConfiguratorManagingApi_ProductFamilyVersionManagingViewInitData } from "src/api/generated/erp/configurator/management/api/configuratorManagingApi.ts";
import {
	ProductFamilyVersionManagingViewContextValue,
	ProductFamilyVersionManagingViewContext, OnDropComponentParams, SelectComponentParams
} from "./ProductFamilyVersionManagingViewContext";

export interface ProductFamilyVersionManagingViewContextProviderProps {
	productFamilyVersion: ConfigurationProductFamilyVersion;
	initialComponents: ConfigurationComponentView[];
	onDirtyStateChanged: DataDirtyStateChangeHandler;
	refresh: () => Promise<ConfiguratorManagingApi_ProductFamilyVersionManagingViewInitData>;
	children: React.ReactNode;
}

export const ProductFamilyVersionManagingViewContextProvider = ({
	productFamilyVersion: productFamilyVersionInitial,
	initialComponents,
	refresh: refreshProp,
	onDirtyStateChanged,
	children,
}: ProductFamilyVersionManagingViewContextProviderProps) => {
	const showConfirmDialog = useConfirmDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [productFamilyVersion, setProductFamilyVersion] =
		useState<ConfigurationProductFamilyVersion>(productFamilyVersionInitial);
	const [allComponents, setAllComponents] = useState<ConfigurationComponentView[]>(initialComponents);
	const [selectedComponentIds, setSelectedComponentIds] = useState<number[]>([]);
	const [focusedDataIsDirty, setFocusedDataIsDirty] = useState(false);

	const findComponent = (id: number) => allComponents.find((c) => c.configurationComponentId === id);

	const selectedComponents = filterNulls(selectedComponentIds.map(findComponent));

	const contextValue: ProductFamilyVersionManagingViewContextValue = {
		productFamilyVersion,
		allComponents,
		selectedComponents,
		selectComponent,
		unselectAllComponents,
		updateComponent,
		focusedDataIsDirty,
		setFocusedDataIsDirty: (isDirty) => {
			setFocusedDataIsDirty(isDirty);
			onDirtyStateChanged({ isDirty });
		},
		getOrderNumForNewTab,
		addComponent,
		deleteComponent,
		onComponentDropped,
		moveComponentsToAnotherTab,
		refresh,
		limitedEditing: productFamilyVersion.state === "PUBLISHED" && !productFamilyVersion.unrestrictedEditing,
	};

	return (
		<ProductFamilyVersionManagingViewContext.Provider value={contextValue}>
			{children}
		</ProductFamilyVersionManagingViewContext.Provider>
	);

	async function refresh() {
		const newData = await refreshProp();
		setAllComponents(newData.components);
		setProductFamilyVersion(newData.productFamilyVersion);
	}

	async function selectComponent({
		component,
		keepExistingSelections,
	}: SelectComponentParams): Promise<undefined | "cancelled"> {
		const maybeCancelled = await maybeCancelComponentSelection();
		if (maybeCancelled === "cancelled") return "cancelled";

		const isCurrentlySelected = selectedComponentIds.includes(component.configurationComponentId);
		const newSelection =
			keepExistingSelections ?
				selectedComponentIds
					.filter((id) => id !== component.configurationComponentId)
					.concat(isCurrentlySelected ? [] : component.configurationComponentId)
			:	[component.configurationComponentId];
		setSelectedComponentIds(newSelection);
	}

	async function unselectAllComponents(): Promise<undefined | "cancelled"> {
		const maybeCancelled = await maybeCancelComponentSelection();
		if (maybeCancelled === "cancelled") return "cancelled";

		setSelectedComponentIds([]);
	}

	async function maybeCancelComponentSelection(): Promise<undefined | "cancelled"> {
		if (focusedDataIsDirty) {
			const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
			if (!confirmed) return "cancelled";
		}
		setFocusedDataIsDirty(false);
		return undefined;
	}

	async function updateComponent(newValues: ConfigurationComponent) {
		const updatedComponent = await ConfiguratorComponentEditApi.updateComponent({
			component: newValues,
		});
		await refresh();
		setFocusedDataIsDirty(false);
		return updatedComponent;
	}

	async function addComponent(newComponent: Partial<ConfigurationComponent>) {
		const saved = await ConfiguratorComponentEditApi.insertComponent({
			component: newComponent as ConfigurationComponent,
		});
		await refresh();
		return saved;
	}

	async function onComponentDropped({ dragItem, targetPosition, parentTabComponentId }: OnDropComponentParams) {
		try {
			switch (dragItem.type) {
				case "existingComponent": {
					await moveComponent(dragItem.componentId, targetPosition);
					break;
				}
				case "newComponent": {
					const componentProps =
						dragItem.componentProps === undefined ? {}
						: typeof dragItem.componentProps === "function" ? await dragItem.componentProps()
						: dragItem.componentProps;
					if (componentProps === "cancelled") return;

					const namePrefix = componentProps.name ?? "component";
					const name = `${namePrefix}-${allComponents.length + 1}`;

					await addComponent({
						label: name,
						...componentProps,
						name: name,
						productFamilyVersionId: productFamilyVersion.configurationProductFamilyVersionId,
						componentType: dragItem.componentType,
						parentTabComponentId: parentTabComponentId,
						orderNum: targetPosition,
					});
					break;
				}
			}
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function deleteComponent(componentId: number) {
		await ConfiguratorComponentEditApi.deleteComponent({
			componentId,
		});
		setAllComponents((prev) => prev.filter((c) => c.configurationComponentId !== componentId));
	}

	async function moveComponent(componentId: number, newPosition: number) {
		await ConfiguratorComponentEditApi.moveComponentToPosition({
			componentId,
			newPosition,
		});
		await refresh();
	}

	async function moveComponentsToAnotherTab(componentIds: number[], newTabId: number) {
		await ConfiguratorComponentEditApi.moveComponentsToTab({
			componentIds: componentIds,
			newParentTabId: newTabId,
		});
		await refresh();
	}

	function getOrderNumForNewTab(): number {
		if (allComponents.length === 0) return 0;

		const currentMax = Math.max(...allComponents.filter((c) => c.componentType === "TAB").map((c) => c.orderNum));
		return currentMax + 1;
	}
};
