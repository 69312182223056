import React, { ReactNode, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { treeItemClasses } from "@mui/x-tree-view";
import { getMainMenuItemLabel, getMainMenuItemTreeViewNodeKey, MainMenuItem } from "./mainMenuItem";
import { join_paths } from "src/utils/paths";
import { VIEWS } from "src/urls";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { TooltipWithWrapper } from "src/components/common/tooltips/TooltipWithWrapper.tsx";
import { ErrorView } from "src/components/common/errors/ErrorView.tsx";
import i18n from "i18next";
import { useFrontendViewsContext } from "src/components/views/frontendViews/useFrontendViewsContext.ts";
import { ResolvedFrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { TreeItem2, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { mergeSx } from "src/utils/styles.ts";
import { faQuestionCircle, faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { AavoTreeView } from "src/components/common/tree/AavoTreeView.tsx";

export interface MainMenuTreeViewProps {
	menuItems: MainMenuItem[];
	openNodes: string[];
	setOpenNodes: (nodeIds: string[]) => void;
	defaultSelectedItem: MainMenuItem | null;
	onViewMenuItemClicked: () => void;
	favoriteViewKeys: string[];
	addViewToFavorites: (viewKey: string) => void;
	removeViewFromFavorites: (viewKey: string) => void;
}

export const MainMenuTreeView = ({
	menuItems,
	openNodes,
	setOpenNodes,
	defaultSelectedItem,
	onViewMenuItemClicked,
	favoriteViewKeys,
	addViewToFavorites,
	removeViewFromFavorites,
}: MainMenuTreeViewProps) => {
	const [selectedNodeId, setSelectedNodeId] = useState<string | null>(
		defaultSelectedItem === null ? null : getMainMenuItemTreeViewNodeKey(defaultSelectedItem),
	);

	const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollToSelectedElement(scrollContainerRef.current);
		}
	}, []);

	if (menuItems.length === 0) return <CenteredTypography children={i18n.t("no_views_found")} />;

	return (
		<MainMenuTreeViewContext.Provider
			value={{
				favoriteViewKeys,
				onViewMenuItemClicked,
				addViewToFavorites,
				removeViewFromFavorites,
			}}
		>
			<Box
				ref={scrollContainerRef}
				sx={{
					overflowY: "auto",
					alignSelf: "stretch",
					flex: "1 1",
				}}
			>
				<AavoTreeView<MainMenuItem>
					items={menuItems}
					slots={{
						item: CustomTreeItem as any,
					}}
					getItemId={getMainMenuItemTreeViewNodeKey}
					getItemLabel={getMainMenuItemLabel}
					expandOnlyOnIconClick={false}
					expandedItems={openNodes}
					onExpandedItemsChange={setOpenNodes}
					selectedItems={selectedNodeId}
					onSelectedItemsChange={(_: React.SyntheticEvent, itemId: string | null) => {
						setSelectedNodeId(itemId);
					}}
					hideActionBar
					sx={{
						[`& .${treeItemClasses.content}`]: {
							flexDirection: "row-reverse",
							paddingY: "0.125rem",
						},
					}}
				/>
			</Box>
		</MainMenuTreeViewContext.Provider>
	);
};

interface MainMenuTreeViewContextValue {
	favoriteViewKeys: string[];
	onViewMenuItemClicked: () => void;
	addViewToFavorites: (viewKey: string) => void;
	removeViewFromFavorites: (viewKey: string) => void;
}

const MainMenuTreeViewContext = React.createContext<MainMenuTreeViewContextValue | undefined>(undefined);

const useMainMenuTreeViewContext = () => useContextOrThrow(MainMenuTreeViewContext);

const CustomTreeItem = React.memo((props: TreeItem2Props) => {
	const { itemId } = props;
	const { publicAPI } = useTreeItem2(props);
	const item = publicAPI.getItem(itemId) as MainMenuItem;

	return (
		<TreeItem2
			{...props}
			slots={{
				label: CustomTreeItemLabel,
			}}
			slotProps={{
				label: {
					item,
				} as any,
				iconContainer: {
					style: {
						display: item.type === "viewGroup" ? undefined : "none",
					},
				},
			}}
		/>
	);
});

interface CustomTreeItemLabelProps {
	item: MainMenuItem;
}

const CustomTreeItemLabel = ({ item }: CustomTreeItemLabelProps) => {
	const { palette } = useTheme();
	return (
		<HorizontalBox
			sx={{
				flex: 1,
				[`&:hover .${FAVORITE_BUTTON_CLASS}`]: {
					opacity: 1,
					transitionDelay: "0.5s",
				},
			}}
		>
			<TreeItemLabelContentWrapper item={item}>
				<FontAwesomeIcon icon={["far", item.icon]} color={palette.grey[900]} />
				<ItemLabelContent item={item} />
			</TreeItemLabelContentWrapper>
			<ItemHelpTextIcon item={item} />
			<ToggleFavoriteButton item={item} />
		</HorizontalBox>
	);
};

interface TreeItemLabelContentWrapperProps {
	item: MainMenuItem;
	children: ReactNode;
}

const TreeItemLabelContentWrapper = ({ item, children }: TreeItemLabelContentWrapperProps) => {
	const { getFrontendViewByKey } = useFrontendViewsContext();
	const { onViewMenuItemClicked } = useMainMenuTreeViewContext();

	const commonSx = {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		alignItems: "center",
		minHeight: "2.5rem",
		marginY: "0.1rem",
		flex: 1,
	};

	switch (item.type) {
		case "view":
			return (
				<Link
					component={RouterLink}
					to={getViewItemUrl(item, getFrontendViewByKey)}
					sx={mergeSx(commonSx)}
					underline={"hover"}
					color={"black"}
					onClick={onViewMenuItemClicked}
				>
					{children}
				</Link>
			);
		case "viewGroup":
			return <Box sx={mergeSx(commonSx)}>{children}</Box>;
	}
};

const ItemLabelContent = ({ item }: { item: MainMenuItem }) => {
	const label = item.label;

	const typographyProps = {
		fontWeight: item.type === "viewGroup" && item.parentKey == null ? "bold" : "normal",
	};

	if (typeof label === "string") return <Typography {...typographyProps}>{label}</Typography>;

	if (label.loading) return <AavoLoading />;
	if (label.error)
		return (
			<TooltipWithWrapper title={<ErrorView error={label.error} />}>
				<Typography {...typographyProps}>{i18n.t("error")}</Typography>
			</TooltipWithWrapper>
		);

	return <Typography {...typographyProps}>{label.data ?? ""}</Typography>;
};

const FAVORITE_BUTTON_CLASS = "toggle-favorite-button";
const ToggleFavoriteButton = ({ item }: { item: MainMenuItem }) => {
	const { favoriteViewKeys, addViewToFavorites, removeViewFromFavorites } = useMainMenuTreeViewContext();

	if (item.type === "viewGroup") return null;

	const isFavorite = favoriteViewKeys.includes(item.key);

	return (
		<AavoButton
			className={FAVORITE_BUTTON_CLASS}
			sx={{
				opacity: item.isFavoritesGroupShortcutItem ? 1 : 0,
				transition: "opacity 0.3s",
				padding: 0.5,
				alignSelf: "center",
			}}
			tooltip={isFavorite ? i18n.t("remove_from_favorites") : i18n.t("add_to_favorites")}
			iconEl={<FontAwesomeIcon icon={isFavorite ? faStarSolid : faStarRegular} size={"2xs"} />}
			onClick={() => {
				if (isFavorite) {
					removeViewFromFavorites(item.key);
				} else {
					addViewToFavorites(item.key);
				}
			}}
		/>
	);
};

const ItemHelpTextIcon = ({ item }: { item: MainMenuItem }) => {
	if (!item.helpText) return null;

	return (
		<TooltipWithWrapper title={item.helpText}>
			<FontAwesomeIcon
				icon={faQuestionCircle}
				style={{
					alignSelf: "center",
					paddingRight: "0.25rem",
				}}
			/>
		</TooltipWithWrapper>
	);
};

const getViewItemUrl = (
	view: MainMenuItem,
	getFrontendViewByUrl: (url: string) => ResolvedFrontendViewConfig | undefined,
): string => {
	const frontendView = getFrontendViewByUrl(view.key);
	if (frontendView) {
		return frontendView.url;
	} else {
		return join_paths([VIEWS, view.key]);
	}
};

const scrollToSelectedElement = (scrollContainer: HTMLDivElement) => {
	// A bit hacky way to find the selected element.
	const selectedElement = scrollContainer.querySelector(".MuiTreeItem-content.Mui-selected");
	if (selectedElement) {
		selectedElement.scrollIntoView({ block: "center" });
	}
};
