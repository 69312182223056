import {
    MellanoComponentProductionStatusViewApi,
    MellanoComponentProductionStatusViewApi_Order,
} from "src/api/generated/tenants/mellano/api/mellanoComponentProductionStatusViewApi.ts";
import {coloredBooleanColumn, dateTimeColumn, integerColumn,} from "src/components/common/dataGrid/columns.tsx";
import {dataGridPersistentStateProps} from "src/components/common/dataGrid/dataGridStateStorage.ts";
import {useState} from "react";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {useAsyncFetch} from "src/utils/async/asyncFetch.ts";
import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {ControlledAsyncDataGrid} from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import {useServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {MellanoComponentBatch} from "../../../../api/generated/tenants/mellano/models/mellanoComponentBatch.ts";

export interface ComponentProductionStatusViewProps {}

export const ComponentProductionStatusView = ({}: ComponentProductionStatusViewProps) => {
	const [selectedOrder, setSelectedOrder] = useState<
		MellanoComponentProductionStatusViewApi_Order | undefined
	>(undefined);

	return (
		<AavoMosaic
			viewId={"B9B65A000C35295"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: "Tilaukset",
					content: <OrdersDataGrid onSelectionChanged={setSelectedOrder} />,
				},
				second: {
					type: "panel",
					title: "Työmääräimet",
					content:
						selectedOrder === undefined ? (
							<CenteredTypography>Valitse tilaus</CenteredTypography>
						) : (
							<ComponentBatchesDataGrid
								key={selectedOrder?.orderNum}
								selectedOrderNum={selectedOrder?.orderNum}
							/>
						),
				},
			}}
		/>
	);
};

interface OrdersDataGridProps {
	onSelectionChanged: (
		selectedOrder: MellanoComponentProductionStatusViewApi_Order | undefined,
	) => void;
}

const OrdersDataGrid = ({ onSelectionChanged }: OrdersDataGridProps) => {
	const { dataGridProps, refreshData } = useServerSideDataGridModel({
		fetchData: MellanoComponentProductionStatusViewApi.getOrders,
		gridId: "1A77FB95564135D4",
		getRowId: (row) => row.orderNum,
		initialParams: {
			orderNumFilter: "",
		},
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
		defaultGridState: {
			pagination: {
				paginationModel: {
					pageSize: 10,
				},
			},
		},
	});

	return (
		<ControlledAsyncCrudDataGrid<MellanoComponentProductionStatusViewApi_Order>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "orderNum",
					headerName: "Tilausnumero",
					width: 120,
				}),
				integerColumn({
					field: "componentBatchCount",
					headerName: "Työmääräimiä",
					width: 120,
				}),
				coloredBooleanColumn({
					field: "completed",
					headerName: "Valmis",
					falseColor: "grey",
				}),
			]}
			actionBarComponents={
				<>
					<AavoTextField
						label={"Tilausnumero"}
						onSubmit={(value) =>
							refreshData({
								orderNumFilter: value,
							})
						}
					/>
				</>
			}
			{...dataGridProps}
		/>
	);
};

interface ComponentBatchesDataGridProps {
	selectedOrderNum: number;
}

const ComponentBatchesDataGrid = ({ selectedOrderNum }: ComponentBatchesDataGridProps) => {
	const [rowsAsync, refresh] = useAsyncFetch(() =>
		MellanoComponentProductionStatusViewApi.getOrderComponentBatches({
			orderNumber: selectedOrderNum,
		}),
	);

	return (
		<ControlledAsyncDataGrid<MellanoComponentBatch>
			rowsAsync={rowsAsync}
			refreshData={refresh}
			getRowId={(row) => row.batchCode!}
			columns={[
				integerColumn({
					field: "batchCode",
					headerName: "Työmääräin",
					width: 200,
				}),
				dateTimeColumn({
					field: "startDate",
					headerName: "Aloitusaika",
					width: 150,
				}),
				integerColumn({
					field: "kanbanQuantity",
					headerName: "Kanban määrä",
					width: 150,
				}),
				integerColumn({
					field: "specialsQuantity",
					headerName: "Erik. määrä",
					width: 150,
				}),
				coloredBooleanColumn({
					field: "completed",
					headerName: "Valmis",
					width: 150,
					falseColor: "grey",
				}),
			]}
			{...dataGridPersistentStateProps("718EA26A558FEBC4")}
		/>
	);
};
