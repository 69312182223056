import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { Typography } from "@mui/material";
import i18n from "i18next";

export interface CatalogPartRevisionUnrestrictedEditingWarningProps {
	revision: CatalogPartRevisionView | undefined | null;
}

export const CatalogPartRevisionUnrestrictedEditingWarning = ({
	revision,
}: CatalogPartRevisionUnrestrictedEditingWarningProps) => {
	if (revision == null) return;
	if (revision.state === "INITIAL") return;
	if (!revision.unrestrictedEditingCascaded) return;

	return (
		<Typography
			variant={"subtitle1"}
			color={"error"}
			children={i18n.t("part_revision_unrestricted_editing_warning")}
			sx={{
				marginY: 0.5,
				marginX: 1.5,
			}}
		/>
	);
};
