import { useCallback } from "react";
import { StoredStateType, useStoredState, UseStoredStateParams } from "src/utils/useStoredState.ts";
import { UseMaybeControlledParams, useMaybeControlledState } from "./useMaybeControlledState.ts";

export interface UseMaybeStoredAndMaybeControlledStateParams<T extends StoredStateType>
	extends UseMaybeControlledParams<T>,
		Omit<UseStoredStateParams<T>, "defaultValue" | "key"> {
	key?: string; // Stored if not undefined
}

/**
 * State which is always stored in local storage and maybe controlled by a parent component.
 */
export const useMaybeStoredAndMaybeControlledState = <T extends StoredStateType>({
	controlledValue,
	onChange,
	defaultValue,
	key,
	type,
}: UseMaybeStoredAndMaybeControlledStateParams<T>): [state: T, setState: (newState: T) => void] => {
	const [storedState, setStoredState] = useStoredState<T>({
		defaultValue: defaultValue,
		key: key ?? "__useMaybeStoredStateFallback__",
		type: type,
	});

	const [maybeControlledState, setMaybeControlledState] = useMaybeControlledState({
		controlledValue: controlledValue,
		onChange: onChange,
		defaultValue: key != undefined ? storedState : defaultValue,
	});

	const setState = useCallback(
		(newState: T) => {
			if (key != undefined) {
				setStoredState(newState);
			}
			setMaybeControlledState(newState);
		},
		[setStoredState, setMaybeControlledState, key],
	);

	return [maybeControlledState, setState];
};
