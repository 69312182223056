import { getFrontendReleaseCode } from "src/config/appConfig.ts";
import { Button, Typography } from "@mui/material";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { unregisterServiceWorkers } from "src/utils/serviceWorkerUtils.ts";
import { ParsedError } from "src/errorHandling/errorParsing.ts";

export interface OutdatedFrontendVersionOnErrorViewProps {
	error: ParsedError;
}

export const OutdatedFrontendVersionOnErrorView = ({ error }: OutdatedFrontendVersionOnErrorViewProps) => {
	const userFrontendVersion = getFrontendReleaseCode();
	const latestFrontendVersion = error.latestFrontendVersion;
	if (latestFrontendVersion === "") return null;
	if (userFrontendVersion === latestFrontendVersion) return null;

	return (
		<>
			<Typography
				sx={{
					textAlign: "center",
					marginTop: 1,
					fontWeight: "bold",
				}}
			>
				{i18n.t("outdated_frontend_version_message_on_error")}
			</Typography>
			<Button
				variant={"outlined"}
				startIcon={<FontAwesomeIcon icon={faRefresh} />}
				onClick={async () => {
					await unregisterServiceWorkers();
					window.location.reload();
				}}
			>
				<Typography variant={"button"}>{i18n.t("update_app_now")}</Typography>
			</Button>
		</>
	);
};
