import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import i18n from "i18next";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { useState } from "react";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CatalogPartRevisionDocumentConfigurationRulesView } from "src/components/views/erp/configurator/managing/catalogPart/CatalogPartRevisionDocumentConfigurationRulesView.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";
import { getOnlyMember } from "src/utils/arrayUtils.ts";

export interface CatalogPartRevisionDocumentsViewProps {
	catalogPartRevision: CatalogPartRevisionView;
}

export const CatalogPartRevisionDocumentsView = ({
	catalogPartRevision,
}: CatalogPartRevisionDocumentsViewProps) => {
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentConnectionView[]>([]);
	const [rulesFormIsDirty, setRulesFormIsDirty] = useState(false);

	const showConfirmDialog = useConfirmDialog();

	const setSelectedDocumentWrapped = async (docs: DocumentConnectionView[]) => {
		if (rulesFormIsDirty) {
			const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
			if (!confirmed) return;
		}
		setRulesFormIsDirty(false);
		setSelectedDocuments(docs);
	};
	const onlySelectedDocument = getOnlyMember(selectedDocuments);

	return (
		<DocumentsOfSingleObjectView
			objectRef={{
				objectType: "CATALOG_PART_REVISION",
				objectId: catalogPartRevision.catalogPartRevisionId,
			}}
			viewId={"D19C10F84BD5E65B"}
			selectedDocuments={selectedDocuments}
			setSelectedDocuments={setSelectedDocumentWrapped}
			extraViews={[
				{
					type: "panel",
					hidden: !catalogPartRevision.isConfigurable,
					title: i18n.t("configurator_rules"),
					content:
						onlySelectedDocument == null ?
							<CenteredTypography>{i18n.t("select_a_document")}</CenteredTypography>
						:	<CatalogPartRevisionDocumentConfigurationRulesView
								key={onlySelectedDocument.documentId}
								document={onlySelectedDocument}
								catalogPartRevision={catalogPartRevision}
								onDirtyStateChanged={setRulesFormIsDirty}
							/>,
				},
			]}
		/>
	);
};
