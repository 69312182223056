import type { ShowConfirmDialogFunc } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import i18n from "i18next";
import { CustomerOrderQueryApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";
import { CustomerOrderActionApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderActionApi.ts";

export async function cancelCustomerOrder({
	customerOrderId,
	showConfirmDialog,
}: {
	customerOrderId: number;
	showConfirmDialog: ShowConfirmDialogFunc;
}): Promise<boolean> {
	const confirmed = await showConfirmDialog({
		title: i18n.t("order_cancellation"),
		message: i18n.t("are_you_sure"),
	});
	if (!confirmed) return false;

	const invoices = await CustomerOrderQueryApi.getCustomerOrderInvoices({
		customerOrderId,
	});
	if (invoices.length > 0) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("order_cancellation"),
			message: i18n.t("customer_order_has_invoices_are_you_sure"),
		});
		if (!confirmed) return false;
	}

	const hasApprovedCommissionLines = await CustomerOrderQueryApi.getCustomerOrderHasApprovedCommissionLines(
		{
			customerOrderId,
		},
	);
	if (hasApprovedCommissionLines) {
		const confirmed = await showConfirmDialog({
			title: i18n.t("order_cancellation"),
			message: i18n.t("confirm_cancel_customer_order.has_approved_commission_lines"),
		});
		if (!confirmed) return false;
	}

	await CustomerOrderActionApi.cancelCustomerOrder({
		customerOrderId: customerOrderId,
	});
	return true;
}
