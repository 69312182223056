// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { CustomerOrder } from "src/api/generated/erp/db/types/tables/customerOrder";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderCopyFormApi {
	export async function getFormInitData(args: {
		customerOrderId: number;
	}): Promise<CustomerOrderCopyFormApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/customerOrderCopyForm/getFormInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as CustomerOrderCopyFormApi_FormInitData;
	}

	export async function copyCustomerOrder(args: {
		sourceCustomerOrderId: number;
		targetCustomerId: number;
		targetPlannedDeliveryDate: IsoDateString;
		targetDeliveryAddressId: number;
		targetInvoiceAddressId: number;
		targetSiteId: number;
		copyTasks: boolean;
		copyLines: boolean;
		copyDocuments: boolean;
		copyConfigurations: boolean;
		copyBillingPlan?: boolean;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/sales/customerOrderCopyForm/copyCustomerOrder",
			method: "POST",
			data: {
				sourceCustomerOrderId: args.sourceCustomerOrderId,
				targetCustomerId: args.targetCustomerId,
				targetPlannedDeliveryDate: args.targetPlannedDeliveryDate,
				targetDeliveryAddressId: args.targetDeliveryAddressId,
				targetInvoiceAddressId: args.targetInvoiceAddressId,
				targetSiteId: args.targetSiteId,
				copyTasks: args.copyTasks,
				copyLines: args.copyLines,
				copyDocuments: args.copyDocuments,
				copyConfigurations: args.copyConfigurations,
				copyBillingPlan: args.copyBillingPlan,
			},
		});
		return response as number;
	}
}

export interface CustomerOrderCopyFormApi_FormInitData {
	siteOptions: Array<Site>;
	sourceOrder: CustomerOrder;
}
