import { ControlChartGraphRecordDto } from "src/api/generated/spc/graphs/controlChartGraphRecordDto.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
	openLegacyConnectSpcRecordToImprovementView,
	openLegacyControlChartLimitCalculationForm,
	openLegacyProcessImprovementView,
} from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import i18n from "i18next";
import { faBan, faCalculator, faWrench, faSquareRootAlt, faLink } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import dayjs from "dayjs";
import { askInputAndOpenControlChartCapabilityView } from "src/components/views/spc/controlChart/utils.tsx";
import { SpcRecordActionForm } from "src/components/views/spc/controlChart/controlChartInspectingView/SpcRecordActionForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ControlChartInspectingViewContext } from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingViewContext.ts";
import { useContext } from "react";
import { ControlChartCommonApi } from "src/api/generated/spc/controlChart/api/controlChartCommonApi.ts";

export interface ControlChartGraphDataPointContextMenuProps {
	record: ControlChartGraphRecordDto;
	refreshGraph: () => Promise<unknown>;
}

export const ControlChartGraphDataPointContextMenu = ({
	record,
	refreshGraph,
}: ControlChartGraphDataPointContextMenuProps) => {
	const openLegacyView = useOpenLegacyView();
	const { openDialog } = useGenericDialog();
	const showInputDialog = useInputDialog();
	const { tenantConfig } = useTenantCustomizations();

	const { unresolvedSpecialCausesDataGridRefreshRef } = useContext(ControlChartInspectingViewContext) ?? {};

	const {
		recordId,
		controlChartId,
		processImprovementId,
		savedSpecialCauseCode,
		chartName,
		observationTime,
	} = record;

	return [
		<AsyncMenuButton
			key={"disableRecord"}
			label={i18n.t("disable")}
			icon={faBan}
			onClick={async () => {
				await ControlChartCommonApi.disableRecord({
					recordId: recordId,
				});
				await refreshGraph();
			}}
		/>,
		<AsyncMenuButton
			key={"action"}
			label={i18n.t("spc_action")}
			icon={faWrench}
			onClick={() => {
				openDialog(({ closeDialog, onContentEdited }) => ({
					title: i18n.t("spc_action"),
					size: "sm",
					content: (
						<SpcRecordActionForm
							recordIds={[recordId]}
							onFormEdited={onContentEdited}
							onCompleted={async () => {
								await closeDialog();
								await Promise.all([
									refreshGraph(),
									unresolvedSpecialCausesDataGridRefreshRef?.refresh(),
								]);
							}}
						/>
					),
				}));
			}}
		/>,
		<AsyncMenuButton
			key={"calculateLimits"}
			label={i18n.t("calculate_control_limits")}
			icon={faCalculator}
			onClick={() => {
				openLegacyControlChartLimitCalculationForm({
					openLegacyView,
					controlChartId: controlChartId,
					recordId: recordId,
					refreshSource: () => refreshGraph(),
				});
			}}
		/>,
		<AsyncMenuButton
			key={"openStatisticsView"}
			label={i18n.t("capability")}
			icon={faSquareRootAlt}
			onClick={async () => {
				await askInputAndOpenControlChartCapabilityView({
					showInputDialog,
					openLegacyView,
					controlChartId: controlChartId,
					chartName: chartName,
					beforeDate: dayjs(observationTime),
				});
			}}
		/>,
		tenantConfig.spc.processImprovementsEnabled && [
			savedSpecialCauseCode != null && processImprovementId == null && (
				<AsyncMenuButton
					key={"connectToProcessImprovement"}
					label={i18n.t("connect_to_an_improvement")}
					icon={faLink}
					onClick={() => {
						openLegacyConnectSpcRecordToImprovementView({
							openLegacyView,
							recordId: recordId,
							refreshSource: () => refreshGraph(),
						});
					}}
				/>
			),
			processImprovementId != null && (
				<AsyncMenuButton
					key={"openImprovement"}
					label={i18n.t("open_improvement")}
					icon={faLink}
					onClick={() => {
						openLegacyProcessImprovementView({
							openLegacyView,
							processImprovementId: processImprovementId!,
						});
					}}
				/>
			),
		],
		tenantConfig.documentsEnabled && (
			<DocumentsOfObjectButton
				key={"openRecordDocuments"}
				objectRef={{
					objectType: "SPC_RECORD",
					objectId: recordId,
				}}
				label={i18n.t("documents")}
				variant={"menu"}
			/>
		),
	];
};
